import { observable, action, computed, runInAction, toJS, comparer } from 'mobx';
import { http, eq, myLocalStorage, copy, compareArr, Log, getCarTreeNodeId, } from 'src/utils';
import { DeviceType, Online, } from 'src/models';
import { FetchStatus, } from 'src/types';
import _ from 'lodash';

const concernCarStr = 'concernCarsObj_';
const videoCountList = [1, 4, 6, 8, 9, 13, 16];

interface SetDev {
  deviceId: string,
  devNo: string,
}

interface RightClickVeh {
  veh: CarTreeVeh,
  position: [number, number],
}

interface OrgTreeParams {
  plateNo?: string,
  deviceId?: string,
  online?: number,
  deviceType?: string,
  orgName?: string,
}

export class ComDeviceStore {

  // node 节点 包括 机构、车辆、设备、通道等
  // org 机构 veh 车 

  allNodeObj: { [nodeId: string]: CarTreeNodeVehOrOrg } = {};  // key nodeId  所有数据唯一存放的地方
  @observable.shallow nodeTreeMap = observable.map<string, CarTreeNodeVehOrOrg>(); // 设备树数据 nodeId
  @observable batchDispatchCarMap = observable.map<string, CarTreeVeh>();     // 批量下发车辆的keys; nodeId

  @observable carListCheckedKeys: string[] = [];     // 设备树选择的keys nodeId

  @observable.shallow concernCarsMap = observable.map<string, CarTreeVeh>(); // 关注车辆数据 nodeId
  @observable concernListCheckedKeys: string[] = []; // 关注列表选择的keys; nodeId

  @observable.ref firstDeviceRoot: CarTreeNode | undefined;
  @observable fetchStatus: FetchStatus = 'none';

  @observable.shallow selectedNode: CarTreeNode[] = []; // 鼠标点击选中的

  @observable concernCarsDetail: CarTreeVeh[] = [];
  @observable lookedVehStatus: CarTreeNode | undefined = undefined;
  @observable lookDevTalk: CarTreeNode | undefined = undefined;  // 设备语音对讲

  @observable search = {
    type: 'vehicle',
    searchStr: '',
    online: -1,
    deviceType: 0,
  };

  @observable dispatchVoiceCar: CarTreeVeh | undefined;

  @observable rightClickVeh: RightClickVeh | undefined;


  // 车辆数组件的展开的key nodeId
  @observable treeExpandedKeys: string[] = [];
  // 是否自动展开父节点
  @observable autoExpandParent = true;
  // 车辆列表/关注
  @observable carListTabKey = '1';

  userId = '';

  @observable dispatchInstructShow: boolean = false; //是否右键指令下发显示弹窗
  @observable.shallow insModalCarsMap = observable.map<string, CarTreeVeh>(); // 指令下发弹框中的车辆 key nodeId
  @observable selectedCarId: string = ''; // 指令下发弹框中的车辆 勾选车辆的nodeId
  @observable dispatchedInsMap = observable.map<string, DispathcInsRecord>(); // 下发的指令记录 key msgId
  @observable insResMap = observable.map<string, InsResource>(); // 下发的指令返回的资源 key vehId+time

  @observable insResTs = 0;  // 媒体列表最新时间
  @observable selectedInsKey = '';

  showParamSetMenu = false;
  @observable paramSetDev: SetDev | undefined = undefined;

  // 车辆选择弹框
  @observable showCarSelect = true;
  @observable carSelectTotal = 0;
  @observable carSelectPage = 0;
  @observable carSelectVehMap = observable.map<string, CarTreeVeh>(); // key vehId,
  @observable carSelectItem: CarTreeVeh | undefined = undefined;
  @observable carSelectFetchStatus: FetchStatus = 'none';


  @observable hasAudio = false;
  @observable streamType = 0;
  @observable.ref videoUrls: { [index: number]: string } = {}; // deviceId_channelNo_devType_Date.now()
  @observable.ref videos: RTVSVideos = {}; // 
  @observable videoCount = 4;
  videoIns: any;
  @observable videoActiveIndex: undefined | number = undefined;
  @observable isJTVideo: boolean | undefined = true;   // 记录当前是不是部标设备播放视频
  @observable tableTabKey = '1'


  constructor() {

  }

  @computed
  get videoUrlIndexs() {
    return Object.keys(toJS(this.videoUrls));
  }

  @action
  changeIsJTVideo = (is: boolean) => {
    this.isJTVideo = is;
  }

  @action
  changeTableTabKey = (key: string) => {
    this.tableTabKey = key;
  }

  @action
  addVideoUrls = (urls: string[]) => {
    if (eq(urls.length, 1) && !eq(this.videoActiveIndex, undefined)) {
      const src = urls[0];
      this.videoUrls[this.videoActiveIndex!] = src;
    } else {
      // 如果视频窗口不够新增视频窗口
      const newVideoCount = this.getNextVideoWinCount(urls.length + this.videoUrlIndexs.length);
      this.changeVideoCount(newVideoCount);

      const countArr = new Array(this.videoCount).fill(1).map((x, i) => i);
      const filledKeys = this.videoUrlIndexs;
      const emptyKeys = countArr.filter(x => !filledKeys.includes(x.toString()));
      const emptyKeysLen = emptyKeys.length;

      for (let j = 0, len = urls.length; j < len; j++) {
        // 如果没有多余的窗口就从0开始
        const effectiveIndex = j < emptyKeysLen ? emptyKeys[j] : (j - emptyKeysLen) % this.videoCount;
        const src = urls[j];
        this.videoUrls[effectiveIndex] = src;
      }
    }
    this.changeVideoUrls(copy(this.videoUrls));
  }

  @action
  changeVideoUrls = (urls: { [index: number]: string }) => {
    this.videoUrls = urls;

    // 当前视频窗口不够预览全部时，增加窗口
    let count = Object.keys(urls).length;
    if (count > this.videoCount) {
      let i = 0;
      while (videoCountList[i] < count) {
        i++;
      }
      this.changeVideoCount(videoCountList[i]);
    }
  }

  @computed
  get videoIndexs() {
    if (this.isJTVideo) {
      return Object.keys(toJS(this.videos));
    } else {
      return Object.keys(toJS(this.videoUrls));
    }
  }

  @computed
  get carSelectVehArr() {
    return Array.from(this.carSelectVehMap.values());
  }

  @action
  setCarSelectItem = (veh: CarTreeVeh) => {
    this.carSelectItem = veh;
  }

  @action
  setShowCarSelect = (show: boolean) => {
    this.showCarSelect = show;
  }

  @computed
  get batchDispatchCarArr() {
    return Array.from(this.batchDispatchCarMap.values());
  }

  @computed
  get dispatchedInsArr() {
    const insArr =  Array.from(this.dispatchedInsMap.values())
    return insArr.sort((a, b) => b.createTime - a.createTime)
      .map((x, i) => {
        x['index'] = insArr.length - i;
        return x;
      })
  }

  @computed
  get insResArr() {
    return Array.from(this.insResMap.values())
      .sort((a, b) => b.uploadTime - a.uploadTime)
      .map((x, i) => {
        x['index'] = i + 1;
        return x;
      })
  }

  @computed
  get nodeTreeArr() {
    return Array.from(this.nodeTreeMap.values());
  }


  @computed
  get allNodeTree() {
    console.time('allTree');
    const tree = this.mountDevice(this.nodeTreeArr);
    console.timeEnd('allTree');
    return tree;
  }

  /**
   * 车辆列表 车辆
   */
  @computed
  get vechileArr() {
    return Array.from(this.nodeTreeMap.values()).filter(x => this.isVeh(x)) as CarTreeVeh[];
  }


  @action
  changeParamSetDev = (dev: SetDev | undefined) => {
    this.paramSetDev = dev;
  }

  @action
  changeSelectedInsKey = (key: string) => {
    this.selectedInsKey = key;
  }

  //右键菜单切换指令下发modal items 为当前node节点的数据
  @action
  changeInstructModal = (bools: boolean) => {
    this.dispatchInstructShow = bools;
  }

  @action
  addInsRes = (insRes: InsResource) => {
    const key = insRes.vehId + '_' + Date.now();
    this.insResMap.set(key, copy(insRes));
  }

  @action
  changeInsModalCheckedCarKeys = (nodeId: string) => {
    this.selectedCarId = nodeId;
  }

  @action
  changeInsResTs = (ts: number) => {
    this.insResTs = ts;
  }

  //车辆列表右键指令下发弹窗,右侧的关注车辆
  @action
  addInsModalCar = (dev: any) => {
    const { nodeId, pid } = dev;
    const org = this.getDeviceByKey(pid, 'org')!;
    this.insModalCarsMap.set(nodeId, dev);
    this.selectedCarId = nodeId;
  }

  @action
  addNewInsRecord = (msgId: string, veh: CarTreeVeh, insName: string, insAct: InsAct, key: string, JTKey: string | undefined, params = {}) => {
    const { vehicleId, nodeId, plateNo, devs = [], orgName = '' } = veh;
    const newIns: DispathcInsRecord = {
      msgId,
      vehId: vehicleId,
      plateNo: plateNo,
      orgName,
      devId: devs.length ? devs[0].deviceId! : '',
      devType: veh.devType,
      sendStatus: "success",
      createTime: Date.now(),
      insName,
      insAct,
      key,
      JTKey,
      paramValues: params,
    }
    this.dispatchedInsMap.set(msgId, newIns);
  }

  /**
   * @desc 更新指令记录  实时监控页面使用
   */
  @action
  changeInsRecord = (msgId: string, sendRes: InsTableConfig) => {
    console.log('changeInsRecord', msgId, sendRes);
    const insRecord = this.dispatchedInsMap.get(msgId);
    if (insRecord) {
      const { sendStatus, queryResult = '' } = sendRes;
      insRecord.sendStatus = sendStatus!;
      this.dispatchedInsMap.set(msgId, copy(insRecord));
    }
  }

  @action
  changeExpandedKeys = (keys: string[]) => {
    this.treeExpandedKeys = keys;
  }

  @action
  changeAutoExpandParent = (auto: boolean) => {
    this.autoExpandParent = auto;
  }

  @action
  chagngeCarListTabKey = (tabKey: string) => {
    this.carListTabKey = tabKey;
  }


  @action
  changeLookDevTalk = (dev: CarTreeNode | undefined) => {
    this.lookDevTalk = dev;
  }

  @action
  changeLookedVehStatus = (veh: CarTreeVeh | undefined) => {
    this.lookedVehStatus = veh ? copy(veh) : veh;
  }

  @action
  changeRightClickVeh = (veh: RightClickVeh | undefined) => {
    this.rightClickVeh = veh;
  }

  @computed
  get concernCarsArr() {
    return Array.from(this.concernCarsMap.values());
  }

  @computed
  get insModalCarsArr() {
    return Array.from(this.insModalCarsMap.values());
  }

  @computed
  get concernCarsArrLen() {
    return this.concernCarsArr.length || 0;
  }

  @computed
  get isMonitorAll() {
    return !!this.concernCarsArrLen && eq(this.concernCarsArrLen, this.concernListCheckedKeys.length);
  }

  getDeviceByKey = (id: string, type: DeviceType = 'vehicle') => {
    const nodeId = this.getNodeIdById(id, type);
    return this.allNodeObj[nodeId];
  }

  getNodeIdById = (id: string, type: DeviceType) => {
    const prefixMap: { [key in DeviceType]: string } = {
      '': 'unknow',
      'org': 'org',
      'vehicle': 'vehicle',
      channel: 'channel',
      device: 'channel',
    }
    const nodeId = prefixMap[type] + id;
    return nodeId;
  }

  @action
  changeDispatchVoiceCar = (car: CarTreeVeh | undefined) => {
    this.dispatchVoiceCar = car;
  }

  @action
  setConcernCarsFromLocal(userId: string) {
    this.userId = userId;
    const concernCarsArr = Array.from(myLocalStorage.getItem<CarTreeVeh[]>(concernCarStr + userId, 'obj'))
      || [];
    concernCarsArr.forEach(x => {
      this.concernCarsMap.set(x.nodeId, x);
      this.allNodeObj[x.nodeId] = x;
    });
  }

  @action
  changeSearch = (searchParams) => {
    this.search = { ...this.search, ...searchParams }
  }

  @action
  addConcernCar = (dev: CarTreeVeh) => {
    const { nodeId, nodePid } = dev;
    const org = this.getDeviceByKey(nodePid, 'org')!;
    this.concernCarsMap.set(nodeId, dev);
    this.saveConcernCarsToLocal();
  }


  @action
  deleteConcernCars = (cars: CarTreeNode[]) => {
    cars.forEach(car => {
      this.concernCarsMap.delete(car.nodeId);
    })
    this.saveConcernCarsToLocal();
  }

  /**
   * @desc 删除指令右键弹窗里面的全部关注车辆
   */
  @action
  deleteInstructConcernCars = (cars: any[]) => {
    cars.forEach(car => {
      this.insModalCarsMap.delete(car.nodeId);
    })
  }

  getParentIds = (node: CarTreeNode) => {
    let parent = this.getParent(node);
    const parentKeys: string[] = []
    while (parent) {
      parentKeys.push(parent.nodeId);
      parent = this.getParent(parent);
    }
    return parentKeys;
  }

  /**
   * @desc 删除所有批量下发的车辆
   */
  @action
  delAllBatchDispatchCar = () => {
    this.batchDispatchCarArr.forEach(c => {
      const { nodeId } = c;
      if (this.batchDispatchCarMap.get(nodeId)) {
        this.batchDispatchCarMap.delete(nodeId)
      }
    })
  }

  saveConcernCarsToLocal() {
    const concernCarsArr = this.concernCarsArr;
    myLocalStorage.setItem(concernCarStr + this.userId, concernCarsArr, 'obj');
  }

  /**
   * @desc 设置车辆列表选择车辆
   * @param keys 
   */
  @action
  setCarListCheckedKeys(keys: string[], checked: boolean) {
    console.log('setCarListCheckedKeys');
    const compareRes = compareArr(toJS(this.carListCheckedKeys), keys);
    this.carListCheckedKeys = keys;

    let newCars: CarTreeNode[] = [];
    const action = checked ? 'add' : 'del';

    newCars = compareRes[action].map(x => {
      const carInList = this.nodeTreeMap.get(x)!;
      return carInList;
    });
    // this.changeMonitorCars(action, newCars);
  }

  /**
   * @desc 设置关注列表选择车辆
   * @param keys 全部的监控的keys
   */
  @action
  setConcernCarBykeys(keys: string[]) {

    const compareRes = compareArr(toJS(this.concernListCheckedKeys), keys);
    this.concernListCheckedKeys = keys;

    let newCars: CarTreeNode[] = [];
    const action = compareRes.add.length > 0 ? 'add' : 'del';

    newCars = compareRes[action].map(x => {
      return this.concernCarsMap.get(x)!;
    });
    // this.changeMonitorCars(action, newCars);
  }

  /**
   * 点击车辆
   * @param keys 
   */
  @action
  setSelectedDeviceByKey(keys: string[]) {
    console.log('setSelectedCarByKey', keys)
    this.selectedNode = keys.map(k => (this.nodeTreeMap.get(k) || this.concernCarsMap.get(k))!);
  }

  @action
  changeSelectNode = (obj) => {
    this.selectedNode = obj;
  }

  getSelectedNodeKey = () => {
    return this.selectedNode.length
      ? this.selectedNode[0].nodeId
      : '';
  }

  isVeh(device: CarTreeNode) {
    return device.nodeType === 'vehicle'
  }

  isOrg(device: CarTreeNode) {
    return device.nodeType === 'org'
  }

  getParent = (device: CarTreeNode) => {
    const { nodePid } = device;
    return this.nodeTreeMap.get(nodePid);
  }

  /**
   * @desc 根据设备数组组装设备树
   */
  mountDevice = (devices: CarTreeNodeVehOrOrg[]) => {
    console.log('mountDevice');
    // 一份新的键值数据
    const deviceObj: { [nodeId: string]: CarTreeNodeVehOrOrg } = {};
    devices.forEach(x => {
      deviceObj[x.nodeId] = { ...x }
    });

    const carTreeArr: CarTreeNodeVehOrOrg[] = [];
    const mountDevice = (m: CarTreeNodeVehOrOrg) => {
      const { nodeId, nodePid, } = m;
      const node = deviceObj[nodeId] as CarTreeOrg;
      const pNode = deviceObj[nodePid] as CarTreeOrg;
      const total = m['total'] || 1;

      // 一级菜单
      if (!pNode) {
        node['actualTotal'] = 0;
        carTreeArr.push(node);
        if (!this.firstDeviceRoot) {
          this.firstDeviceRoot = node;
        }
      } else {
        // 统计子节点车辆总数
        const { actualTotal = 0 } = pNode;
        pNode.actualTotal = actualTotal + total;

        // 加上车辆名称
        // device.orgName = pDevice.name;
        this.updateAllNodeData({ ...node, ...{ orgName: pNode.name } });
        pNode.children = pNode.children || [];
        pNode.children.push(node);
      }
    }
    devices.forEach(m => mountDevice(m));
    console.log('menuTree', carTreeArr);
    return carTreeArr;
  }


  searchNode = (searchStr: string) => {
    return this.nodeTreeArr.filter(x => x.name.includes(searchStr));
  }

  getNodeClass = (nodeId: string) => {
    return 'nodeId-' + nodeId;
  }

  updateAllNodeData = (data: { nodeId: string }) => {
    // log.data('updateAllNodeData', data.nodeId, data);
    const { nodeId } = data;
    const oldData = this.allNodeObj[nodeId] || {};

    const newData = Object.assign(oldData, data);
    this.allNodeObj[nodeId] = newData;
  }

  /**
   * @desc 获取设备子节点
   * @params 设备id
   */
  @action
  getOrgChild = (orgId = '') => {
    const { online, } = this.search;

    const url = 'comm/tree/vehNodePage';
    const params = {
      orgId,
      online,
      page: 0,
      pageSize: 99999,
      includeChildOrg: false
    }
    let result: CarTreeVeh[] = [];
    return http.post(url, params)
      .then(
        res => {
          console.log('getChild', res);
          runInAction(() => {
            const vehList = _.get(res, 'data.items', []);
            result = vehList.map((x: CarTreeVeh) => {
              let { vehicleId, orgId, plateNo } = x;

              const nodeId = getCarTreeNodeId('vehicle', vehicleId);
              x.nodeId = nodeId;
              x.nodePid = getCarTreeNodeId('org', orgId);
              x.key = nodeId;
              x.title = x.plateNo;
              x.isLeaf = true;
              x.nodeType = 'vehicle';
              x.name = plateNo;

              // x.value = x.id;
              this.nodeTreeMap.set(nodeId, x);
              this.allNodeObj[nodeId] = x;
              return x;
            })
          });
          return result;
        },
        err => {
          console.log('err', err);
          return result;
        }
      )
  }

  @action
  getOrgTreeList = (onlyOrg: boolean) => {

    const url = 'comm/tree/searchnode';
    this.fetchStatus = 'pending';
    const searchNames = {
      org: 'orgName',
      vehicle: 'plateNo',
      device: 'deviceId',
    }
    const { searchStr, type, online, } = this.search;
    const params = {
      [searchNames[type]]: searchStr,
      online,
      // onlyOrg: !searchStr,
      onlyOrg: onlyOrg,
    }
    return http.post(url, params).then(
      res => {

        runInAction(() => {
          // 每次请求清空map
          this.nodeTreeMap.clear();
          const { orgNodes = [], vehNodes = [] } = res.data || {};
          orgNodes.map(x => {
            let { total, onlineNum, id, pid } = x as CarTreeOrg;

            const nodeId = getCarTreeNodeId('org', id);
            const nodePid = getCarTreeNodeId('org', pid);
            x.nodeId = nodeId;
            x.nodePid = nodePid;
            x.key = x.nodeId;
            x.title = x.name + `(${onlineNum}/${total})`;
            x.isLeaf = total ? false : true;
            x.nodeType = 'org';

            this.nodeTreeMap.set(nodeId, x);
            this.allNodeObj[nodeId] = x;
          });

          vehNodes.map(x => {
            let { orgId, vehicleId, plateNo } = x as CarTreeVeh;

            const nodeId = getCarTreeNodeId('vehicle', vehicleId);
            const nodePid = getCarTreeNodeId('org', orgId);
            x.nodeId = nodeId;
            x.nodePid = nodePid;
            x.key = x.nodeId;
            x.title = plateNo;
            x.isLeaf = true;
            x.nodeType = 'vehicle';

            this.nodeTreeMap.set(nodeId, x);
            this.allNodeObj[nodeId] = x;
          });
          this.fetchStatus = 'success';
          this.carListCheckedKeys = [];
        })
      },
      err => {
        runInAction(() => {
          this.fetchStatus = 'fail';
        })
        console.log('err', err);
      }
    )
  }


  getNextVideoWinCount = (count: number) => {
    if (count > this.videoCount) {
      // let i = 0;
      // while (i < vide (videoCountList[i] < count)) {
      //   i++;
      // }
      let newCount = 0;
      for (let j = 0, jLen = videoCountList.length; j < jLen; j++) {
        if (videoCountList[j] > count) {
          newCount = videoCountList[j];
          break;
        }
        newCount = videoCountList[videoCountList.length - 1]
      }
      return newCount;
    } else {
      return this.videoCount;
    }
  }

  @action
  changeVideoCount = (count: number) => {
    this.videoCount = count;
    // todo
  }

  @action
  changeVideo = (videos: RTVSVideos) => {
    this.videos = videos;

    // 当前视频窗口不够预览全部时，增加窗口
    let count = Object.keys(videos).length;
    if (count > this.videoCount) {
      let i = 0;
      while (videoCountList[i] < count) {
        i++;
      }
      this.changeVideoCount(videoCountList[i]);
    }
  }

  @action
  addVideo = (videos: RTVSVideo[]) => {
    if (videos.length === 1) {
      const video = videos[0];
      const index = this.videoIns.NowSelectVideo;
      this.videos[index] = video;
    } else {
      // 如果视频窗口不够新增视频窗口
      const newVideoCount = this.getNextVideoWinCount(videos.length + this.videoIndexs.length);
      this.changeVideoCount(newVideoCount);

      const countArr = new Array(this.videoCount).fill(1).map((x, i) => i + 1);
      const filledKeys = this.videoIndexs;
      const emptyKeys = countArr.filter(x => !filledKeys.includes(x.toString()));
      const emptyKeysLen = emptyKeys.length;

      for (let j = 0, len = videos.length; j < len; j++) {
        // 如果没有多余的窗口就从1开始
        const effectiveIndex = j < emptyKeysLen ? emptyKeys[j] : (j - emptyKeysLen) % this.videoCount + 1;
        this.videos[effectiveIndex] = videos[j];
      }
    }

    const newVideos = copy(this.videos);
    console.log('addVideo', newVideos);
    this.changeVideo(newVideos)
  }


  @action
  queryVehByIds = (vehicleIds: string[]) => {
    const url = 'comm/vehquery';
    const params = {
      vehicleIds
    }
    return http.post(url, params).then(res => {
      console.log('vehquery', res);
      this.concernCarsDetail = res.data;
    }, err => {

    })
  }

  @action
  updateVehNode = (vehs: { id: string, }[]) => {
    const url = 'comm/tree/updatevn';
    // const url = 'https://console-mock.apipost.cn/app/mock/project/6565149f-0dca-4995-f526-b60b8a99789c/';
    const params = vehs;
    return http.post(url, params).then(res => {
      const updateNodes = res.data as CarTreeVeh[];
      runInAction(() => {
        const newNodes = updateNodes.forEach(x => {
          const { vehicleId } = x;
          const nodeId = getCarTreeNodeId('vehicle', vehicleId);
          for (let k in x) {
            if (x[k] === null) {
              delete x[k]
            }
          }
          const oldNode = this.allNodeObj[nodeId]!;
          const newNode = Object.assign({}, oldNode, x);
          this.allNodeObj[nodeId] = newNode;  // 更新数据
          // log.data('updateVehNode', newNode);
          if (this.nodeTreeMap.has(nodeId)) {
            this.nodeTreeMap.set(nodeId, newNode);
          }
          if (this.concernCarsMap.has(nodeId)) {
            this.concernCarsMap.set(nodeId, newNode);
          }
          // 更新监控车辆数据
          // if (this.monitorCarMap.has(nodeId)) {
          //   this.monitorCarMap.set(nodeId, newNode);
          // }
        })
      })
    })
  }


  updateVehNodeInterval = () => {
    return setInterval(() => {
      const updateNodes = toJS([...this.vechileArr, ...this.concernCarsArr]);
      const params = updateNodes.map(x => {
        const { vehicleId, } = x;
        return { id: vehicleId, }
      })
      this.updateVehNode(params);
    }, 5 * 1000)
  }
}


