import React from 'react';
import { inject, observer } from "mobx-react";
import { Empty, message, Modal, Input, Tabs, Divider } from 'antd';
import ParamButton from '../paramButton/index';
import 'xterm/css/xterm.css'
import { getSearchParams, eq } from '../../../../src/utils/mix';
import { DeviceStore, CommonStore, DeviceTreeStore } from '../../../../src/stores'
import { getVideoSrc } from '../../../../src/components/flvPlayer';
import ReactTer from 'terminal-in-react';

import DevConfigForm from '../../../../src/components/devConfigForm/devConfigForm';
import { action, toJS, observable, when, computed } from "mobx";
import { setTimeout } from "timers";
import H5Info from '../h5Info';
import './index.scss';
import FileManager from '../fileManager';
import paramsData from './paramData';
import { h5DevTypes } from 'src/consts';
import RightParams from './RightParams'

const { TabPane } = Tabs;
interface DevConcoleProps {
  deviceStore?: DeviceStore;
  commonStore?: CommonStore;
  deviceTreeStore?: DeviceTreeStore;
}

@inject('deviceStore', 'commonStore', 'deviceTreeStore')
@observer
export default class DevConsolePage extends React.Component<DevConcoleProps> {
  @observable devId = '3';
  @observable devType = 3; // default
  // @observable devConfigPara: any = []; // default
  // @observable commConfigPara: any = []; // default
  @observable intervalIds: any = undefined;

  constructor(props: DevConcoleProps) {
    super(props);
    const { deviceStore, deviceTreeStore, commonStore } = this.props;
    // when(
    //   () => !!Object.keys(deviceStore!.devConfigPara).length,
    //   () => this.getConfigData()
    // )
    const { arr } = getSearchParams();
    commonStore!.openWebSocket();
    document.title = decodeURI('设备控制台');
    if (arr) {
      let ar = arr.split('_');
      const devType = Number(ar[2]);
      this.changeDevId(String(ar[0]))
      this.changeDevType(devType);
      // h5 设备
      if (h5DevTypes.includes(devType)) {
        deviceStore!.getH5Info(ar[1]);
        this.intervalIds = setInterval(() => {
          deviceStore!.getH5Info(ar[1]);
        }, 1000 * 6);
      } else {
        deviceStore!.getDevConsole(ar[0], ar[1]).then(res => {

          let d = toJS(deviceStore!.devConsoleDetailInfo);
          let channels: any[] = [];

          let newChannel: any = (d.deviceChannels || []).sort((a, b) => a.channelPos - b.channelPos);
          newChannel.map((x, index) => {
            channels.push(getVideoSrc(d.deviceId, x.channelNo, d.devType, d.devName, Date.now()));
            console.log('clickChannel', x.channelNo);
          });

          //实时视频
          setTimeout(function () {
            deviceTreeStore!.addVideoUrls(channels);
            commonStore!.subscribeSimpleDevice([{ deviceId: d.deviceId, devType: d.devType }]);
          }, 1300);

        }, error => {

        });
        this.intervalIds = setInterval(() => {
          deviceStore!.getDevConsole(ar[0], ar[1]);
        }, 1000 * 6);
      }
      deviceStore!.getParamConfig(ar[0], ar[1], ar[2], ar[4]);
    }
  }

  componentWillUnmount() {
    console.log('devConsole page componentWillUnmount');
    const { commonStore } = this.props;
    commonStore!.closeWebSocket();
    this.intervalIds && clearInterval(this.intervalIds);
  }

  @computed
  get commConfigPara() {
    const { deviceStore } = this.props;
    let devConfigPara = toJS(deviceStore!.devConfigPara) || {};

    let commConfig = {};
    for (let key in paramsData) {
      const configItem = devConfigPara[key];
      if(configItem){
        commConfig[key] = configItem;
      }else{

      }
    }
    return commConfig;

  }

  @action
  changeDevId = (devId: string) => {
    this.devId = devId;
  }

  @action
  changeDevType = (type: number) => {
    this.devType = type;
  }

  @action
  showMsg = () => {
    return 'hello,world';
  }

  //null string will not come in
  @action
  creactPrint = (texts, print) => {
    let str = texts.join(' ');
    //document.getElementsByClassName('iQSzyY')[0].setAttribute('id','hello')
    document.getElementsByClassName('iQSzyY')[0].setAttribute('class', 'sc-htoDjs iQSzyY hides')
    print(`connect...`);
    this.shellZhiling(str, print);
  }

  //shell zhiling
  @action
  shellZhiling = (cmd: string, print) => {
    const { commonStore, deviceStore } = this.props;
    let d = toJS(deviceStore!.devConsoleDetailInfo);
    commonStore!.dispatchShell(d.deviceId, cmd, d.devType).then(res => {
      let curdata: any = res;
      const reg = /^\{.*\}$/;
      const isJSON = reg.test(curdata.content);
      let responseStr = '';
      if (isJSON) {
        let result = JSON.parse(curdata.content).ret; //true or false
        responseStr = result.replace('success:', '');
      } else {
        responseStr = curdata.content
      }

      print(<p style={{ color: '#21A3F1', maxWidth: '600px', wordWrap: 'break-word' }}>{responseStr}</p>);

      document.getElementsByClassName('iQSzyY')[0].setAttribute('class', 'sc-htoDjs iQSzyY')
      document.getElementsByClassName('fDBBfG')[0].setAttribute('id', 'iiput')
      // @ts-ignore
      document.getElementById('iiput').focus()

    }, err => {
      let errdata: any = err;
      console.log('shell error data=', errdata);
      print(<div style={{ color: 'red' }}>{`fail time out`}</div>);
      document.getElementsByClassName('iQSzyY')[0].setAttribute('class', 'sc-htoDjs iQSzyY')
      document.getElementsByClassName('fDBBfG')[0].setAttribute('id', 'iiput')
      // @ts-ignore
      document.getElementById('iiput').focus()
    });
  }

  @action
  judgeColor = () => {
    let len = document.getElementsByClassName('ihWlDo').length;
    for (let i = 0; i < len; i++) {
      let str = document.getElementsByClassName('ihWlDo')[i].innerHTML
      if (str.indexOf('fail time out') > -1) {
        document.getElementsByClassName('ihWlDo')[i].setAttribute('class', 'sc-gzVnrw ihWlDo fail');
      } else if (str.indexOf('bin etc test.log') > -1) {
        document.getElementsByClassName('ihWlDo')[i].setAttribute('class', 'sc-gzVnrw ihWlDo success');
      }
    }
    document.getElementsByClassName('iQSzyY')[0].setAttribute('class', 'sc-htoDjs iQSzyY')
  }

  // @action
  // getConfigData = () => {
  //   const { deviceStore } = this.props
  //   let curdata = toJS(deviceStore!.devConfigPara);
  //   let devType = toJS(this.devType);
  //   let arr: any = [];
  //   for (let key in curdata) {
  //     // let values: any = curdata[va].value;
  //     const { value, ov } = typeof curdata[key] == 'object' && curdata[key]
  //       ? curdata[key]
  //       : JSON.parse(curdata[key]);
  //     let obj = {
  //       deviceType: devType,
  //       key: key,
  //       value: value,
  //       ov
  //     }
  //     arr.push(obj);
  //   };
  //   this.devConfigPara = arr;

  //   arr = [];
  //   for (let key in paramsData) {
  //     const { value, ov } = typeof curdata[key] == 'object' && curdata[key]
  //       ? curdata[key]
  //       : JSON.parse(curdata[key]);
  //     let obj = {
  //       deviceType: devType,
  //       key: key,
  //       value: value,
  //       ov
  //     }
  //     arr.push(obj);
  //   }
  //   this.commConfigPara = arr;
  //   console.log('this.devConfigPara', toJS(this.devConfigPara));
  //   console.log('this.commConfigPara', toJS(this.commConfigPara));
  // }

  render() {
    // console.log('config parxxa=', toJS(deviceStore!.devConfigPara));
    const { deviceStore } = this.props;
    return (
      <div className={'devcConcolePage h5'}>
        <Tabs defaultActiveKey='1'>
          <TabPane tab="状态信息" key="1">
            <div className="statusInfo">
              <div className={'left'}>
                <H5Info />
                <ParamButton />
              </div>
              <div className={'right'}>
                <RightParams devType={toJS(this.devType)} devId={toJS(this.devId)}/>
              </div>
            </div>
          </TabPane>
          <TabPane tab="常用参数配置" key="2">
            <DevConfigForm
              initValues={toJS(this.commConfigPara)}
              readonly={false}
              devType={toJS(this.devType)} />
          </TabPane>
          <TabPane tab="参数配置" key="3">
            <DevConfigForm
              initValues={toJS(deviceStore!.devConfigPara)}
              readonly={false}
              devType={toJS(this.devType)} />
          </TabPane>
          <TabPane tab="控制台" key="4">
            <div className="console">
              <FileManager />
              <div className={'right'} id={'devdos'}>
                <ReactTer
                  color='white'
                  prompt='white'
                  promptSymbol={'>$ '}
                  outputColor={'white'}
                  backgroundColor='black'
                  barColor='black'
                  hideTopBar={true}
                  allowTabs={false}
                  style={{ fontWeight: "bold", fontSize: "1.4em" }}
                  commandPassThrough={(cmd, print) => {
                    this.creactPrint(cmd, print);
                  }}
                  commands={{
                    'open-google': () => window.open('https://www.google.com/', '_blank'),
                    showmsg: this.showMsg,
                    popup: () => alert('Terminal in React'),
                  }}
                  description={{
                    'open-google': 'opens google.com',
                    showmsg: 'shows a message',
                    alert: 'alert', popup: 'alert'
                  }}
                />
              </div>
            </div>
          </TabPane>
        </Tabs>
      </div>
    )
  }
}
