import React from "react";
import { inject, observer } from "mobx-react";
import { Modal, Input, message } from "antd";
import Form, { FormComponentProps } from "antd/lib/form";
import { CommonStore, DeviceStore } from "../../../../src/stores";

interface IProps extends FormComponentProps {
  commonStore?: CommonStore;
  deviceStore?: DeviceStore;
  visible?: boolean;
  onCancel?: any;
}

@inject("commonStore", "deviceStore")
@observer
class BurnModal extends React.Component<IProps> {
  state = {
    loading: false,
  };

  handleOk = (e) => {
    const {
      commonStore,
      deviceStore,
      form: { validateFields },
    } = this.props;
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        let d = deviceStore!.devConsoleDetailInfo;
        const { deviceId, devType } = d;
        this.setState({ loading: true });
        commonStore!.noInfoZhiling(deviceId, 1003, devType, values).then(
          (res: any) => {
            this.setState({ loading: false });
            if (res.code === -1) {
              message.error("无IC卡，烧录失败");
            } else {
              message.success("操作成功");
              this.props.onCancel();
            }
          },
          (err) => {
            message.error("操作失败"); //this.zhilingObj[act]
          }
        );
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        title="驾驶员IC卡烧录"
        destroyOnClose
        confirmLoading={this.state.loading}
        onOk={this.handleOk}
        {...this.props}
      >
        <Form>
          <Form.Item label="驾驶证ID">
            {getFieldDecorator("driverId", {
              rules: [{ required: true, message: "请输入驾驶证ID!" }],
            })(<Input placeholder="请输入" />)}
          </Form.Item>
          <Form.Item label="驾驶员姓名">
            {getFieldDecorator("driverName", {
              rules: [{ required: true, message: "请输入驾驶员姓名!" }],
            })(<Input placeholder="请输入" />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default Form.create<IProps>()(BurnModal);
