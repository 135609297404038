import React from 'react';
import { observer, inject } from 'mobx-react';
import { Empty, Input, Pagination, Tabs, Tag } from 'antd';
import _ from 'lodash';
import { ProductTestStore } from 'src/stores';
import { getSearchParams, formatTime } from 'src/utils/mix';
import { localDict } from 'src/consts';

const { TabPane } = Tabs;

interface IndexProps {
  productTestStore?: ProductTestStore,
}

@inject('productTestStore')
@observer
export default class Index extends React.Component<IndexProps> {


  constructor(props: IndexProps) {
    super(props);
    const { productTestStore } = props;
    const { category } = getSearchParams();
    productTestStore!.changeDetailCategory(category);
  }

  changeTab = (category: string) => {
    const { productTestStore } = this.props;
    const { changeDetailCategory, changeDetailIndex } = productTestStore!;
    changeDetailCategory(category);
    changeDetailIndex(0);
  }


  render() {
    let { productTestStore } = this.props;
    const {
      changeDetailCategory, detailCategory,
    } = productTestStore!;

    return <div className="afterComWrapper">
      <Tabs activeKey={detailCategory} onChange={this.changeTab}>
        <TabPane tab="后装整机" key="1">
          <MainForm />
        </TabPane>
        <TabPane tab="后装PCBA" key="5">
          <MainForm />
        </TabPane>
      </Tabs>
    </div>
  }
}


@inject('productTestStore')
@observer
export class MainForm extends React.Component<IndexProps> {

  imei = '';

  constructor(props) {
    super(props);
    const { imei } = getSearchParams();
    this.imei = imei;
  }

  render() {
    let { productTestStore } = this.props;
    const { detailIndex, changeDetailIndex,
      curTabData
    } = productTestStore!;
    if (!curTabData.length) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ marginTop: '30vh' }} />
    }

    let data = curTabData[detailIndex];
    let time = data.checkTime;
    let timeStr = time ? formatTime(time, 1) : '';

    data = _.mapValues(data, (v, k, o) => {
      if (v === null) {
        return 'N/A'
      } else {
        return v
      }
    })

    const totalResult = data.totalResult;
    const color = totalResult === 'PASS' ? '#87d068' : '#f50';
    const sysVerson = data.category === 5 ? data.systemVersion : data.software;

    return <div className="afterCom">
      <div className="top">
        <div className="title">IMEI: {this.imei}  产测详情</div>
        <div className="time">{timeStr}</div>
        <Pagination simple defaultCurrent={detailIndex + 1} total={curTabData.length} pageSize={1}
          onChange={(page) => { changeDetailIndex(page - 1) }}
        />
        <div className="result" style={{ marginLeft: 40 }}>
          <span>产测结果：</span>
          <Tag color={color} style={{ margin: 0 }}>{localDict.result[totalResult]}</Tag>
        </div>
      </div>
      <div className="main">

        <div className="col">
          <div className="block">
            <div className="title">设备信息</div>
            <div className="list">
              <Input addonBefore="IMEI号" value={data.imei} readOnly />
              <Input addonBefore="SN号" value={data.sn} readOnly />
              <Input addonBefore="MCU版本" value={data.mcuVersion} readOnly />
              <Input addonBefore="系统版本" value={sysVerson} readOnly />
              <Input addonBefore="固件/APP版本" value={data.firmware} readOnly />
              <Input addonBefore="硬件版本" value={data.hardware} readOnly />
              <Input addonBefore="生产厂认证代码" value={data.mcCode} readOnly />
              <Input addonBefore="认证产品型号" value={data.cpModel} readOnly />
              <Input addonBefore="产品型号" value={data.productType} readOnly />
              <Input addonBefore="生产流水号" value={data.proNum} readOnly />
              <Input addonBefore="生产日期" value={data.productDate} readOnly />
              <Input addonBefore="产品编码" value={data.productNumber} readOnly />
              <Input addonBefore="设备Salt" value={data.productSalt} readOnly />
            </div>
          </div>

          <div className="block">
            <div className="title">电压信息</div>
            <div className="list">
              <Input addonBefore="输入电压" value={data.inputVoltage} readOnly />
              <Input addonBefore="5V电源输出" value={data.voltage5000} readOnly />
              <Input addonBefore="12V电源输出" value={data.voltage12000} readOnly />
              {/* <Input addonBefore="设备电流" value={data.currentConsumption} readOnly /> */}
              <Input addonBefore="防护存储器编码" value={data.memoryNumber} readOnly />

              <Input addonBefore="VIN" value={data.voltageVIN} readOnly />
              <Input addonBefore="SYS_12V" value={data.voltageSYS_12V} readOnly />
              <Input addonBefore="SYS_5V" value={data.voltageSYS_5V} readOnly />
              <Input addonBefore="VGSM" value={data.voltageVGSM} readOnly />
              <Input addonBefore="VCPU_3V3" value={data.voltageVCPU_3V3} readOnly />
              <Input addonBefore="VGPS_3V3" value={data.voltageVGPS_3V3} readOnly />
              <Input addonBefore="VEDR_3V3" value={data.voltageVEDR_3V3} readOnly />
              <Input addonBefore="SUV_5V" value={data.voltageSUV_5V} readOnly />
              <Input addonBefore="CORE_5V" value={data.voltageCORE_5V} readOnly />
              <Input addonBefore="WIFI_BT_3V3" value={data.voltageWIFI_BT_3V3} readOnly />
            </div>
          </div>
        </div>
        <div className="col">
          <div className="block">
            <div className="title">通讯信息</div>
            <div className="list">
              <Input addonBefore="加速度传感器" value={data.accSensor} readOnly />
              <Input addonBefore="电池充放电" value={data.powerCharge} readOnly />
              <Input addonBefore="备用电池开关电" value={data.batterySwitch} readOnly />
              <Input addonBefore="GPS开路短路" value={data.gpsCircuit} readOnly />
              <Input addonBefore="CAN1" value={data.can1} readOnly />
              <Input addonBefore="CAN2" value={data.can2} readOnly />
              <Input addonBefore="MCU串口" value={data.mcu} readOnly />
              <Input addonBefore="SOC串口" value={data.soc} readOnly />
              <Input addonBefore="RS485串口" value={data.rs485} readOnly />
              <Input addonBefore="IPC通讯" value={data.ipc} readOnly />
              <Input addonBefore="超级电容放电" value={data.discharge} readOnly />
              <Input addonBefore="熄火延时录像" value={data.delayRecord} readOnly />
              <Input addonBefore="摄像头1-4开关" value={data.power14Switch} readOnly />
              <Input addonBefore="摄像头5-8开关" value={data.power58Switch} readOnly />

            </div>
          </div>
        </div>
        <div className="col">
          <div className="block">
            <div className="title">IO信息</div>
            <div className="list">
              <Input addonBefore="ACC点火熄火" value={data.acc} readOnly />
              <Input addonBefore="右转向" value={data.turnRight} readOnly />
              <Input addonBefore="左转向" value={data.turnLeft} readOnly />
              <Input addonBefore="远光灯" value={data.highBeam} readOnly />
              <Input addonBefore="近光灯" value={data.dippedBeam} readOnly />
              <Input addonBefore="后雾灯" value={data.fogLamp} readOnly />
              <Input addonBefore="倒车" value={data.backUp} readOnly />
              <Input addonBefore="司机安全带" value={data.safetyBelt} readOnly />
              <Input addonBefore="车门" value={data.carDoor} readOnly />
              <Input addonBefore="紧急输入" value={data.emInput} readOnly />
              <Input addonBefore="刹车输入" value={data.breakInput} readOnly />
              <Input addonBefore="AD输入" value={data.adInput} readOnly />
              <Input addonBefore="自检状态输出" value={data.checkState} readOnly />
              <Input addonBefore="车速脉冲输入" value={data.speedInput} readOnly />
              <Input addonBefore="车速脉冲输出" value={data.speedOutput} readOnly />
            </div>
          </div>
        </div>
        <div className="col">
          <div className="block">
            <div className="title">存储及其他信息</div>
            <div className="list">
              <Input addonBefore="前置USB读写" value={data.frontUsb} readOnly />
              <Input addonBefore="IC卡读写" value={data.ic} readOnly />
              <Input addonBefore="SD卡1读写" value={data.sd} readOnly />
              <Input addonBefore="SD卡2读写" value={data.sd2} readOnly />
              <Input addonBefore="硬盘读写" value={data.hardDisk} readOnly />
              <Input addonBefore="防护存储器读写" value={data.memoryReadwrite} readOnly />
              <Input addonBefore="开关锁检测" value={data.switchLock} readOnly />
              <Input addonBefore="终端按键菜单栏" value={data.keyMenuBar} readOnly />
              <Input addonBefore="终端按键上" value={data.keyUpper} readOnly />
              <Input addonBefore="终端按键下" value={data.keyLower} readOnly />
              <Input addonBefore="终端按键确定" value={data.keyDetermine} readOnly />
              <Input addonBefore="蓝牙通讯" value={data.bluetooth} readOnly />
              <Input addonBefore="WIFI通讯" value={data.wifi} readOnly />
              <Input addonBefore="4G通讯模块版本" value={data.var4g} readOnly />
              <Input addonBefore="4G通讯信号强度" value={data.lteStatus} readOnly />
              <Input addonBefore="GPS模块版本" value={data.gpsVer} readOnly />
              <Input addonBefore="GPS信号" value={data.gps} readOnly />
              <Input addonBefore="终端复位" value={data.terReset} readOnly />

            </div>
          </div>
        </div>
        <div className="col">
          <div className="block">
            <div className="title">摄像头及其他信息</div>
            <div className="list">
              <Input addonBefore="摄像头1(是否有图像)" value={data.ipc1} readOnly />
              <Input addonBefore="摄像头2(是否有图像)" value={data.ipc2} readOnly />
              <Input addonBefore="摄像头3(是否有图像)" value={data.ipc3} readOnly />
              <Input addonBefore="摄像头4(是否有图像)" value={data.video4} readOnly />
              <Input addonBefore="摄像头5(是否有图像)" value={data.video5} readOnly />
              <Input addonBefore="摄像头6(是否有图像)" value={data.video6} readOnly />
              <Input addonBefore="摄像头7(是否有图像)" value={data.video7} readOnly />
              <Input addonBefore="摄像头8(是否有图像)" value={data.video8} readOnly />

              <Input addonBefore="音频1(是否正常)" value={data.audio1} readOnly />
              <Input addonBefore="音频2(是否正常)" value={data.audio2} readOnly />
              <Input addonBefore="音频3(是否正常)" value={data.audio3} readOnly />
              <Input addonBefore="音频4(是否正常)" value={data.audio4} readOnly />
              <Input addonBefore="外置喇叭输出(是否正常)" value={data.extHorn} readOnly />
              <Input addonBefore="内置喇叭输出(是否正常)" value={data.horn} readOnly />
              <Input addonBefore="TTS语音播放(是否正常)" value={data.tts} readOnly />
              <Input addonBefore="显示屏幕亮度(是否正常)" value={data.displayBr} readOnly />
              <Input addonBefore="显示屏背光开关(是否正常)" value={data.displaySwitch} readOnly />
              <Input addonBefore="通话(是否正常)" value={data.call} readOnly />

            </div>
          </div>

        </div>

      </div>
    </div>
  }
}
