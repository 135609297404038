const paramData = {
  "jt808:808protocolversion": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "2013" },
  "jt808:citystandard": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "4101" },

  "terminal_alarm_adas:hmwl1": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },
  "terminal_alarm_adas:pdwl1": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },
  "terminal_alarm_dms:upheadl1": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },
  //"terminal_alarm_adas:zebral1": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },
  "terminal_alarm_dms:lookroundl1": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },
  "terminal_alarm_dms:yawl1": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },
  "terminal_alarm_dms:smokingl1": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },
  //"terminal_alarm_dms:nsbl1": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },
  "terminal_alarm_adas:ldwl1": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },
  "terminal_alarm_dms:phoningl1": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },
  //"terminal_alarm_adas:flcl1": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },
  "terminal_alarm_adas:fcwl1": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },
  "terminal_alarm_dms:downheadl1": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },
  //"terminal_alarm_adas:obsl1": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },
  "terminal_alarm_dms:occl1": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },
  "terminal_alarm_dms:downlookl1": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },
  "terminal_alarm_dms:closeeyel1": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },
  "terminal_alarm_dms:nofacel1": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },
  "terminal_alarm_dms:inbl1": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },

  "terminal_alarm_adas:hmwl2": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },
  "terminal_alarm_dms:upheadl2": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },
  "terminal_alarm_adas:pdwl2": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },
  //"terminal_alarm_adas:zebral2": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },
  "terminal_alarm_dms:lookroundl2": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },
  "terminal_alarm_dms:yawl2": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },
  "terminal_alarm_dms:smokingl2": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },
  //"terminal_alarm_dms:nsbl2": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },
  "terminal_alarm_adas:ldwl2": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },
  "terminal_alarm_dms:phoningl2": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },
  //"terminal_alarm_adas:flcl2": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },
  "terminal_alarm_adas:fcwl2": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },
  "terminal_alarm_dms:downheadl2": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },
  //"terminal_alarm_adas:obsl2": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },
  "terminal_alarm_dms:occl2": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },
  "terminal_alarm_dms:downlookl2": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },
  "terminal_alarm_dms:closeeyel2": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },
  "terminal_alarm_dms:nofacel2": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },
  "terminal_alarm_dms:inbl2": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "1" },

  "terminal_alarm_adas_fcw:speedthreshold": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "0" },
  "terminal_alarm_dms_facematchok:speedthreshold": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "0" },
  "terminal_alarm_dms_downhead:speedthreshold": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "0" },
  //"terminal_alarm_adas_obs:speedthreshold": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "0" },
  "terminal_alarm_dms_lookround:speedthreshold": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "0" },
  "terminal_alarm_dms_noface:speedthreshold": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "0" },
  "terminal_alarm_dms_lookdown:speedthreshold": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "0" },
  "terminal_alarm_adas_hmw:speedthreshold": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "0" },
  "terminal_alarm_dms:speedthreshold": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "30" },
  "terminal_alarm_adas_pdw:speedthreshold": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "0" },
  "terminal_alarm_dms_closeeye:speedthreshold": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "0" }, 
  "terminal_alarm_bsd:speedthreshold": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "0" },
  //"terminal_alarm_adas_rds:speedthreshold": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "0" }, 
  "terminal_alarm_dms_phone:speedthreshold": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "0" },
  //"terminal_alarm_dms_handoff:speedthreshold": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "0" },
  "terminal_alarm_dms_smoking:speedthreshold": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "0" },
  //"terminal_alarm_adas_flc:speedthreshold": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "0" },
  "terminal_alarm_dms_facematchcge:speedthreshold": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "0" },
  "terminal_alarm_adas:speedthreshold": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "30" },
  "terminal_alarm_dms_yaw:speedthreshold": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "0" },
  //"terminal_alarm_dms_handsgloff:speedthreshold": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "0" },
  "terminal_alarm_dms_facematchfail:speedthreshold": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "0" },
  //"terminal_alarm_adas_zba:speedthreshold": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "0" },
  "terminal_alarm_dms_uphead:speedthreshold": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "0" },
  //"terminal_alarm_dms_seatbelt:speedthreshold": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "0" },
  "terminal_alarm_dms_occ:speedthreshold": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "0" },
  "terminal_alarm_adas_ldw:speedthreshold": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "0" },
  "terminal_alarm_dms_ido:speedthreshold": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "0" },

  "terminal_alarm_dms_lookround:begintoend": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "5" },
  "terminal_alarm_adas_fcw:begintoend": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "5" },
  "terminal_alarm_dms_facematchok:begintoend": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "5" },
  "terminal_alarm_dms_facematchfail:begintoend": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "5" }
  ,"terminal_alarm_dms_ido:begintoend": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "5" }
  ,"terminal_alarm_adas_hmw:begintoend": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "5" },
  //"terminal_alarm_dms_handoff:begintoend": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "5" },
  "terminal_alarm_dms_downhead:begintoend": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "5" },
  "terminal_alarm_dms_yaw:begintoend": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "5" },
  "terminal_alarm_dms_lookdown:begintoend": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "5" },
  //"terminal_alarm_adas_zba:begintoend": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "5" },
  "terminal_alarm_dms_smoking:begintoend": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "5" },
  "terminal_alarm_dms_facematchcge:begintoend": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "5" },
  "terminal_alarm_dms_phone:begintoend": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "5" },
  //"terminal_alarm_adas_flc:begintoend": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "5" },
  "terminal_alarm_adas_ldw:begintoend": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "5" },
  "terminal_alarm_adas_pdw:begintoend": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "5" },
  "terminal_alarm_dms_noface:begintoend": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "5" },
  //"terminal_alarm_dms_handsgloff:begintoend": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "5" },
  //"terminal_alarm_adas_obs:begintoend": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "5" },
  "terminal_alarm_dms_uphead:begintoend": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "5" },
  //"terminal_alarm_adas_rds:begintoend": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "5" },
  "terminal_alarm_dms_closeeye:begintoend": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "5" },
  //"terminal_alarm_dms_seatbelt:begintoend": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "5" },
  "terminal_alarm_dms_occ:begintoend": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "5" },

  "terminal_alarm_dms_phone:pics": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "3" },
  //"terminal_alarm_dms_handoff:pics": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "3" },
  "terminal_alarm_dms_yaw:pics": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "3" },
  "terminal_alarm_adas_ldw:pics": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "3" },
  "terminal_alarm_dms_smoking:pics": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "3" },
  "terminal_alarm_adas_fcw:pics": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "3" },
  "terminal_alarm_dms_ido:pics": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "3" },
  "terminal_alarm_dms_closeeye:pics": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "3" },
  "terminal_alarm_dms_downhead:pics": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "3" },
  "terminal_alarm_dms_occ:pics": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "3" },
  "terminal_alarm_dms_facematchfail:pics": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "3" },
  //"terminal_alarm_adas_obs:pics": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "3" },
  "terminal_alarm_dms_uphead:pics": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "3" },
  //"terminal_alarm_dms_handsgloff:pics": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "3" },
  "terminal_alarm_dms_noface:pics": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "3" },
  //"terminal_alarm_adas_rds:pics": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "3" },
  //"terminal_alarm_adas_flc:pics": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "3" },
  "terminal_alarm_dms_facematchcge:pics": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "3" },
  "terminal_alarm_dms_facematchok:pics": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "3" },
  "terminal_alarm_dms_lookdown:pics": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "3" },
  //"terminal_alarm_dms_seatbelt:pics": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "3" },
  "terminal_alarm_adas_hmw:pics": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "3" },
  //"terminal_alarm_adas_zba:pics": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "3" },
  "terminal_alarm_dms_lookround:pics": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "3" },
  "terminal_alarm_adas_pdw:pics": { "create": 1666584681713, "ov": 0, "update": 1666843905361, "value": "3" },

};

export default paramData;


