type LogType = 'data' | 'flow';

const splitStr = '===>';
let disableAll = false;

if(process.env.NODE_ENV !== 'production'){
  // 不是生产环境，打印日志
  disableAll = false;
}



export class Log {
  private prefix: string;
  private disabled = false;

  constructor(prefix: string = '') {
    this.prefix = prefix;
  }

  private log = (type: LogType, ...data) => {
    if (disableAll || this.disabled) {
      return;
    }
    console.log(this.prefix, type, splitStr, ...data);
    // console.trace();

  }

  // setPrefix = (prefix: string = '') => {

  // }

  data = (...data) => {
    this.log('data', ...data);
  }

  flow = (...data) => {
    this.log('flow', ...data);
  }

  disable = () => {
    this.disabled = true;
  }

}