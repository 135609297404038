import React from "react";
import { Input, Form, Row, Col, Button, message, Spin } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { inject, observer } from "mobx-react";
import _ from "lodash";
import { CommonStore, DeviceStore } from "../../../../src/stores";
import "./index.scss";

const transParam = (obj) => {
  const arr: Array<string> = [];
  _.each(obj, (i, idx) => {
    arr.push(`${idx}=${i};`);
  });
  return arr.join("");
};

const formItemLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 10,
  },
};

interface IProps extends FormComponentProps {
  devId: string;
  devType: number;
  commonStore?: CommonStore;
  deviceStore?: DeviceStore;
}

@inject("commonStore", "deviceStore")
@observer
class BsdParams extends React.Component<IProps> {
  state = {
    fields: [],
    keyVal: "",
    saveLoading: false,
    detailLoading: false,
  };

  getParamsDetail = () => {
    // 获取参数详情
    const { commonStore, devId, devType } = this.props;
    this.setState({ detailLoading: true });
    const params = {
      type: 0,
      param: "",
    };
    commonStore!.noInfoZhiling(devId, 1004, devType, params).then(
      (res: any) => {
        this.setState({ detailLoading: false });
        if (res.error && res.error.length) {
          message.error("查询adas参数失败");
        } else {
          res.content &&
            !res.content.startsWith("<") &&
            this.setState({ fields: _.compact(res.content.split(";")) });
        }
      },
      (err) => {
        message.error("查询adas参数失败");
        this.setState({ detailLoading: false });
      }
    );
  };

  componentDidMount(): void {
    // 通过设备 走wx获取参数数据
    // this.getParamsDetail();
    // 通过平台 走接口获取参数数据
    const val = this.props.deviceStore?.devConfigPara.adas_param?.value || "";
    this.setState({
      fields: _.compact(val.split(";")),
    });
  }

  handleSubmit = (e) => {
    const {
      devId,
      devType,
      commonStore,
      form: { validateFieldsAndScroll },
    } = this.props;
    e.preventDefault();
    validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ saveLoading: true });
        const params = {
          type: 1, // 0-获取参数 1-设置参数
          param: transParam(values),
        };
        commonStore!.noInfoZhiling(devId, 1004, devType, params).then(
          (res: any) => {
            this.setState({ saveLoading: false });
            if (res.error && res.error.length) {
              message.error("保存失败");
            } else {
              message.success("保存成功");
            }
          },
          (err) => {
            message.error("保存失败");
            this.setState({ saveLoading: false });
          }
        );
      }
    });
  };

  render() {
    const { fields, keyVal, saveLoading, detailLoading } = this.state;
    const { getFieldDecorator } = this.props.form;
    const paramsArr = _.filter(fields, (f) =>
      (f as string)
        .split("=")[0]
        .toLocaleLowerCase()
        .includes(keyVal.toLocaleLowerCase())
    );
    return (
      <div className="params-div">
        <div className="search">
          <div>
            <Input
              placeholder="请输入关键字搜索字段"
              style={{ width: 260 }}
              value={keyVal}
              onChange={(e) => this.setState({ keyVal: e.target.value })}
              allowClear
            />
          </div>
          <div>
            {/* <Button
              type="primary"
              style={{ marginRight: 12 }}
              onClick={this.getParamsDetail}
              loading={detailLoading}
            >
              刷新
            </Button> */}
            <Button
              type="primary"
              htmlType="submit"
              onClick={this.handleSubmit}
              loading={saveLoading}
            >
              保存
            </Button>
          </div>
        </div>
        <Spin spinning={detailLoading}>
          <Form {...formItemLayout}>
            <Row gutter={24}>
              {paramsArr.map((i, idx) => {
                const itemArr = (i as string).split("=");
                return (
                  <Col span={12} key={idx}>
                    <Form.Item label={itemArr[0]}>
                      {getFieldDecorator(itemArr[0], {
                        rules: [
                          // {
                          //   required: true,
                          //   message: "请输入!",
                          // },
                          // {
                          //   pattern: /^[0-9]+([.][0-9]{1,})?$/,
                          //   message: "请输入非负数字!",
                          // },
                        ],
                        initialValue: itemArr[1],
                      })(<Input placeholder="请输入" />)}
                    </Form.Item>
                  </Col>
                );
              })}
            </Row>
          </Form>
        </Spin>
      </div>
    );
  }
}

export default Form.create<IProps>()(BsdParams);
