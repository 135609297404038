import { http, } from '../../src/utils/http';
import { ReqData } from 'src/models';
import { observable, action, computed } from 'mobx';
 
export class HomeStore {

  @observable customerId = '';
  @observable productId = '';
  productCode = '';
  customerCode = '';

  @observable verStaData: any;
  @observable verInfoData: any;
  @observable loading = false;

  @observable.ref otaTrend: any;

  reqCustomer = new ReqData<any, Customer2>(
    'comm/customer',
    {},
    { key: 'customerId', parseFunc: res => res.data }
  )

  reqProduct = new ReqData<any, Product>(
    'product-info/list',
    {},
    { key: 'productId', parseFunc: res => res.data }
  )

  @computed
  get customerName(){
    let name = '';
    const version = this.reqCustomer.dataMap.get(this.customerId);
    if(version){
      name = version.customerName
    }
    return name;

  }

  @action
  changeCusId = (id: string) => {
    this.customerId = id;
    const customer = this.reqCustomer.dataMap.get(id);
    if(customer){
      this.customerCode = customer.customerCode;
    }
  }

  @action
  changeProId = (id: string) => {
    this.productId = id;
    // debugger;
    const product = this.reqProduct.dataMap.get(id);
    if(product){
      this.productCode = product.productCode;
    }
  }

  getVerSta = () => {
    const url = 'home/statistics';
    http.post(url).then(res => {
      this.verStaData = res.data;
    })
  }

  @action
  getVerInfo = (customerId: string, productId: string, productCode: string, customerCode: string) => {
    const url = 'home/data';
    const params = {
      customerId, productId, productCode, customerCode
    }
    this.loading = true;
    http.post(url, params).then(res => {
      this.verInfoData = res.data;
      this.loading = false;
    }, err => {
      this.loading = false;
    })
  }

  @action
  getOtaTrand = (productCode: string, customerCode: string) => {
    const url = 'home/trend';
    const params = {
      productCode, customerCode
    }

    http.post(url, params).then(res => {
      this.otaTrend = res.data;
    }, err => {
      this.otaTrend = null;
    })
  }
}