import { observable, computed, action, runInAction } from 'mobx';

import cookies from 'js-cookie';
import { ThemeType } from '../../src/types/index';
import { checkFull } from '../../src/utils/mix';


export const colorMap = {
  blue: '#4D547D',
  blue2: '#6070FB',
  green: '#52c41a',
  dark: '#000',
  red: '#FB6060',
}

const themes: { [k in ThemeType]?: Object } = {};
for(let theme in colorMap){
  const color: string = colorMap[theme];
  themes[theme] = {
    '@primary-color': color,
    '@btn-primary-bg': color,
  }

}

interface windowDimensionsType {
  width: number;
  height: number;
}
export class UiStore {
  @observable lang = cookies.get('lang') || "en_US";
  @observable theme = cookies.get('theme') || 'blue';
  @observable isFull = false;
  @observable showWarnDialog = true;

  // .struct 确保不会通知观察者，除非尺寸对象以深度相等的方式改变
  @observable.struct windowDimensions;

  constructor() {
    this.windowDimensions = this.getWindowDimensions();
    window.addEventListener('resize', () => {
      runInAction(() => {
        this.windowDimensions = this.getWindowDimensions();
        this.isFull = checkFull();
      })
    })
  }

  getWindowDimensions = () => {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }

  @action
  toggleShowWarnDialog = () => {
    this.showWarnDialog = !this.showWarnDialog;
  }


  getThemeColor = (theme: ThemeType) => {
    return colorMap[theme]
  }

}

