import { observable, action, runInAction } from 'mobx';
import { eq } from '../../src/utils/mix';
import { http } from '../../src/utils/http';

export class LoginStore {
  @observable loginLoading: boolean = false;


  @action
  RequestLogin = (para) => {
    const url = '/login';
    return http.post(url, para).then(res => {
      runInAction(() => {

        console.log('DevOpsrpt res.data=', res.data);


      });
    }, err => {
      runInAction(() => {

      });
    })
  }
}