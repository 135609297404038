import { Progress } from 'antd';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { AuthStore, DeviceStore } from 'src/stores';
import { formatTime, getSearchParams, transDict } from 'src/utils/mix';
import consts from 'src/consts';
import { toJS } from 'mobx';
import { getChannelStr, getStoreStr, intlGet } from 'src/utils';

const channelStatus = {
  0: '异常',
  1: '正常',
  2: '录像中',
}

const storageType = {
  0: 'SD卡',
  1: '硬盘',
  2: '防护存储器',
}

const storageState = {
  1: '正常',
  0: '未插卡',   // 未知
  '-1': '错误',
  '-2': '未格式化',
}

const speedSignalMap = {
  '-1': '未知来源',
  0: 'GPS',
  1: 'CAN',
  2: '硬线',
}

const valManufactorMap = {
  '-1': '未接',
  '0': '诺冠',
  '1': '东江',
  '2': '宜志',
  '3': "自研拉线电机"
}

const statusMap = {
  '-1': '未知',
  '0': '异常 ',
  '1': '正常',
}

const gasMap = {
  '-1': '未知',
  '1': '正常 ',
  '2': '过压',
  '3': '欠压',
}

interface H5InfoProps {
  deviceStore?: DeviceStore;
  authStore?: AuthStore;
}

@inject('deviceStore', 'authStore')
@observer
export default class H5Info extends React.Component<H5InfoProps>{

  plateNo = '';

  constructor(props) {
    super(props);

    const { arr } = getSearchParams();
    const [a0, a1, a2, plateNo] = arr.split('_');
    this.plateNo = decodeURI(plateNo);
  }

  formatValue = (value) => {
    if (value || value === 0) {
      return value;
    } else {
      return consts.defaultEmptyStr;
    }
  }

  render() {

    const { deviceStore, authStore } = this.props;
    const { device_type = {} } = authStore!.dict || {};
    const { devConsoleDetailInfo, } = deviceStore!;
    console.log('devConsoleDetailInfo', toJS(devConsoleDetailInfo));
    const speed = _.get(devConsoleDetailInfo, 'devState.speed.value', 0);
    const speedSignal = speedSignalMap[_.get(devConsoleDetailInfo, 'devState.speed.signal', -1)];
    // const devType = transDict[authStore!.dict[_.get(devConsoleDetailInfo, 'devType', -1)]];
    const devType = transDict(device_type[_.get(devConsoleDetailInfo, 'devType', -1)])
    const softwareVer = _.get(devConsoleDetailInfo, 'softwareVer', consts.defaultEmptyStr);
    const softwareCode = _.get(devConsoleDetailInfo, 'softwareCode', consts.defaultEmptyStr);

    const imsi = this.formatValue(_.get(devConsoleDetailInfo, 'devState.imsi'));
    const imei = this.formatValue(_.get(devConsoleDetailInfo, 'devState.imei'));
    const iccid = this.formatValue(_.get(devConsoleDetailInfo, 'devState.iccid'));
    const sn = this.formatValue(_.get(devConsoleDetailInfo, 'devState.sn'));

    const gpsState = _.get(devConsoleDetailInfo, 'devState.gps.status', 0) ? '已定位' : '未定位';
    const gpsMax = _.get(devConsoleDetailInfo, 'devState.gps.max', 0);
    const gpsAvailable = _.get(devConsoleDetailInfo, 'devState.gps.available', 0);
    const latitude = _.get(devConsoleDetailInfo, 'devState.gps.latitude', 0);
    const longitude = _.get(devConsoleDetailInfo, 'devState.gps.longitude', 0);


    const simState = _.get(devConsoleDetailInfo, 'devState.sim.status', 0);
    const options1 = ['未连接', '已连接'];
    const simStateEl = <MyStatus status={simState} texts={options1} />;
    const dbm = _.get(devConsoleDetailInfo, 'devState.sim.dbm', 0);
    const net = _.get(devConsoleDetailInfo, 'devState.sim.net', consts.defaultEmptyStr);
    // 运维链路
    const jmServer = _.get(devConsoleDetailInfo, 'devState.jmServer', 0);
    const server1 = _.get(devConsoleDetailInfo, 'devState.cms[0].connect', 0);
    const server2 = _.get(devConsoleDetailInfo, 'devState.cms[1].connect', 0);
    const server3 = _.get(devConsoleDetailInfo, 'devState.cms[2].connect', 0);
    const jmServerEl = <MyStatus status={jmServer} texts={options1} />
    const serverEl1 = <MyStatus status={server1} texts={options1} />
    const serverEl2 = <MyStatus status={server2} texts={options1} />
    const serverEl3 = <MyStatus status={server3} texts={options1} />

    // 制动器
    let _valManufactor = _.get(devConsoleDetailInfo, 'devState.valve.manufactor', -1);
    const valManufactor = valManufactorMap[_valManufactor];
    const valStatus = statusMap[_.get(devConsoleDetailInfo, 'devState.valve.status', -1)];
    const valPressureRear = statusMap[_.get(devConsoleDetailInfo, 'devState.valve.pressure.rear', -1)];
    const valPressureFront = statusMap[_.get(devConsoleDetailInfo, 'devState.valve.pressure.front', -1)];
    const valElectric = statusMap[_.get(devConsoleDetailInfo, 'devState.valve.electric', -1)];
    const valGasFront = gasMap[_.get(devConsoleDetailInfo, 'devState.valve.gas.front', -1)];
    const valGasRear = gasMap[_.get(devConsoleDetailInfo, 'devState.valve.gas.rear', -1)];

    const acc = _.get(devConsoleDetailInfo, 'devState.acc');
    const accOps = ['关', '开'];
    const accJsx = <MyStatus status={acc} texts={accOps} />

    const can = _.get(devConsoleDetailInfo, 'devState.can.status', -1);
    const canStatusMap = {
      '-1': '未知',
      '0': '无信号 ',
      '1': '有信号',
    }
    const canJsx = <MyStatus status={can} texts={canStatusMap} />

    const radar = _.get(devConsoleDetailInfo, 'devState.radar[0]', 0);
    const radarOps = ['未接入', '正常'];
    const radarJsx = <MyStatus status={radar} texts={radarOps} />

    // 分区状态
    const partitionMap = {
      '-1': '未知',
      '0': '正常',
      '1': '异常',
    }
    const partitionLog = _.get(devConsoleDetailInfo, 'devState.partition.log', -999);
    const partitionUserdata = _.get(devConsoleDetailInfo, 'devState.partition.userdata', -999);
    const partitionLogJsx = <MyStatus status={partitionLog} texts={partitionMap} defaultValue={-999} className='power' />
    const partitionUserdataJsx = <MyStatus status={partitionUserdata} texts={partitionMap} defaultValue={-999} className='power' />
    // 电源信息
    const powerTypeMap = {
      0: '外部电源',
      1: '内部电源',
      2: '未知',
    };
    const powerStatusMap = {
      0: '正常',
      1: '欠压',
      2: '掉电',
      3: '未知',
    }
    const powerType = _.get(devConsoleDetailInfo, 'devState.power.type', -1);
    const powerStatus = _.get(devConsoleDetailInfo, 'devState.power.status', -1);
    const powerExdown = _.get(devConsoleDetailInfo, 'devState.power.exdown', consts.defaultEmptyStr);

    const powerTypeJsx = powerType === -1 ? consts.defaultEmptyStr : powerTypeMap[powerType];
    const powerStatusJsx = <MyStatus status={powerStatus} texts={powerStatusMap} defaultValue={-1} className='power' />

    const updateTime = formatTime(_.get(devConsoleDetailInfo, 'devState.uploadTime', 0), 1);

    let valveJsx = <div className='item'>
        <div className='label'>厂家</div>
        <div className='value'>{valManufactor}</div>
      </div>
    // 视频通道
    let channelStr: any = '';
    let storageExcStr: any = '';
    if (devConsoleDetailInfo) {
      const chOptions = ['未检到', '已连接'];
      channelStr = _.get(devConsoleDetailInfo, 'devState.channle.list', [])
        .map((x, i) => {
          // 通道状态，0.未接入  1.正常  2.录像中
          const state = x.state === 0 ? 0 : 1;
          return <div className='item' key={i}>
            <div className='label'>{intlGet('channel')}{x.channelId}:</div>
            <div className='value'>
              <MyStatus status={state} texts={chOptions} />
            </div>
          </div>
        });
      // 存储器 -1.错误   -2.未格式化  1.正常  0.未知   
      // type 存储器类型 ，0.SD卡   1.硬盘   2.防护存储器
      const storageArr: any[] = _.get(devConsoleDetailInfo, 'devState.storage.list', []);
      // 硬盘
      const st1 = storageArr.find(x => x.type === 1);
      const storage1 = st1 && st1.state === 1 ? 1 : 0;
      // 防护存储器
      const st2 = storageArr.find(x => x.type === 2);
      const storage2 = st2 && st2.state === 1 ? 1 : 0;
      // SD卡
      const st0 = storageArr.filter(x => x.type === 0);
      const storage3 = st0[0] && st0[0].state === 1 ? 1 : 0;
      const storage4 = st0[1] && st0[1].state === 1 ? 1 : 0;

      storageExcStr = <>
        <div className='item'>
          <div className='label'>硬盘:</div>
          <div className='value'>
            <MyStatus status={storage1} texts={chOptions} />
          </div>
        </div>
        <div className='item'>
          <div className='label'>防护存储器:</div>
          <div className='value'>
            <MyStatus status={storage2} texts={chOptions} />
          </div>
        </div>
        <div className='item'>
          <div className='label'>SD卡1:</div>
          <div className='value'>
            <MyStatus status={storage3} texts={chOptions} />
          </div>
        </div>
        <div className='item'>
          <div className='label'>SD卡2:</div>
          <div className='value'>
            <MyStatus status={storage4} texts={chOptions} />
          </div>
        </div>
      </>;
    }

    return <div className='h5InfoCom'>
      <div className='topLine'>
        <div>车牌号</div>
        <div className='plateNo'>{this.plateNo}</div>
        <div className='speed'>{speed}km/h({speedSignal})</div>
        <div className='time'>{updateTime}</div>
      </div>
      <div className='info'>
        <div className='item'>
          <div className='label'>设备类型:</div>
          <div className='value'>{devType}</div>
        </div>
        <div className='item'>
          <div className='label'>版本名称:</div>
          <div className='value'>{softwareVer}</div>
        </div>
        <div className='item'>
          <div className='label'>版本号:</div>
          <div className='value'>{softwareCode}</div>
        </div>
      </div>
      <div className='info'>
        <div className='item'>
          <div className='label'>IMEI:</div>
          <div className='value'>{imei}</div>
        </div>
        <div className='item'>
          <div className='label'>IMSI:</div>
          <div className='value'>{imsi}</div>
        </div>
        <div className='item'>
          <div className='label'>SN:</div>
          <div className='value'>{sn}</div>
        </div>
      </div>

      <div className='gps'>
        <div className='title'>
          <div className='line'></div>
          GPS信息
        </div>
        <div className='content'>
          <div className='item'>
            <div className='label'>状态</div>
            <div className='value'>{gpsState}</div>
          </div>
          <div className='item'>
            <div className='label'>最大卫星数</div>
            <div className='value'>{gpsMax}</div>
          </div>
          <div className='item'>
            <div className='label'>可用卫星数</div>
            <div className='value'>{gpsAvailable}</div>
          </div>
          <div className='item'>
            <div className='label'>经度</div>
            <div className='value'>{latitude}</div>
          </div>
          <div className='item'>
            <div className='label'>纬度</div>
            <div className='value'>{longitude}</div>
          </div>
        </div>
      </div>
      <div className='gps'>
        <div className='title'>
          <div className='line'></div>
          制动器
        </div>
        <div className='content'>
          {valveJsx}
          <div className='item'>
            <div className='label'>制动器通讯状态</div>
            <div className='value'>{valStatus}</div>
          </div>
          <div className='item'>
            <div className='label'>制动器工作状态</div>
            <div className='value'>{valElectric}</div>
          </div>
        </div>
      </div>
      <div className='sim'>
        <div className='title'>
          <div className='line'></div>
          网络状态
        </div>
        <div className='content'>
          <div className='item'>
            <div className='label'>联网状态</div>
            <div className='value'>{simStateEl}</div>
          </div>
          <div className='item'>
            <div className='label'>DBM信号值</div>
            <div className='value'>{dbm}</div>
          </div>
          <div className='item'>
            <div className='label'>网络类型</div>
            <div className='value'>{net}</div>

          </div>
        </div>
        <div className='content'>
          <div className='item'>
            <div className='label'>运维链路:</div>
            <div className='value'>{jmServerEl}</div>
          </div>
          <div className='item'>
            <div className='label'>第一服务器:</div>
            <div className='value'>{serverEl1}</div>
          </div>
          <div className='item'>
            <div className='label'>第二服务器:</div>
            <div className='value'>{serverEl2}</div>
          </div>
          <div className='item'>
            <div className='label'>第三服务器:</div>
            <div className='value'>{serverEl3}</div>
          </div>
        </div>
      </div>

      {channelStr ? <div className='icons channel'>
        <div className='title'>
          <div className='line'></div>
          视频通道
        </div>
        <div className='content'>
          {channelStr}
        </div>
      </div> : null}
      {storageExcStr ? <div className='icons'>
        <div className='title'>
          <div className='line'></div>
          存储器状态
        </div>
        <div className='content'>
          {storageExcStr}
        </div>
      </div> : null}

      <div className='otherInfo '>
        <div className='title'>
          <div className='line'></div>
          分区状态
        </div>
        <div className='content'>
          <div className='item'>
            <div className='label'>log分区状态 :</div>
            <div className='value'>{partitionLogJsx}</div>
          </div>
          <div className='item'>
            <div className='label'>userdata分区状态 :</div>
            <div className='value'>{partitionUserdataJsx}</div>
          </div>
        </div>
      </div>
      <div className='otherInfo '>
        <div className='title'>
          <div className='line'></div>
          电源信息
        </div>
        <div className='content'>
          <div className='item'>
            <div className='label'>电源类型 :</div>
            <div className='value'>{powerTypeJsx}</div>
          </div>
          <div className='item'>
            <div className='label'>电源状态 :</div>
            <div className='value'>{powerStatusJsx}</div>
          </div>
          <div className='item'>
            <div className='label'>异常掉电次数 :</div>
            <div className='value'>{powerExdown}</div>
          </div>
        </div>
      </div>

      <div className='otherInfo '>
        <div className='title'>
          <div className='line'></div>
          其他信息
        </div>
        <div className='content'>
          <div className='item'>
            <div className='label'>ACC状态:</div>
            <div className='value'>{accJsx}</div>
          </div>
          <div className='item'>
            <div className='label'>CAN状态 :</div>
            <div className='value'>{canJsx}</div>
          </div>
          <div className='item'>
            <div className='label'>雷达状态 :</div>
            <div className='value'>{radarJsx}</div>
          </div>
        </div>
      </div>

    </div>
  }
}

interface H5Info2Props {
  deviceStore?: DeviceStore;
}


@inject('deviceStore')
@observer
export class H5Info2 extends React.Component<H5Info2Props>{


  formatStorageData = (storageData: any[]) => {
    const result = [
      { type: 1, state: 0, total: 0, free: 0, index: 1 },
      { type: 2, state: 0, total: 0, free: 0, index: 1 },
      { type: 0, state: 0, total: 0, free: 0, index: 1 },
      { type: 0, state: 0, total: 0, free: 0, index: 2 },
    ];
    storageData.forEach(x => {
      const { type, index, state, total, free } = x;
      if (type === 0) {
        if (index === 1) {
          result[2] = x
        } else if (index === 2) {
          result[3] = x
        }
      } else if (type === 1) {
        result[0] = x
      } else if (type === 2) {
        result[1] = x
      }
    });
    return result;
  }

  render() {
    const { deviceStore, } = this.props;
    const { devConsoleDetailInfo } = deviceStore!;
    const storageCount = _.get(devConsoleDetailInfo, 'devState.storage.count', 0);
    const storageList = this.formatStorageData(_.get(devConsoleDetailInfo, 'devState.storage.list', []));

    const channelCount = _.get(devConsoleDetailInfo, 'devState.channle.count', 0);
    const channelList = _.get(devConsoleDetailInfo, 'devState.channle.list', []);
    console.log('storageList', storageList);
    const options = ['异常', '正常'];

    return <div className='h5Info2Com'>
      <div className='storage'>
        <div className='topLine'>
          <div className='line'></div>
          <div className='topTitle'>存储器</div>
          <div className='count'>({storageCount}张)</div>
        </div>
        <div className='content'>
          {storageList.map((x, i) => {
            const { type, index, state, total, free } = x;
            const percent = 100 - Math.round(free / total * 100);
            const totalStore = (total / 1024 / 1024).toFixed(1);
            const freeStore = (free / 1024 / 1024).toFixed(1);
            const stateEl = <MyStatus status={state} texts={storageState} />

            return <div className='item' key={`${i}_${Date.now()}`}>
              <div className='detailInfo'>
                <div className='name'>{storageType[type]}{type === 0 ? index : ""}</div>
                <div className='status'>{stateEl}</div>
                <div className='size'>{freeStore}G可用，共{totalStore}G</div>
              </div>
              <Progress percent={percent} showInfo={false} strokeColor="#1890FF" />
            </div>
          })}
        </div>
      </div>
      <div className='channel'>
        <div className='topLine'>
          <div className='line'></div>
          <div className='topTitle'>视频通道</div>
          <div className='count'>({channelCount}通道)</div>
        </div>
        <div className='content'>
          {channelList.map((x, i) => {
            const { interface: inter, channelId, state, ip } = x;
            return <div className='item' key={channelId}>
              <div className='detailInfo'>
                <div className='name'>通道{channelId}</div>
                <div className='status'>
                  <div className={`point s${state}`}></div>
                  {channelStatus[state]}
                </div>
                {/* <div className='size'>接口: ipc</div> */}
              </div>
            </div>
          })}
        </div>
      </div>
    </div>
  }
}


interface MyStatusProps {
  status: number,
  texts: Object,
  defaultValue?: number | string,
  className?: string,
}
const MyStatus = (props: MyStatusProps) => {
  const { status, texts, defaultValue, className='' } = props;
  const satusClass = `s${status}`;
  const text = texts[status] || consts.defaultEmptyStr;
  const hasPoint = defaultValue !== status;

  return <div className={`myStatusCom ${satusClass} ${className}`}>
    {hasPoint &&<div className={`point ${satusClass}`} ></div>}
    <div className='text' >{text}</div>
  </div>
}
