import uuidv1 from 'uuid/v1';
import EventEmitter from 'events';
import { eq, Log } from 'src/utils';


const log = new Log('webscoket.ts');


/**
  1	订阅设备实时消息
  2	取消订阅设备实时信息
  3	指令控制
  3001	统一消息回复
  2001	GPS实时信息
  2002	报警实时信息
  2003	报警媒体文件实时信息
  2004	故障实时信息
  1001  心跳
*/
export type MessageType = 1 | 2 | 3 | 3001 | 2001 | 2002 | 2003 | 2004 | 2005 | 1001 | 105;

const messageTypeMap: { [key in MessageType]: string } = {
  1: '订阅设备实时消息',
  2: '取消订阅设备实时信息',
  3: '指令控制',
  3001: '统一消息回复',
  2001: 'GPS实时信息',
  2002: '报警实时信息',
  2003: '报警媒体文件实时信息',
  2004: '故障实时信息',
  2005: '',
  1001: '心跳',
  105: '语音',
}

export interface WSRequestParams {
  devId: string;
  devType?: number,
  gpsMain?: number,
  content?: Object,
  act?: number,
  devRegSn?: string, //设备注册序列号(部标)
  devRegPhone?: string, //设备注册手机号(部标)
  timeout?: number,
};


/**
 * @desc 报警实时信息
 */
export interface WSResponse2002 {
  plateNo: string,   //车牌号
  vehId: string,  //车辆ID
  devNo: string,    //设备号
  orgName: string,   //机构名称
  alarmId: string,  //报警ID
  alarm: number,     //报警类型
  alarmCtn: string,  //报警内容
  alarmTime: string,   //报警时间
  location: [number, number], //坐标
  speed: number,       //速度
  dir: number,      //方向
  alt: number,      //高度
  address: string,   //详细地址
  acc: number,      //acc
  accuracy: number,
  signal: number,   //信号
  satellite: number, //卫星数
  mode: number      //定位方式
  picRes: Resource[],
  vodRes: Resource[],
  driverName: string,
  faceUrl: string,
  idcard: string,
  phone: string,

  statusInfo: string;
  statusInfoJson?: { 				//状态信息
    sd: number,				//SD卡状态 0：异常 1：正常
    dbm: number,			//信号强度
    sim: number,			//SIM卡状态
    turn: number,			//转向状态 0：未接入 1：已接入
    adasCam: number,	//adas ipc 状态 0：异常 1：正常
    dmsCam: number,		//dms ipc状态 
    ipc0: number,			//ipc0 状态
    ipc1: number,			//ipc1 状态
    ipc2: number,			//ipc2 状态
  },

  key: string; // alarmId
  vehId_alarm: string; // vehId + alarm
  alarmCount: number; // 报警次数， 统计出来的
  plateColor?: string,
}

/**
 * @desc 报警媒体文件实时信息
 */
export interface WSResponse2003 {
  devNo: string,  //设备编号
  vehId: string,
  alarmId: string,   //报警ID
  alarm: number,     //报警类型
  alarmTime: string,   //报警时间
  uploadTime: string,  //上传时间  
  url: string,  //访问地址
  playUrl: string,  //播放地址
  snapshotUrl: string,  //预览地址
  fileName: string,  //文件名
  size: number,  //文件大小
  format: number,   //文件格式 
  speed: number,

  time?: number,
}

/**
 * @desc 故障实时信息
 */
export interface WSResponse2004 {
  plateNo: string,   //车牌号
  vehId: string,   //车辆ID
  devNo: string,    //设备号
  orgName: string,   //机构名称
  faultId: string,  //故障ID
  fault: number,     //故障类型
  faultCtn: string,  //故障内容
  faultTime: string, //故障时间
  location: [number, number],   //坐标
  speed: number,     //速度
  dir: number,      //方向
  alt: number,        //高度
  address: string,  //详细地址
  acc: number,        //acc
  signal: number,     //信号
  satellite: number,   //卫星数
  mode: number        //定位方式
}

export interface WSResponse2005 {
  devId: string,
  fileName: string,
  format: number,
  orgName: string,
  plateNo: string,
  playUrl: string,
  size: number,
  snapshotUrl: string,
  uploadTime: string,
  url: string,
  vehId: string,
}
export interface WSResponse3001 {
  code: number,
  content: Object,
  error: string;
}

export type WSS = WSResponse2002 | WSResponse2003 | WSResponse2004 | WSResponse3001 | WSResponse2005;
export interface WSResponse {
  msgId: string,
  type: number,
  ts: number,
  data: WSS | WSS[],
  code?: 0 | -1, // 0 成功 -1 超时
};



export class WS {
  uri: string;
  websocket!: WebSocket;
  event = new EventEmitter();
  retryTimes: number;  // >= 0
  count = 0;

  constructor(uri, retryTimes = 0) {
    this.uri = uri
    this.retryTimes = retryTimes;
    this.openWebSocket();

    this.event.on('error', (e) => {
      log.data('event error', e);
    })
  }

  openWebSocket = () => {
    if (this.count <= this.retryTimes) {
      if (this.count > 0) {
        log.data(`ws 重连${this.count}次`)
      }
      this.count++;
      const websocket = this.websocket = new WebSocket(this.uri);
      websocket.onopen = (evt) => {
        this.onOpen(evt)
      };
      websocket.onclose = (evt) => {
        this.onClose(evt)
      };
      websocket.onmessage = (evt) => {
        this.onMessage(evt)
      };
      websocket.onerror = (evt) => {
        this.onError(evt)
      };
    }
  }

  close = () => {
    this.event.removeAllListeners();
    this.websocket.close();
  }

  onOpen(evt: Event) {
    log.data("onOpenWs.", evt);
  }

  onClose(evt: CloseEvent) {
    log.data("onCloseWs.", evt);
  }

  onError = (evt: Event) => {
    log.data("onErrorWs.", evt);
  }

  onMessage = (evt: MessageEvent) => {
    const { type, data, ts, msgId, code } = JSON.parse(evt.data);
    // console.log('onMessage', type.toString(), data);
    this.event.emit(type.toString(), {
      type, data, ts, msgId, code
    });
    log.data('ws onMessage', { type, data, ts, msgId, code });
  }

  sendWS = (type: MessageType, data: WSRequestParams[] | {}) => {
    const msgId: string = uuidv1().replace(/-/g, '');
    const ts = Date.now();
    const params = {
      msgId,
      type,
      data,
      ts
    };
    const { OPEN, CLOSED, readyState } = this.websocket;

    if (eq(readyState, OPEN)) {
      this.websocket.send(JSON.stringify(params));
      this.count = 0;
      log.data('sendWS', `type:${type}(${messageTypeMap[type]}), act:${params.data['act']}`, data)
      return msgId;
    } else {
      const map = {
        0: '正在链接中',
        1: '已经链接并且可以通讯',
        2: '连接正在关闭',
        3: '连接已关闭或者没有链接成功',
      }
      log.data('sendWS', map[readyState]);
      this.openWebSocket();
      return '';
    }
  }
}
