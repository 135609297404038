export class FixedLenMap{
  len: number;
  map = new Map();
  constructor(len: number){
    this.len = len;
  }

  add = (key: string, value) => {
    if(key === undefined){
      console.warn('FixedLenArr add key undefined');
    }else{
      this.map.set(key, value);
      if(this.map.size > this.len){
        const firstKey = [...this.map.keys()][0];
        this.map.delete(firstKey);
      }
    }
  }

  has = (key: string) => {
    return this.map.has(key);
  }
}