import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import MyIcon from '../myIcon';
import './index.scss';

export interface LabelAndValueProps {
  label: string,
  value: React.ReactNode,
  icon?: string,
  className?: string,
  separator?: boolean,
  title?: string,
  styleObj?: Object,
  clickValue?: (data: any) => void,
  valueMultiline?: boolean;
}

@observer
export default class LabelAndValue extends React.Component<LabelAndValueProps>{
  render() {
    let { icon, label, value, separator = true, className = '', title,
      clickValue, styleObj = {},valueMultiline=false } = this.props;
    title = title != undefined
      ? title
      : (
        typeof value === 'string'
          ? value
          : ''
      );
    const valueMultilineClass = valueMultiline? 'valueMultiline': null;
    return (
      <div className={`labelAndValueComponent ${className} ${valueMultilineClass} `} style={styleObj}>
        {icon ?
          <MyIcon icon={icon} /> : null
        }
        <span className="label">{label}</span>
        {separator ?
          (<span className="separator">:</span>)
          : null
        }
        <span className="value" title={title}
          {...(clickValue
            ? { onClick: clickValue }
            : {})
          }
        >{value}</span>
      </div>
    )
  }
}

