import { eq, http, copy, Log } from "../utils";
import { runInAction, action, observable } from "mobx";
import {
  CheckCarResponse,
  CheckCarItem,
  FetchStatus,
  PaginationData,
} from "src/types";
import { Online, Device } from "src/models";

const log = new Log("checkCar.store.ts");

// 选择类型 组织 车辆 通道 组织车辆 车辆通道
export type CheckCarSelectType = "org" | "veh" | "cha" | "ov" | "vc";

export interface SelectedItem {
  type: CheckCarSelectType;
  id: string;
  name: string;
  orgName?: string; // type = veh 时 机构名称
  plateNo?: string; // type = cha 时 车牌号
  plateColor?: string; // type = veh 是 车牌颜色

  channels?: Channel[]; // type= vc
  vehicleId?: string;
  originData?: any;
  protocolType?: number;
}

interface IdAndName {
  id: string;
  name: string;
}

export class CheckCarStore {
  @observable selectedCar: CheckCarItem | undefined;
  @observable vehicleList: CheckCarItem[] = [];
  @observable channelList: Channel[] = [];
  @observable vehicleDetail: CheckCarResponse | undefined;
  @observable activeAlarmIndex = 0;

  @observable pageSize = 20;
  @observable page = 0;
  @observable total = 0;
  @observable fetchStatus: FetchStatus = "none";
  @observable fetchVehListStatus: FetchStatus = "none";

  @observable selectedItem: SelectedItem | undefined = undefined;

  orgId = "";
  plateNo = ""; // 用于查询
  vehicleId = "";
  online: Online = -1;

  displayPlateNo = ""; // 只用于显示 车牌-通道，查询等都不使用

  selectType: CheckCarSelectType = "ov";

  setSelectType = (type: CheckCarSelectType) => {
    log.data(`setSelectType type: ${type}`);
    this.selectType = type;
  };

  @action
  changeChannelList = (channelList: Channel[]) => {
    this.channelList = channelList;
  };

  @action
  clear = () => {
    this.selectedCar = undefined;
    this.vehicleList = [];
    this.vehicleDetail = undefined;
    this.activeAlarmIndex = 0;
    this.pageSize = 0;
    this.total = 0;
    this.fetchStatus = "none";
    this.fetchVehListStatus = "none";
    this.selectedItem = undefined;
    this.channelList = [];
  };

  @action
  changeSeletedChannel = (channel: Channel | undefined) => {
    if (channel) {
      const { channelId, channelNo } = channel;
      this.selectedItem = {
        type: "cha",
        id: channelId,
        name: this.displayPlateNo + " " + "通道" + channelNo,
        channels: [channel],
        vehicleId: this.vehicleId,
      };
    }
  };

  @action
  changeSelectedCar = (car: CheckCarItem | undefined, loadDetail = true) => {
    this.selectedCar = car;
    if (car) {
      const { vehicleId, plateNo, orgName, plateColor, channels } = copy(car);
      this.vehicleDetail = undefined;
      loadDetail && this.getVechileDetailFromServer(vehicleId);

      const selectedItem: SelectedItem = {
        id: vehicleId,
        name: plateNo,
        orgName,
        plateColor,
        type: "veh",
        channels,
        vehicleId,
      };
      this.selectCar(selectedItem, copy(car));
      this.changeChannelList(channels);
      this.displayPlateNo = plateNo;
      this.vehicleId = vehicleId;
    } else {
      this.changeChannelList([]);
    }
  };

  @action
  selectCar = (car: SelectedItem, originData?) => {
    if (["veh", "ov", "vc"].includes(this.selectType)) {
      const { id, name, plateColor, orgName, channels = [] } = car;
      this.selectedItem = {
        type: "veh",
        id,
        name,
        plateColor,
        orgName,
        channels,
        originData: originData || copy(car),
      };
    }
  };

  @action
  selectOrg = (org: IdAndName) => {
    if (["org", "ov"].includes(this.selectType)) {
      const { id, name } = org;
      this.selectedItem = {
        type: "org",
        id,
        name,
      };
    }
  };

  @action
  changeActiveAlarmIndex = (index: number) => {
    this.activeAlarmIndex = index;
  };

  @action
  getVechileDetailFromServer = (vehicleId: string) => {
    const url = "oc/workbench/sc/view";
    const params = {
      vehicleId,
    };
    this.fetchStatus = "pending";
    http.post(url, params).then(
      (res) => {
        console.log("getVechileDetailFromServer", res);
        runInAction(() => {
          this.vehicleDetail = res.data;
          this.changeActiveAlarmIndex(0);
          this.fetchStatus = "success";
        });
      },
      (err) => {
        runInAction(() => {
          this.fetchStatus = "fail";
        });
      }
    );
  };

  @action
  getVechileListFromServer = (
    orgId: string,
    plateNo: string,
    page: number,
    pageSize: number,
    online: Online,
    deviceId: string = ""
  ) => {
    const url = "comm/tree/vehNodePage";
    const params = {
      orgId,
      plateNo,
      page,
      pageSize,
      online,
      deviceId,
    };
    this.fetchVehListStatus = "pending";
    return http.post(url, params).then(
      (res) => {
        this.orgId = orgId;
        this.plateNo = plateNo;
        this.online = online;
        // this.deviceId = deviceId;
        runInAction(() => {
          const paginationData: PaginationData<CheckCarItem> = res.data;
          const { items, total } = paginationData;
          this.total = total;
          this.page = page;
          this.pageSize = pageSize;

          this.vehicleList = items;
          this.channelList = [];
          this.fetchVehListStatus = "success";
        });
      },
      (err) => {
        runInAction(() => {
          this.fetchVehListStatus = "fail";
        });
      }
    );
  };

  @action
  getVehTreeList = (
    orgId: string,
    plateNo: string = "",
    page: number,
    pageSize: number,
    online: Online = -1,
    deviceId: string = ""
  ) => {
    const url = "comm/tree/vehNodePage";
    const params = {
      orgId,
      plateNo,
      page,
      pageSize,
      online,
      deviceId,
    };
    this.fetchVehListStatus = "pending";
    return http.post(url, params).then(
      (res) => {
        this.orgId = orgId;
        this.plateNo = plateNo;
        this.online = online;
        // this.deviceId = deviceId;
        runInAction(() => {
          const paginationData: PaginationData<CheckCarItem> = res.data;
          const { total, items } = paginationData;
          this.total = total;
          this.page = page;
          this.pageSize = pageSize;

          this.vehicleList = items;
          this.channelList = [];
          this.fetchVehListStatus = "success";
        });
      },
      (err) => {
        runInAction(() => {
          this.fetchVehListStatus = "fail";
        });
      }
    );
  };
}
