import intl from "react-intl-universal";
import { toJS } from 'mobx';
import moment, { Moment } from 'moment';
import _ from 'lodash';
import { Dict, LabelAndValue } from "src/types";
import consts from "src/consts";
import { http } from ".";
import { formatTime, timeFormatDefaultStr } from "./time";
import { Log } from "./log";

const log = new Log('Utils');
export * from "./log";
export * from './time';

export const intlGet = (key: string, param = {}) => {
  // console.log('intlGet key', key);
  // return key && intl.get(key, param) || key; 
  const res = key && intl.get(key, param);
  if (res) {
    return res;
  } else {
    // log.data(`intlGet key: ${key}, res: ${res}`);
    return key;
  }
}

export const addSpaces = (strArr: (string | number)[]) => {
  return strArr.join(' ');
}

export const translateMenu = (menuPath: string) => {
  const formatedPath = 'menu_' + menuPath.replace('/private/', '').replace(':', '/').toLowerCase();
  return intlGet(formatedPath);
}

type ItemType = 'str' | 'bool' | 'obj';
type storageValue = { [key: string]: any } | string | boolean;

// export const Proxy = ProxyPolyfillFun();

class MyStorage {
  constructor(storage: Storage) {
    this.storage = storage;
  }
  storage: Storage;

  setItem(key: string, value: storageValue, type: ItemType = 'str') {
    let transformValue: any;
    if (type === 'str') {
      transformValue = value;
    } else if (type === 'bool') {
      transformValue = value.toString();
    } else if (type === 'obj') {
      transformValue = JSON.stringify(value);
    }
    this.storage.setItem(key, transformValue);
  }

  getItem<T>(key: string, type: ItemType = 'str') {
    const value = this.storage.getItem(key);
    let result: storageValue = '';
    if (value) {
      // return type === 'str' ? value : JSON.parse(value);
      if (type === 'str') {
        result = value;
      } else if (type === 'bool') {
        result = eq(value, 'true') ? true : false;
      } else if (type === 'obj') {
        result = JSON.parse(value);
      }
    }
    return result as T;
  }

  removeItem(key: string) {
    this.storage.removeItem(key);
  }

  clear() {
    this.storage.clear();
  }
}
export const mySessionStorage = new MyStorage(sessionStorage);
export const myLocalStorage = new MyStorage(localStorage);


export const eq = <T>(a: T, b: T): boolean => {
  return a === b;
}
export const eqByToJS: <T>(a: T, b: T) => boolean = (a, b) => {
  return toJS(a) === b;
}

export const isUndefinded = (value: any) => {
  return typeof value === 'undefined';
}
export const emptyFunc = () => { };
export const emptyEventFunc = () => { };

export const getDefault: <T>(value: T, defaultValue: T) => T = (value, defaultValue) => {
  return (value === undefined || value === null) ? defaultValue : value;
}

// type GetProxyObjByDefault = <T>(obj: T, defaultValue?: string | number) => T;
// export const getProxyObjByDefault: GetProxyObjByDefault = (obj, defaultValue = '- -') => {
//   if (!obj) {
//     obj = {} as any;
//   }
//   return new Proxy(obj as any, {
//     get: (target, property) => {
//       const value = obj[property];
//       return (value === undefined || value === null || value === '') ? defaultValue : value;
//     }
//   })
// }

export const isDefaultEmptyStr = (str: any) => {
  return eq(str, consts.defaultEmptyStr);
}

export function captureVideoFrame(video, format) {
  if (typeof video === 'string') {
    video = document.getElementById(video);
  }

  format = format || 'jpeg';

  if (!video || (format !== 'png' && format !== 'jpeg')) {
    return false;
  }

  var canvas = document.createElement("CANVAS") as HTMLCanvasElement;

  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;

  canvas.getContext('2d')!.drawImage(video, 0, 0);

  var dataUri = canvas.toDataURL('image/' + format);
  var data = dataUri.split(',')[1];
  var mimeType = dataUri.split(';')[0].slice(5)

  var bytes = window.atob(data);
  var buf = new ArrayBuffer(bytes.length);
  var arr = new Uint8Array(buf);

  for (var i = 0; i < bytes.length; i++) {
    arr[i] = bytes.charCodeAt(i);
  }

  var blob = new Blob([arr], { type: mimeType });
  return { blob: blob, dataUri: dataUri, format: format };
};

export function captureVideoFrame2(video: HTMLVideoElement) {
  const canvas = document.createElement("CANVAS") as HTMLCanvasElement;

  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;

  canvas.getContext('2d')!.drawImage(video, 0, 0);
  const image = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
  return image;
};


export const convertTime = (timeString: string, type: number) => {
  if (!timeString) { return '' }
  const map = {
    1: 'YYYY-MM-DD HH:mm:ss',
    2: 'YYYY-MM-DD',
    3: 'HH:mm:ss',
    4: 'MM-DD',
  }

  let newTime: Moment;
  let date = timeString.substring(0, 19);
  date = date.replace(/-/g, '/');
  let timestamp = new Date(date).getTime();
  newTime = moment(timestamp);
  return newTime.format(map[type]);
}

export const getMonthAndDay = (times: string | number) => {
  let day1 = moment(times);
  let yesDay = formatTime(day1, 4);
  return yesDay
}

export const convertParkTime = (number: number) => {
  if (number < 60) {
    return number + '分钟';
  } else if (number == 60) {
    return `1${intlGet('hour')}`;
  } else if (number > 60) {
    return (Math.floor(number / 60) + intlGet('hour') + number % 60 + intlGet('minute'));
  }
}

export const isStrEmpty = (str: string) => {
  return !str.trim().length;
}

export const showLonLat = (lon: number, lat: number) => {
  let str = '';
  if (lon && lat) {
    str = lon.toFixed(6) + ', ' + lat.toFixed(6);
  }
  return str;
}


/**
 * 判断元素是否可见
 * @param {Object} elm
 */
export const checkVisible = elem => !!elem && !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length);

/**
 * @desc 比较两个数组，返回新增与删除的数据
 * @param oldArr 
 * @param newArr 
 * @param key 不传值直接比较两个数组，传值只比较key值
 */
export const compareArr = <T>(oldArr: T[], newArr: T[], key?: string) => {
  let add: T[] = [];
  let del: T[] = [];
  let unchanged: T[] = [];

  if (key) {
    newArr.forEach(n => {
      if (oldArr.some(o => eq(o[key], n[key]))) {
        unchanged.push(n);
      } else {
        add.push(n);
      }
    });
    oldArr.forEach(o => {
      if (newArr.every(n => !eq(o[key], n[key]))) {
        del.push(o);
      }
    });
  } else {
    newArr.forEach(n => {
      if (oldArr.some(o => eq(o, n))) {
        unchanged.push(n);
      } else {
        add.push(n);
      }
    });
    oldArr.forEach(o => {
      if (newArr.every(n => !eq(o, n))) {
        del.push(o);
      }
    })
  }
  return {
    add, del, unchanged
  }
}

/**
 * @desc 驼峰转下划线
 */
export const mountainToLine = (name) => {
  let index = name.lastIndexOf("\.");
  let obj = name.substring(index + 1, name.length);
  return obj.replace(/([A-Z])/g, "_$1").toLowerCase();
}

export const copy = <T>(obj: T): T => {
  return JSON.parse(JSON.stringify(obj));
}

export const isElementHidden = (el: HTMLElement) => {
  return el.offsetWidth >= 0 && el.offsetHeight <= 0;
}

/**
 * @desc 转化车型图标
 */
export const convertVehicleType = (typeNum) => {
  const obj = {
    0: 'iconjiaoche',
    1: 'iconkeche',
    2: 'iconhuoche',
    3: 'iconchuzuche',
    4: 'icongongchengche',
    5: "iconzhongxinghuoche",
    6: 'iconweixianpinyunshuche',
    7: 'icongongjiaoche',
    8: 'iconbanguaqianyinche',
    9: 'other'
  };
  return obj[typeNum];
}

export const setDictData = (data: Partial<Dict>) => {
  const dictData: { [key in keyof Dict]?: LabelAndValue[] } = {};
  Object.keys(data).map((k) => {
    const obj = data[k];

    const arr: LabelAndValue[] = [];
    Object.keys(obj).map(x => {
      const item = obj[x];
      arr.push({ value: x, label: item })
    });
    dictData[k] = arr;
  });
  return dictData;
}

export const getBase64 = (file) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });
}

export const requestFullScreen = (element) => {
  // 判断各种浏览器，找到正确的方法
  const requestMethod = element.requestFullScreen || //W3C
    element.webkitRequestFullScreen || //FireFox
    element.mozRequestFullScreen || //Chrome等
    element.msRequestFullScreen; //IE11
  if (requestMethod) {
    requestMethod.call(element);
  } else if (typeof window['ActiveXObject'] !== "undefined") { //for Internet Explorer
    const wscript = new window['ActiveXObject']("WScript.Shell");
    if (wscript !== null) {
      wscript.SendKeys("{F11}");
    }
  }
}

//退出全屏 判断浏览器种类
export const exitFullScreen = () => {
  // 判断各种浏览器，找到正确的方法
  var exitMethod = document.exitFullscreen || //W3C
    document['mozCancelFullScreen'] || //FireFox
    document['webkitExitFullscreen'] || //Chrome等
    document['webkitExitFullscreen']; //IE11
  if (exitMethod) {
    exitMethod.call(document);
  } else if (typeof window['ActiveXObject'] !== "undefined") { //for Internet Explorer
    var wscript = new window['ActiveXObject']("WScript.Shell");
    if (wscript !== null) {
      wscript.SendKeys("{F11}");
    }
  }
}

export const checkFull = () => {
  let isFull = window['fullScreen'] || document['webkitIsFullScreen'] || document['msFullscreenEnabled'];
  if (isFull === undefined) { isFull = false; }
  return !!isFull;
}

/**
 * @desc 数组倒序排序
 */
export const sortBy = (field) => {
  return function (a, b) {
    return b[field] - a[field];
  }
}

/**
 * @desc 秒转换成分钟+秒
 */
export const convertMinutesSec = (sec: number) => {
  let mins = Math.floor(sec / 60);
  let seconds = sec % 60;
  if (sec >= 60) {
    return mins + intlGet("minute") + seconds + intlGet("second")
  } else {
    return sec + intlGet("second")
  }
}

/**
 * @desc 秒转换成时分秒
 * @param sec 秒
 */
export const converHMS = (sec: number) => {
  const second = sec % 60;
  const hour = Math.floor(sec / 60 / 60);
  const minute = Math.floor((sec - second - hour * 60 * 60) / 60);

  // console.log('converHMS', sec, `${hour}:${minute}:${second}`)
  if (hour) {
    return intlGet('time_hms', { h: hour, m: minute, s: second });
  } else if (minute) {
    return intlGet('time_ms', { m: minute, s: second });
  } else {
    return intlGet('time_s', { s: second });
  }
}


/**
 * @desc 转化成分钟
 */
export const convertMinutes = (mins) => {
  let hours = Math.floor(mins / 60);
  let minutes = mins % 60;
  if (mins >= 60) {
    return hours + intlGet("hour") + minutes + intlGet("minute")
  } else {
    return minutes + intlGet("minute")
  }
}

export const getSearchParams = () => {
  const search = window.location.search.replace('?', '');
  const paramArr = search.split('&');
  const parsmObj: any = {};
  paramArr.forEach(x => {
    const [key, value] = x.split('=');
    parsmObj[key] = value;
  });
  return parsmObj;
}

/**
 * @desc 转化设备图标
 */
export const convertDeviceType = (typeNum) => {
  const obj = {
    1: 'iconADAS',
    2: 'icondms',
    3: 'iconshebei',  // h2
    5: 'iconshebei',   // h3

    4: 'iconyijianpai1',     // 一键拍
    6: "icondantiji",  // h1
    7: "iconapp_icons-_huaban",  // 部标机
  };
  return obj[typeNum] || obj[1];
}

/**
 * @desc 转化告警图标
 */
export const convertAlarmIcon = (alarmName) => {
  const obj = {
    'adas_alarm': 'iconjiashifuzhu',
    'veh_state_alarm': 'iconcheliangzhuangtai',
    "person_alarm": 'iconhangrenbanmaxian',
    "fatigue_alarm": 'iconbiyan1',
    "distraction_alarm": 'iconzuoguyoupan1',
    "behavior_unusual_alarm": 'iconhangweiyichang',
    "driver_identity_alarm": 'iconjiashiyuanshibie',
    "cloud_base_alarm": 'iconpingtaijichugaojing',
    "cloud_trigger_alarm": 'iconpingtaichufagaojing',
    "traffic_sign_alarm": 'iconlukuang',
  };
  const icon = obj[alarmName];
  if (!icon) {
    log.data(`convertAlarmIcon no icon alarmName ${alarmName}`);
  }
  return obj[alarmName] || 'iconhangweiyichang'; //没有找到图标就给一个默认的
}

export const isInt = (num: string) => {
  const re = /^(0|[1-9][0-9]*)$/; //判断字符串是否为数字 //判断正整数 /^[1-9]+[0-9]*]*$/ 
  if (re.test(num)) {
    return true;
  } else {
    return false;
  }
}

export const isTelphone = (num: string) => {
  const g = /0\d{2,3}-\d{7,8}/
  const t = /[1][3,4,5,7,8][0-9]\d{8}/
  const re = /^(0\d{2,3}-\d{7,8}|[1][34578][0-9]\d{8})(;(0\d{2,3}-\d{7,8}|[1][34578][0-9]\d{8}))*$/;
  if (re.test(num)) {
    return true;
  } else {
    return false;
  }
}

// 0 和 正整数
export const isInteger = (num: string) => {
  // const re = /^[0-9]+\.?[0-9]*$/; //判断字符串是否为数字 //判断正整数 /^[1-9]+[0-9]*]*$/ 
  const re = /^(0|[1-9][0-9]*)$/; //判断字符串是否为数字 //判断正整数 /^[1-9]+[0-9]*]*$/ 
  if (re.test(num)) {
    return true;
  } else {
    return false;
  }
}

/**
 * @desc 导出媒体资源
 */
export const exportMedia = (fileName: string = '', url, content) => {
  return http.post(url, content, { responseType: 'blob' }).then(res => {

    console.log('res', res);
    const { data, headers } = res;
    let blob = new Blob([data], { type: 'application/zip' });

    if (!fileName) {
      fileName = headers['content-disposition'].split('=')[1];
      fileName = decodeURI(fileName);
    }

    if (window.navigator['msSaveOrOpenBlob']) {
      navigator['msSaveBlob'](blob, fileName);
    } else {
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(link.href);
    }

  }, error => {
    throw error;
  })
}

interface ExportResParam {
  type: number,
  password?: string,
  folderName?: string,
  urls?: string[],
  childFolders?: ExportResParam,
}

/**
 * 通用资源下载
 */
export const exportRes = (params: ExportResParam) => {
  const url = 'center/download/resource';
  return http.post(url, params)
}

/**
 * @desc 过滤出小时
 */
export const abstractHours = (str: string) => {
  console.log(str)
  if (str.length > 0) {
    let beginIndex = str.lastIndexOf('天');
    let endIndex = str.lastIndexOf('分');
    return str.substr(beginIndex - 1, str.length);
  } else {
    return '0小时0分0秒'
  }
}

/**
 * @desc 失败成功颜色 成功true失败false
 */
export const convertFailSColor = (types) => {

  return types ? '#108ee9' : '#f50';
}

/**
 * @desc 根据字符串前缀提取设备类型
 */
export const abstractDeviceType = (str) => {
  let endIndex = str.lastIndexOf(':');
  return str.substr(0, endIndex);
}

export const getBdmStr = (dbm: number) => {
  let dbmStr = '';
  if (dbm < -90) {
    dbmStr = intlGet('weak');
  } else if (dbm >= -90 && dbm < -60) {
    dbmStr = intlGet('normal');
  } else if (dbm >= -60 && dbm < 0) {
    dbmStr = intlGet('strong');
  } else {
    dbmStr = intlGet('Abnormal')
  }
  return dbmStr;
}

export const showTotal = (total) => {
  return `${intlGet('in_total')} ${total} ${intlGet('item')}`;
}

export const InArray = (num: number, arr: [number, number]) => {
  return (num >= arr[0]) && (num <= arr[1]);
}

export const hasErrors = (fieldsError) => {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

/**
 * 
 * @param numArr 10进制数组 [1,2,3]
 * @returns 返回的和为10进制
 */
export const getBinarySum = (numArr: number[]) => {
  let sum = 0;
  numArr.forEach(x => {
    sum += 1 << x;
  });
  return sum;
}

/**
 * 
 * @param num2 二进制
 * @param optionNum 二进制
 * @returns []number 10进制
 */
export const decompositionBinary = (num2: number, optionNum: number) => {
  let result: number[] = []
  for (let i = 0; i < optionNum; i++) {
    if (((num2 >> i) & 0x1) === 1) {
      result.push(i)
    }
  }
  console.log('decompositionBinary', num2, optionNum, result);
  return result;
}


// UTF8字符集实际长度计算
export const getStrLeng = (str: string) => {
  str = str.toString();
  var realLength = 0;
  var len = str.length;
  var charCode = -1;
  for (var i = 0; i < len; i++) {
    charCode = str.charCodeAt(i);
    if (charCode >= 0 && charCode <= 128) {
      realLength += 1;
    } else {
      // 如果是中文则长度加3
      realLength += 3;
    }
  }
  return realLength;
}

// 比较两个对象是否相等，简单对比，不会递归
export const isObjsEqual = (obj1, obj2) => {
  // 1. 如果两个都为空，返回false
  // 2. 一个为空，返回true
  if (!obj1 && !obj2) { return false };
  if (!obj1 || !obj2) { return true };

  const keys = Object.keys(obj1);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (obj1.hasOwnProperty(key)) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
  }
  return true;
}

export const getVehStatus = (vehState?: string, bracket = false) => {
  if (!vehState) {
    log.data(`vehState为空`)
    return '';
  }
  let resStr = '';
  let [state, value = ''] = vehState.split('|');
  const statusMap = {
    0: intlGet('offline'),
    1: intlGet('online'),
    2: intlGet('no_location'),
    3: intlGet('stop_vehicle'),
    4: intlGet('driving'),
    5: intlGet('acc_type_close'),
  }
  let statusStr = statusMap[state] || '';
  if (value) {
    value = value.replace('d', intlGet('days'))
      .replace('h', intlGet('h'))
      .replace('m', intlGet('minute2'));
  }
  resStr = `${statusStr}${value}`;
  return bracket
    ? `【${resStr}】`
    : resStr;
}

export const getVehStatusType = (vehState: string | undefined) => {
  let num = 0;
  if (!vehState) {

  } else {
    let [state, value] = vehState.split('|');
    num = Number(state);
  }
  return {
    num: num,
    clazz: `vehStatusInfo s${num}`
  }
}

export const vehIconMap = {
  0: 'iconche',
  1: 'iconche',
  2: 'iconlixianweidingwei1',
  3: 'iconche',
  4: 'iconhangshizhong',
  5: 'iconche',
}

export const replaceStr = (str: string, params: Object) => {
  let res = str;
  for (let k in params) {
    res = res.replace('${' + k + '}', params[k])
  }
  return res;
}

export const formatLngLat = (lon: number, lat: number) => {
  if (lon == undefined) {
    lon = 0;
  }
  if (lat == undefined) {
    lat = 0;
  }
  return [lon, lat]
}


export const getDevIdAndType = (devs) => {
  let devId = '';
  let devType = 1;
  if (devs && devs.length) {
    const dev = devs[0];
    devId = dev.deviceId;
    devType = dev.devType;
  }
  return { devId, devType }
}

/**
 * @desc 获取acc的翻译
 * @param value 1 点火 0 熄火
 */
export const getAccStr = (value: number) => {
  return eq(value, 1) ? intlGet('acc_type_open') : intlGet('acc_type_close');
}

export const isJTDev = (devType: string | number) => {
  return devType && devType.toString() === '7';
}

export const getVehFormatInfo = (statusInfo: string | undefined) => {
  let mileage = 0;
  let duration = 0;

  const statusInfoJson = statusInfo ? JSON.parse(statusInfo) : undefined;
  if (statusInfoJson) {
    mileage = statusInfoJson.mileage;
    duration = statusInfoJson.duration;
  }

  return {
    mileage,
    duration,
  }
}

export const transformDirection = (direction: string) => {
  const directionMap = {
    n: intlGet('north'),
    w: intlGet('west'),
    e: intlGet('east'),
    s: intlGet('south'),
  }
  const dirWord = intlGet('just');

  if (!direction) {
    return intlGet('unknown');
  }
  const words = direction.split('|');
  const [w1, w2, w3] = words;
  const wordsLen = words.length;
  if (wordsLen === 1) {
    const dirStr = directionMap[w1];
    return dirWord + dirStr;
  } else if (wordsLen === 2) {
    return directionMap[w1] + directionMap[w2];
  } else if (wordsLen === 3) {
    return directionMap[w1] + intlGet('lean') + directionMap[w2] + w3 + '°';
  }
}

const loadMap_bb = {
  0: '空车',
  1: '保留',
  2: '半载',
  3: '满载',
}
const roofMap_bb = {
  0: '',
  1: '合上',
  2: '敞开',
  3: '剪线',
}
const liftMap_bb = {
  0: '',
  1: '抬起',
  2: '降落',
  3: '剪线',
}

type Num = number | undefined;
export const getLoadStr = (load: Num, roof: Num, lift: Num, loadWeight: Num) => {
  const strArr: string[] = [];

  if (load != undefined && load != 0) {
    strArr.push(`负载: ${loadMap_bb[load!]}`);
  }
  if (roof != undefined && roof != 0) {
    strArr.push(`顶棚: ${roofMap_bb[roof]}`);
  }
  if (lift != undefined && lift != 0) {
    strArr.push(`举升: ${liftMap_bb[lift]}`);
  }

  if (loadWeight != undefined && loadWeight != 0) {
    strArr.push(`载重重量: ${loadWeight / 1000}吨`);
  }

  return strArr.join('，')
}

export const replaceHttp = (url: string) => {
  const reg = /^http\:/;
  return url.replace(reg, 'https:');
}


export const disabledFutureDate = (current) => {
  return current >= moment().startOf('day');
}
export const disabledFutureMonth = (current) => {
  return current > moment().endOf('month');
}

const gpsErrMsg = {
  1: '两点里程差大于10km',
  2: '连续第n段两点里程差2km~10km',
  3: '定位时间错误',
  4: '经纬度错误',
  5: '速度错误',
  6: '海拔错误',
  7: '方向错误',
  8: 'gps漂移',
}
export const getGpsErrMsg = (errType: number, par1?: any) => {
  let msg = gpsErrMsg[errType] || '';
  if (errType === 2) {
    msg = msg.replace('n', par1);
  }
  return msg;
}
const lightArr = [
  ['', '近光灯'],
  ['', '远光灯'],
  ['', '右转向'],
  ['', '左转向'],
  ['', '制动'],
  ['', '倒挡'],
  ['', '雾灯'],
  ['', '示廓灯'],
  ['', '喇叭'],
  ['', '空调'],
  ['', '空挡'],
  ['', '缓速器'],
  ['', 'ABS'],
  ['', '加速器'],
  ['', '离合器'],
]
export const getLightStr = (arr: number[]) => {
  console.log('getLightStr', arr);
  const res: string[] = [];
  arr.forEach((x, i) => {
    // if (x === 1) {
    //   const str = _.get(lightArr, `[${i}][${x}]`, '')
    //   res.push(str);
    // }
    const str = _.get(lightArr, `[${x}][1]`, '')
    if (str) {
      res.push(str);
    }

  })
  return res.join(',');

}


export const gpsToCarTreeVeh = (gpsData: GpsDialogInter) => {
  const { plateNo, plateColor, vehicleId, groupId, deviceType: devType,
    acc, deviceId, } = gpsData;
  const nodeId = getCarTreeNodeId('vehicle', vehicleId);
  const carTreeNode: CarTreeVeh = {
    nodeId: nodeId,
    nodePid: '',
    nodeType: 'vehicle',  // org vehicle
    name: plateNo,

    vehicleId: vehicleId,	// 非必须 车辆id	
    plateNo: plateNo,	// 非必须 车牌号	
    orgId: groupId,	// 非必须 机构id	
    orgName: '',	// 非必须 机构id	
    plateColor: plateColor, // 车牌颜色	
    vehModel: devType,	// 车辆类型	
    online: 0, //在线状态	
    protocolType: 1, //协议类型	
    acc: acc, // acc状态	
    deviceId,
    devType,
    proDevId: '',
    // alt: altitude, // gps高度
    // dir: direction, // gps方向	
    // direction: '',	// 非必须 方向详情	
    // lat: latitude, // 纬度	
    // lon: longitude, // 经度
    // locationTs: gpsTime, //定位时间
    // satellite: gnssNum, //卫星数	
    // signal: networkIntensity, // 网络信号	
    // speed: gpsSpeed, //速度	
    vehState: '',	// 非必须 车辆运行状态	
    devs: [], //设备信息 	
    channels: [],

  };
  return carTreeNode;
}

export const carTreeVehToGps = (car: CarTreeVeh) => {
  const {
    vehicleId, plateNo, orgId, orgName, plateColor, vehModel, online,
    protocolType, vehState, devs, channels, deviceId, proDevId, devType,
  } = car;
  const time = Date.now();
  const id = `${vehicleId}_${time}`;
  const gpsData: GpsDialogInter = {
    id,
    plateNo,
    plateColor,
    deviceId: deviceId,
    deviceType: devType,
    vehicleId: vehicleId,
    gpsTime: formatTime(time, 1),	// GPS定位时间, UTC+0	
    gpsSpeed: 0, // GPS车速,km/h	
    longitude: 0, // 经度,保留小数后6位	
    latitude: 0, // 纬度,保留小数点6位	
    direction: 0, // 转向
    position: '',
    altitude: 0, // 高程	
    locationState: 0, // GPS定位状态
    mileage: 0, // 总里程 单位米
    address: '', // 详细地址
    acc: 0, // acc状态	
    gnssNum: 0, // GNSS卫星数量	
    networkIntensity: 0, // 信号强度
    stateBit: 0,	
    online: online, // 0 离线 1 在线
    state: 0,

    channels: channels, // 通道
    videoLostWarnBit: 0, // 视频通道丢失状态	
    videoOccWarnBit: 0, // 视频通道遮挡状	
    storageWarnBit: 0, // 存储器状态	

    loadState: 0, // 车辆负载状态 0.空车 1.半载 2.保留 3.满载	
    roof: 0, // 顶棚闭合状态 ，0.不显示 1.合上 2.敞开 3.剪线	
    lift: 0, // 举升状态 ，0.不显示 1.抬起 2.降落 3.剪线	
    loadWeight: 0, // 载重重量	

    signalState: 0,
    driverId: '',	// 驾驶员唯一编码	
    faceId: '',	// 驾驶员面部唯一标识	
    groupId: orgId,	// 机构id	
    orgName: orgName, // 机构名称 后加
    gpsResume: 0,
    receiveTime: '',
    warnBit: 0,
    effectIndex: 0,   // 后加
  }
  return gpsData;
}

export const getCarTreeNodeId = (type: VehOrOrgType, id: string) => {
  const typeMap = {
    org: 'org',
    vehicle: 'vehicle',
  }
  const typeStr = typeMap[type];
  if (typeStr) {
    return `${type}_${id}`
  } else {
    console.error('getCarTreeNodeId err', type, id);
    return '';
  }
}

export const getAlarmRes = (alarmFiles: Resource[]) => {
  // 格式,1-图片，2-视频，3-其他
  const picArr = alarmFiles.filter(x => x.format === 1);
  const videoArr = alarmFiles.filter(x => x.format === 2);
  const otherArr = alarmFiles.filter(x => x.format === 3);
  return {
    picArr, videoArr, otherArr
  }

}

export const getIsLocation = (num: number) => {
  const locationStateMap = {
    0: intlGet('no_location'),
    1: intlGet('has_located'),
  }
  return locationStateMap[num] || '';
}


export const fileSizeFormat = (filesize: number) => {
  const M = 1024 * 1024;
  if (filesize >= M) return (filesize / 1024 / 1024).toFixed(1) + 'M'
  else if (filesize >= 0) return (filesize / 1024).toFixed(1) + 'kb'
}

export const sumBinary = (dataArr: number[]) => {
  let sum = 0;
  dataArr.forEach(x => {
    sum += 1 << x
  })
  return sum;
}

/**
 * 判断数据是否为空   null {} []
 */
export const isDataEmpty = (data) => { 
  if (data === null) {
    return true;
  } else if ((typeof data === "object") && (Object.keys(data).length === 0)) {
    return true;
  }else{
    return true;
  }
}

/**
 * @desc 转换安装结果
 */
export const convertInstallResult = (num: number) => {
  const temp = {
    0: '待审核',
    1: '成功',
    2: '失败'
  };
  return temp[num] || '--';
}

/**
 * @desc 转换颜色
 */
export const convertInstallColor = (num: number) => {
  const temp = {
    0: 'dsh',
    1: 'cg',
    2: 'sb',
  };
  return temp[num] || ' ';
}

/**
 * @desc 车辆类型转换
 */
export const covnertVehicleType = (num: number) => {
  const temp = {
    0: '轿车',
    1: '客车',
    2: '货车',
    3: '出租车',
    4: '工程车',
    5: '重型货车',
    6: '危险品运输车',
    7: '公交车',
    8: '半挂牵引车',
    9: '其他',
  };

  return temp[num] || '--';
}

/**
 * @desc 接入方式
 */
export const jrfs = (num: number) => {
  const temp = {
    1: '硬线安装',
    2: 'CAN线安装',
  };
  return temp[num] || '--';
}

/**
 * @desc 速度方式
 */
export const sdfs = (num: number) => {
  const temp = {
    0: "GPS",
    1: 'CAN',
    2: '脉冲',
    3: 'OBD',
  };
  return temp[num] || '--';
}

/**
 * @desc 转向方式
 */
export const zxfs = (num: number) => {
  const temp = {
    0: "无",
    1: 'CAN',
    2: '脉冲',
  };
  return temp[num] || '--';
}

/**
 * @desc 疲劳镜头安装方式
 */
export const pljt = (num: number) => {
  const temp = {
    0: "水平",
    1: '竖直',
  };
  return temp[num] || '--';
}

/**
 * @desc 告警模式
 */
export const gjms = (num: number) => {
  const temp = {
    0: '无',
    1: '苏标',
    2: '陕标',
  };
  return temp[num] || '--';
}

/**
 * @desc SD卡
 */
export const sdCard = (num: number) => {
  const temp = {
    1: '正常',
    0: '异常',
  };
  return temp[num] || '--';
}

/**
 * @desc SIM卡
 */
export const simCard = (num: number) => {
  const temp = {
    1: '正常',
    0: '异常',
  };
  return temp[num] || '--';
}

/**
 * @desc GPS
 */
export const gps = (num: number) => {
  const temp = {
    1: '正常',
    0: '异常',
  };
  return temp[num] || '--';
}

/**
 * @desc 转换图标
 */
export const convertIcon = (num: number) => {
  const temp = {
    2: 'iconbuhege',
    1: 'iconhege',
    0: 'icondaishenhe'
  };
  return temp[num] || 'icondaishenhe';
}

/**
 *
 * @desc  转换安装类型
 */
export const convertInstallType = (type: number) => {
  const dict = {
    0: 'H2',
    1: 'H2+IPC摄像头',
    2: 'H2+耳机',
    3: "H2+IPC摄像头+耳机",
    4: "售后维护",
    5: "--",
  };
  return dict[type] || '--'
}

export const downloadIamge2 = (imgsrc, name) => {
  var a = document.createElement('a');
  var url = imgsrc;
  var filename = name;
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
}

export const judgeSuffix = (url: String) => {
  let lastIndex = url.lastIndexOf('.');
  let str = url.substr(lastIndex + 1, url.length);
  return str;
}


export const currentTime = () => {
  var myDate = new Date(); //实例一个时间对象；
  return myDate.getFullYear().toString() +
    (myDate.getMonth() + 1).toString() +
    myDate.getDate().toString() +
    myDate.getHours().toString() +
    myDate.getMinutes().toString() +
    myDate.getSeconds().toString()
}

export const convertSignal = (num) => {
  if (num < -90) {
    return `弱(${num}dbm)`
  } else if ((-90 <= num) && (num <= -60)) {
    return `正常(${num}dbm)`
  } else if ((-60 < num) && (num < 0)) {
    return `强(${num}dbm)`
  } else if (eq(num, 0)) {
    return '未知'
  }
}

export const convertMenu = (str) => {
  let dicts = {
    'menu_cd': '标定详情',
    'menu_hr': '人员管理',
    'menu_rh': '角色管理',
    'menu_uh': '用户管理',
  }
  return dicts[str];
}

export const convertCurrentTime = (timeString: string, type: number) => {
  
  const map = {
    1: 'YYYY-MM-DD HH:mm:ss',
    2: 'YYYY-MM-DD',
    3: 'HH:mm:ss',
    4: 'MM-DD',
  }

  let newTime: Moment;
  let date = timeString.substring(0, 19);
  date = date.replace(/-/g, '/');
  let timestamp = new Date().getTime();
  newTime = moment(timestamp);
  return newTime.format(map[type]);
}


/**
 * @desc zhuanhuan log name value
 */
export const logCovert = (fileName: string, devType: number) => {
  let dict = {
    //固件日志
    'jm800_log.txt': "/storage/sdcard0/jimu/jm800/logs/jm800_log.txt",
    'jm800_log.txt.1': "/storage/sdcard0/jimu/jm800/logs/jm800_log.txt.1",
    'jm800_log.txt.2': "/storage/sdcard0/jimu/jm800/logs/jm800_log.txt.2",
    'jm800_log.txt.3': "/storage/sdcard0/jimu/jm800/logs/jm800_log.txt.3",
    'jm800_log.txt.4': "/storage/sdcard0/jimu/jm800/logs/jm800_log.txt.4",
    'jm800_log.txt.5': "/storage/sdcard0/jimu/jm800/logs/jm800_log.txt.5",
    'jm800_log.txt.6': "/storage/sdcard0/jimu/jm800/logs/jm800_log.txt.6",
    //录像日志
    'record264-0.log': "/storage/sdcard0/jimu/jm800/logs/record264-0.log",
    'record264-1.log': "/storage/sdcard0/jimu/jm800/logs/record264-1.log",
    'record264-2.log': "/storage/sdcard0/jimu/jm800/logs/record264-2.log",
    'record264-3.log': "/storage/sdcard0/jimu/jm800/logs/record264-3.log",
    'record264-4.log': "/storage/sdcard0/jimu/jm800/logs/record264-4.log",
    //通讯日志
    'remote_log.txt': "/storage/sdcard0/jimu/jm800/logs/remote_log.txt",
    'remote_log.txt.1': "/storage/sdcard0/jimu/jm800/logs/remote_log.txt.1",
    'remote_log.txt.2': "/storage/sdcard0/jimu/jm800/logs/remote_log.txt.2",
    'remote_log.txt.3': "/storage/sdcard0/jimu/jm800/logs/remote_log.txt.3",
    'remote_log.txt.4': "/storage/sdcard0/jimu/jm800/logs/remote_log.txt.4",
    'remote_log.txt.5': "/storage/sdcard0/jimu/jm800/logs/remote_log.txt.5",
    'remote_log.txt.6': "/storage/sdcard0/jimu/jm800/logs/remote_log.txt.6",
    //协议日志
    'jm808_log.txt': "/storage/sdcard0/jimu/jm800/logs/JM808Log/jm808_log.txt",
    'jm808_log. txt.1': "/storage/sdcard0/jimu/jm800/logs/JM808Log/jm808_log.txt.1",
    'jm808_log.txt.2': "/storage/sdcard0/jimu/jm800/logs/JM808Log/jm808_log.txt.2",
    'jm808_log.txt.3': "/storage/sdcard0/jimu/jm800/logs/JM808Log/jm808_log.txt.3",
    'jm808_log.txt.4': "/storage/sdcard0/jimu/jm800/logs/JM808Log/jm808_log.txt.4",
    'jm808_log.txt.5': "/storage/sdcard0/jimu/jm800/logs/JM808Log/jm808_log.txt.5",
    'jm808_log.txt.6': "/storage/sdcard0/jimu/jm800/logs/JM808Log/jm808_log.txt.6",
    'jm808_log.txt.7': "/storage/sdcard0/jimu/jm800/logs/JM808Log/jm808_log.txt.7",
    'jm808_log.txt.8': "/storage/sdcard0/jimu/jm800/logs/JM808Log/jm808_log.txt.8",
    
  }
  let path: string = dict[fileName];
  if(devType === 6){
    path = path.replace(/jm800/g, 'jm600');
  }
  return path;
}

export const singleSelectTreedata = () => {
  let treeData: any = [
    {
      key: 'gjrz',
      title: '固件日志',
      child: [
        { title: 'jm800_log.txt' },
        { title: 'jm800_log.txt.1' },
        { title: 'jm800_log.txt.2' },
        { title: 'jm800_log.txt.3' },
        { title: 'jm800_log.txt.4' },
        { title: 'jm800_log.txt.2' },
        { title: 'jm800_log.txt.6' },
      ],
    },
    {
      key: 'lxrz',
      title: '录像日志',
      child: [
        { title: 'record264-0.log' },
        { title: 'record264-1.log' },
        { title: 'record264-2.log' },
        { title: 'record264-3.log' },
        { title: 'record264-4.log' },
      ],
    },
    {
      key: 'txrz',
      title: '通讯日志',
      child: [
        { title: 'remote_log.txt' },
        { title: 'remote_log.txt.1' },
        { title: 'remote_log.txt.2' },
        { title: 'remote_log.txt.3' },
        { title: 'remote_log.txt.4' },
        { title: 'remote_log.txt.5' },
        { title: 'remote_log.txt.6' },
      ],
    },
    {
      key: 'xyrz',
      title: '协议日志',
      child: [
        { title: 'jm808_log.txt' },
        { title: 'jm808_log.txt.1' },
        { title: 'jm808_log.txt.2' },
        { title: 'jm808_log.txt.3' },
        { title: 'jm808_log.txt.4' },
        { title: 'jm808_log.txt.5' },
        { title: 'jm808_log.txt.6' },
        { title: 'jm808_log.txt.7' },
        { title: 'jm808_log.txt.8' },
      ],
    },
  ];

  return treeData;
}



export const converVehicleState = (state) => {
  const statusMap = {
    0: '离线',
    1: '在线',
    2: '未定位',
    3: '停车',
    4: '行驶',
    5: '熄火',
  };
  return statusMap[state] || '--';
} 

/**
 * @desc 翻译字典
 */
export const transDict = (item) => {
  const param = {
    "online": "在线",
    "offline": "离线",
    "never_online": "从未上线",
    "adas": "辅助驾驶(ADAS)",
    "dms": "疲劳监测(DMS)",
    "h2": "一体机(H2)",
    "yjp": "一键拍",
    "jm600": "jm600",
    "jmf500": "jmf500",
    "jm800": "jm800",
    "mqtt": "mqtt",
    "jt808": "部标设备",
    'qb': '全部',
    "h3":"一体机(H3)",
    "h1":"单体机(H1)",
    "h5":"多合一(H5)",
    "H1-D": "一体机(H1-D)",
    "H1-E": "一体机(H1-E)"
  }

  return param[item] || item;
}

/**
 * @desc 转换camera的状态
 */
export const convertCameraState = (state) =>{
  const obj = {
    0:'yc',
    1:'zc',
    2:'yb',
  }

  if(obj[state]){
    return obj[state];
  }else{
    return 'yc';
  }
}


export const timeFormatStrs = {
  1: 'YYYY-MM-DD HH:mm:ss',
  2: 'YYYY-MM-DD',
  3: 'HH:mm:ss',
  4: 'MM-DD',
  5: 'YYYY-MM',
  6: 'YYYY-MM-DD HH:mm',
  7: 'YYYY/MM/DD HH:mm:ss',
}

export const isFormValueEmpty = (value) => {
  return value === 0 || !!value;

}

export const encryptionPhone = (phone: string) => {
  let result = phone || '';
  if(result.length > 7){
    let start = result.slice(0, 3);
    let end = result.slice(-4);
    result = `${start}****${end}`
  }
  return result;
}
