export default {
  colors: ['#77B59E ', '#8872CE', '#6CBBD8', '#58CEFA', '#7D8EF6', '#D992ED', '#91C1A5', '#DA8565'],
  session_key: {
    jimu_api_token: 'jimu_api_token',
    is_login: 'is_login',
  },
  http_code: {
    success: 0,
    fail: 1,
    unanthent: -200,
  },
  key_code: {
    up: 38,
    down: 40,
    left: 37,
    right: 39,
    enter: 13,
    backspace: 8,
  },
  gpsAdsTdWidth: 300,
  defaultEmptyStr: '-',
  voiceTemplate: {
    low_danger: [
      "Please concentrate on driving, thank you!",
      "Do not drive tiredly, thank you!",
      "Please keep the safe distance, thank you!",
      "The road is wet and slippery. Please pay attention to traffic safety. Thank you!"
    ],
    mid_danger: [
      "Don't be distracted, pay attention to traffic safety, thank you!",
      "Beware of fatigue driving and ensure driving safety. Thank you!",
      "Please don't drive dangerously, pay attention to the current traffic safety, thank you!",
    ],
    high_danger: [
      "You are tired driving, please drive to the nearest safe area to stop and rest, thank you!",
      "You are driving with serious fatigue. Please drive to the nearest safe area immediately and stop for a rest. Thank you!",
      "You are driving in serious danger. Please pay attention to the current driving safety in time. Thank you!",
    ]
  },
  pageSizeOptions: ['20', '50', '100'],
  vehStatusIcon: {
    1: 'iconche',
    0: 'iconche',
    2: 'iconlixianweidingwei1',
    3: 'iconche',
  },
}

export const localDict = {
  categoryMap: {                 
    1: '后装整机',
    5: '后装PCBA', 
    2: '前装整机',
    3: '前装PCBA',
    4: '前装摄像头',
  },
  result: {
    PASS: '成功',
    FAIL: '失败'
  },
  operators: {
    1: '繁睿信息',
    2: '西普科技',
    3: '中国移动',
    4: '中国联通',
  },
  branchType: {
    0: 'master',
    1: 'test',
    3: 'other',
  }
}

export const h5DevTypes= [8,9, 10];
 