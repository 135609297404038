import { observable, action, runInAction, reaction } from 'mobx';
import { eq } from '../../src/utils/mix';
import { http } from '../../src/utils/http';
import { mySessionStorage } from '../../src/utils/mix';
import consts from '../consts';
import { User } from 'src/models';

export class AuthStore {

  @observable isLogin: boolean = !!mySessionStorage.getItem(consts.session_key.is_login, 'bool');
  @observable isLogout = false;

  @observable authMap = observable.map<string, any>();
  @observable dict: any = undefined;
  @observable.shallow orgsMap = observable.map<string, any>();
  @observable.ref firstDeviceRoot: any;
  @observable orgTree: any[] = []; //机构数
  @observable user: User | undefined;
  
  constructor() {
    reaction(
      () => !!this.isLogin,
      isLogin => {
        console.log('reaction isLogin', isLogin);
        mySessionStorage.setItem(consts.session_key.is_login, isLogin, 'bool');
      }
    )
  }

  @action.bound
  setIsLogin(isLogin: boolean) {
    console.log('setIsLogin', isLogin);
    this.isLogin = isLogin;
  }

  @action
  isHasBtnPermission = (id: string) => {
    const auths = Array.from(this.authMap.values());
    let bools = false;
    auths.map(x => {
      if (eq(x.resourceId, id)) {
        bools = true;
      }
    });
    return bools;
  }

  /**
   * @desc 查看是否有权限
   * @params path /private/xx
   */
  getAuth = (path: string) => {
    // const router = getRoterByKey(path);
    // const hasAuth = router.noNeedPermissions || !!this.authMap.get(path);
    // console.log(`${path} hasAuth --> ${hasAuth}`, router);
    const hasAuth = true;
    return hasAuth && this.isLogin;
  }

  /**
   * @desc heartbeat
   */
  getHeart = () =>{
    http.get('heartbeat').then(res=>{

    },err=>{

    });
  }

  /**
   * @desc 从后台获取字典
   */
  @action
  getDict = () => {
    console.log('getDict come in');
    const url = 'dictvalue/detail';
    return http.get(url).then(
      res => {
        console.log('dict res', res);
        runInAction(() => {
          this.dict = res.data;
        })
      },
      err => {
        console.log('err', err);
      }
    )
  }

  /**
  * @desc 根据org数组组装org树
  */
  mountDevice = (devices: any[]) => {
    // 一份新的键值数据
    const deviceObj: { [id: string]: any } = {};
    devices.forEach(x => {
      const { type } = x;
      const selectable = eq<any>(type, 'vehicle');
      deviceObj[x.id] = { ...x, selectable }
    });
    const deviceTree: any[] = [];
    const mountDevice = (m: any) => {
      const { pid, id } = m;
      const device = deviceObj[id];
      const { mounted } = device;
      // 如果已经装载了，就提前返回 
      if (mounted) { return; }

      // 一级菜单
      const pDevice = deviceObj[pid];
      if (pDevice) {
        pDevice.children = pDevice.children || [];
        pDevice.children.push(device);
        device.mounted = true;
        mountDevice(pDevice);
      }
      else {
        device.mounted = true;
        deviceTree.push(device);
      }

    }
    devices.forEach(m => mountDevice(m));
    console.log('menuTree', deviceTree);
    return deviceTree;
  }

  /**
   * @desc 获取机构树
   */
  getTree = () => {
    const url = 'v-vehtree/tree/org';
    return http.get(url).then(
      res => {
        console.log('org tree res=', res);
        runInAction(() => {
          const orgs = res.data;
          this.orgsMap.clear();
          orgs.map(x => {
            const { id, type, total, onlineNum, pid } = x;
            x.key = x.id;
            x.title = x.name;
            x.isLeaf = true;
            x.value = x.id;
            x.name = x.name;
            this.orgsMap.set(id, x);
          });
          const orgTree = this.mountDevice(Array.from(this.orgsMap.values()));
          this.orgTree = orgTree.length ? orgTree : [];
          this.firstDeviceRoot = this.orgTree[0];
        });
      },
      err => {
        console.log('err', err);
      }
    )
  }

  /**
   * 页面登陆接口
   */
  proLogin = (para) => {
    const url = 'login';
    return http.post(url, para)
  }

  testLL = () => {
    const url = 'example/1586744489975';
    http.get(url).then(res => {
      console.log('mock test res.data=', res);
    }, err => {

    })
  }

  /**
   * 页面退出登陆
   */
  logOut = () => {
    const url = 'logout';
    return new Promise((resolve, reject) => {
      http.get(url).then(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }


}