export const SUPPOER_LOCALES = [
  {
    name: "简体中文",
    value: "zh-CN"
  },
  {
    name: "English",
    value: "en-US"
  },
  // {
  //   name: "繁體中文",
  //   value: "zh-TW"
  // },
  // {
  //   name: "français",
  //   value: "fr-FR"
  // },
  // {
  //   name: "日本の",
  //   value: "ja-JP"
  // }
];

export const apiPrefix = '/devconsole/';
export const hasLogoArea = true;
export const echartToolTipBg = 'rgba(70, 74, 95, 0.8)';
export const pageSizeOption = ['20', '50', '100']
export const hasWXLink = true;
export const aebAlarm = [20, 21, 22, 26, 27, 28, 19, 30];
export const defaultHheme = 'blue2'; 