import React from 'react';
import { DevConfigItem } from '../../../src/types';
import Form, { FormComponentProps } from 'antd/lib/form';
import { Input, Select, Slider, Switch, Tabs, Checkbox, Button, Modal, message, Icon } from 'antd';
import { action, observable, toJS, computed, when } from 'mobx';
import { intlGet, eq, emptyFunc, copy } from '../../../src/utils/mix';
import templateJson from './template.json';
import InputDialog, { DialogType } from './inputDialog';
import configTypeData from './configType';
import { inject, observer } from 'mobx-react';
import { CommonStore, DeviceStore } from '../../../src/stores';
import './devConfigForm.scss';
import _ from 'lodash';
import { h5DevTypes } from 'src/consts';


// h5params.forEach(x => {
//   const { key } = x;
//   templateJson["8"][key] = {
//     "text": key,
//     "type": "input",
//     "parent": 1
//   }
// })


interface RenderFormItemProps extends FormComponentProps {
  // formItemArr: DevConfigItem[],
  initValues: {[key: string]: any},
  devType: number,
  // setValue?: (field: string, value) => void,
  hasCheck?: boolean,
  readonly?: boolean,
  commonStore?: CommonStore;
  deviceStore?: DeviceStore;
}

const Option = Select.Option;

@inject('commonStore', 'deviceStore')
@observer
class DevConfigForm extends React.Component<RenderFormItemProps>{

  dialogType: DialogType = 'dms';
  inputField = '';
  inputValue: number = 0;

  @observable onlySaveLoading: boolean = false;// only save loading
  @observable saveSetLoading: boolean = false;
  @observable isFugai: number = 0; //is fugai
  @observable queryLoading: boolean = false;
  @observable selectItem: any = undefined; // current select item
  @observable realParaInfo: any = undefined;
  @observable configModalName: string = '' // modal name
  @observable showConfigModal: boolean = false; //config modal boolean
  @observable selectedParent = -1;
  @observable configMap = observable.map<string, DevConfigItem>(); // key field_devType
  @observable orgConfigObj: any = {}; // org canshu moban 
  @observable showDialog = false;
  @observable tcEdit: boolean = false; // tanchuang is edit
  @observable selectOrgItemV: any = undefined; // shengcheng jigou moban peizhi value

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { deviceStore, devType, initValues } = this.props;
    const parentTypes = configTypeData[devType];
    this.selectedParent = parentTypes ? Number(Object.keys(parentTypes)[0]) : -1;
    const hasInitValues = !!Object.keys(initValues).length;

    if(h5DevTypes.includes(devType)){
      if(hasInitValues){
        this.setH5FormValues(initValues);
      }
    }else{
      this.initForm();
      if(hasInitValues){
        this.setFormValues(initValues);
      }
    }
    when(
      () => !!Object.keys(deviceStore!.orgConfigPara).length,
      () => this.changeOrgConfigPara()
    )
  }

  componentDidUpdate(prevProps: Readonly<RenderFormItemProps>): void {
    const { deviceStore, devType } = this.props;
    const {initValues: oldValues} = prevProps;
    const {initValues} = this.props;
    if(initValues !== oldValues){
      if(h5DevTypes.includes(devType)){
        this.setH5FormValues(initValues);
      }else{
        this.setFormValues(initValues);
      }
    }
  }

  @computed
  get configArr() {
    return _.sortBy(Array.from(this.configMap.values()).filter(x => x.parent == this.selectedParent),
    x => x.key.toLowerCase())
  }

  @action
  changeOrgConfigPara = () => {
    const { devType, deviceStore } = this.props;
    let curdata = toJS(deviceStore!.orgConfigPara);
    console.log('changeOrgConfigPara curdata', curdata);
    let arr: any = [];
    Object.keys(curdata).map(x => {
      let values: any = '';
      if (typeof curdata[x] == 'object' && curdata[x]) {
        values = curdata[x]['value'];
      } else {
        values = JSON.parse(curdata[x])['value'];
      }
      let obj = {
        deviceType: devType,
        key: x,
        value: values,
      }
      arr.push(obj);
    })

    //set value
    arr.forEach(x => {
      console.log('ORG config PARA=', toJS(this.orgConfigObj));
      const { deviceType, value, key } = x;
      const templateKey = `${key}_${deviceType}`
      console.log('org template set config before=', templateKey, toJS(this.orgConfigObj[templateKey]));
      this.orgConfigObj[templateKey] = value;
      console.log('org template set config after=', templateKey, toJS(this.orgConfigObj[templateKey]));
    });
  }

  @computed
  get checkedConfig() {
    return Array.from(this.configMap.values()).filter(x => x.checked);
  }

  @action
  setH5FormValues = (initValues) => {
    const { devType,} = this.props;
    console.log('initH5Form', initValues);

    for(let key in initValues){
      const {value, ov } = initValues[key];
      const templateKey = `${key}_${devType}`
      const config = this.configMap.get(templateKey);
      const isH5 = h5DevTypes.includes(devType);
      if (config) {
        config.initValue = value;
        config.checked = true;
        config.ov = ov;
      } else {
        const newConfig: DevConfigItem = {
          type: 'input',
          parent: isH5 ? 1 : 999,
          key: templateKey,
          field: key,
          devType,
          option: [],
          initValue: value,
          checked: false,
          ov,
        }
        this.configMap.set(templateKey, newConfig);
      }
    };
  }

  @action
  initForm2 = (initValues) => {
    const { devType} = this.props;
    console.log('initForm', initValues);
    if(!initValues.length){
      return;
    }
    const initValueObj: any = {};
    initValues.forEach(x => {
      const {key, value, ov} = x;
      const templateKey = `${key}_${devType}`;
      initValueObj[templateKey] = {value: value || '', ov: ov || 0};
    })
    // 初始化表单项
    this.configMap.clear();

    const configData = templateJson[devType];
    for (let key in configData) {
      const { type, parent, option = [],} = configData[key];
      const templateKey = `${key}_${devType}`;
      const initItem = initValueObj[templateKey];
      if(initItem){

      }
      console.log('initItem', initItem)
      const {value, ov} = initItem;
      const newConfg: DevConfigItem = {
        type,
        parent,
        key: templateKey,
        field: key,
        devType, 
        option,
        initValue: value,
        checked: false,
        ov
      }
      this.configMap.set(key, newConfg);
    }
    
    Object.keys(initValues).forEach(key => {
      const { value, ov} = initValues[key];
      const keyWithDevType = `${key}_${devType}`;
      const config = this.configMap.get(keyWithDevType);

    })
  }

  @action
  initForm = () => {
    const { devType} = this.props;
    
    const configData = templateJson[devType];
    for (let key in configData) {
      const { type, parent, option = [],} = configData[key];
      const templateKey = `${key}_${devType}`;
      const newConfg: DevConfigItem = {
        type,
        parent,
        key: templateKey,
        field: key,
        devType, 
        option,
        // initValue: null,
        checked: false,
        ov: 0
      }
      this.configMap.set(key, newConfg);
    }
  }

  @action
  setFormValues = (initValues) => {
    console.log('setFormValues', toJS(initValues));
    const { devType} = this.props;
    for(let key in initValues){
      const {ov, value} = initValues[key];
      const config = this.configMap.get(key);
      if (config) {
        config.initValue = value;
        config.checked = true;
        config.ov = ov;
      } else {
        const templateKey = `${key}_${devType}`;
        const newConfig: DevConfigItem = {
          type: 'input',
          parent: 999,
          key: templateKey,
          field: key,
          devType,
          option: [],
          initValue: value,
          checked: false,
          ov,
        }
        this.configMap.set(key, newConfig);
      }
    }
  }

  @action
  changeParent = (parent: number) => {
    const { form } = this.props;
    form.validateFields((err, values) => {
      if (err) {
        console.log('err', err);
      } else {
        this.selectedParent = parent;
      }
    });
  }

  @action
  changeShowInputDialog = (show: boolean) => {
    this.showDialog = show;
  }

  @action
  showInputDialog = (type: DialogType, field: string, value: any, isEdit: boolean) => {
    const { form } = this.props;
    this.tcEdit = isEdit;
    this.changeShowInputDialog(true);
    this.dialogType = type;
    this.inputField = field;
    this.inputValue = value;

    if (eq(type, 'h3')) {
      form.validateFields((err, values) => {
        if (err) {
        } else {
          let judge: any = values['CvbsMode_5'];
          if (judge || eq(judge, 0)) {
            this.tcEdit = isEdit;
            this.changeShowInputDialog(true);
            this.dialogType = type;
            this.inputField = field;
            this.inputValue = value;
          } else {
            // message.warn(intlGet('please_select_cvbs_mode'), 2);
            // return false;
          }
        }
      })
    } else {
      this.tcEdit = isEdit;
      this.changeShowInputDialog(true);
      this.dialogType = type;
      this.inputField = field;
      this.inputValue = value;
    }
  }

  getLabel = (devConfigItem: DevConfigItem) => {
    const { devType, field } = devConfigItem;
    let label = '';
    if (h5DevTypes.includes(devType)) {
      label = `${field}`;
    } else {
      label = intlGet(`devConfig_${[5].includes(devType) ? 3 : devType}_${field}`);
    }
    return label;
  }

  getLabelFieldStr = (devConfigItem: DevConfigItem) => {
    const label = this.getLabel(devConfigItem);
    const { parent, field, devType} = devConfigItem;
    return parent === 999 || devType == 8 ||  devType === 9
      ? field
      : label + `(${field})`;
  }

  setInputValue = (num: number) => {
    const { form } = this.props;
    const field = this.inputField;

    form.setFieldsValue({
      [field]: num,
    })
    this.onChange(field, 'dialog', num);
  }

  @action
  onCheck = (e) => {
    const { value, checked } = e.target;
    console.log('oncheck', value, checked);
    const config = this.configMap.get(value)!;
    config.checked = checked;
  }

  @action
  onChange = (key, type, e) => {
    const { form } = this.props;
    let value: any;
    switch (type) {
      case 'select':
      case 'ionRangeSlider':
      case 'dialog':
        value = e;
        break;
      case 'switch':
        value = e ? '1' : '0';
        break;
      case 'input':
        value = e.target.value;
        break;
    }
    console.log('onchange', key, e, value);
    let current = this.selectItem;

    //const config =this.configMap.get(current.key)!;
    //console.log('current=',config);
    current.initValue = value;
    current.checked = true;

    console.log('current worth=', toJS(this.selectItem));
  }

  @action
  changeIsFugai = (e) => {
    this.isFugai = e;
    console.log('is fugai change=', e);
  }

  @action
  setShowConfigModal = (bool, item) => {
    if (bool) {
      this.selectItem = copy(item);
      console.log('this.selectItem=', toJS(this.selectItem));
      const { key, devType, field, type, option = [], initValue, checked, ov } = item;
      this.isFugai = ov || 0;
      this.configModalName = this.getLabelFieldStr(item);
      this.selectOrgItemV = this.orgConfigObj[key] || toJS(this.props.deviceStore!.devConsoleDetailInfo).templateName;
    } else {
      this.realParaInfo = undefined;
    }
    this.showConfigModal = bool;
  }

  getFormItem = (devConfigItem: DevConfigItem, showTitle: boolean, isEdit: boolean) => {

    const formItemWidth = 218;

    const { form, readonly = false, hasCheck = false } = this.props;
    const { key, devType, field, type, option = [], initValue, parent } = devConfigItem;
    // console.log('key=', key);
    const switchValue = eq(initValue, '1') ? true : false;
    const defaultValueMap = {
      input: initValue,
      select: initValue,
      switch: switchValue,
      ionRangeSlider: initValue || 0,
      dialog_adas: initValue,
      dialog_dms: initValue,
      dialog_all: initValue,
    }
    const formItemMap = {
      input: <Input
        value={initValue}
        style={{ width: formItemWidth }} disabled={isEdit}
        onChange={this.onChange.bind(this, key, 'input')}
        autoComplete='off'
      />,
      select: <Select
        value={(initValue || eq(initValue, 0)) ? Number(initValue) : undefined}
        style={{ width: formItemWidth }} disabled={isEdit}
        onChange={this.onChange.bind(this, key, 'select')}
      >
        {option.map(x => {
          return <Option key={x.value} value={x.value}>{x.label}</Option>
        })}
      </Select>,
      switch: <Switch checked={switchValue} disabled={isEdit}
        onChange={this.onChange.bind(this, key, 'switch')}
      />,
      ionRangeSlider: <Slider
        value={(Number(initValue) || 0)}
        marks={{ 0: 0, 100: 100 }}
        style={{ width: formItemWidth }} disabled={isEdit}
        onChange={this.onChange.bind(this, key, 'ionRangeSlider')}
      />,
      dialog_adas: <Input
        value={initValue}
        autoComplete='off'
        style={{ width: formItemWidth }}
        disabled={isEdit}
        onClick={isEdit ? emptyFunc : this.showInputDialog.bind(this, 'adas', key, initValue, true)} />,
      dialog_dms: <Input
        value={initValue}
        autoComplete='off'
        style={{ width: formItemWidth }}
        disabled={isEdit}
        onClick={isEdit ? emptyFunc : this.showInputDialog.bind(this, 'dms', key, initValue, true)} />,
      dialog_all: <Input autoComplete='off'
        value={initValue}
        style={{ width: formItemWidth }}
        disabled={isEdit}
        onClick={isEdit ? emptyFunc : this.showInputDialog.bind(this, 'all', key, initValue, true)} />,
      dialog_h3: <Input autoComplete='off'
        style={{ width: formItemWidth }}
        disabled={isEdit}
        onClick={isEdit ? emptyFunc : this.showInputDialog.bind(this, 'h3', key, initValue, true)} />,
    }

    const labelStr = this.getLabelFieldStr(devConfigItem);

    return <div className="formItem" key={key}>

      {showTitle &&
        <span className='xxskr'>
          <Button
            onClick={this.setShowConfigModal.bind(this, true, devConfigItem)}
            style={{ margin: '0 10px' }}
            type={'primary'} >配置</Button>
          <span className={'skr'} title={labelStr}>{labelStr}</span>
        </span>
      }

      <Form.Item key={key} >
        {formItemMap[type]}
      </Form.Item>
    </div>

  }

  //对话框显示什么内容
  @action
  convertContent = (devConfigItem: any, intvalue: any, name: string) => {

    const formItemWidth = 218;

    const { form, readonly = false, hasCheck = false } = this.props;
    const { key, devType, field, type, option = [], checked } = devConfigItem;
    const label = this.getLabel(devConfigItem);
    let initValue = intvalue;
    const switchValue = eq(initValue ? (initValue).toString() : undefined, '1') ? true : false;
    const formItemMap = {
      input: <Input value={initValue} style={{ width: formItemWidth }} disabled={true}
        autoComplete='off'
      />,
      select: <Select value={(initValue || eq(initValue, 0)) ? Number(initValue) : undefined} style={{ width: formItemWidth }} disabled={true}
      >
        {option.map(x => {
          return <Option key={x.value} value={x.value}>{x.label}</Option>
        })}
      </Select>,
      switch: <Switch checked={switchValue} disabled={true}
      />,
      ionRangeSlider: <Slider value={(Number(initValue) || 0)} marks={{ 0: 0, 100: 100 }} style={{ width: formItemWidth }} disabled={true}
      />,
      dialog_adas: <Input autoComplete='off'
        style={{ width: formItemWidth }}
        disabled={readonly}
        value={initValue}
        onClick={readonly ? emptyFunc : this.showInputDialog.bind(this, 'adas', key, initValue, false)} />,
      dialog_dms: <Input autoComplete='off'
        value={initValue}
        style={{ width: formItemWidth }}
        disabled={readonly}
        onClick={readonly ? emptyFunc : this.showInputDialog.bind(this, 'dms', key, initValue, false)} />,
      dialog_all: <Input autoComplete='off'
        value={initValue}
        style={{ width: formItemWidth }}
        disabled={readonly}
        onClick={readonly ? emptyFunc : this.showInputDialog.bind(this, 'all', key, initValue, false)} />,
      dialog_h3: <Input autoComplete='off'
        style={{ width: formItemWidth }}
        disabled={readonly}
        onClick={readonly ? emptyFunc : this.showInputDialog.bind(this, 'h3', key, initValue, true)} />,
    }

    const defaultValueMap = {
      input: initValue,
      select: initValue,
      switch: switchValue,
      ionRangeSlider: initValue || 0,
      dialog_adas: initValue,
      dialog_dms: initValue,
      dialog_h3: initValue,
    }
    const defaultValue = defaultValueMap[type];

    return <div className="formItem" key={key}>
      {formItemMap[type]}
    </div>
  }

  //config query
  @action
  configQuery = () => {
    const { commonStore, deviceStore } = this.props;
    let item = toJS(this.selectItem);
    const devType = item.devType;
    let d = toJS(deviceStore!.devConsoleDetailInfo);
    const reg = new RegExp(`_${devType}$`);
    let key = item.key.replace(reg, '');
    this.queryLoading = true;
    commonStore!.configQuery(d.deviceId, key, d.devType).then(res => {
      this.queryLoading = false;
      let data: any = res;
      this.realParaInfo = JSON.parse(data.content)[key] || undefined;
      console.log('configQuery res', JSON.parse(data.content));
      message.success('配置查询成功', 2);
    }, err => {
      console.log('configQuery err', err);
      this.queryLoading = false;
      message.error('配置查询失败', 2)
    });
  }

  //only save
  @action
  onlySave = (bools: boolean) => {
    
    if (this.isFugai || eq(this.isFugai, 0)) {
      bools && (this.onlySaveLoading = true)
      const { deviceStore } = this.props;
      let d = toJS(deviceStore!.devConsoleDetailInfo);
      const { key, devType, field, type, initValue } = toJS(this.selectItem);
      const config = this.configMap.get(key);
      const reg = new RegExp(`_${devType}$`);
      let curKey = key.replace(reg, '');
      let fugai = this.isFugai;
      let para = {
        devId: d.deviceId,
        devNo: d.devNo,
        deviceType: devType,
        key: curKey,
        value: (initValue || eq(initValue, 0)) ? (initValue).toString() : '',
        flag: fugai,
      };
      console.log('obj string=', para);
      deviceStore!.saveParamConfig(para).then(res => {
        bools && (this.onlySaveLoading = false);
        //baocun shujuku de or baocun ws de 
        if (config) {
          config.ov = toJS(this.isFugai);
          config.initValue = initValue;
          config.checked = true;
        }
        true && message.success('参数配置保存成功', 2);
      }, err => {
        bools && (this.onlySaveLoading = false)
        true && message.error('参数配置保存失败', 2);
      })
    } else {
      message.warning('请选择覆盖类型', 2);
    }
  }


  //save and set
  @action
  saveAndSet = () => {
    if (this.isFugai || eq(this.isFugai, 0)) {
      // let promiseArr: any[] = [this.onlySave(false), this.setParas()]
      let promiseArr: any[] = [this.onlySave(false)]
      Promise.all(promiseArr).then(res => {

      }, err => {
        console.log('saveAndSet err=', err);

      })
    } else {
      message.warning('请选择覆盖类型', 2);
    }
  }

  //set
  setParas = () => {
    const { commonStore, deviceStore } = this.props;
    const { key, devType, field, type, initValue } = toJS(this.selectItem);
    let d = toJS(deviceStore!.devConsoleDetailInfo);
    let curKey = key.split('_')[0];
    var curObj: any = {};
    curObj[curKey] = (initValue || eq(initValue, 0)) ? (initValue).toString() : '';
    let obj: any = {
      set: curObj
    };
    this.saveSetLoading = true;
    commonStore!.configDispatch(d.deviceId, obj, d.devType).then(res => {
      this.saveSetLoading = false;
      message.success('设置下发成功', 2);
      console.log('save and set res=', res);
      //this.saveSetLoading = false;
      // message.success('参数配置设置成功', 2);
    }, err => {
      message.error('设置下发失败', 2);
      this.saveSetLoading = false;
      console.log('save and set err=', err);
      //this.saveSetLoading = false;
      // message.error('参数配置设置失败', 2);
    })
  }

  render() {
    const { devType, deviceStore } = this.props;
    const types: { [key: string]: string } = configTypeData[devType] || {};
    // console.log('devConfigForm render devType', devType);
    console.log('this.configArr', copy(this.configArr), copy(this.configMap));

    return (
      <div className='devConfigForm'>
        <div className="parent">
          {Object.keys(types).map(configType => {
            const className = eq(this.selectedParent, Number(configType))
              ? 'typeItem active p-bg' : 'typeItem';
            const typeStr = intlGet(`devConfigType_${[5].includes(devType) ? 3 : devType}_${configType}`);

            return <div className={className}
              key={configType}
              onClick={this.changeParent.bind(this, Number(configType))}>
              {typeStr}
            </div>
          })}
        </div>
        <Form >
          {this.configArr.map(x => {
            return this.getFormItem(toJS(x), true, true);
          })}

          {this.showDialog ?
            <InputDialog
              isEdit={toJS(this.tcEdit)}
              type={this.dialogType}
              value={this.inputValue}
              setVisiable={this.changeShowInputDialog}
              onSumbit={this.setInputValue}
            /> : null
          }
        </Form>

        {this.showConfigModal && <Modal
          width={900}
          visible={true}
          title={(this.configModalName)}
          mask={false}
          maskClosable={false}
          onCancel={this.setShowConfigModal.bind(this, false)}
          className={`zltsModal `}
          footer={
            <div className={'footer'}>
              <span className={'tips'}>
                <Icon type="info-circle" />
                参数设置完成后，重启应用生效
              </span>
              <Button
                loading={this.saveSetLoading}
                onClick={this.saveAndSet}
                style={{ margin: '0 10px' }}
                type={'primary'} >设置</Button>
            </div>
          }
        >
          <div className={'configModal'}>
            <div className={'content'}>
              <div className={'top'}>
                <span className={'title'}>当前配置：</span>
                {this.getFormItem(toJS(this.selectItem), false, false)}
                <Select
                  placeholder="覆盖选择"
                  style={{ width: 110, marginLeft: 30 }}
                  value={this.isFugai ? 1 : 0}
                  onChange={this.changeIsFugai}
                >
                  <Option key={1} value={1}>{('覆盖')}</Option>
                  <Option key={0} value={0}>{('不覆盖')}</Option>
                </Select>
                <span className={'tips'}>
                  <Icon type="info-circle" />
                  当前数据库内配置值,若选择覆盖则强制生效
                </span>
              </div>
              <div className={'mid'}>
                <span className={'title'}>生效模板配置：</span>
                {this.convertContent(toJS(this.selectItem), toJS(this.selectOrgItemV), 'sxjg')}
                <span className={'tips'}>
                  <Icon type="info-circle" />
                  匹配 [{deviceStore!.curTemplateName || deviceStore!.devConsoleDetailInfo.templateName || '--'}] 配置值
                </span>
              </div>
              <div className={'bottom'}>
                <span className={'title'}>实时配置：</span>
                {true && this.convertContent(toJS(this.selectItem), this.realParaInfo, 'ssjg')}
                <Button
                  loading={this.queryLoading}
                  onClick={this.configQuery}
                  style={{ marginLeft: '30px' }}
                  type={'primary'} >实时获取</Button>
                <span className={'tips'}>
                  <Icon type="info-circle" />
                  实时获取设备内配置值
                </span>
              </div>
            </div>

          </div>
        </Modal>}
      </div>
    )
  }

}

export default Form.create<RenderFormItemProps>()(DevConfigForm); 
