import { http, } from '../../src/utils/http';
import { observable, action, runInAction, computed } from 'mobx';
import { ReqTableData, ParseObj, ReqData, ParseObj2 } from 'src/models';

const defaultSearch = {
  startTime: '',
  endTime: '',
  key: '',
  customerCode: '',
  deviceType: -1,
  version: '',
}

const parseObj: ParseObj = {
  key: 'id',
  parseFunc: res => {
    const { items, pageSize, pageNum, total } = res.data;
    return {
      page: pageNum,
      pageSize,
      total,
      data: items
    }
  }
}

export class VersionHisStore {

  @observable showMoreFilter = false;
  @observable.ref detailData: any[] = [];

  @observable detailLoading = false;
  @observable detailIndex = 0;

  @observable detailCategory = '2';

  proTestTable = new ReqTableData(
    'ota/page',
    defaultSearch,
    parseObj
  )

  @action
  changeDetailCategory = (category: string) => {
    this.detailCategory = category;
  }

  @action
  changeDetailIndex = (i: number) => {
    this.detailIndex = i;
  }

  @action
  changeShowMoreFilter = (show: boolean) => {
    this.showMoreFilter = show;
  }

  @action
  getDetail = (viewId: string, category: string) => {
    const url = 'product-info/check/view';
    const params = {
      viewId
    }
    this.detailLoading = true;
    return http.post(url, params).then(res => {
      runInAction(() => {
        this.detailLoading = false;
        const data = res.data || [];
        // 后装
        if (category === '1') {
          this.detailIndex = data.findIndex(x => x.viewId === viewId) || 0;
        } else {
          // 前装
          this.detailIndex = data
            .filter(x => x.category == category)
            .findIndex(x => x.viewId === viewId) || 0;
        }

        this.detailData = data;
      });
    }, err => {
      this.detailLoading = false;
    })
  }
}  