import React from 'react';
import './index.scss';
import { inject, observer, Provider } from "mobx-react";
import { Button, message, Modal, Input, Select, Icon, TreeSelect, Empty, Spin, Tree } from "antd";
import { intlGet, eq, converHMS, downLoadFileByUrl, replaceMediaUrl, convertSignal, convertCurrentTime, copy, convertCameraState, getGps, transDict, converVehicleState, getVehStatus, getSearchParams } from "src/utils";
import { CommonStore, DeviceStore, UiStore, AuthStore } from '../../../../src/stores';
import { action, observable, runInAction, toJS, computed, when, } from "mobx";
import { MessageType, WSResponse, } from '../../../../src/models';
import DevMonitor from '../../../../src/pages/devConsole/paramButton/devMonitor';
import TestHistory from './testHistory';
import Rollback from './devMonitor/rollback';

import ReactEcharts from 'echarts-for-react';
import MyIcon from '../../../../src/components/myIcon';
import { H5Info2 } from '../h5Info';

import { http } from "../../../utils/http";
import { getStoreStr, getChannelStr } from '../../../utils/common';
import LabelAndValue from 'src/components/labelAndValue';
import FileManager from '../fileManager';
import BurnModal from './BurnModal'
import FormatModal from './FormatModal'
import { h5DevTypes } from 'src/consts';



const { TextArea } = Input;
const { Option } = Select;
const { TreeNode } = TreeSelect;
const { TreeNode: TreeNod } = Tree;
const { confirm } = Modal;
const colorArr = ['#7D8EF6', '#D992ED', '#91C1A5'];

const log800: any = [
  {
    key: 'gjrz',
    title: '固件日志',
    child: [
      { title: 'jm800_log.txt' },
      { title: 'jm800_log.txt.1' },
      { title: 'jm800_log.txt.2' },
      { title: 'jm800_log.txt.3' },
      { title: 'jm800_log.txt.4' },
      { title: 'jm800_log.txt.5' },
      { title: 'jm800_log.txt.6' },
    ],
  },
  {
    key: 'lxrz',
    title: '录像日志',
    child: [
      { title: 'record264-0.log' },
      { title: 'record264-1.log' },
      { title: 'record264-2.log' },
      { title: 'record264-3.log' },
      { title: 'record264-4.log' },
    ],
  },
  {
    key: 'txrz',
    title: '通讯日志',
    child: [
      { title: 'remote_log.txt' },
      { title: 'remote_log.txt.1' },
      { title: 'remote_log.txt.2' },
      { title: 'remote_log.txt.3' },
      { title: 'remote_log.txt.4' },
      { title: 'remote_log.txt.5' },
      { title: 'remote_log.txt.6' },
    ],
  },
  {
    key: 'xyrz',
    title: '协议日志',
    child: [
      { title: 'jm808_log.txt' },
      { title: 'jm808_log.txt.1' },
      { title: 'jm808_log.txt.2' },
      { title: 'jm808_log.txt.3' },
      { title: 'jm808_log.txt.4' },
      { title: 'jm808_log.txt.5' },
      { title: 'jm808_log.txt.6' },
      { title: 'jm808_log.txt.7' },
      { title: 'jm808_log.txt.8' },
    ],
  },
];

const log600: any = [
  {
    key: 'gjrz',
    title: '固件日志',
    child: [
      { title: 'jm600_log.txt' },
      { title: 'jm600_log.txt.1' },
      { title: 'jm600_log.txt.2' },
      { title: 'jm600_log.txt.3' },
      { title: 'jm600_log.txt.4' },
      { title: 'jm600_log.txt.5' },
      { title: 'jm600_log.txt.6' },
    ],
  },
  {
    key: 'lxrz',
    title: '录像日志',
    child: [
      { title: 'record264-0.log' },
      { title: 'record264-1.log' },
      { title: 'record264-2.log' },
      { title: 'record264-3.log' },
      { title: 'record264-4.log' },
    ],
  },
  {
    key: 'txrz',
    title: '通讯日志',
    child: [
      { title: 'remote_log.txt' },
      { title: 'remote_log.txt.1' },
      { title: 'remote_log.txt.2' },
      { title: 'remote_log.txt.3' },
      { title: 'remote_log.txt.4' },
      { title: 'remote_log.txt.5' },
      { title: 'remote_log.txt.6' },
    ],
  },
  {
    key: 'xyrz',
    title: '协议日志',
    child: [
      { title: 'jm808_log.txt' },
      { title: 'jm808_log.txt.1' },
      { title: 'jm808_log.txt.2' },
      { title: 'jm808_log.txt.3' },
      { title: 'jm808_log.txt.4' },
      { title: 'jm808_log.txt.5' },
      { title: 'jm808_log.txt.6' },
      { title: 'jm808_log.txt.7' },
      { title: 'jm808_log.txt.8' },
    ],
  },
];


const getLogPath = (fileName: string, devType: number) => {
  // h1
  if (devType === 6) {
    if (fileName.includes('jm808_log.txt')) {
      return `/storage/emulated/0/jimu/jm600/logs/JM808Log/${fileName}`
    } else {
      return `/storage/emulated/0/jimu/jm600/logs/${fileName}`
    }
  } else {
    if (fileName.includes('jm808_log.txt')) {
      return `/storage/sdcard0/jimu/jm800/logs/JM808Log/${fileName}`
    } else {
      return `/storage/sdcard0/jimu/jm800/logs/${fileName}`;
    }
  }
}

interface paramButtonProps {
  // detailInfo?: any;
  commonStore?: CommonStore;
  deviceStore?: DeviceStore;
  uiStore?: UiStore;
  authStore?: AuthStore;
}

const tipMessage = {
  6: '请输入语音内容',
  17: '请输入CAN脚本',
  9: '请输入文件绝对路径',
}

@inject('commonStore', 'deviceStore', 'uiStore', 'authStore')
@observer
export default class ParamButton extends React.Component<paramButtonProps> {

  unSubscribeWS: Function[] = [];

  @observable popIndex: number = 0;  //default no show popconfirm
  @observable clearTimeOuts: any = undefined;
  @observable clearInternals: any = undefined;
  @observable daojishi: number = 30; //dao ji shi
  @observable timeouFlag = false;
  @observable modalShow: boolean = false;
  @observable devMonitorShow: boolean = false;
  @observable testHistoryShow: boolean = false;
  @observable logModalShow: boolean = false;
  @observable voiceLoading: boolean = false;
  @observable modalActive: number = 6;
  @observable isDispatchLoading: boolean = false;
  @observable inputInfo: string = '';
  @observable replayInfo: string = ''; //reply info
  @observable tczlIndex: any = '6'; //默认语音下发
  @observable tcLoading: boolean = false; //tanchuang loading
  @observable selectLogValue: string = 'jm800_log.txt'; //default
  @observable logTxtUrl: string = ''; //log txt file url
  @observable logTxtName: string = 'temp_log.txt'; //default
  @observable logContent: string = ''; //log open text content info
  @observable logLoading: boolean = false; //log request loading
  @observable zhilingObj = {
    1: '设备关机',
    2: 'app重启',
    3: '设备升级',
    5: '配置查询',
    6: '语音下发',
    7: '抓拍图片',
    8: '抓拍视频',
    9: '日志抓取',
    14: '卸载app',
    15: '硬线频率',
    16: '开关adb',
    17: 'CAN脚本',
    22: '格式化TF卡',
  };
  @observable noInfoLoading = {
    1: false,
    2: false,
    3: false,
    5: false,
    7: false,
    8: false,
    9: false,
    14: false,
    15: false,
    16: false,
    17: false,
    22: false,
  }
  @observable activeCache: string = ''; //历史记录选中效果
  @observable burnModalShow: boolean = false;
  @observable formatModalShow: boolean = false;

  //设置历史记录
  @observable txtCacheList: any = {};
  tempSelectLogValue: string = '';
  txtLoading: boolean = false;

  plateNo = '';

  constructor(props) {
    super(props);
    const { authStore } = this.props;
    authStore!.getDict();

    const { arr } = getSearchParams();
    if (arr) {
      let infoArr = arr.split('_');
      this.plateNo = window.decodeURI(infoArr[3]) || '--';
    }
  }

  componentDidMount() {
    const { commonStore, deviceStore } = this.props;
    //log file real time info
    const unSubscribeFun2 = commonStore!.subscribeWSEvent(2005, this.receiveWS2005);
    this.unSubscribeWS.push(unSubscribeFun2);
  }

  componentWillUnmount() {
    this.unSubscribeWS.forEach(x => x());
  }

  @action
  changeTxtLoading = (bool) => {
    this.txtLoading = bool;
  }

  //receive log file reasltime info
  @action
  receiveWS2005 = (type: MessageType, data: WSResponse) => {
    const { deviceStore } = this.props;
    if (eq(type, 2005)) {
      const result = data.data as any;
      // console.log('receive receiveLogRealInfo =', data);
      if (eq(data.code, 0) && deviceStore!.grabbingFile == 2) {
        deviceStore!.grabbingFile = 0;
        if (eq(result.format, 3) && result.url) {
          this.logTxtUrl = result.url;
          this.logTxtName = result.fileName;
          // console.log('log txt=', result.url);
          this.showLog(replaceMediaUrl(result.url), result.fileName, result.url);

        }
      }
    }
  }

  @action
  setShowModal = (bool) => {
    this.modalShow = bool;
    if (!bool) {
      this.inputInfo = '';
    }
  }

  @action
  setLogModal = (bool) => {
    this.logModalShow = bool;
    if (!bool) {
      this.logContent = '';
      this.logTxtUrl = ''; //clear download url
      //清空历史记录选中效果
      this.activeCache = '';
    }
  }

  @action
  changeInputInfo = (e) => {
    const voice = e.target.value;
    // console.log('modal textarea=', e.target.value);
    this.inputInfo = voice;
  }

  @action
  openModal = (bool: boolean, act: number) => {
    this.modalShow = bool;
    this.modalActive = act;
  }

  @action
  dispatchContent = () => {
    let index = Number(this.tczlIndex);
    //不需要发送内容的那种指
    if (index === 9) {
      this.grabFile(this.inputInfo);
    } else if (this.inputInfo.trim().length > 0) {
      //需要输入内容的那种
      this.replayInfo = '';
      //alert(this.modalActive);
      switch (index) {
        case 6:
          this.dispatchVoice(this.inputInfo);
          break;
        case 17:
          this.dispatchCan(this.inputInfo);
          break;
        // case 9:
        //   this.grabFile(this.inputInfo);
      }
    } else {
      message.warning('内容不能为空', 2);
    }
  }

  //reset pop index
  @action
  setPopIndex = (index: number) => {
    if (this.noInfoLoading[index]) {

    } else {
      this.popIndex = index;
    }
  }

  @action
  confirmClose = () => {
    confirm({
      title: '确认关机',
      icon: <Icon type="info-circle" />,
      content: '确认后将会执行此操作',
      okText: "确认",
      cancelText: "取消",
      onOk: () => {
        this.noInfoZhiling('123', 1)
      },
      onCancel: () => { },
    });
  }

  @action
  confirmRestart = () => {
    confirm({
      title: '确认重启',
      icon: <Icon type="info-circle" />,
      content: '确认后将会执行此操作',
      okText: "确认",
      cancelText: "取消",
      onOk: () => {
        this.noInfoZhiling('123', 2)
      },
      onCancel: () => { },
    });
  }

  @action
  confirmUpdate = () => {
    confirm({
      title: '确认升级',
      icon: <Icon type="info-circle" />,
      content: '确认后将会执行此操作',
      okText: "确认",
      cancelText: "取消",
      onOk: () => {
        this.noInfoZhiling('123', 3)
      },
      onCancel: () => { },
    });
  }

  @action
  confirmUnistall = () => {
    confirm({
      title: '确认卸载',
      icon: <Icon type="info-circle" />,
      content: '确认后将会执行此操作',
      okText: "确认",
      cancelText: "取消",
      onOk: () => {
        this.noInfoZhiling('123', 14)
      },
      onCancel: () => { },
    });
  }

  @action
  confirmADB = () => {
    confirm({
      title: 'ADB开关',
      icon: <Icon type="info-circle" />,
      content: '确认后将会执行此操作',
      okText: "确认",
      cancelText: "取消",
      onOk: () => {
        this.noInfoZhiling('123', 16)
      },
      onCancel: () => { },
    });
  }
  @action
  confirmResetTFC = () => {

    confirm({
      title: '确认格式化TF卡',
      icon: <Icon type="info-circle" />,
      content: '确认后将会执行此操作',
      okText: "确认",
      cancelText: "取消",
      onOk: () => {
        this.noInfoZhiling('123', 22)
      },
      onCancel: () => { },
    });
  }

  //no info zhiling
  @action
  noInfoZhiling = (devId: string, act: number) => {
    this.setPopIndex(0);
    this.noInfoLoading[act] = true;
    this.isDispatchLoading = true;
    const { commonStore, deviceStore } = this.props;
    let d = toJS(deviceStore!.devConsoleDetailInfo);
    commonStore!.noInfoZhiling(d.deviceId, act, d.devType).then(res => {
      let dat: any = res;
      this.noInfoLoading[act] = false;
      this.isDispatchLoading = false;
      // console.log('no zhiling res=', dat);
      if (dat.error && dat.error.length) {
        message.error('操作失败');
        this.replayInfo = dat.error || '';
      } else {
        message.success('操作成功');
      }
    }, err => {
      this.noInfoLoading[act] = false;
      this.isDispatchLoading = false;
      message.error('操作失败'); //this.zhilingObj[act] 
    })
  }

  //获取日志文件列表
  @action
  getLogList = (devId: string, act: number) => {
    const { commonStore, deviceStore } = this.props;
    let d = toJS(deviceStore!.devConsoleDetailInfo);
    commonStore!.noInfoZhiling(d.deviceId, act, d.devType).then(res => {
      let dat: any = res;
      // console.log('getLogList res=', dat);
      if (dat.error && dat.error.length) {

      } else {

      }
    }, err => {
      //message.error(this.zhilingObj[act]+'失败');
    })
  }

  @action
  dispatchVoice = (txt) => {
    const { commonStore, deviceStore } = this.props;
    let d = toJS(deviceStore!.devConsoleDetailInfo);
    this.isDispatchLoading = true;
    commonStore!.dispatchVoice(d.deviceId, txt, 2, 2, d.devType).then(res => {
      this.isDispatchLoading = false;
      let resCode: any = res;
      if (eq(resCode.code, 0)) {
        message.success('语音下发成功', 2);
      }
      console.log('dispatch voice res=', res);
    }, err => {
      this.isDispatchLoading = false;
      message.error('语音下发失败', 2)
    });
  }

  @action
  dispatchCan = (txt) => {
    const { commonStore, deviceStore } = this.props;
    let d = toJS(deviceStore!.devConsoleDetailInfo);
    let vid = '';
    let pid = '';
    this.isDispatchLoading = true;
    commonStore!.dispatchCan(d.deviceId, vid, pid, txt, d.devType).then(res => {
      this.isDispatchLoading = false;
      let resCode: any = res;
      if (eq(resCode.code, 0)) {
        message.success('CAN下发成功', 2);
      }
      console.log('dispatch voice res=', res);
    }, err => {
      this.isDispatchLoading = false;
      message.error('CAN下发失败', 2)
    });
  }

  //指令调试
  @action
  zlTiaoshi = () => {
    this.modalShow = !this.modalShow;
  }

  //版本管理
  @action
  changeDevMonitor = () => {
    this.devMonitorShow = !this.devMonitorShow;
  }

  @action
  changeTestHistoryShow = () => {
    this.testHistoryShow = !this.testHistoryShow;
  }

  @action
  openLogModal = () => {
    this.logModalShow = true;
    //this.logTxtUrl = '';

    const { deviceStore } = this.props;
    const { devConsoleDetailInfo } = deviceStore!;
    let { devType } = devConsoleDetailInfo || {};
    console.log('componentDidMount');
    if (devType != undefined) {
      let defaultLogName = '';

      if (devType === 6) {
        defaultLogName = 'jm600_log.txt'
      } else {
        defaultLogName = 'jm800_log.txt'
      }
      this.changeSelectLog(defaultLogName);
    }
  }

  @action
  changeTanchuangIndex = (tanchuangIndex: string) => {
    this.tczlIndex = tanchuangIndex;
    this.inputInfo = '';
    // if (tanchuangIndex === '9') {
    //   this.inputInfo = "{\"files\":[]}";
    // } else {
    // }
  }

  @action
  changeSelectLog = (str) => {
    this.selectLogValue = str;
  }

  //scrap log file抓取日志文件
  @action
  scrapLogFile = () => {
    const { commonStore, deviceStore } = this.props;
    let d = toJS(deviceStore!.devConsoleDetailInfo);
    let path = getLogPath(toJS(this.selectLogValue), d.devType);
    this.logLoading = true;
    this.logTxtUrl = ''; //will clear log url
    this.logContent = '';
    //清空当前选中的历史记录
    this.activeCache = '';

    this.timeouFlag = true; //regard as tome out verify
    this.clearTimeOuts = setTimeout(() => {
      if ((this.timeouFlag)) {
        message.warning('日志地址返回超时', 2);
        this.timeouFlag = false;
        this.clearTimeOuts && clearTimeout(this.clearTimeOuts);
        this.clearInternals && window.clearInterval(this.clearInternals);
        this.daojishi = 30;
      } else {

      }
      this.logLoading = false;
    }, 30000);
    this.clearInternals = setInterval(() => {
      this.daojishi = this.daojishi - 1;
    }, 1000);
    deviceStore!.grabbingFile = 2;
    commonStore!.logScrapFile(d.deviceId, path, d.devType).then(res => {
      //this.logLoading = false;
      //message.success('日志抓取成功,等待硬件返回日志',2); 

    }, err => {
      this.timeouFlag = false;
      this.clearTimeOuts && clearTimeout(this.clearTimeOuts);
      this.clearInternals && window.clearInterval(this.clearInternals);
      this.logLoading = false;
      this.daojishi = 30;
      message.error('日志抓取失败', 2)
    });
  }

  @action
  grabFile = (txt) => {
    const { commonStore, deviceStore } = this.props;
    let d = toJS(deviceStore!.devConsoleDetailInfo);
    this.isDispatchLoading = true;
    deviceStore!.grabbingFile = 2;
    commonStore!.logScrapFile(d.deviceId, txt, d.devType).then(res => {
      this.isDispatchLoading = false;
      let resCode: any = res;
      if (eq(resCode.code, 0)) {
        message.success('抓取文件成功', 2);
      }
      console.log('dispatch voice res=', res);
    }, err => {
      this.isDispatchLoading = false;
      message.error('抓取文件成功', 2)
    });
  }

  //定时器判断日志地址返回是否超时
  @action
  judgeOverTime = () => {
    setTimeout(() => {
      if ((this.timeouFlag)) {
        message.warning('日志地址返回超时', 2);
        this.timeouFlag = false;
        this.clearTimeOuts && clearTimeout(this.clearTimeOuts);
        clearInterval(this.clearInternals);
      } else {
        if (this.timeouFlag) {
          //message.success('日志抓取成功',2);

        }
      }
      this.logLoading = false;
    }, 10000);
  }

  openQuanping = () => {
    console.log('this.logTxtUrl=', this.logTxtUrl);
    window.open(this.logTxtUrl);
  }

  @action
  showLog = (url, txtName, ysUrl) => {
    console.log('url=', url);
    const ruls = url.replace("/jm-txt", "jm-txt");
    http.get(ruls).then(res => {
      console.log('log content info res=', res);
    }, err => {
      console.log('log content info err=', err);
      console.log('log content info err=', err.data);
      message.success('日志抓取成功', 2);
      this.timeouFlag = false;
      this.logContent = err.data;

      //设置历史记录
      let tempData = toJS(this.txtCacheList);
      tempData[txtName] = {
        timeStamp: Date.now(),
        name: txtName,
        content: err.data,
        url: ysUrl,
        time: convertCurrentTime(new Date().getTime() + '', 1),
      };
      this.txtCacheList = tempData;
      //当前选中的历史记录
      this.activeCache = txtName;
      console.log('this.txtCacheList=', toJS(this.txtCacheList));

      this.clearTimeOuts && clearTimeout(this.clearTimeOuts);
      this.clearInternals && window.clearInterval(this.clearInternals);
      this.logLoading = false;
      this.daojishi = 30;
    })
  }

  downloadLogFile = () => {
    let ur = toJS(this.logTxtUrl);
    let name = toJS(this.logTxtName);
    console.log('url before=', ur);
    if (ur.length) {
      downLoadFileByUrl(replaceMediaUrl(ur), name + '.txt');
      console.log('ur after=', replaceMediaUrl(ur));
    } else {
      message.warning('没有log文件无法下载', 2);
    }
  }


  getOption = () => {
    const { } = this.props;
    let cardSateData = [
      { value: 0, name: '总存储量' },
      { value: 0, name: '已使用量' },
    ];
    let curData = cardSateData.filter(x => {
      return !eq(x.name, '总卡数')
    });
    // console.log('change cardSateData 20191213=', cardSateData, curData);
    const option = {
      tooltip: {
        trigger: 'item',
        formatter: "{b}<br/>{c}G",
        backgroundColor: 'rgba(70, 74, 95, 0.8)',
        padding: 8
      },
      color: colorArr, //过滤掉了总卡数
      series: [
        {
          hoverAnimation: false,
          type: 'pie',
          radius: [0, 60],
          avoidLabelOverlap: false,
          label: {
            normal: {
              show: false,
              position: 'center'
            },
          },
          labelLine: {
            normal: {
              show: false
            }
          },
          data: curData || []
        }
      ],
    };
    return option;
  }

  @action
  onChangeSelectlog = (value) => {
    this.selectLogValue = value;
    console.log('this.selectLogValue=', toJS(this.selectLogValue));
  }

  //点击单个缓存历史记录
  @action
  chakanCacheSingle = (singleCache) => {

    //替换select的选中值
    this.selectLogValue = singleCache.name.replace('temp_', '');
    //替换txt里面内容的值
    this.logContent = singleCache.content;
    this.logTxtName = singleCache.name;
    //切换点击选中效果
    this.activeCache = singleCache.name;
    //替换下载地址的值
    this.logTxtUrl = singleCache.url;
  }

  @action
  //根据key进行排序
  compareKey = (keys) => {
    return function (value1, value2) {
      var val1 = value1[keys];
      var val2 = value2[keys];
      return val2 - val1;
    }
  }

  //删除当前选中的缓存
  @action
  deleteCurCahe = (info, event) => {
    event.stopPropagation();
    confirm({
      title: '确认删除',
      icon: <Icon type="info-circle" />,
      content: '确认后将会删除',
      okText: "确认",
      cancelText: "取消",
      onOk: () => {
        delete this.txtCacheList[info.name];
      },
      onCancel: () => { },
    });

  }

  //删除所有的缓存
  @action
  deleteAllCache = (event) => {
    event.stopPropagation();
    if (Object.keys(this.txtCacheList).length) {
      confirm({
        title: '确认全部删除',
        icon: <Icon type="info-circle" />,
        content: '确认后将会全部删除',
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          this.txtCacheList = {};
        },
        onCancel: () => { },
      });
    } else {
      message.warn('没有可清除的历史记录', 2);
    }
  }

  //监测textArea里面得内容是否发生变化
  @action
  inspectorTextArea = () => {
    console.log('inspectorTextArea change=');
  }

  @action
  returnSelectTree = () => {
    let datas: any = [
      {
        key: 'gjrz',
        title: '固件日志',
        child: [
          { title: 'jm800_log.txt' },
          { title: 'jm800_log.txt.1' },
          { title: 'jm800_log.txt.2' },
          { title: 'jm800_log.txt.3' },
          { title: 'jm800_log.txt.4' },
          { title: 'jm800_log.txt.5' },
          { title: 'jm800_log.txt.6' },
        ],
      },
      {
        key: 'lxrz',
        title: '录像日志',
        child: [
          { title: 'record264-0.log' },
          { title: 'record264-1.log' },
          { title: 'record264-2.log' },
          { title: 'record264-3.log' },
          { title: 'record264-4.log' },
        ],
      },
      {
        key: 'txrz',
        title: '通讯日志',
        child: [
          { title: 'remote_log.txt' },
          { title: 'remote_log.txt.1' },
          { title: 'remote_log.txt.2' },
          { title: 'remote_log.txt.3' },
          { title: 'remote_log.txt.4' },
          { title: 'remote_log.txt.5' },
          { title: 'remote_log.txt.6' },
        ],
      },
      {
        key: 'xyrz',
        title: '协议日志',
        child: [
          { title: 'jm808_log.txt' },
          { title: 'jm808_log.txt.1' },
          { title: 'jm808_log.txt.2' },
          { title: 'jm808_log.txt.3' },
          { title: 'jm808_log.txt.4' },
          { title: 'jm808_log.txt.5' },
          { title: 'jm808_log.txt.6' },
          { title: 'jm808_log.txt.7' },
          { title: 'jm808_log.txt.8' },
        ],
      },
    ];
    console.log('change toJS(this.selectLogValue)=', toJS(this.selectLogValue));
    return (
      <TreeSelect
        defaultValue={'sss'}
        showSearch
        style={{ width: '250px', marginRight: '20px' }}
        value={toJS(this.selectLogValue)}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        placeholder="请选择"
        allowClear={false}
        treeDefaultExpandAll
        onChange={this.onChangeSelectlog}
      >
        {datas.map((x, index) => {
          return (
            <TreeNode key={x.title} disabled={x.child ? true : false} value={x.title} title={x.title}>
              {x.child && toJS(x.child).map((y, index) => {
                return (
                  <TreeNode key={y.title} disabled={y.child ? true : false} value={y.title} title={y.title} />
                )
              })}
            </TreeNode>
          )
        })}
      </TreeSelect>
    )

  }

  render() {
    const { uiStore, authStore, deviceStore } = this.props;
    const { showFileManager, changeShowFileManager, devConsoleDetailInfo, } = deviceStore!;

    const detailInfo = toJS(devConsoleDetailInfo) || {};
    let { deviceId = '', devType = 0, deviceChannels = [] } = devConsoleDetailInfo || {};
    const { device_type = {} } = authStore!.dict || {};
    const channelNos = deviceChannels.map(x => x.channelNo);

    console.log('gps.statusInfo.=', detailInfo.statusInfo, 'detailInfo=', detailInfo);
    let str: any = toJS(detailInfo.statusInfo);
    let formatStatusInfo: any = {};
    if (typeof str == 'object' && str) {
      formatStatusInfo = detailInfo!.statusInfo || {};
    } else {
      formatStatusInfo = detailInfo!.statusInfo ? JSON.parse(detailInfo!.statusInfo) : {};
    }
    let {
      mileage,
      duration,
      dbm, //网络信号
      chLos = [], // 视频信号丢失通道 最多8位
      chOcc = [], // 视频信号遮挡通道
      storageExc = [], // /主存储器故障位置 1-12
      storageBkExc = [], // /备存储器故障位置 1-4
    } = formatStatusInfo || {};
    let storageExcStr = getStoreStr(storageExc, storageBkExc);
    let channelStr = getChannelStr(chOcc, chLos, channelNos, true);

    let logOption = devType === 6 ? log600 : log800;
    const isH5 = h5DevTypes.includes(devType);

    return (
      <div className={'paramButton'}>
        {this.devMonitorShow &&
          <DevMonitor
            changeDevMonitor={this.changeDevMonitor.bind(this)} />}

        {this.testHistoryShow &&
          <TestHistory
            changeDevMonitor={this.changeTestHistoryShow.bind(this)} />}

        {deviceStore!.showRollback && <Rollback
          deviceId={deviceId}
          devType={devType}
        />}

        {this.modalShow && <Modal
          width={900}
          visible={true}
          title={intlGet('指令调试')}
          mask={false}
          maskClosable={false}
          onCancel={this.setShowModal.bind(this, false)}
          //onOk={this.dispatchContent}
          //confirmLoading={toJS(this.isDispatchLoading)}
          className={`zltsModal `}
          footer={null}
        >
          <div className="item dispatch_content">
            <Select
              style={{ width: 370, marginRight: 16 }}
              value={toJS(this.tczlIndex)}
              onChange={this.changeTanchuangIndex}
            >
              <Option key="6">{('语音下发')}</Option>
              <Option key="17">{('CAN脚本')}</Option>
              {/* <Option key="9">{('抓取文件')}</Option> */}
            </Select>
            <div className={'neirong'}>
              <div className={'lft'}>
                <TextArea
                  placeholder={tipMessage[this.tczlIndex]}
                  autoSize={{
                    minRows: 16,
                    maxRows: 16,
                  }}
                  style={{ resize: 'none' }}
                  onChange={this.changeInputInfo}
                  value={toJS(this.inputInfo)}
                />
              </div>
              <div className={'lbtn'}>
                <Button
                  loading={this.isDispatchLoading}
                  onClick={this.dispatchContent}
                  //icon="redo"
                  type='primary'>{('发送')}</Button>
              </div>
              <div className={'lft'}>
                <TextArea
                  placeholder={('回复内容')}
                  autoSize={{
                    minRows: 16,
                    maxRows: 16,
                  }}
                  style={{ resize: 'none', }}
                  value={toJS(this.replayInfo)}
                />
              </div>
            </div>
          </div>
        </Modal>}
        {this.logModalShow && <Modal
          width={1200}
          visible={true}
          title={intlGet('日志分析')}
          mask={false}
          maskClosable={false}
          onCancel={this.setLogModal.bind(this, false)}
          className={`zltsModal `}
          footer={null}
        >
          <div className={'logModal'}>
            <div className='leftB'>
              <div className='leftTop'>
                <span> 历史记录</span>
                <span className='delAll'
                  onClick={this.deleteAllCache.bind(this)}
                >
                  <MyIcon icon="iconshanchu" />
                  清空
                </span>
              </div>
              <div className={'leftBt'}>
                {Object.keys(this.txtCacheList).length ? Object.values(toJS(this.txtCacheList))
                  .sort(this.compareKey('timeStamp'))
                  .map((x: any, index) => {
                    return <div className='singleCache'
                      style={{ background: `${eq(x.name, this.activeCache) ? 'lightgrey' : ''}` }}
                      key={index + ''}
                      onClick={this.chakanCacheSingle.bind(this, x)}
                    >
                      <div className='title'>
                        <div className='left'>
                          {x!.name.replace('temp_', '')}
                        </div>
                        <div className='right'>
                          <span
                            onClick={this.deleteCurCahe.bind(this, x)}
                            className='rig'>
                            <MyIcon icon="iconshanchu" />
                          </span>
                        </div>
                      </div>
                      <div className='time'>
                        {x!.time}
                      </div>

                    </div>
                  }) :
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ width: '100%' }} />
                }
              </div>
            </div>

            <div className={'rightB'}>

              <div className={'top'}>
                <div className={'select'}>
                  <TreeSelect
                    defaultValue={'sss'}
                    showSearch
                    style={{ width: '250px', marginRight: '20px' }}
                    value={toJS(this.selectLogValue)}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    placeholder="请选择"
                    allowClear={false}
                    treeDefaultExpandAll
                    onChange={this.onChangeSelectlog}
                  >
                    {logOption.map((x, index) => {
                      return (
                        <TreeNode key={x.title} disabled={x.child ? true : false} value={x.title} title={x.title}>
                          {x.child && toJS(x.child).map((y, index) => {
                            return (
                              <TreeNode key={y.title} disabled={y.child ? true : false} value={y.title} title={y.title} />
                            )
                          })}
                        </TreeNode>
                      )
                    })}
                  </TreeSelect>

                  <Button
                    loading={this.logLoading}
                    onClick={this.scrapLogFile}
                    type='primary'>{`抓取${this.timeouFlag ? '(' + this.daojishi + 'S)' : ''}`}</Button>
                </div>
                <div className={'twoIcon'}>
                  <span className={'ic'} onClick={this.downloadLogFile}>
                    <MyIcon icon={'iconCombinedShape'} className={`p-color`} />
                    下载
                  </span>
                  <span
                    onClick={this.openQuanping}
                    className={'ic'}>
                    {false ? <span><MyIcon icon={'iconsuoxiao2'} />退出全屏</span> : <span> <MyIcon icon={'iconfangda'} />预览</span>}
                  </span>
                </div>
              </div>
              <div className={'content '}>
                <Spin
                  spinning={this.txtLoading}
                >
                  <TextArea
                    id={'myTextarea'}
                    // onChange={this.inspectorTextArea}
                    value={toJS(this.logContent)}
                    className={'logInfoTxt'}
                    placeholder={('日志内容')}
                    style={{ resize: 'none' }}
                  />
                </Spin>
              </div>
            </div>
          </div>
        </Modal>}
        <div className={'topFour'}>
          {!isH5 ? <div className='title'>实时信息</div> : null}
          
          <div className='btn-wrappers'>
            <Button
              onClick={() => this.burnModalShow = true}
              type='primary'>{('驾驶员IC卡烧录')}</Button>
            <Button
              loading={this.noInfoLoading[16]}
              onClick={this.confirmADB.bind(this)}
              //icon="redo"
              type='primary'>{('adb开关')}</Button>

            <Button
              loading={this.noInfoLoading[22]}
              // onClick={this.confirmResetTFC.bind(this)}
              onClick={() => this.formatModalShow = true}
              //icon="redo"
              type='primary'>{('格式化')}</Button>
            <Button
              loading={this.noInfoLoading[14]}
              onClick={this.confirmUnistall.bind(this)}
              //icon="redo"
              type='primary'>{('卸载')}</Button>

            <Button
              loading={this.noInfoLoading[3]}
              onClick={this.confirmUpdate.bind(this)}
              //icon="redo"
              type='primary'>{('升级')}</Button>

            <Button
              loading={this.noInfoLoading[2]}
              onClick={this.confirmRestart.bind(this)}
              //icon="redo"
              type='primary'>{('重启')}</Button>

            <Button
              loading={this.noInfoLoading[1]}
              onClick={this.confirmClose.bind(this)}
              //icon="redo"
              type='primary'>{('关机')}</Button>
          </div>
        </div>
        {!isH5 ? <div className={'content'}>
          <div className={'info'}>

            <div className={'txsop'}>
              <div>
                设备状态: <span className={`s${(detailInfo && detailInfo.vehState || '0').split('|')[0]}`}>
                  {(detailInfo && detailInfo.vehState) ? getVehStatus(detailInfo.vehState, true) : '--'}
                </span>
              </div>
              <div>
                设备类型: <span>{detailInfo ? transDict(device_type[detailInfo.devType || 0]) || '--' : '--'}</span>
              </div>
              <div>
                车牌号: <span>{this.plateNo}</span>
              </div>
            </div>

            <div className={'txsop'}>
              <div>
                终端ID: <span>{detailInfo ? detailInfo.deviceId : '--'}</span>
              </div>
              <div>
                版本名: <span>{detailInfo ? detailInfo.softwareVer || '--' : '--'}</span>
              </div>
              <div>
                版本号: <span>{detailInfo ? detailInfo.softwareCode || '--' : '--'}</span>
              </div>
            </div>

            <div className={'middle'} style={{ display: 'none' }}>
              <div>
                ACC状态: <span className={eq(detailInfo.acc, 1) ? 'zc' : 'yc'}></span>
              </div>

            </div>
            <div className={'bottozxm'}>
              <div>
                速度: <span>{detailInfo ? (detailInfo.gpsSpeed || 0) + 'km/h' : '--'}</span>
              </div>
              <div>
                里程: <span>{detailInfo ? (mileage || 0) / 1000 + '公里' : "--"}</span>
              </div>
              <div>
                时长: <span>{detailInfo ? converHMS((duration || 0)) : "--"}</span>
              </div>
            </div>
            <div className={'bottozxm'}>
              <span>状态: </span>
              <div style={{ marginRight: 15 }}>
                ACC:{eq(detailInfo.acc, 1) ? '点火' : '熄火'}
              </div>

              <div>
                网络信号: <span>{convertSignal(dbm || 0)}</span>
              </div>
              <div>
                卫星数: <span>{detailInfo ? (detailInfo.gpsSatellite || 0) : "--"}</span>
              </div>

              <div>
                定位: <span>{detailInfo ? getGps(formatStatusInfo.gps || [1, 0, 0, 0]) : "--"}</span>
              </div>
            </div>

            <div className={'bottozxm'}>
              <div>
                视频通道: <span>{channelStr}</span>
              </div>
            </div>
            <div className={'bottozxm'}>
              <div>
                存储: <span>{storageExcStr}</span>
              </div>
            </div>
          </div>
        </div> : null}


        <div className={'bbbTTT'}>
          <div className={'bottombt'}>
            <div className={'leftBlock'}>
              <ReactEcharts option={this.getOption()} style={{ width: '130px', height: '130px' }} />
              <div className={'descirption'}>
                <div className={'tfstate'}>
                  TF卡状态 <span className={eq((detailInfo.statusInfo || {}).sd || 0, 1) ? 'zc' : 'yc'}></span>
                </div>
                <div className={'title'}>
                  <span className={'dot'} style={{ background: colorArr[0] }} />  <span>总存储量：</span>0G
                </div>
                <div className={'title'}>
                  <span className={'dot'} style={{ background: colorArr[1] }} /> <span>已使用量：</span>0G
                </div>
                <div className={'tfCard'}>
                  <Button
                    loading={this.noInfoLoading[22]}
                    onClick={this.confirmResetTFC.bind(this)}
                    //icon="redo"
                    type='primary'>{('格式化TF卡')}</Button>
                </div>

              </div>
              <div className={'rightBlock'}>
                <div className={'single'} onClick={this.openLogModal}
                //icon="redo"
                >
                  <MyIcon icon={'iconbiaodan'} />
                  <span>日志分析</span>
                </div>
                <div className={'single'} onClick={this.zlTiaoshi}>
                  <MyIcon icon={'iconpingtaichufagaojing'} />
                  <span>指令调试</span>
                </div>
                <div className={'single'}
                  onClick={this.changeDevMonitor}
                >
                  <MyIcon icon={'iconreport'} />
                  <span>版本管理</span>
                </div>
                <div className={'single'}
                  onClick={this.changeTestHistoryShow}
                >
                  <MyIcon icon={'iconyichangxiangqing'} />
                  <span>产测历史</span>
                </div>
                <div className={'single'}
                  onClick={changeShowFileManager.bind(deviceStore, true)}
                >
                  <MyIcon icon={'iconyichangxiangqing'} />
                  <span>文件管理</span>
                </div>
              </div>
            </div>

          </div>
          <FileManagerModal />
          {this.burnModalShow && <BurnModal visible={this.burnModalShow} onCancel={() => this.burnModalShow = false} />}
          {this.formatModalShow && <FormatModal visible={this.formatModalShow} onCancel={() => this.formatModalShow = false} />}
        </div>
      </div>

    )
  }
}

interface FileManagerProps {
  deviceStore?: DeviceStore,
  commonStore?: CommonStore,
}



console.log('FileManager')

@inject('deviceStore', 'commonStore')
@observer
class FileManagerModal extends React.Component<FileManagerProps>{

  render() {
    const { commonStore, deviceStore } = this.props;
    return <Modal
      visible={deviceStore!.showFileManager}
      title="文件管理"
      onCancel={deviceStore!.changeShowFileManager.bind(deviceStore, false)}
      width={800}
      className="devConsoleModal"
    >
      <FileManager />

    </Modal>
  }
}