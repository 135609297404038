import React from "react";
import { AuthStore, CommonStore } from '../../../src/stores';
import { withRouter, RouteComponentProps, Switch, Route, Redirect, } from 'react-router-dom';
import { observer, inject } from "mobx-react";
import { Menu, Icon, Popover, Button } from 'antd';
import { getRoterByKey, myRouter, getKeyByPath, authMenus } from 'src/router';
import MyIcon from '../../../src/components/myIcon';

import './index.scss';
import { action, toJS, observable, computed } from "mobx";
import _ from "lodash";

const { SubMenu } = Menu;

interface Props {
  authStore?: AuthStore,
  commonStore?: CommonStore;
}

@inject('authStore', 'commonStore')
@observer
class Layout extends React.Component<Props & Partial<RouteComponentProps>>{

  constructor(props) {
    super(props);
  }

  @observable dlVisible: boolean = false;
  
  componentWillUnmount() {

  }

  componentDidMount() {
  }


  @action
  clickExit = () => {
    const { authStore, history } = this.props;
    this.changeDlVisible(false);
    history!.push('/login');
    authStore!.setIsLogin(false);
    console.log(authStore!.isLogin);

  }

  @action
  handleVisibleChange = (visible) => {
    console.log('visible=', visible);
    this.dlVisible = visible;
  }

  @action
  changeDlVisible = (bools: boolean) => {
    this.dlVisible = bools;
  }

  render() {

    let user: any = JSON.parse(sessionStorage.getItem('userInfo') || '{}');
    console.log('Layout render', this.dlVisible);

    return (
      <div className='layoutPage'>

        <div className='header'>
          <div className='left'>
            终端运维管理
          </div>
          <div
            className='right'>
            <Popover
              className={'dlPopConfirm'}
              onVisibleChange={this.handleVisibleChange}
              visible={this.dlVisible}
              placement="bottomRight"
              content={
                <div className='twoBt'>
                  <Button onClick={this.clickExit} >
                    退出登录
                  </Button>
                </div>
              }
              trigger="click">
              <Icon type="user" style={{ fontSize: '20px' }} />
              {user!.username || '--'}
            </Popover>
          </div>
        </div>
        <div className='bottom'>
          <div className='leftMenu'>
            <LeftMenus />
          </div>
          <div className="main">
            <Main />
          </div>
        </div>

      </div>
    )
  }
}

export default Layout;

const Main = observer(() => {
  return <Switch>
    {myRouter.map(x => {
      const { key, component: Com } = x;
      const path = '/private/' + key;
      // return <Route path={path}></Route>
      return <PrivateRoute key={path} path={path}>
        <Com />
      </PrivateRoute>
    })
    }
  </Switch>
})

interface PrivateRouteProps {
  authStore?: AuthStore,
  path: string,
}

@inject('authStore')
@observer
class PrivateRoute extends React.Component<PrivateRouteProps>{
  render() {
    const { authStore, children, ...rest } = this.props;
    const path: string = this.props.path as string;
    const hasAuth = authStore!.getAuth(path);
    console.log('PrivateRoute', `path ${path}, hasAuth ${hasAuth}`)
    return (
      <Route
        {...rest}
        render={({ location }) =>
          hasAuth
            ? children
            : <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
        }
      />
    );
  }
}

interface LeftMenusProps extends Partial<RouteComponentProps> {
  authStore?: AuthStore,
}

@inject('authStore')
@(withRouter as any)
@observer
class LeftMenus extends React.Component<LeftMenusProps>{

  @computed
  get leftMenu() {
    let curUser = JSON.parse(sessionStorage.getItem('userInfo') || '{}');
    return authMenus.filter(m => {
      const {key, users} = m;
      if(users && users.length){
        const hasPer = users.includes(curUser.username);
        return hasPer;
      }else{
        return true;
      }
    })
  }

  componentDidMount() {

  }

  clickMenu = (item) => {
    console.log('item=', toJS(item));
    const isRoute = getRoterByKey(item!.key.replace('/private/', ''));
    if (isRoute) {
      this.props.history!.push('/private/' + item!.key);
    }
  }


  render() {
    const { history } = this.props;
    const activeKey = getKeyByPath(history!.location.pathname);

    return <Menu
      theme="light"
      onClick={this.clickMenu}
      style={{ width: '100%' }}
      selectedKeys={[activeKey]}
      defaultOpenKeys={['version']}
      mode="inline"
    >
      {_.map(this.leftMenu, (x, index) => {
        if (x.child) {
          return (
            <SubMenu
              key={x.key}
              title={<span>
                <MyIcon icon='iconbanbenguanli' />
                {x.name}
              </span>
              }
            >
              {
                (x.child).map(x => {
                  return (
                    <Menu.Item key={x.key}>{x.name}</Menu.Item>
                  )
                })
              }
            </SubMenu>
          )
        } else {
          return (
            <Menu.Item key={x.key}>
              <MyIcon icon='iconzhongduanguanli' />
              {x.name}</Menu.Item>
          )
        }
      })}
    </Menu>
  }
}