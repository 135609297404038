import React from "react";
import loadable from "@loadable/component";
import { Provider, observer } from "mobx-react";
import { MultipleStore } from "../src/stores";
import permData from "./config/permission.json"

export const loadWidthStore = (Com) => {
  // const Com = loadable(func);
  return observer(
    class PageWidthStore extends React.Component {
      store: MultipleStore;
      constructor(props) {
        super(props);
        this.store = new MultipleStore();
      }

      render() {
        return (
          <Provider {...this.store}>
            <Com></Com>
          </Provider>
        );
      }
    }
  );
};

export const asyncLoadWidthStore = (func) => {
  const Com = loadable(func);
  return observer(
    class PageWidthStore extends React.Component {
      store: MultipleStore;
      constructor(props) {
        super(props);
        this.store = new MultipleStore();
      }

      render() {
        return (
          <Provider {...this.store}>
            <Com></Com>
          </Provider>
        );
      }
    }
  );
};

const DevicePage = asyncLoadWidthStore(() => import("../src/pages/device"));
const VersionPage = asyncLoadWidthStore(() => import("../src/pages/version"));
const VersionHisPage = asyncLoadWidthStore(
  () => import("../src/pages/version/versionHis")
);
const Homepage = asyncLoadWidthStore(() => import("../src/pages/home"));
const ProductTestPage = asyncLoadWidthStore(
  () => import("src/pages/productTest")
);
const ProductTestDetailPage = asyncLoadWidthStore(
  () => import("src/pages/productTest/detail")
);
const WarnDetailPage =   asyncLoadWidthStore(() => import("src/pages/warnDetail"));
const TestPage = asyncLoadWidthStore(() => import("src/pages/test"));

const authMenus: any[] = [
  {
    key: "device",
    name: "终端管理",
  },
  {
    key: "productTest",
    name: "产测历史",
  },
  {
    key: "version",
    name: "版本管理",
    users: permData.versionManagerUser,
    child: [
      {
        key: "product",
        name: "基线产品",
      },
      {
        key: "project",
        name: "客户定制",
      },
    ],
  },
  // {
  //   key: "warnDetail",
  //   name: "报警明细",
  //   users: permData.alarmUser,
  // },
];

const myRouter = [
  { key: "home", icon: "", component: Homepage },
  { key: "device", icon: "", component: DevicePage },
  // { key: 'version', icon: '', component: VersionPage },
  { key: "product", icon: "", component: VersionPage },
  { key: "project", icon: "", component: VersionPage },
  { key: "versionHis", icon: "", component: VersionHisPage },
  { key: "productTest", icon: "", component: ProductTestPage },
  { key: "productTestDetail", icon: "", component: ProductTestDetailPage },
  { key: "warnDetail", icon: "", component: WarnDetailPage },
  { key: "test", icon: "", component: TestPage },
];

const myRouterObj: { [key: string]: any } = {};
myRouter.forEach((m) => {
  myRouterObj[m.key] = m;
});

const protectedPath = "/private/";

const getRoterByKey = (key: string) => {
  return myRouterObj[key.replace(protectedPath, "")];
};
const getPathByKey = (key: string) => {
  return protectedPath + key;
};

const getKeyByPath = (path: string) => {
  return path.replace(protectedPath, "");
};

export { myRouter, authMenus, getRoterByKey, getPathByKey, getKeyByPath };
