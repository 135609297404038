import { observable, action, computed, runInAction, toJS, comparer } from 'mobx';
import { eq, myLocalStorage, copy, compareArr } from '../../src/utils/mix';
import { http } from '../../src/utils/http';
import { DeviceTree, DeviceType, Online, Device } from '../../src/models';
import { FetchStatus, VehQueryResponse } from '../../src/types/index';

const concernCarStr = 'concernCarsObj_';

const videoCountList = [4,  9];

interface Search {
  type: DeviceType,
  searchStr: string,
  online: Online,
}

interface RightClickVeh {
  veh: DeviceTree,
  position: [number, number],
}

export class DeviceTreeStore {

  allVechileObj = {};  // key id
  @observable carListCheckedKeys: string[] = [];     // 设备树选择的keys
  @observable concernListCheckedKeys: string[] = []; // 关注列表选择的keys;


  @observable.shallow devicesTreeMap = observable.map<string, DeviceTree>(); // 设备树数据
  @observable.shallow concernCarsMap = observable.map<string, DeviceTree>(); // 关注车辆数据
  @observable monitorCarMap = observable.map<string, DeviceTree>();     // 监控车辆的keys;

  @observable.ref firstDeviceRoot: DeviceTree | undefined;
  @observable fetchStatus: FetchStatus = 'none';

  @observable.shallow selectedDevice: DeviceTree[] = []; // 鼠标点击选中的
  @observable.ref videoUrls: { [index: number]: string } = {}; // deviceId_channelNo_devType_Date.now()
  @observable videoCount = 4;

  @observable fullScreenIndex = -1;   // 全屏的视频序号 -1 表示没有全屏

  @observable concernCarsDetail: VehQueryResponse[] = [];

  @observable search: Search = {
    type: 'org',
    searchStr: '',
    online: -1,
  };

  @observable dispatchVoiceCar: DeviceTree | undefined;

  @observable rightClickVeh: RightClickVeh | undefined;

  userId = '';

  @computed
  get deviceTreeArr() {
    return Array.from(this.devicesTreeMap.values());
  }

  /**
   * 车辆列表 车辆
   */
  @computed
  get vechileArr() {
    return Array.from(this.devicesTreeMap.values()).filter(x => this.isCar(x));
  }

  @computed
  get monitorCarArr() {
    return Array.from(this.monitorCarMap.values());
  }

  @computed
  get monitorCarKeys() {
    return this.monitorCarArr.map(x => x.id).sort();
  }

  @action
  changeRightClickVeh = (veh: RightClickVeh | undefined) => {
    this.rightClickVeh = veh;
  }

  @action
  changeMonitorCars = (operate: 'add' | 'del', cars: DeviceTree[]) => {
    switch (operate) {
      case 'add':
        cars.forEach(x => {
          // 如果不存在，则新增
          const { id } = x;
          if (!this.monitorCarMap.get(id) && this.isCar(x)) {
            this.monitorCarMap.set(id, toJS(x));
            this.allVechileObj[id] = toJS(x);
          }
        });
        break;
      case 'del':
        cars.forEach(x => {
          const { id } = x;

          if (this.monitorCarMap.get(id) && this.isCar(x)) {
            this.monitorCarMap.delete(id);
            // 如果在设备树里面与关注列表里面也不存在，则删除掉数据备份
            if (!this.devicesTreeMap.get(id) && !this.concernCarsMap.get(id)) {
              delete this.allVechileObj[id];
            }
          }
        })
        break;
    }
  }

  @computed
  get concernCarsArr() {
    return Array.from(this.concernCarsMap.values());
  }

  @computed
  get concernCarsArrLen() {
    return this.concernCarsArr.length || 0;
  }

  @computed
  get checkedOrgKeys() {
    return this.carListCheckedKeys.map(x => this.devicesTreeMap.get(x)!).filter(x => this.isOrg(x)).map(x => x.id);
  }

  @computed
  get isMonitorAll() {
    return !!this.concernCarsArrLen && eq(this.concernCarsArrLen, this.concernListCheckedKeys.length);
  }

  getDeviceByKey = (id: string) => {
    return this.allVechileObj[id];
  }

  @computed({ equals: comparer.structural })
  get allVechileKeys() {
    let allVechileKeys: string[] = [...toJS(this.concernCarsArr.map(x => x.id)),
    ...toJS(this.vechileArr.map(x => x.id)),
    ...toJS(this.monitorCarArr.map(x => x.id))
    ];
    allVechileKeys = Array.from(new Set(allVechileKeys));
    return allVechileKeys.sort();
  }

  /**
   * 所有需要地图上展示的车辆
   * 包括车辆列表里面的车与关注的车辆,与监控的车辆
   */
  @computed
  get allVechile() {
    console.log('allVechileKeys', this.allVechileKeys);

    return this.allVechileKeys.map(id => copy(this.allVechileObj[id]));
  }

  getNextVideoWinCount = (count: number) => {
    if (count > this.videoCount) {
      // let i = 0;
      // while (i < vide (videoCountList[i] < count)) {
      //   i++;
      // }
      let newCount = 0;
     for(let j=0,jLen = videoCountList.length; j<jLen;j++){
       if(videoCountList[j] > count){
         newCount = videoCountList[j];
         break;
       }
       newCount = videoCountList[videoCountList.length -1]
     }
      return newCount;
    }else{
      return this.videoCount;
    }
  }

  @computed
  get videoUrlIndexs() {
    return Object.keys(toJS(this.videoUrls));
  }

  @action
  changeVideoCount = (count: number) => {
    this.videoCount = count;
    // todo
  }


  @action
  changeVideoUrls = (urls: { [index: number]: string }) => {
    this.videoUrls = urls;

    // 当前视频窗口不够预览全部时，增加窗口
    let count = Object.keys(urls).length;
    if (count > this.videoCount) {
      let i = 0;
      while (videoCountList[i] < count) {
        i++;
      }
      this.changeVideoCount(videoCountList[i]);
    }
  }

  @action
  addVideoUrls = (urls: string[]) => {
    // 如果视频窗口不够新增视频窗口
    const newVideoCount = this.getNextVideoWinCount(urls.length + this.videoUrlIndexs.length);
    this.changeVideoCount(newVideoCount);

    const countArr = new Array(this.videoCount).fill(1).map((x, i) => i);
    const filledKeys = this.videoUrlIndexs;
    const emptyKeys = countArr.filter(x => !filledKeys.includes(x.toString()));
    const emptyKeysLen = emptyKeys.length;

    for (let j = 0, len = urls.length; j < len; j++) {
      // 如果没有多余的窗口就从0开始
      const effectiveIndex = j < emptyKeysLen ? emptyKeys[j] : (j - emptyKeysLen) % this.videoCount;
      const src = urls[j];
      this.videoUrls[effectiveIndex] = src;
    }
    this.changeVideoUrls(copy(this.videoUrls));
  }


  @action
  changeFullScreenIndex = (index: number) => {
    this.fullScreenIndex = index;
  }

  @action
  changeDispatchVoiceCar = (car: DeviceTree | undefined) => {
    this.dispatchVoiceCar = car;
  }

  @action
  setConcernCarsFromLocal(userId: string) {
    this.userId = userId;
    const concernCarsArr = Array.from(myLocalStorage.getItem<DeviceTree[]>(concernCarStr + userId, 'obj')) || [];
    concernCarsArr.forEach(x => {
      this.concernCarsMap.set(x.key, x);
      this.allVechileObj[x.key] = x;
    });
  }

  @action
  changeSearch = (searchParams: Partial<Search>) => {
    this.search = { ...this.search, ...searchParams }
  }

  @action
  addConcernCar = (dev: DeviceTree) => {
    const { id, pid } = dev;
    // const org = this.getDeviceByKey(pid)!;
    // dev.orgName = org.name;
    this.concernCarsMap.set(id, dev);
    this.saveConcernCarsToLocal();
  }

  @action
  deleteConcernCars = (cars: DeviceTree[]) => {
    cars.forEach(car => {
      this.concernCarsMap.delete(car.key);
    })
    this.saveConcernCarsToLocal();
  }

  @action
  deleteMointerCars = (cars: DeviceTree[]) => {
    cars.forEach(c => {
      const { id } = c;

      const carListIndex = this.carListCheckedKeys.indexOf(id);
      const concernListIndex = this.concernListCheckedKeys.indexOf(id);

      if (carListIndex > -1) {
        this.carListCheckedKeys.splice(carListIndex, 1);
        this.carListCheckedKeys = toJS(this.carListCheckedKeys);
      }
      if (concernListIndex > -1) {
        this.concernListCheckedKeys.splice(concernListIndex, 1);
        this.concernListCheckedKeys = toJS(this.concernListCheckedKeys);
      }
      if (this.monitorCarMap.get(id)) {
        this.monitorCarMap.delete(id)
      }

      // 如果在设备树里面与关注列表里面也不存在，则删除掉数据备份
      if (!this.devicesTreeMap.get(id) && !this.concernCarsMap.get(id)) {
        delete this.allVechileObj[id];
      }
    })
  }

  @action
  syncDeviceInfo(dev: DeviceTree) {
    const { id } = dev;

    if (this.concernCarsMap.get(id)) {
      // this.concernCarsMap.set(id, dev);
      this.saveConcernCarsToLocal();
    }
    const vehicleObj = this.allVechileObj[id];
    if (vehicleObj) {
      Object.assign(vehicleObj, dev);
    }

    const monitorCar = this.monitorCarMap.get(id);
    if (monitorCar) {
      this.monitorCarMap.set(id, dev);
    }
  }


  saveConcernCarsToLocal() {
    const concernCarsArr = this.concernCarsArr;
    myLocalStorage.setItem(concernCarStr + this.userId, concernCarsArr, 'obj');
  }

  /**
   * @desc 设置车辆列表选择车辆
   * @param keys
   */
  @action
  setCarListCheckedKeys(keys: string[], checked: boolean) {
    console.log('setCarListCheckedKeys');
    const compareRes = compareArr(toJS(this.carListCheckedKeys), keys);
    this.carListCheckedKeys = keys;

    let newCars: DeviceTree[] = [];
    const action = checked ? 'add' : 'del';

    newCars = compareRes[action].map(x => {
      return this.devicesTreeMap.get(x)!;
    });
    this.changeMonitorCars(action, newCars);
  }

  /**
   * @desc 设置关注列表选择车辆
   * @param keys 全部的监控的keys
   */
  @action
  setConcernCarBykeys(keys: string[]) {

    const compareRes = compareArr(toJS(this.concernListCheckedKeys), keys);
    this.concernListCheckedKeys = keys;

    let newCars: DeviceTree[] = [];
    const action = compareRes.add.length > 0 ? 'add' : 'del';

    newCars = compareRes[action].map(x => {
      return this.concernCarsMap.get(x)!;
    });
    this.changeMonitorCars(action, newCars);
  }

  /**
   * 点击车辆
   * @param keys
   */
  @action
  setSelectedDeviceByKey(keys: string[]) {
    console.log('setSelectedCarByKey', keys)
    this.selectedDevice = keys.map(k => (this.devicesTreeMap.get(k) || this.concernCarsMap.get(k))!);
  }

  @action
  changeSelectNode = (obj) => {
    this.selectedDevice = obj;
  }

  isCar(device: DeviceTree) {
    return eq<DeviceType>(device.type, 'vehicle')
  }

  isOrg(device: DeviceTree) {
    return eq<DeviceType>(device.type, 'org')
  }

  getParent = (device: DeviceTree) => {
    const { pid } = device;
    return this.devicesTreeMap.get(pid);
  }

  /**
   * @desc 根据设备数组组装设备树
   */
  mountDevice = (devices: DeviceTree[]) => {
    // 一份新的键值数据
    const deviceObj: { [id: string]: DeviceTree } = {};
    devices.forEach(x => {
      const { type } = x;
      const selectable = eq<DeviceType>(type, 'vehicle');
      // deviceObj[x.id] = { ...x, selectable }
      deviceObj[x.id] = { ...x }
    });

    const deviceTree: DeviceTree[] = [];
    const mountDevice = (m: DeviceTree) => {
      const { pid, id } = m;
      const device = deviceObj[id];
      const pDevice = deviceObj[pid];

      // 一级菜单
      if (!pDevice) {
        deviceTree.push(device);
        if (!this.firstDeviceRoot) {
          this.firstDeviceRoot = device;
        }
      } else {
        pDevice.children = pDevice.children || [];
        pDevice.children.push(device);
      }
    }
    devices.forEach(m => mountDevice(m));
    console.log('menuTree', deviceTree);
    return deviceTree;
  }

  @computed
  get allDeviceTree() {
    console.time('allTree');
    const tree = this.mountDevice(this.deviceTreeArr);
    console.timeEnd('allTree');
    return tree;
  }

  @computed
  get allOrgExpandKeyArr() {
    let allIds = this.deviceTreeArr.map(x => x.id);
    const allPids = this.deviceTreeArr.map(x => x.pid);
    allIds = allIds.filter(x => {
      if (allPids.indexOf(x) > -1) {
        return true
      }
    })
    return allIds;
  }

  @computed
  get vehicleTree() {
    const types: DeviceType[] = ['org', 'vehicle'];
    const cars = this.deviceTreeArr.filter(x => {
      return types.includes(x.type)
    });
    return this.mountDevice(cars);
  }

  searchNode = (searchStr: string) => {
    return this.deviceTreeArr.filter(x => x.name.includes(searchStr));
  }

  /**
   * @desc 获取设备子节点
   * @params 设备id
   */
  @action
  getOrgChild = (pid = '', online: Online = this.search.online) => {
    const url = '/comm/tree/veh';
    const params = {
      pid,
      isSearch: false,
      online,
    }
    let result: DeviceTree[] = [];
    return http.post(url, params)
      .then(
        res => {
          console.log('getChild', res);
          runInAction(() => {
            result = res.data.map((x: DeviceTree) => {
              x.key = x.id;
              x.title = x.name;
              x.value = x.id;
              x.isLeaf = true;

              this.devicesTreeMap.set(x.id, x);
              this.allVechileObj[x.id] = x;
              return x;
            })
          });
          return result;
        },
        err => {
          console.log('err', err);
          return result;
        }
      )
  }

  /**
   * @desc 获取车辆树数据
   * @param pid 父级节点为“”时,默认只获取机构信息
   * @parmam isSearch 是否搜索模式
   * @param online 父级节点为“”时, 在线状态
   * @param onlyOrg 只有组织
   */
  @action
  getVehTreeFromServer = (pid = '', isSearch = false, online: Online = this.search.online,
    searchStr = this.search.searchStr, searchType = this.search.type,
    onlyOrg = false
  ) => {

    const url = '/comm/tree/veh';
    const params = {
      pid,
      isSearch,
      search: searchStr,
      searchType: searchType,
      online,
      onlyOrg
    };
    this.fetchStatus = 'pending';
    return http.post(url, params).then(
      res => {
        console.log('comm/tree res', res);
        runInAction(() => {
          const devices: DeviceTree[] = res.data;
          // 每次请求清空map
          this.devicesTreeMap.clear();
          devices.map(x => {
            const { id, type, total, onlineNum } = x;
            x.key = x.id;
            if (eq(type, 'org')) {
              x.title = x.name + `(${onlineNum}/${total})`;
              x.isLeaf = total ? false : true;
            } else {
              x.title = x.name;
              x.isLeaf = true;
            }
            x.value = x.id;
            this.devicesTreeMap.set(id, x);
            this.allVechileObj[id] = x;
          });
          this.fetchStatus = 'success';
          this.carListCheckedKeys = [];
        })
      },
      err => {
        runInAction(() => {
          this.fetchStatus = 'fail';
        })
        console.log('err', err);
      }
    )
  }

  @action
  queryVehByIds = (vehicleIds: string[]) => {
    const url = 'comm/vehquery';
    const params = {
      vehicleIds
    }
    return http.post(url, params).then(res => {
      console.log('vehquery', res);
      this.concernCarsDetail = res.data;
    }, err => {

    })
  }

}
