import moment, { Moment, DurationInputArg2 } from 'moment';

export const timeFormatStrs = {
  1: 'YYYY-MM-DD HH:mm:ss',
  2: 'YYYY-MM-DD',
  3: 'HH:mm:ss',
  4: 'MM-DD',
  5: 'YYYY-MM',
  6: 'YYYY-MM-DD HH:mm',
  7: 'YYYY/MM/DD HH:mm:ss',
  8: 'YYMMDDHHmmss',
}
export const timeFormatDefaultStr = timeFormatStrs[1];
export const formatTime = (time: number | Moment, type: number,defaultStr = '') => {
  let newTime: Moment;
  if (typeof time === 'number') {
    newTime = moment(time);
  }else if(typeof time === 'string'){
    return time;
  }else if(time instanceof moment) {
    newTime = time;
  }else{
    return defaultStr;
  }
  return newTime.format(timeFormatStrs[type]);
}

export const operateTime = (time: number | string | Moment, operate: 'add' | 'subtract',
  amount: number, unit: DurationInputArg2, format = 1) => {
  if (!time) { return '' }
  let momentTime: Moment;
  if (typeof time === 'object') {
    momentTime = time;
  } else {
    momentTime = moment(time);
  }
  const newTime = momentTime[operate](amount, unit);
  return newTime.format(timeFormatStrs[format]);
}