import React from "react";
import { inject, observer } from "mobx-react";
import { Modal, Radio, message, Icon } from "antd";
import Form, { FormComponentProps } from "antd/lib/form";
import { CommonStore, DeviceStore } from "../../../stores";

interface IProps extends FormComponentProps {
  commonStore?: CommonStore;
  deviceStore?: DeviceStore;
  visible?: boolean;
  onCancel?: any;
}

@inject("commonStore", "deviceStore")
@observer
class FormatModal extends React.Component<IProps> {
  state = {
    loading: false,
  };

  handleOk = (e) => {
    const {
      commonStore,
      deviceStore,
      form: { validateFields },
    } = this.props;
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        Modal.confirm({
          title: "确认格式化吗",
          icon: <Icon type="info-circle" />,
          content: "确认后将会执行此操作",
          okText: "确认",
          cancelText: "取消",
          onOk: () => {
            let d = deviceStore!.devConsoleDetailInfo;
            const { deviceId, devType } = d;
            this.setState({ loading: true });
            commonStore!
              .noInfoZhiling(deviceId, 1007, devType, {
                formatType: Number(values.formatType),
              })
              .then(
                (res: any) => {
                  this.setState({ loading: false });
                  if (res.code === -1) {
                    message.error("格式化失败");
                  } else if (res.code === -2) {
                    message.error("无存储");
                  } else if (res.code === -1001) {
                    message.error("远程文件下载失败");
                  } else if (res.code === -1002) {
                    message.error("远程文件校验失败");
                  } else {
                    message.success("操作成功");
                    this.props.onCancel();
                  }
                },
                (err) => {
                  message.error("操作失败");
                }
              );
          },
          onCancel: () => {},
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        title="格式化"
        destroyOnClose
        confirmLoading={this.state.loading}
        onOk={this.handleOk}
        {...this.props}
      >
        <Form>
          <Form.Item label="存储类型">
            {getFieldDecorator("formatType", {
              rules: [{ required: true, message: "请选择存储类型!" }],
            })(
              <Radio.Group>
                <Radio value="0">TF卡</Radio>
                <Radio value="1">SD卡1</Radio>
                <Radio value="2">SD卡2</Radio>
                <Radio value="3">硬盘</Radio>
              </Radio.Group>
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default Form.create<IProps>()(FormatModal);
