import React, { Component } from 'react';
import './index.scss';
import { Modal, Select, Table, Pagination, Tag } from 'antd';
import { action, observable, toJS } from 'mobx';
import { DeviceStore, VersionStore } from '../../../../../src/stores';
import { inject, observer } from 'mobx-react';
import Filter from './filter';
import { mountainToLine, copy, eq, getSearchParams, formatTime } from 'src/utils/mix';

const Option = Select.Option;
interface devMonitorProps {
  changeDevMonitor: () => void;
  deviceStore?: DeviceStore;
  versionStore?: VersionStore;
}

@inject('deviceStore', 'versionStore')
@observer
export default class DevMonitor extends React.Component<devMonitorProps>{

  devId = '';

  constructor(props) {
    super(props);
    const {arr} = getSearchParams();
    this.devId = arr.split('_')[0]

    this.firstQuery();
  } 

  componentDidMount() {
    // this.queryTable();
  
  }

  @observable tableLoading: boolean = false;
  @observable total: number = 0;
  @observable tableData: any[] = [];
  @observable filterPara: any = {};
  @observable page: number = 0;
  @observable pageSize: number = 20;
  @observable orderItem: string = '';
  @observable orderDirection: string = '';

  @observable formRef: any = undefined;

  colums: any[] = [
    {
      align: 'center',
      title: '升级结果',
      width: 100,
      fixed: 'left',
      dataIndex: "upgradeOk",
      render(text,record){
        return eq(text,1) ? <Tag color={'#1890ff'}>成功</Tag>: <Tag color={'red'}>失败</Tag>
      }
    },
    {
      align: 'center',
      title: '升级时间',
      sorter: (a, b) => a - b,
      sortDirections: ['descend', 'ascend'],
      width: 200,
      fixed: 'left',
      dataIndex: "upgradeTime",
      render: (text, record) => {
        return formatTime(text, 1)
      }
    },
    {
      align: 'center',
      title: '升级版本名',
      width: 200,
      dataIndex: "upgradeVerName"
    },
    {
      align: 'center',
      title: '升级版本号',
      sorter: (a, b) => a - b,
      sortDirections: ['descend', 'ascend'],
      width: 150,
      dataIndex: "upgradeVerNo"
    },
    {
      align: 'center',
      title: '原版本名',
      width: 200,
      dataIndex: "sourceVerName"
    },
    {
      align: 'center',
      title: '原版本号',
      sorter: (a, b) => a - b,
      sortDirections: ['descend', 'ascend'],
      width: 150,
      dataIndex: "sourceVerNo"
    },
    {
      align: 'center',
      title: '升级包名',
      width: 200,
      dataIndex: "upgradePackageName"
    },
    {
      align: 'center',
      title: '错误码',
      width: 150,
      dataIndex: "errorCode"
    },
    {
      align: 'center',
      title: '错误信息',
      width: 250,
      fixed: 'right',
      dataIndex: "errorMsg",
      render(text,record){
      return <div
      title={text}
      style={{whiteSpace:"nowrap",textOverflow:'ellipsis',maxWidth:230,overflow:'hidden'}}
      >{text}</div>
      }
    },
  ];


  //改变过滤条件
  @action
  changeFilterPara = (para) => {
    this.filterPara = para;
  }

  @action
  firstQuery = () => {
    let para: any = {

      page: this.page,
      pageSize: this.pageSize,
      deviceId: this.devId,
      orderItem: this.orderItem,
      orderDirection: this.orderDirection,
    };
    this.getTable(para);
  }

  @action
  queryTable = () => {
    const {versionStore } = this.props;
    let temp = toJS(this.filterPara);
    this.formRef && this.formRef.handleSearchSubmit().then(res => {
      temp = res;
      console.log('temp=', temp);

      const upgradeOk = temp.updateResult;
      const startTime = temp.startTime ? temp.startTime : undefined;
      const endTime = temp.endTime ? temp.endTime : undefined;
      const key = temp.fuzzy ? temp.fuzzy.trim() : undefined;
      let para: any = {
        startTime: startTime,
        endTime: endTime,
        key: key,
        upgradeOk: upgradeOk,

        page: this.page,
        pageSize: this.pageSize,
        deviceId: this.devId,
        orderColumn: this.orderItem,
        orderDir: this.orderDirection,
      };
      this.getTable(para);
    }, err => {

    });
  }

  @action
  getTable = (para) => {
    const { versionStore } = this.props
    this.tableLoading = true;
    versionStore!.queryVersionHistoryTable(para).then(rs => {
      const res: any = rs;
      this.tableData = res.data.items;
      this.total = res.data.total;
      this.tableLoading = false;
    }, err => {
      this.tableLoading = false;
    });
  }

  @action
  closeModal = () => {
    const { changeDevMonitor } = this.props;
    changeDevMonitor();
  }

  @action
  changePageNum = (num: number) => {
    this!.page = num - 1;
    this.queryTable();
    console.log('changeNum', num);
  }

  @action
  changPageSize = (pageSize: number) => {

    this!.pageSize = pageSize;
    this!.page = 0;
    this.queryTable();
  }

  @action
  tableChange = (pagination, filters, sorter) => {
    const { deviceStore } = this.props;
    console.log('tableChange', pagination, filters, sorter);
    if (sorter && sorter.order) {
      this.orderItem = mountainToLine(sorter.field);
      if (sorter.order == 'descend') {
        this.orderDirection = 'desc';
      } else {
        this.orderDirection = 'asc';
      };
      this.page = 0;

      this.queryTable();
    }
  }

  getScrollHeight = () => {
    const { } = this.props;
    if (window.innerWidth < 1630) {
      return 'calc(100vh - 340px)';
    } else {
      return 'calc(100vh - 340px)';
    }
  }

  render() {

    return (
      <Modal
        width={1200}
        visible={true}
        title={'历史版本'}
        mask={false}
        maskClosable={false}
        onCancel={this.closeModal.bind(this)}
        footer={null}
        className='devMonitor'>
        <div className='filter'>
          <Filter
            wrappedComponentRef={(form) => {
              this.formRef = form;
            }}
            loading={this.tableLoading}
            func={this.queryTable}
          />
        </div>
        <div className='tableDown'>
          <Table
            className="antdTableAndPagination"
            scroll={{ x: 1530, y: 400 }}
            rowKey="id"
            pagination={false}
            onChange={this.tableChange}
            loading={{
              tip: '加载中' + '...',
              spinning: this!.tableLoading
            }}
            bordered={true}
            size="small"
            dataSource={toJS(this!.tableData)}
            columns={this.colums} />

          {(toJS(this!.tableData).length > 0) &&
            <Paginations
              changPageSize={this.changPageSize}
              pageNum={this!.page + 1}
              pageSize={this!.pageSize}
              total={this!.total}
              changePageNum={this.changePageNum} />
          }
        </div>

      </Modal>
    )
  }
}

interface PageProps {
  pageNum: number;
  pageSize: number;
  total: number;
  changePageNum: (num: number) => void;
  changPageSize: (pageSize: number) => void;
}

@observer
class Paginations extends React.Component<PageProps> {

  constructor(props) {
    super(props);
  };

  @action
  onChange = (page) => {
    const { changePageNum, } = this.props;
    // console.log(page);
    changePageNum(page);
  }

  showTotal = (total) => {
    return '总共' + total + '条';
  }


  @action
  onShowSizeChange = (current, pageSizes) => {
    const { changPageSize } = this.props;
    console.log('onShowSizeChange', current, pageSizes);
    changPageSize(pageSizes);
  }

  render() {
    return <Pagination
      pageSizeOptions={['20', '50', '100']}
      showSizeChanger
      showQuickJumper={false}
      onShowSizeChange={this.onShowSizeChange}
      pageSize={this.props.pageSize}
      current={this.props.pageNum}
      onChange={this.onChange}
      total={this.props.total}
      showTotal={this.showTotal}
    />;
  }
}

