import React from "react";
import { FormComponentProps } from "antd/lib/form";
import { Form, Button, message, Spin } from "antd";
import { inject, observer } from "mobx-react";
import { UnControlled as CodeMirror } from "react-codemirror2";
import "codemirror/lib/codemirror.js";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import { CommonStore } from "../../../../src/stores";

interface IProps extends FormComponentProps {
  devId: string;
  devType: number;
  commonStore?: CommonStore;
}

@inject("commonStore")
@observer
class CanParam extends React.Component<IProps> {
  state = {
    saveLoading: false,
    detailLoading: false,
    val: "",
  };

  instance:any = null;

  getParamsDetail = () => {
    // 获取参数详情
    const { commonStore, devId, devType } = this.props;
    this.setState({ detailLoading: true });
    const params = {
      type: 0,
      canxml: "",
    };
    commonStore!.noInfoZhiling(devId, 1005, devType, params).then(
      (res: any) => {
        this.setState({ detailLoading: false });
        if (res.error && res.error.length) {
          message.error("查询can xml参数失败");
        } else {
          this.setState({ val: res.content });
        }
      },
      (err) => {
        message.error("查询can xml参数失败");
        this.setState({ detailLoading: false });
      }
    );
  };

  componentDidMount(): void {
    // this.getParamsDetail();
  }

  handleSubmit = (e) => {
    const {
      devId,
      devType,
      commonStore,
      form: { validateFieldsAndScroll },
    } = this.props;
    e.preventDefault();
    validateFieldsAndScroll((err, values) => {
      if (!err) {
        const xmlContent = this.instance.getValue()
        if(!xmlContent) {
          message.error('请录入xml')
          return
        }
        this.setState({ saveLoading: true });
        const params = {
          type: 1, // 0-获取参数 1-设置参数
          canxml: xmlContent,
        };
        commonStore!.noInfoZhiling(devId, 1005, devType, params).then(
          (res: any) => {
            this.setState({ saveLoading: false });
            if (res.error && res.error.length) {
              message.error("保存失败");
            } else {
              message.success("保存成功");
            }
          },
          (err) => {
            message.error("保存失败");
            this.setState({ saveLoading: false });
          }
        );
      }
    });
  };

  render() {
    const { saveLoading, detailLoading, val } = this.state;
    return (
      <div className="code-right">
        <div style={{ textAlign: "right", marginBottom: 16 }}>
          {/* <Button
            type="primary"
            style={{ marginRight: 12 }}
            onClick={this.getParamsDetail}
            loading={detailLoading}
          >
            刷新
          </Button> */}
          <Button
            type="primary"
            htmlType="submit"
            onClick={this.handleSubmit}
            loading={saveLoading}
          >
            保存
          </Button>
        </div>
        <Spin spinning={detailLoading}>
          <CodeMirror
            value={val}
            options={{
              mode: "xml",
              theme: "material",
              lineNumbers: true,
            }}
            editorDidMount={editor => { this.instance = editor }}
          />
        </Spin>
      </div>
    );
  }
}

export default Form.create<IProps>()(CanParam);
