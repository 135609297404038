import { observable, action, computed, runInAction, } from 'mobx';
import { RouteItem } from 'src/types';
import { myRouter } from 'src/router';
import { eq } from 'src/utils';

const privateStr = '/private/';

export interface JumpParams{
  vehId: string, 
  plateNo: string,
  channels: Channel[],
  
}

export interface PageParams<T=any>{
  time: number,
  data: T;
}

export class TabStore {
  @observable tabList: RouteItem[] = [];
  @observable tabOperate = '';   // '' add remove
  
  @observable activeTabKey = '';
  // 用于保存每个页面的参数
  pageParams: {[key: string]: PageParams} = {
    // route: Device
  }

  constructor() {

  }

  // @computed 
  // get tabListLen(){
  //   return this.tabList.length
  // }

  // setTabListScroll(){
  //   setTimeout(() => {
  //     let hasScroll = false;
  //     const tabList = document.querySelector('#root > .App >.headerComponent .tabList');
  //     if(tabList && tabList.scrollWidth > tabList.clientWidth){
  //       hasScroll = true;
  //     }
  //     runInAction(() => {
  //       this.hasTabListScroll = hasScroll;
  //     })
  //   }, 10);
  // }

  @action
  clear = () => {
    this.tabList = [];
    this.pageParams = {};
  }

  setPageParams = (key: string, value:PageParams) => {
    this.pageParams[key] = value;
  }

  getPageParams = (key: string) => {
    return this.pageParams[key] || {};
  }


  getTabKeyFromPathname(pathname: string) {
    return pathname.replace(privateStr, '');
  }

  getPathFromTabKey(key: string) {
    return privateStr + key;
  }


  @action
  removeTab = (key: string) => {
    this.tabOperate = 'remove';
    const tabIndex = this.tabList.findIndex(t => t.key === key);
    this.tabList.splice(tabIndex, 1);
    delete this.pageParams[key];
    let newTabKey: string | undefined = undefined;

    // 如果关闭的tab是当前活跃的tab, 增返回新的tab
    if (this.tabList.length && eq(key, this.activeTabKey)) {
      let newTabIndex = 0;
      // 如果是最后一个tab,则newTab为前面一个tab, 否则为当前index下的tab
      if (eq(tabIndex, this.tabList.length)) {
        newTabIndex = tabIndex - 1;
      } else {
        newTabIndex = tabIndex;
      }
      const { key: tabKey } = this.tabList[newTabIndex];
      newTabKey = tabKey;
    }
    return newTabKey;
  }

  @action
  addTab = (key: string) => {
    const isOpen = this.tabList.find(t => t.key === key);
    if (!isOpen) {
      let newTab = myRouter.find(m => m.key === key);
      this.tabList.push(newTab!);
      this.tabOperate = 'add'
    }
    
    this.activeTabKey = key;
  }

}

