import React from "react";
import { inject, observer } from 'mobx-react';
import { observable, action, toJS, computed } from "mobx";
import { Modal, Checkbox, Row, Col, Button, message } from "antd";
import { intlGet, eq } from "../../../src/utils/mix";
import { 
  alarmType_dms_h2, alarmType_adas_h2, alarmType_all_h2,
  getNum_adas, getNum_dms, getNum_all,
  getAlarm_adas, getAlarm_dms,  getAlarm_all, getNum_h3, getcvbsCh_h3, cvbsCh_h3
} from './configType';
const CheckboxGroup = Checkbox.Group;

export type DialogType = 'dms' | 'adas' | 'all' | 'h3';

interface InputDialogProps{
  cvbsMode?:any,
  isEdit:boolean,
  type: DialogType,
  value: number,
  setVisiable: (show: boolean) => void;
  onSumbit: (num: number) => void;
}
interface Dialog{
  getNum: Function,
  getAlarm: Function,
  FormData: {[key: string]: number}
}
const dialogMap: {[DialogType: string]: Dialog} = {
  all: {
    getNum: getNum_all,
    getAlarm: getAlarm_all,
    FormData: alarmType_all_h2,
  },
  dms: {
    getNum: getNum_dms,
    getAlarm: getAlarm_dms,
    FormData: alarmType_dms_h2,
  },
  adas: {
    getNum: getNum_adas,
    getAlarm: getAlarm_adas,
    FormData: alarmType_adas_h2,
  },
  h3: {
    getNum: getNum_h3,
    getAlarm: getcvbsCh_h3,
    FormData: cvbsCh_h3,
  }
}

@observer
export default class InputDialog extends React.Component<InputDialogProps>{

  @observable checkAll = false;
  @observable checkedList: string[] = [];

  @action
  componentDidMount(){
    const {value, type} = this.props;
    if(value){
      let checkedList: string[] = dialogMap[type].getAlarm(value);
     // if(eq(type, 'dms')){
      //   checkedList = getAlarm_dms(value);
      // }else if(eq(type, 'adas')){
      //   checkedList = getAlarm_adas(value);
      // }else if(eq(type, 'all')){
      //   checkedList = getAlarm_all(value);
      // }
      this.onChange(checkedList);
    }
  }

  @action
  onCheckAllChange = (e) => {
    const checked = e.target.checked;
    this.checkAll = checked;
    this.checkedList = checked ? this.getDialogOption() : [];
  }

  @action
  onChange = (checkedList) => {
    const { type, cvbsMode } = this.props;
    if (eq(type, 'h3')) {
      if (eq(cvbsMode, 0)) {
        if (checkedList.length > 4) {
          message.warn('分屏模式下最多只能选择4个', 2);
          return false;
        }
      } else {
        if (checkedList.length > 4) {
          message.warn('最多只能选择4个', 2);
          return false;
        }
      }

    }
    this.checkedList = checkedList;
    const dialogOptions = this.getDialogOption();
    const isCheckedAll = eq(dialogOptions.length, checkedList.length);
    this.checkAll = isCheckedAll;

  }

  getDialogData = () => {
    const {type} = this.props;
    // const dialogData = eq(type, 'dms') ? alarmType_dms_h2 : alarmType_adas_h2;
    const dialogData = dialogMap[type].FormData;
    return dialogData;
  }

  getDialogOption = () => {
    const dataObj = this.getDialogData();
    return Object.keys(dataObj);
  }

  onSubmit = () => {
    const {type, onSumbit, setVisiable} = this.props;
    const func =  dialogMap[type].getNum;
    const num = func(toJS(this.checkedList));
    console.log('current count=',num);
    onSumbit(num);
    setVisiable(false);
  }

  render() {
    const {setVisiable,isEdit} = this.props;
    let bools = !isEdit;
    const options = this.getDialogOption();
    console.log(' tanchuang checkbox render', toJS(this.checkedList),bools);
    return <Modal
      className='templateManageInputDialog'
      title="选项"
      visible={true}
      //onOk={this.onSubmit}
      onCancel={setVisiable.bind(this, false)}
      footer={[
        <Button disabled={bools} key="back" onClick={setVisiable.bind(this, false)}>取消</Button>,
        <Button 
        disabled={bools}
        key="submit" 
        type="primary" 
        //loading={loading} 
        onClick={this.onSubmit}>
            确定  
        </Button>]}
    >
      <div className="top">
        <span className="title">告警类型</span>
        <Checkbox
          disabled={bools}
          onChange={this.onCheckAllChange}
          checked={toJS(this.checkAll)
          }
        >
          全选
      </Checkbox>
      </div>
      <CheckboxGroup
        disabled={bools}
        value={toJS(this.checkedList)}
        onChange={this.onChange}
      >
        <Row>
        {options.map(key => {
          return <Col span={12} key={key}>
              <Checkbox disabled={bools}  value={key}>{intlGet(key)}</Checkbox>
            </Col>
        })}
        </Row>
      </CheckboxGroup>
    </Modal>
  }
}
