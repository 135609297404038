import React, { Component } from 'react';
import { observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';
import { Form, Icon, Button, Row, Col, Input, message } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { AuthStore } from '../../../src/stores';
import logo from '../../../src/assets/loginLogo.png';
import user from '../../../src/assets/user.png';
import pwd from '../../../src/assets/pwd.png';
import * as config from '../../../src/config';

import './index.scss';
import MyIcon from '../../../src/components/myIcon';
import { DictStore } from '../../stores/dict.store';

const userNameStr = 'userName';
const passwordStr = 'password';

interface loginProps extends FormComponentProps {
  authStore?: AuthStore;
  dictStore?:DictStore
}

@inject('authStore','dictStore')
@observer
class LoginPage extends Component<loginProps & Partial<RouteComponentProps>>{

  @observable codeUrl: string = '';
  @observable loading = false;
  @observable.ref loginInfo = {
    userName: '',
    password: '',
    remember: false,
  }

  constructor(props) {
    super(props);
    this.setCodeUrl();
  }

  componentDidMount() {
    const { authStore } = this.props;
    console.log('login page islogin', authStore!.isLogin);

  }

  @action
  setCodeUrl = () => {
    this.codeUrl = config.apiPrefix + 'captcha?d=' + Date.now();
  }

  @action
  handleLogin = (e: any) => {
    const { history, authStore,location,dictStore } = this.props;
    const { state } = history!.location as any;
    const from: string = state && state.from || '';
    e.preventDefault();
    const { validateFields, setFields } = this.props.form;
    validateFields((err, values) => {
      console.log('login validate=', values);
      // const homeUrl = '/private/home';
      const homeUrl = '/private/device';
      let newUrl: string = from || homeUrl;
      if (!err) {
        this.loading = true;
        authStore!.proLogin(values).then(res => {
          authStore!.setIsLogin(true);  //确认登陆
          sessionStorage.setItem('userInfo',JSON.stringify(res.data));
          
          const getDict = authStore!.getDict();
          const getDict2 = dictStore!.getDictFromServer;
          const getAlarmTree = dictStore!.getAlarmTreeFromServer;
          Promise.all([getDict,getDict2()]).then(() => {
            this.loading = false;
            history!.push(newUrl);
          })

        }, err => {
          this.loading = false;
          console.log('account err!',err.data);
          const {code} = err.data;
          let msg = '';
          if(code === -200){
            msg = '账号密码错误！'
          }else if(code === 130){
            msg = '验证码错误！'
          }
          message.error(msg, 2);
          this.setCodeUrl();
        });
      }
    });
  }

  render() {
    console.log('login page render');
    const { getFieldDecorator } = this.props.form;
    const { userName, password } = this.loginInfo

    return (
      <div className='loginPage'>
        <div className='loginComponent'>
          <div className='Logintitle'>
            <div className='left'>
              <img src={logo} />
            </div>
            <div className='right'>
              <span className='big'>
                终端运维平台
                </span>
              <span className='small'>
                Welcome欢迎登录
                </span>
            </div>
          </div>
          <Form onSubmit={this.handleLogin} className="login-form">
            <Form.Item >
              {getFieldDecorator(userNameStr, {
                rules: [{ required: true, message: '请输入用户名' }],
                initialValue: userName,
              })(
                <Input
                  prefix={<img src={user} />}
                  size="large"
                  autoComplete="off"
                  placeholder={'用户名'} />
              )}
            </Form.Item>
            <Form.Item>
              {getFieldDecorator(passwordStr, {
                rules: [{ required: true, message: '请输入密码' }],
                initialValue: password
              })(
                <Input size="large" autoComplete="off"
                  prefix={<img src={pwd} />}
                  type="password"
                  placeholder={'密码'} />
              )}
            </Form.Item>

            <div className='verCode'>
              <Form.Item >
                {getFieldDecorator('randomCode', {
                  rules: [{ required: true, message: '请输入验证码' }],
                  initialValue: '',
                })(
                  <Input size="large" autoComplete="off"
                    prefix={<MyIcon icon="iconyanzhengma" />}
                    placeholder={'验证码'} />
                )}
              </Form.Item>
              <div className="codeImg">
                <div className="divider" />
                <img src={this.codeUrl} onClick={this.setCodeUrl} />
              </div>
            </div>

            <Button type="primary" htmlType="submit" size="large"
              className="login-form-button" style={{}}
              loading={this.loading}
            >
              {'登录'}
            </Button>

          </Form>
        </div>
      </div>
    )
  }

}

export default Form.create()(LoginPage);