import { observable, action, runInAction, toJS, computed } from 'mobx';
import { http } from '../../src/utils/http';
import { copy } from '../utils/mix';
import { FetchStatus } from '../types';


interface SearchParams {
  productType: number,
  isCustom: number,

  customerId: string,
  page: number,
  pageSize: number,
  orderDir?: string,
  orderColumn?: string,
  total?: number,
}

const defaultSearchParams: SearchParams = {
  productType: -1,
  isCustom: 1,

  customerId: '',
  page: 0,
  pageSize: 20,
  orderDir: '',
  orderColumn: '',
  total: 0,
}

export class ProjectStore {

  @observable showCusList = false;
  @observable showAddCustomer = false;

  
  @observable productMap = observable.map<string, Project>();  // key productId
  @observable searchParams = copy(defaultSearchParams);
  @observable loading: FetchStatus = 'none';

  
  @observable customerMap = observable.map<string, Customer>();  // key customerId
  @observable selectedCustomId = '';

  @computed
  get productArr() {
    return Array.from(this.productMap.values());
  }

  @computed
  get customerArr() {
    return Array.from(this.customerMap.values());
  }

  @computed
  get selectedCus(){
    return this.customerMap.get(this.selectedCustomId);
  }

  @action
  changeShowCusList = (show: boolean) => {
    this.showCusList = show;
  }

  @action
  changeShowAddCustomer = (show: boolean) => {
    this.showAddCustomer = show;
  }

  @action
  changeSearchParams = (params: Partial<SearchParams>) => {
    this.searchParams = { ...this.searchParams, ...params };
  }

  @action
  changeSelectCusId = (customerId: string) => {
    this.selectedCustomId = customerId;
  }


  /**
   * 获取自己公司产品的列表
   */
  @action
  getOurProductList = () => {
    let para = {
      page: 0,
      pageSize: 100,
      productType: -1,
      //  orderColumn: this.orderColumn,
      //  orderDir: this.orderDir,
      isCustom: 0,
    }
    const url = 'product-info/page';
    return http.post(url, para).then(res => {
      runInAction(() => {
        // this.ourProductList = res.data.items;
        this.productMap.clear();
        (res.data.items || []).forEach(x => {
          const { productId } = x;
          this.productMap.set(productId, x);
        })
      });
    }, err => {
      runInAction(() => {
      });
    });
  }

  /**
   * 产品信息list
   * 
   */
  @action
  getProductData = () => {
    const { page, pageSize, productType, isCustom,
      orderColumn, orderDir } = this.searchParams;
    let para = {
      page,
      pageSize,
      orderColumn,
      orderDir,
      productType,
      isCustom,
      customerId: this.selectedCustomId,
      //  customerId: this.curShowCustomer.customerId || ''
    }
    this.loading = 'pending';
    const url = 'product-info/page';
    return http.post(url, para).then(res => {
      runInAction(() => {
        this.loading = 'success';
        this.productMap.clear();
        (res.data.items || []).forEach(x => {
          const { productId } = x;
          this.productMap.set(productId, x);
        })
      });
    }, err => {
      runInAction(() => {
        this.loading = 'fail';
      });

    });

  };


  /**
   * 获取定制客户信息list
   */
  @action
  getCustomList = (customerId: string) => {
    let paras = {
      customerId
    }
    console.log('getCustomList')
    const url = 'customer-info/data';
    return http.post(url, paras).then(res => {
      runInAction(() => {
        this.customerMap.clear();
        const customData = res.data || [];
        customData.map(x => {
          const { customerId } = x;
          this.customerMap.set(customerId, x);
        });
        if(customData.length){
          this.selectedCustomId = customData[0].customerId;
        }
        

      });
      if (this.selectedCustomId) {
        this.getProductData();
      }
    }, err => {
      runInAction(() => {

      });
    });
  };



}