import React, { Component } from 'react'

interface Props {
  icon: string
  style?: any
  title?: string
  className?: any
  version?: string
  onClick?: (e: any) => any
  onMouseOver?: (e: any) => any
  onMouseOut?: (e: any) => any
}

export default class MyIcon extends React.PureComponent<Props> {
  render() {
    const { icon, className = '', version = '', ...rest } = this.props
    return (
      <span
        className={`myIconComponent iconfont${version} ${icon} ${className}`}
        {...rest}
      />
    )
  }
}
