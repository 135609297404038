import React from "react";
import { Tabs } from "antd";
import { toJS } from "mobx";
import { inject, observer } from "mobx-react";
import { DeviceStore } from "../../../../src/stores";
import BsdParams from "./BsdParams";
import CanParam from "./CanParam";

const { TabPane } = Tabs;

interface IProps {
  devId: string;
  devType: number;
  deviceStore?: DeviceStore;
}

@inject("deviceStore")
@observer
class RightParams extends React.Component<IProps> {
  render() {
    const { deviceStore, devId, devType } = this.props;
    const TABS = [
      {
        key: "adas",
        label: "adas参数设置",
        component: <BsdParams devId={devId} devType={devType} />,
      },
      {
        key: "canxml",
        label: "can xml设置",
        component: <CanParam devId={devId} devType={devType} />,
      },
    ];

    return (
      <Tabs defaultActiveKey="adas">
        {TABS.map((i) => (
          <TabPane key={i.key} tab={i.label}>
            {toJS(deviceStore!.devConsoleDetailInfo)?.deviceId &&
              toJS(deviceStore!.devConfigPara) &&
              i.component}
          </TabPane>
        ))}
      </Tabs>
    );
  }
}

export default RightParams;
