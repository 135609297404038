import React from 'react';
import { observer, inject } from 'mobx-react';
import { Input, Pagination, Tabs, Empty, Tag } from 'antd';
import _ from 'lodash';
import { ProductTestStore } from 'src/stores';
import { getSearchParams, formatTime } from 'src/utils/mix';
import consts, { localDict } from 'src/consts';

const { TabPane } = Tabs;

interface IndexProps {
  productTestStore?: ProductTestStore,
}

@inject('productTestStore')
@observer
export default class Index extends React.Component<IndexProps> {


  constructor(props: IndexProps) { 
    super(props);
    const { productTestStore } = props;
    const { category } = getSearchParams();
    productTestStore!.changeDetailCategory(category);
  }

  changeTab = (category: string) => {
    const { productTestStore } = this.props;
    const { changeDetailCategory, changeDetailIndex } = productTestStore!;
    changeDetailCategory(category);
    changeDetailIndex(0);
  }


  render() {
    let { productTestStore } = this.props;
    const {
      changeDetailCategory, detailCategory,
    } = productTestStore!;

    return <div className="boforeComWrapper">
      <Tabs activeKey={detailCategory} onChange={this.changeTab}>
        <TabPane tab="前装整机" key="2">
          <MainForm />
        </TabPane>
        <TabPane tab="前装PCBA" key="3">
          <MainForm />
        </TabPane>
        <TabPane tab="前装摄像头" key="4">
          <CameraForm />
        </TabPane>
      </Tabs>
    </div>
  }
}

const getValue = (data, key, key2?) => {
  // console.log('getValue', key);
  const value = _.get(data, key)
  if (value === undefined || value === null) {
    // return 'N/A'
    if(key2){
      return getValue(data, key2);
    }else{
      return 'N/A';
    }
  } else {
    return value;
  }
}
interface MainFormProps {
  productTestStore?: ProductTestStore,
}
 
@inject('productTestStore')
@observer
class MainForm extends React.Component<MainFormProps>{

  render() {

    let { productTestStore } = this.props;
    const { detailIndex, changeDetailIndex,
      curTabData
    } = productTestStore!;
    if (!curTabData.length) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ marginTop: '30vh' }} />
    }

    let data = curTabData[detailIndex];
    let time = getValue(data, 'checkTime');
    let timeStr = time ? formatTime(time, 1) : '';
    const totalResult = getValue(data, 'totalResult');
    const color = totalResult === 'PASS' ? '#87d068' : '#f50';

    return <div className="boforeCom">
      <div className="top">
        <div className="title">  产测详情</div>
        <div className="time">{timeStr}</div>
        <Pagination simple current={detailIndex + 1} total={curTabData.length} pageSize={1}
          onChange={(page) => { changeDetailIndex(page - 1) }}
        />
        <div className="result" style={{marginLeft: 40}}>
          <span>产测结果：</span>
          <Tag color={color} style={{ margin: 0 }}>{localDict.result[totalResult]}</Tag>
        </div>
      </div>
      <div className="main">
        <div className="col">
          <div className="block">
            <div className="title">工单信息</div>
            <div className="list">
              <Input addonBefore="工单号" value={getValue(data, 'orderNum')} readOnly />
              <Input addonBefore="产品型号" value={getValue(data, 'productType')} readOnly />
              <Input addonBefore="产品名称" value={getValue(data, 'productName')} readOnly />
              <Input addonBefore="客户名称" value={getValue(data, 'customerName')} readOnly />
              <Input addonBefore="客户料号" value={getValue(data, 'customerCode')} readOnly />
              <Input addonBefore="软件版本" value={getValue(data, 'softwareJob')} readOnly />
              <Input addonBefore="硬件版本" value={getValue(data, 'hardwareJob')} readOnly />
              <Input addonBefore="MCU版本" value={getValue(data, 'mcuVersionJob')} readOnly />
              <Input addonBefore="SOC版本" value={getValue(data, 'socVersionJob')} readOnly />
              <Input addonBefore="CAN配置版本" value={getValue(data, 'canVersionJob')} readOnly />

              <Input addonBefore="操作员" value={getValue(data, 'jobOperator')} readOnly />
              <Input addonBefore="产品物料号" value={getValue(data, 'jpNum')} readOnly />
              <Input addonBefore="产品系名称" value={getValue(data, 'jpName')} readOnly />
              <Input addonBefore="车型号" value={getValue(data, 'jcType')} readOnly />
            </div>
          </div>

          <div className="block">
            <div className="title">SN及PSN信息</div>
            <div className="list">
              <Input addonBefore="手动烧录SN" value={getValue(data, 'sn')} readOnly />
              <Input addonBefore="读取SN" value={getValue(data, 'sn')} readOnly />
            </div>
          </div>

        </div>
        <div className="col">
          <div className="block">
            <div className="title">设备信息</div>
            <div className="list">
              <Input addonBefore="PSN号" value={getValue(data, 'psn')} readOnly />
              <Input addonBefore="SOC号" value={getValue(data, 'socVersion')} readOnly />
              <Input addonBefore="MCU版本" value={getValue(data, 'mcuVersion')} readOnly />
              <Input addonBefore="输入电压" value={getValue(data, 'inputVoltage', 'vcc')} readOnly />
              <Input addonBefore="消耗电流" value={getValue(data, 'currentConsumption')} readOnly />
              <Input addonBefore="休眠电流" value={getValue(data, 'standbyCurrent')} readOnly />
              <Input addonBefore="VIN_AD" value={getValue(data, 'vinAd')} readOnly />
              <Input addonBefore="IGN_AD" value={getValue(data, 'ignAd')} readOnly />
              <Input addonBefore="CAN配置版本" value={getValue(data, 'canVersion')} readOnly />
            </div>
          </div>

          <div className="block">
            <div className="title">电压信息</div>
            <div className="list">
              <Input addonBefore="0.75V" value={getValue(data, 'voltage750')} readOnly />
              <Input addonBefore="1V" value={getValue(data, 'voltage1000')} readOnly />
              <Input addonBefore="1.5V" value={getValue(data, 'voltage1500')} readOnly />
              <Input addonBefore="1.8V" value={getValue(data, 'voltage1800')} readOnly />
              <Input addonBefore="3.3V" value={getValue(data, 'voltage3300')} readOnly />
              <Input addonBefore="5V" value={getValue(data, 'voltage5000')} readOnly />
              <Input addonBefore="24V" value={getValue(data, 'voltage24000')} readOnly />
              <Input addonBefore="MCU_3.3V" value={getValue(data, 'voltageMcu3300')} readOnly />
            </div>
          </div>

        </div>
        <div className="col">
          <div className="block">
            <div className="title">通信接口</div>
            <div className="list">
              <Input addonBefore="串口" value={getValue(data, 'serialPort')} readOnly />
              <Input addonBefore="CAN0" value={getValue(data, 'can0')} readOnly />
              <Input addonBefore="CAN1" value={getValue(data, 'can1')} readOnly />
              <Input addonBefore="CAN2" value={getValue(data, 'can2')} readOnly />
              <Input addonBefore="USB" value={getValue(data, 'usb')} readOnly />
              <Input addonBefore="SPI0" value={getValue(data, 'spi0')} readOnly />
              <Input addonBefore="US_L_DATA" value={getValue(data, 'utDataL')} readOnly />
              <Input addonBefore="US_R_DATA" value={getValue(data, 'utDataR')} readOnly />
              <Input addonBefore="RS232_LCD" value={getValue(data, 'rs232Lcd')} readOnly />
              <Input addonBefore="IPC" value={getValue(data, 'ipc')} readOnly />
              <Input addonBefore="软件版本" value={getValue(data, 'software')} readOnly />
              <Input addonBefore="硬件版本" value={getValue(data, 'hardware')} readOnly />
            </div>
          </div>

          <div className="block">
            <div className="title">Port IO信息</div>
            <div className="list">
              <Input addonBefore="IO_输入1" value={getValue(data, 'ioIn1')} readOnly />
              <Input addonBefore="IO_输入2" value={getValue(data, 'ioIn2')} readOnly />
              <Input addonBefore="IGN" value={getValue(data, 'ign')} readOnly />
            </div>
          </div>
        </div>
        <div className="col">

          <div className="block">
            <div className="title">外设信息</div>
            <div className="list">
              <Input addonBefore="LED" value={getValue(data, 'led')} readOnly />
              <Input addonBefore="12V_RADAR" value={getValue(data, 'radarVcc')} readOnly />
              <Input addonBefore="VCC_CAM" value={getValue(data, 'cameraVcc')} readOnly />
              <Input addonBefore="L_IN" value={getValue(data, 'turnLeft')} readOnly />
              <Input addonBefore="R_IN" value={getValue(data, 'turnRight')} readOnly />
              <Input addonBefore="Rear_IN" value={getValue(data, 'rear')} readOnly />

            </div>
          </div>
          <div className="block">
            <div className="title">存储信息</div>
            <div className="list">
              <Input addonBefore="NAND Flash" value={getValue(data, 'flash')} readOnly />
              <Input addonBefore="DDR III" value={getValue(data, 'ddr')} readOnly />
              <Input addonBefore="TF卡" value={getValue(data, 'tfCard')} readOnly />
            </div>
          </div>

          <div className="block">
            <div className="title">功能信息</div>
            <div className="list">
              <Input addonBefore="行人检测" value={getValue(data, 'pedDet')} readOnly />
              <Input addonBefore="车辆检测" value={getValue(data, 'vehDet')} readOnly />
              <Input addonBefore="车道线" value={getValue(data, 'laneLine')} readOnly />
              <Input addonBefore="限速标识" value={getValue(data, 'speedLimit')} readOnly />
            </div>
          </div>
        </div>
        <div className="col">
          <div className="block">
            <div className="title">声音及摄像头信息</div>
            <div className="list">
              <Input addonBefore="喇叭(是否正常)" value={getValue(data, 'horn')} readOnly />
              <Input addonBefore="蜂鸣器(是否正常)" value={getValue(data, 'buzzer')} readOnly />
              <Input addonBefore="按键(是否正常)" value={getValue(data, 'button')} readOnly />
              <Input addonBefore="摄像头(是否有图像)" value={getValue(data, 'cameraStatus')} readOnly />
            </div>
          </div>

          <div className="block">
            <div className="title">其他信息</div>
            <div className="list">
              <Input addonBefore="AVM_L(是否正常)" value={getValue(data, 'avmL')} readOnly />
              <Input addonBefore="AVM_R(是否正常)" value={getValue(data, 'avmR')} readOnly />
              <Input addonBefore="AVM_F(是否正常)" value={getValue(data, 'avmF')} readOnly />
              <Input addonBefore="DMS(是否正常)" value={getValue(data, 'dmsCamera')} readOnly />
              <Input addonBefore="BSD_L(是否正常)" value={getValue(data, 'bsdL')} readOnly />
              <Input addonBefore="BSD_R(是否正常)" value={getValue(data, 'bsdR')} readOnly />
              <Input addonBefore="RCW_IN(是否正常)" value={getValue(data, 'rearCameraIn')} readOnly />
              <Input addonBefore="AHD_OUT(是否正常)" value={getValue(data, 'ahdOut')} readOnly />
              <Input addonBefore="状态指示灯(是否正常)" value={getValue(data, 'statusIndicator')} readOnly />
              <Input addonBefore="USB供电(是否正常)" value={getValue(data, 'usbPs')} readOnly />
            </div>
          </div>

          <div className="block">
            <div className="title">参数信息</div>
            <div className="list">
              <Input addonBefore="设备高度" value={getValue(data, 'cameraHeight').replace('_', ' ')} readOnly />
              <Input addonBefore="设备距左外轮廓" value={getValue(data, 'edgeL').replace('_', ' ')} readOnly />
              <Input addonBefore="设备距右外轮廓" value={getValue(data, 'edgeR').replace('_', ' ')} readOnly />
              <Input addonBefore="标定状态" value={getValue(data, 'calibStatus').replace('_', ' ')} readOnly />
            </div>
          </div>
        </div>

      </div>
    </div>
  }
}

@inject('productTestStore')
@observer
class CameraForm extends React.Component<MainFormProps>{

  render() {

    let { productTestStore } = this.props;
    const { detailIndex, changeDetailIndex,
      curTabData: beforeTabData
    } = productTestStore!;
    if (!beforeTabData.length) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ marginTop: '30vh' }} />
    }

    let data = beforeTabData[detailIndex];
    let time = getValue(data, 'checkTime');
    let timeStr = time ? formatTime(time, 1) : '';

    return <div className="boforeCom camera">
      <div className="top">
        <div className="title">  产测详情</div>
        <div className="time">{timeStr}</div>
        <Pagination simple defaultCurrent={detailIndex + 1} total={beforeTabData.length} pageSize={1}
          onChange={(page) => { changeDetailIndex(page - 1) }}
        />
      </div>
      <div className="main">
        <div className="col">
          <div className="block">
            <div className="title">工单信息</div>
            <div className="list">
              <Input addonBefore="工单号" value={getValue(data, 'orderNum')} readOnly />
              <Input addonBefore="产品型号" value={getValue(data, 'productType')} readOnly />
              <Input addonBefore="产品名称" value={getValue(data, 'productName')} readOnly />
              <Input addonBefore="客户名称" value={getValue(data, 'customerName')} readOnly />
              <Input addonBefore="客户料号" value={getValue(data, 'customerCode')} readOnly />
              <Input addonBefore="软件版本" value={getValue(data, 'softwareJob')} readOnly />
              <Input addonBefore="硬件版本" value={getValue(data, 'hardwareJob')} readOnly />
              <Input addonBefore="摄像头角度" value={getValue(data, 'cameraAngleJob')} readOnly />
            </div>
          </div>

        </div>

        <div className="col">
          <div className="block">
            <div className="title">摄像头信息</div>
            <div className="list">
              <Input addonBefore="摄像头(是否有图像)" value={getValue(data, 'cameraStatus')} readOnly />
            </div>
          </div>

          <div className="block">
            <div className="title">其他信息</div>
            <div className="list">
              <Input addonBefore="SN号" value={getValue(data, 'sn')} readOnly />
              <Input addonBefore="摄像头角度" value={getValue(data, 'cameraAngle')} readOnly />
            </div>
          </div>
        </div>

      </div>
    </div>
  }
}