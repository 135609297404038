import { observable, action, runInAction } from 'mobx';
import { formatTime } from 'src/utils/mix';
import { http } from '../../src/utils/http';

export class DeviceStore {

  @observable authMenus: any[] = [];
  @observable filterPara: any = {}; //过滤条件参数
  @observable page: number = 0; //分页起始
  @observable pageSize: number = 20; //每页条数
  @observable orderColumn: string = ''; //排序字段
  @observable orderDir: string = ''; //排序方式
  @observable total: number = 0;
  @observable tableData: any[] = [];
  @observable tableLoading: boolean = false;
  @observable viewInfo: any = {};
  @observable detailVisible: boolean = false;
  @observable devConfigPara: any = {}; // 参数配置
  @observable orgConfigPara: any = {};  // 设备所在机构模板配置
  @observable curTemplateName: string = '';

  @observable showMoreFilter = false;

  @observable devConsoleDetailInfo: any = undefined;

  @observable isFirstCome: boolean = true; //设备是否第一次进入设备控制台

  @observable showFileManager = false;
  @observable grabbingFile = 0 // 0 未抓取 1 正在抓取(不显示文件) 2 正在抓取(显示文件) 
  
  @observable showRollback = false;
  // @observable h5ReqTime = '';

  @action
  changeShowRollback = (show: boolean) => {
    this.showRollback = show;
  }

  changeShowFileManager = (show: boolean) => {
    this.showFileManager = show;
  }

  @action
  changeShowMoreFilter = (show: boolean) => {
    this.showMoreFilter = show;
  }

  @action
  changeOrderColumn = (column) => {
    this.orderColumn = column;
  }

  @action
  changeSorter = (sorter) => {
    this.orderDir = sorter;
  }

  @action
  changePageNum = (pageNum) => {
    this.page = pageNum;
  }

  @action
  getDevView = (params?) => {
    const url = 'device' + '?id=' + params;
    return new Promise((resolve, reject) => {
      http.get(url).then(res => {
        this.viewInfo = res.data;
        console.log('getDevView data success=', res.data);
        runInAction(() => {

        });
        resolve(res);
      }, err => {
        console.log('getDevView data err=', err);
        reject(err);
      });
    });
  }

  //dev console get request
  @action
  getDevConsole = (devId?, devNo?) => {
    const url = 'device' + '?id=' + devNo;
    return new Promise((resolve, reject) => {
      http.get(url).then(res => {
        this.viewInfo = res.data;
        let statIn: any = JSON.parse(res.data.statusInfo) || {};
        // console.log('getDevConsole data success=', res.data);
        runInAction(() => {
          this.devConsoleDetailInfo = res.data;

          this.curTemplateName = res.data.templateName || '';
          if (res.data && res.data.orgTemParam) {
            this.orgConfigPara = JSON.parse(res.data.orgTemParam);
          }

        });
        resolve(res);
      }, err => {
        console.log('getDevConsole data err=', err);
        reject(err);
      });
    });
  }

  @action
  getH5Info = (devNo: string) => {
    const url = `device/state?id=${devNo}`;
    return http.get(url).then(res => {
      runInAction(() => {
        this.devConsoleDetailInfo = res.data;
      });
    })
  }

  //dev console param config get request
  @action
  getParamConfig = (devId?, devNo?, devType?, orgId?) => {
    const url = 'device-param-config' + '?id=' + devNo;
    return new Promise((resolve, reject) => {
      http.get(url).then(res => {
        if (res.data && res.data.devParam) {
          this.devConfigPara = JSON.parse(res.data.devParam);
          console.log('getParamConfig', JSON.parse(res.data.devParam));
        }
        resolve(res);
      }, err => {
        console.log('getParamConfig err=', err);
        reject(err);
      });
    });
  };

  //device param config save to database
  saveParamConfig = (params) => {
    const url = 'device-param-config/update';
    return new Promise((resolve, reject) => {
      http.post(url, params).then(res => {
        console.log('saveParamConfig data success=', res.data);
        runInAction(() => {

        });
        resolve(res);
      }, err => {
        console.log('saveParamConfig data err=', err);
        reject(err);
      });
    });
  }

  @action
  getTableData = (para) => {
    this.tableLoading = true;
    const url = 'device/page';
    // para.start = 0;
    para.page = this.page;
    para.pageSize = this.pageSize;
    para.orderColumn = this.orderColumn;
    para.orderDir = this.orderDir;
    return new Promise((resolve, reject) => {
      http.post(url, para).then(res => {
        runInAction(() => {
          this.tableLoading = false;
          this.tableData = res.data.items;
          this.total = res.data.total;
          console.log('device getTable res.data=', res.data);

        });
        resolve(res);

      }, err => {
        runInAction(() => {
          this.tableLoading = false;
        });
        reject(err);
      });
    });
  }

}
