import { http } from "../../src/utils/http";
import { eq } from "../../src/utils/mix";
import {
  WS,
  Device,
  WSRequestParams,
  MessageType,
  WSResponse,
  FixedLenMap,
  SimpleDevice,
  RealDevice,
} from "../../src/models/index";
import { observable, computed, runInAction } from "mobx";

const langMap = {
  "zh-cn": "Cn",
  "en-us": "En",
};

const isProd = process.env.NODE_ENV === "production";
const protocol = window.location.protocol === 'https:' ? 'wss': 'ws';
const hosts = window.location.host;
const wsUrl = isProd
  ? `${protocol}://${hosts}/devconsole/ws`
  : `${protocol}://console.jmadas.com/devconsole/ws`;
// : `ws://47.111.16.22:3333/devconsole/ws`;
// const wsUrl = `ws://47.111.16.22:3334/devconsole/ws`;
// const wsUrl = `ws://47.98.231.130:3333/devconsole/ws`;
console.log("host url=", hosts);
//`ws://192.168.0.182:3333/devconsole/ws`;  //localhost:8089 119.3.230.190:8089  47.111.16.22:8089

export class CommonStore {
  webscoket: WS | undefined;
  subscribedDevice: Device[] = [];
  heartbeatTimer: NodeJS.Timeout | undefined;
  videoHeartbeatTimerObj: Object = {}; // key deviceId_channel
  dispathVoiceMsgIds = new FixedLenMap(10);
  grabFileMsgIds = new FixedLenMap(10);
  noInfoMsgIds = new FixedLenMap(10);
  shellMsgIds = new FixedLenMap(10);
  logMsgIds = new FixedLenMap(10);
  canMsgIds = new FixedLenMap(10);
  configQuerysgIds = new FixedLenMap(10);

  customerMap = observable.map<string, Customer2>(); // key customerId

  @observable lookVehId: string | undefined = undefined;  // 车辆详情
  @observable lookDevNo: string | undefined = undefined;  // 设备详情
  
  constructor() {}

  @computed
  get customerArr() {
    return Array.from(this.customerMap.values());
  }

  openWebSocket = () => {
    this.webscoket = new WS(wsUrl);
    this.sendHeartbeat();
  };

  changeLookVehId = (vehId: string | undefined) => {
    this.lookVehId = vehId;
  }

  closeWebSocket = () => {
    this.stopHeartbeat();
    this.webscoket!.close();
  };

  subscribeWSEvent = (
    type: MessageType,
    func: (type: MessageType, data: WSResponse) => void
  ) => {
    const typeStr = type.toString();
    const callBack = (data: WSResponse) => func(type, data);
    this.webscoket!.event.on(typeStr, callBack);
    return () => {
      this.webscoket!.event.off(typeStr, callBack);
    };
  };

  /**
   * @desc 订阅的是设备，而不是车辆
   */
  subscribeDevice = (device: Device[]) => {
    // 取消订阅删除的设备  即原来订阅的设备不在新订阅的设备里面
    const cancleSelectCars = this.subscribedDevice.filter(
      (x) => !device.some((y) => eq(y.vehicleId, x.vehicleId))
    );
    this.unsubscribeDevice(cancleSelectCars as RealDevice[]);

    // 订阅新增的设备 即现在订阅的设备不在原来的设备里
    const newSelectedDevs = device.filter(
      (x) => !this.subscribedDevice.some((y) => eq(y.vehicleId, x.vehicleId))
    );
    this.subscribedDevice = device;
    this.subscribeSimpleDevice(newSelectedDevs as RealDevice[]);
  };

  subscribeSimpleDevice = (device: SimpleDevice[]) => {
    const messageData: WSRequestParams[] = device
      // .filter(x => x.online)
      .map((x) => {
        const { deviceId, devType } = x;
        return {
          devId: deviceId!,
          devType: devType,
          gpsMain: 1,
        };
      });
    if (messageData.length) {
      console.log("send gps realtime ifo ws=");
      this.webscoket!.sendWS(1, messageData);
    }
  };

  /**
   * @desc 取消订阅设备实时信息
   * @param
   */
  unsubscribeDevice = (device: SimpleDevice[]) => {
    if (!device.length) {
      return;
    }

    const messageData: WSRequestParams[] = device.map((x) => {
      const { deviceId, devType } = x;
      return {
        devId: deviceId!,
        devType: devType,
        gpsMain: 1,
      };
    });
    this.webscoket!.sendWS(2, messageData);
  };

  /**
   * @desc 订阅视频，后台返回 messageType 3001 code 0表示成功
   * @returns msgId string
   */
  subsribeVideo = (
    deviceId: string,
    channel = 1,
    devType: number,
    rtmp: string
  ) => {
    const messageData: WSRequestParams = {
      devId: deviceId,
      content: { channel, rtmp },
      devType: devType,
      act: 10, // 10 申请视频 11 心跳 12 停止视频
    };
    this.sendVideoHeartbeat(deviceId, channel);
    return this.webscoket!.sendWS(3, messageData);
  };

  /**
   * @desc 关闭视频直播流
   * @returns msgId string
   */
  closeVideoStream = (deviceId: string, channel = 1, devType: number) => {
    const messageData: WSRequestParams = {
      devId: deviceId,
      content: { channel },
      devType: devType,
      act: 12,
    };
    this.stopVideoHeartbeat(deviceId, channel);
    return this.webscoket!.sendWS(3, messageData);
  };

  recordQuery = (
    devId: string,
    devType: number,
    channel: number,
    startTime: string,
    endTime: string
  ) => {
    const messageData: WSRequestParams = {
      devId,
      devType,
      act: 18,
      content: {
        startTime,
        endTime,
        channel,
      },
    };
    return this.webscoket!.sendWS(3, messageData);
  };

  recordReplay = (devId: string, id: number, url: string, rtmp: string) => {
    const messageData: WSRequestParams = {
      devId,
      act: 19,
      content: {
        id,
        url,
        rtmp,
      },
    };
    return this.webscoket!.sendWS(3, messageData);
  };

  recordStop = (devId: string, id: number, url: string) => {
    const messageData: WSRequestParams = {
      devId,
      act: 20,
      content: {
        id,
        url,
      },
    };
    return this.webscoket!.sendWS(3, messageData);
  };

  recordDownload = (
    devId: string,
    devType: number,
    id: number,
    url: string
  ) => {
    const messageData: WSRequestParams = {
      devId,
      devType,
      act: 21,
      content: {
        id,
        url,
      },
    };
    return this.webscoket!.sendWS(3, messageData);
  };

  //1设备关机 2app重启 3设备升级 5配置查询
  //7抓拍图片 8抓拍视频 9日志抓取
  //14卸载app 15硬线频率 16开关adb 23 获取日志文件列表 1003驾驶员IC卡烧录
  noInfoZhiling = (deviceId: string, act: number, devType: number, content?:any) => {
    return new Promise((resolve, reject) => {
      const messageData: WSRequestParams = {
        devId: deviceId,
        content: content || {},
        act: act,
        devType: devType,
      };
      const theMsgId = this.webscoket!.sendWS(3, messageData);
      this.noInfoMsgIds.add(theMsgId, true);
      const unsubscribleWS = this.subscribeWSEvent(3001, (type, wsData) => {
        const { ts, msgId, data, code } = wsData;
        if (this.noInfoMsgIds.has(msgId)) {
          unsubscribleWS();
          if (eq(code, 0)) {
            resolve(data);
          } else if (eq(code, -1)) {
            reject(data);
          }
        }
      });
    });
  };

  //shell dispatch
  dispatchShell = (deviceId: string, cmd: string, devType: number) => {
    return new Promise((resolve, reject) => {
      const messageData: WSRequestParams = {
        devId: deviceId,
        content: { cmd },
        act: 13,
        devType: devType,
      };

      const theMsgId = this.webscoket!.sendWS(3, messageData);
      // this.shellMsgIds.add(theMsgId, true);
      const unsubscribleWS = this.subscribeWSEvent(3001, (type, wsData) => {
        const { ts, msgId, data, code } = wsData;
        if (theMsgId === msgId) {
          unsubscribleWS();
          if (eq(code, 0)) {
            resolve(data);
          } else if (eq(code, -1)) {
            reject(data);
          }
        }
      });
    });
  };

  //configuration query
  configQuery = (deviceId: string, paraKey: string, devType: number) => {
    return new Promise((resolve, reject) => {
      const messageData: WSRequestParams = {
        devId: deviceId,
        content: { get: [paraKey] },
        act: 5,
        devType: devType,
      };
      const theMsgId = this.webscoket!.sendWS(3, messageData);
      this.configQuerysgIds.add(theMsgId, true);
      const unsubscribleWS = this.subscribeWSEvent(3001, (type, wsData) => {
        const { ts, msgId, data, code } = wsData;
        if (this.configQuerysgIds.has(msgId)) {
          unsubscribleWS();
          if (eq(code, 0)) {
            resolve(data);
          } else if (eq(code, -1)) {
            reject(data);
          }
        }
      });
    });
  };

  //configuration dispatch
  configDispatch = (deviceId: string, keyValueObj: object, devType: number) => {
    return new Promise((resolve, reject) => {
      const messageData: WSRequestParams = {
        devId: deviceId,
        content: keyValueObj,
        act: 4,
        devType: devType,
      };
      const theMsgId = this.webscoket!.sendWS(3, messageData);
      this.configQuerysgIds.add(theMsgId, true);
      const unsubscribleWS = this.subscribeWSEvent(3001, (type, wsData) => {
        const { ts, msgId, data, code } = wsData;
        if (this.configQuerysgIds.has(msgId)) {
          unsubscribleWS();
          if (eq(code, 0)) {
            resolve(data);
          } else if (eq(code, -1)) {
            reject(data);
          }
        }
      });
    });
  };

  /**
   * @desc tts播报
   * @param txt 语音文件
   * @param risk 风险等级
   * @param source  1-实时监控 2-运营监控
   */
  dispatchVoice = (
    deviceId: string,
    txt: string,
    risk: number,
    source: 1 | 2,
    devType: number
  ) => {
    return new Promise((resolve, reject) => {
      const messageData: WSRequestParams = {
        devId: deviceId,
        content: { txt, risk, source, text: txt },
        act: 6,
        devType: devType,
      };
      const theMsgId = this.webscoket!.sendWS(3, messageData);
      this.dispathVoiceMsgIds.add(theMsgId, true);
      const unsubscribleWS = this.subscribeWSEvent(3001, (type, wsData) => {
        const { ts, msgId, data, code } = wsData;
        if (this.dispathVoiceMsgIds.has(msgId)) {
          unsubscribleWS();
          if (eq(code, 0)) {
            resolve(data);
          } else if (eq(code, -1)) {
            reject(data);
          }
        }
      });
    });
  };

  //can jiaoben xiafa
  dispatchCan = (
    deviceId: string,
    vid: string,
    pid: string,
    xml: string,
    devType: number
  ) => {
    return new Promise((resolve, reject) => {
      const messageData: WSRequestParams = {
        devId: deviceId,
        content: { vid, pid, xml },
        act: 17,
        devType: devType,
      };
      const theMsgId = this.webscoket!.sendWS(3, messageData);
      this.canMsgIds.add(theMsgId, true);
      const unsubscribleWS = this.subscribeWSEvent(3001, (type, wsData) => {
        const { ts, msgId, data, code } = wsData;
        if (this.canMsgIds.has(msgId)) {
          unsubscribleWS();
          if (eq(code, 0)) {
            resolve(data);
          } else if (eq(code, -1)) {
            reject(data);
          }
        }
      });
    });
  };

  grabFile = (
    deviceId: string,
    vid: string,
    pid: string,
    xml: string,
    devType: number
  ) => {
    return new Promise((resolve, reject) => {
      const messageData: WSRequestParams = {
        devId: deviceId,
        content: { vid, pid, xml },
        act: 9,
        devType: devType,
      };
      console.log("grabFile messageData", messageData);
      const theMsgId = this.webscoket!.sendWS(3, messageData);
      // this.grabFileMsgIds.add(theMsgId, true);
      const unsubscribleWS = this.subscribeWSEvent(3001, (type, wsData) => {
        const { ts, msgId, data, code } = wsData;
        if (theMsgId === msgId) {
          unsubscribleWS();
          if (eq(code, 0)) {
            resolve(data);
          } else if (eq(code, -1)) {
            reject(data);
          }
        }
      });
    });
  };

  //日志文件抓取 act 9
  logScrapFile = (deviceId: string, path: string, devType: any) => {
    return new Promise((resolve, reject) => {
      const messageData: any = {
        devId: deviceId,
        content: { files: path.trim() === "" ? [] : [path] },
        act: 9,
        devType: devType,
      };
      const theMsgId = this.webscoket!.sendWS(3, messageData);
      this.logMsgIds.add(theMsgId, true);
      const unsubscribleWS = this.subscribeWSEvent(3001, (type, wsData) => {
        const { ts, msgId, data, code } = wsData;
        if (this.logMsgIds.has(msgId)) {
          unsubscribleWS();
          if (eq(code, 0)) {
            resolve(data);
          } else if (eq(code, -1)) {
            reject(data);
          }
        }
      });
    });
  };

  sendVideoHeartbeat = (deviceId: string, channel = 1) => {
    const interval = 10 * 1000;
    const videoHeartbeatTimer = setInterval(() => {
      const messageData: WSRequestParams = {
        devId: deviceId,
        content: { channel },
        devType: 3,
        act: 11, // 10 申请视频 11 心跳 12 停止视频
      };
      this.webscoket!.sendWS(3, messageData);
    }, interval);
    let key = `${deviceId}_${channel}`;
    this.videoHeartbeatTimerObj[key] = videoHeartbeatTimer;
  };

  stopVideoHeartbeat = (deviceId: string, channel = 1) => {
    let key = `${deviceId}_${channel}`;
    const videoHeartbeatTimer = this.videoHeartbeatTimerObj[key];
    if (videoHeartbeatTimer) {
      clearInterval(videoHeartbeatTimer);
      delete this.videoHeartbeatTimerObj[key];
    }
  };

  sendHeartbeat = () => {
    const interval = 20 * 1000;
    this.heartbeatTimer = setInterval(() => {
      this.webscoket!.sendWS(1001, {});
    }, interval);
  };

  stopHeartbeat = () => {
    this.heartbeatTimer && clearInterval(this.heartbeatTimer);
  };

  /**
   * @param points ['114,34', '113,35.23']
   * @param language 0 中文 1英文
   */
  getAddress = (points: string[], language: number) => {
    const url = "comm/pointTranAddress";
    return new Promise<string>((resolve, reject) => {
      // setTimeout(() => {
      //   resove('测试地址...！');
      // }, 500);
      const params = {
        points,
        language,
      };
      http.post(url, params).then(
        (res) => {
          resolve(res.data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };

  /**
   * 导出excel
   */
  exportExcel = (className: string, content: Object, fileName: string = "") => {
    const url = "comm/excel";

    let lang: string = "zh"; //cookies.get('lang');
    lang = langMap[lang.toLowerCase()];

    const params = {
      className,
      content,
      lang,
    };

    http.post(url, params, { responseType: "blob" }).then(
      (res) => {
        console.log("res", res);
        const { data, headers } = res;
        let blob = new Blob([data], { type: "application/x-xls" });

        if (!fileName) {
          fileName = headers["content-disposition"].split("=")[1];
          fileName = decodeURI(fileName);
        }

        // if (window.navigator.msSaveOrOpenBlob) {
        if (true) {
          navigator.msSaveBlob(blob, fileName);
        } else {
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          window.URL.revokeObjectURL(link.href);
        }
      },
      (error) => {
        console.log("error", error);
      }
    );
  };

  getCustomer = () => {
    const url = "comm/customer";
    http.get(url).then((res) => {
      runInAction(() => {
        this.customerMap.clear();
        const dataArr: Customer2[] = res.data || [];
        dataArr.forEach((x) => {
          this.customerMap.set(x.customerId, x);
        });
      });
    });
  };
}
