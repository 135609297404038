import { observable, action, runInAction, toJS } from 'mobx';
import { http } from '../../src/utils/http';
import { message } from 'antd';
import { eq } from '../../src/utils/mix';
import _ from 'lodash';

export class VersionStore {

  @observable tableLoading: boolean = false;
  @observable productList: any[] = [];  // 产品列表 定制与非定制
  @observable addProVisible: boolean = false; //add product visible
  @observable proListVisible: boolean = true;
  @observable versionVisible: boolean = false; //version page visble
  @observable versionDetailVisible: boolean = false;
  @observable verName: string = '';
  @observable curProClickName: string = ''; //default
  @observable addProLoading: boolean = false;
  @observable editProVisible: boolean = false;
  @observable editInfo: any = undefined; //current select pro info
  @observable curBranch: any = undefined;
  @observable curChooseBranch: any = {}; //从当前分支列表选中的那个分支
  @observable addBranchVisible: boolean = false;
  @observable addVersionVisible: boolean = false;
  @observable editVersionVisible: boolean = false;
  @observable editBranchVisible: boolean = false;
  @observable submitPackageVisible: boolean = false;
  @observable curProductInfo: any = undefined; //current click product

  @observable isCustom: number = 0;  //客户编码,公司默认为jm,定制用户传customCode
  @observable total: number = 0;
  @observable productType: number = -1; //product type
  @observable orderColumn: string = 'create_time';//createTime
  @observable orderDir: string = 'desc'; //desc
  @observable page: number = 0;
  @observable pageSize: number = 20;

  //branch bufen
  @observable deleteBranchLoading: boolean = false;
  @observable proBranchArr: any[] = [];
  @observable commitState: any = undefined; //分支是否禁用状态
  @observable braPage: number = 0; //branch page
  @observable braPageSize: number = 20;
  @observable braTotal: number = 0;
  @observable braOrderColumn: string = '';//createTime default value create_time
  @observable braOrderDir: string = ''; // default desc
  @observable braVersionName: any = '';
  @observable braVersionNo: string = '';
  @observable versionTableData: any[] = []; // branch table data
  @observable fenzhiListVisible: boolean = false;
  @observable className: string = 'versionPage';
  @observable editBranchInfo: any = {};
  @observable selectSort: any = undefined; //分支里面的排序选择

  //version bufen
  @observable curVersion: any = undefined; //current version item
  @observable verOrderColumn: string = 'commit_time';//createTime
  @observable verOrderDir: string = 'desc'; //desc
  @observable verPage: number = 0;
  @observable verPageSize: number = 20;
  @observable verHistoryData: any[] = [];//version history table data
  @observable verKey: any = '';//user or name
  @observable versionInfo: any = {}; //version base info
  @observable verTotal: number = 0; //submit hisotry fuzzy key
  @observable tanPaneKey: string = '1'; //three tab pane active key


  //customer
  @observable allCustomerLoading: boolean = false;
  @observable addCustomerVisible: boolean = false;
  @observable allCustomerList: any[] = [];//customer info list
  @observable editCustomerVisible: boolean = false;
  @observable curClickCusInfo: any = {}; //drawer click
  @observable curShowCustomer: any = {}; //main product choosed
  @observable mainProject: any[] = [];//add dingzhi product select
  @observable curAddCustomerInfo: any = {}; //添加定制产品select选中info
  @observable isFirstCome: boolean = true;

  //ota
  @observable otaTableData: any[] = []; //ota data list
  @observable otaTableLoading: boolean = false;
  @observable otaOrderColumn: string = 'create_time';//createTime
  @observable otaOrderDir: string = ''; //desc
  @observable otaPage: number = 0;
  @observable otaPageSize: number = 20;
  @observable otaTotal: number = 0;
  @observable updateOk: any = '-1'; //is success
  @observable zhidingDevVisible: boolean = false;
  @observable zdOrgVisible: boolean = false;
  @observable otaRecordKey: any = undefined; // ota update record

  //device uppdate
  @observable chooseDevVisible: boolean = false;
  @observable devPage: number = 0; //分页起始
  @observable devPageSize: number = 20; //每页条数
  @observable devOrderColumn: string = ''; //排序字段
  @observable devOrderDir: string = ''; //排序方式
  @observable devTotal: number = 0;
  @observable devTableData: any[] = [];
  @observable deviceType: any = undefined;
  @observable configDesc = '';
  @observable rowSelectArr: any[] = [];
  @observable jiexiVisible: boolean = false;
  @observable otaConfigList: any[] = []; //ota配置记录
  @observable otaConfigLoading: boolean = false;

  @observable editDevVisible: boolean = false;
  @observable editOrgVisible: boolean = false;
  @observable editOtaConfigInfo: any = {};// current ota config info 

  @observable curModifyConfigInfo: any = {}; //当前修改配置的info

  @observable updatePercent = 0;

  @action
  changeCurProName = (str) => {
    this.curProClickName = str;
  }

  /**
   * 产品 包括定制与非定制
   */
  @action
  getProducts = () => {
    let para = {
      page: this.page,
      pageSize: this.pageSize,
      productType: this.productType,
      orderColumn: this.orderColumn,
      orderDir: this.orderDir,
      isCustom: this.isCustom,
      customerId: this.curShowCustomer.customerId || ''
    }
    this.tableLoading = true;
    const url = 'product-info/page';
    return new Promise((resolve, reject) => {
      http.post(url, para).then(res => {
        runInAction(() => {
          this.tableLoading = false;
          this.productList = res.data.items;
          this.total = res.data.total;
          console.log('product list getT1able res.data=', toJS(this.productList));

        });
        resolve(res);

      }, err => {
        runInAction(() => {
          this.tableLoading = false;
        });
        reject(err);
      });
    });
  };

  /**
   * 产品 非定制
   */
  @action
  getMainPro = () => {
    let para = {
      page: 0,
      pageSize: 100,
      productType: -1,
      orderColumn: this.orderColumn,
      orderDir: this.orderDir,
      isCustom: 0,
    }
    const url = 'product-info/page';
    return new Promise((resolve, reject) => {
      http.post(url, para).then(res => {
        runInAction(() => {
          this.mainProject = res.data.items;
          console.log('getOurProductList res.data=', res.data);

        });
        resolve(res);

      }, err => {
        runInAction(() => {
        });
        reject(err);
      });
    });
  }

  /**
   * 获取定制客户信息list
   */
  @action
  getCustomers = (id) => {
    let paras = {
      customerId: id
    }
    this.allCustomerLoading = true;
    const url = 'customer-info/data';
    return new Promise((resolve, reject) => {
      http.post(url, paras).then(res => {
        runInAction(() => {
          this.allCustomerLoading = false;
          this.allCustomerList = res.data;
          if (this.isFirstCome) {
            this.curShowCustomer = res.data[0] || {};
          }
          //this.total = res.data.total;
          console.log('getCusListData list getT1able res.data=', res.data);

        });
        if (this.isFirstCome) {
          this.getProducts();
        }
        this.isFirstCome = false;
        resolve(res);

      }, err => {
        runInAction(() => {
          this.allCustomerLoading = false;
        });
        reject(err);
      });
    });
  };

  /**
   * 获取分支下的版本
   */
  @action
  getVersionInBranch = () => {
    let para = {
      page: this.braPage,
      pageSize: this.braPageSize,
      orderColumn: this.braOrderColumn,
      orderDir: this.braOrderDir,
      key: this.braVersionName ? this.braVersionName.trim() : '', //versionName
      versionNo: this.braVersionNo,
      commitState: this.commitState ? Number(this.commitState) : -1, //0 false
      productId: this.curProductInfo.productId,
      productName: this.curProductInfo.productName,
      productCode: this.curProductInfo.productCode,
      branchId: this.curChooseBranch.branchId || '',
      branchName: this.curBranch ? (this.curBranch) : undefined,
    }
    this.tableLoading = true;
    const url = 'branch-info/page';
    return new Promise((resolve, reject) => {
      http.post(url, para).then(res => {
        runInAction(() => {
          this.tableLoading = false;
          this.versionTableData = res.data.items;
          this.braTotal = res.data.total;
          console.log('getBranchListData res.data=', res.data);

        });
        resolve(res);

      }, err => {
        runInAction(() => {
          this.tableLoading = false;
        });
        reject(err);
      });
    });
  };

  /**
   * 添加产品信息
   */
  @action
  addProduct = (para) => {
    this.addProLoading = true;
    const url = 'product-info/save';
    return new Promise((resolve, reject) => {
      http.post(url, para).then(res => {
        runInAction(() => {
          this.addProLoading = false;
          this.addProVisible = false;
          console.log('addProduct res.data=', res.data);
          message.success('添加产品信息成功', 2);
          this.getProducts();
        });
        resolve(res);

      }, err => {
        console.log('addProduct err=', err);
        message.error('添加产品信息失败', 2);
        runInAction(() => {
          this.addProLoading = false;
        });
        reject(err);
      });
    });
  }

  /**
   * 添加分支信息
   */
  @action
  addBranch = (para) => {
    this.addProLoading = true;
    const url = 'branch-info/save';
    return new Promise((resolve, reject) => {
      http.post(url, para).then(res => {
        runInAction(() => {
          this.addProLoading = false;
          this.addBranchVisible = false;
          this.getProBranch(this.curProductInfo.productId);
          console.log('addBranch res.data=', res.data);
          message.success('添加分支成功', 2);
        });
        resolve(res);

      }, err => {
        console.log('addBranch err=', err);
        message.error('添加分支失败', 2);
        runInAction(() => {
          this.addProLoading = false;
        });
        reject(err);
      });
    });
  }

  /**
   * 添加客户信息
   */
  @action
  addCustomer = (para) => {
    this.addProLoading = true;
    const url = 'customer-info/save';
    return new Promise((resolve, reject) => {
      http.post(url, para).then(res => {
        runInAction(() => {
          this.addProLoading = false;
          this.addCustomerVisible = false;
          console.log('addCustomer res.data=', res.data);
          this.getCustomers('');
          message.success('添加客户成功', 2);
        });
        resolve(res);

      }, err => {
        console.log('addCustomer err=', err);
        message.error('添加客户失败', 2);
        runInAction(() => {
          this.addProLoading = false;
        });
        reject(err);
      });
    });
  }

  /**
   * 编辑客户信息
   */
  @action
  editCustomer = (para) => {
    this.addProLoading = true;
    const url = 'customer-info/update';
    return new Promise((resolve, reject) => {
      http.post(url, para).then(res => {
        runInAction(() => {
          this.addProLoading = false;
          this.editCustomerVisible = false;
          if (eq(this.curShowCustomer.customerId, this.curClickCusInfo.customerId)) {
            this.curShowCustomer = para;
          }
          console.log('editCustomer res.data=', res.data);
          this.getCustomers('');
          message.success('编辑客户成功', 2);
        });
        resolve(res);

      }, err => {
        console.log('editCustomer err=', err);
        message.error('编辑客户失败', 2);
        runInAction(() => {
          this.addProLoading = false;
        });
        reject(err);
      });
    });
  }

  /**
   * 编辑分支信息
   */
  @action
  editBranch = (para) => {
    this.addProLoading = true;
    const url = 'branch-info/update';
    return new Promise((resolve, reject) => {
      http.post(url, para).then(res => {
        runInAction(() => {
          this.addProLoading = false;
          this.editBranchVisible = false;
          this.getProBranch(this.curProductInfo.productId);
          if (eq(this.curBranch, this.editBranchInfo.branchName)) {
            this.curBranch = para.branchName;
          }
          console.log('editBranch res.data=', res.data);
          message.success('编辑分支成功', 2);
        });
        resolve(res);

      }, err => {
        console.log('editBranch err=', err);
        message.error('编辑分支失败', 2);
        runInAction(() => {
          this.addProLoading = false;
        });
        reject(err);
      });
    });
  }

  /**
   * 修改产品信息
   */
  @action
  editProduct = (para) => {
    this.addProLoading = true;
    const url = 'product-info/update';
    return new Promise((resolve, reject) => {
      http.post(url, para).then(res => {
        runInAction(() => {
          this.addProLoading = false;
          this.editProVisible = false;
          this.editInfo = undefined;
          console.log('editProduct res.data=', res.data);
          message.success('编辑产品信息成功', 2);
          this.getProducts();
        });
        resolve(res);

      }, err => {
        console.log('editProduct err=', err);
        message.error('编辑产品信息失败', 2);
        runInAction(() => {
          this.addProLoading = false;
        });
        reject(err);
      });
    });
  }

  /**
   * 获取产品分支  master test 等
   */

  @action
  getProBranch = (id) => {
    const url = 'branch-info/data';
    let para = {
      productId: id
    }
    this.allCustomerLoading = true;
    return new Promise((resolve, reject) => {
      http.post(url, para).then(res => {
        runInAction(() => {
          this.allCustomerLoading = false;
          this.proBranchArr = res.data;
        });
        resolve(res);

      }, err => {
        runInAction(() => {
          this.allCustomerLoading = false;
        });
        reject(err);
      });
    });
  }

  /**
   * DELETE产品信息
   */
  @action
  deleteProduct = (para) => {
    this.addProLoading = true;
    const url = 'product-info/delete';
    return new Promise((resolve, reject) => {
      http.post(url, para).then(res => {
        runInAction(() => {
          this.addProLoading = false;
          console.log('deleteProduct res.data=', res.data);
          message.success('删除产品信息成功', 2);
          this.getProducts();
        });
        resolve(res);

      }, err => {
        console.log('deleteProduct err=', err);
        if (eq(err.data.code, 3000)) {
          message.warning('该产品含有版本无法删除', 2);
        } else {
          message.error('删除产品信息失败', 2);
        }
        runInAction(() => {
          this.addProLoading = false;
        });
        reject(err);
      });
    });
  }

  /**
   * 正式发布
   */
  officialRelease = (para) => {
    const url = 'branch-info/release';
    return new Promise((resolve, reject) => {
      http.post(url, para).then(res => {
        runInAction(() => {
          console.log('officialRelease res.data=', res.data);
          message.success('正式发布成功', 2);
          //发布成功立马更新当前分支列表
          this.getVersionInBranch();
        });
        resolve(res);
      }, err => {
        console.log('officialRelease err=', err);
        message.error('正式发布失败', 2);
        runInAction(() => {
        });
        reject(err);
      });
    });
  }

  converToTest = (productId: string, versionId: string) => {
    const url = 'branch-info/unrelease';
    const params = {
      productId,
      versionId
    }
    return http.post(url, params)
  }


  /**
   * DELETE分支信息
   */
  @action
  deleteBranch = (para) => {
    this.deleteBranchLoading = true;
    const url = 'branch-info/delete';
    return new Promise((resolve, reject) => {
      http.post(url, para).then(res => {
        runInAction(() => {
          this.deleteBranchLoading = false;
          console.log('deleteBranch res.data=', res.data);
          this.getProBranch(this.curProductInfo.productId);
          this.curBranch = '发布分支';
          message.success('删除分支信息成功', 2);
          this.getVersionInBranch();
        });
        resolve(res);

      }, err => {
        console.log('deleteBranch err=', err);
        message.error('删除分支信息失败', 2);
        runInAction(() => {
          this.deleteBranchLoading = false;
        });
        reject(err);
      });
    });
  }

  /**
   * add version
   */
  @action
  addVersion = (para) => {
    this.addProLoading = true;
    const url = 'version-info/save';
    console.log('para=', para);
    return new Promise((resolve, reject) => {
      http.post(url, para, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        // `onUploadProgress` 允许为上传处理进度事件
        onUploadProgress: progressEvent => {
          // 对原生进度事件的处理
          // const contentLength = progressEvent.srcElement.getResponseHeader('content-length')
          // console.log('progressEvent', progressEvent);
          const { loaded, total } = progressEvent;
          runInAction(() => {
            this.updatePercent = Math.round(loaded / total * 100);
          })
        },
      }).then(res => {
        runInAction(() => {
          this.addProLoading = false;
          console.log('addVersion res.data=', res.data);
          message.success('添加版本成功', 2);
          this.getProBranch(this.curProductInfo.productId);
          this.getVersionInBranch();
          this.addVersionVisible = false;
        });
        resolve(res);

      }, err => {
        console.log('addVersion err=', err);
        message.error('添加版本失败', 2);
        runInAction(() => {
          this.addProLoading = false;
        });
        reject(err);
      });
    });
  }

  /**
   * 激活/禁用版本
   */
  jihuoJingyong = (para) => {
    const url = 'version-info/change';
    let obj = {
      0: '禁用',
      1: '激活',
    }
    return new Promise((resolve, reject) => {
      http.post(url, para).then(res => {
        runInAction(() => {
          console.log('jihuoJingyong res.data=', res.data);
          message.success(`${obj[para.commitState]}版本成功`, 2);
          //this.getBranchSelect(this.curProductInfo.productId);
          this.getVersionInBranch();
        });
        resolve(res);

      }, err => {
        console.log('jihuoJingyong err=', err);
        if (para.commitState === 0) {
          message.error('禁用失败!');
        } else if (para.commitState === 1) {
          const errCode = err.data.code;
          if (errCode === 101) {
            message.error('验证码错误!');
          } else if (errCode === 104) {
            message.error('验证码失效!');
          }

        }
        reject(err);
      });
    });
  }

  /**
   * 删除版本信息
   */
  deleteVersion = (para) => {
    const url = 'version-info/delete';
    return new Promise((resolve, reject) => {
      http.post(url, para).then(res => {
        runInAction(() => {
          console.log('deleteVersion res.data=', res.data);
          message.success(`删除版本信息成功`, 2);
          this.getProBranch(this.curProductInfo.productId);
          this.getVersionInBranch();
        });
        resolve(res);

      }, err => {
        console.log('deleteVersion err=', err);
        message.error(`删除版本信息失败`, 2);
        runInAction(() => {
        });
        reject(err);
      });
    });
  }

  /**
   * 删除客户信息
   */
  deleteCustomer = (para) => {
    const url = 'customer-info/delete';
    return new Promise((resolve, reject) => {
      http.post(url, para).then(res => {
        runInAction(() => {
          console.log('deleteCustomer res.data=', res.data);
          message.success(`删除客户信息成功`, 2);
          this.isFirstCome = true;
          this.getCustomers('');
        });
        resolve(res);

      }, err => {
        console.log('deleteCustomer err=', err);
        message.error(`删除客户信息失败`, 2);
        runInAction(() => {
        });
        reject(err);
      });
    });
  }

  /**
   * 编辑版本信息
   */
  editVersion = (para) => {
    const url = 'version-info/updateBase';
    return new Promise((resolve, reject) => {
      http.post(url, para).then(res => {
        runInAction(() => {
          console.log('editVersion res.data=', res.data);
          message.success(`编辑版本信息成功`, 2);
          this.editVersionVisible = false;
          //this.getBranchSelect(this.curProductInfo.productId);
          this.getVersionInBranch();
        });
        resolve(res);

      }, err => {
        console.log('editVersion err=', err);
        message.error(`编辑版本信息失败`, 2);
        runInAction(() => {
        });
        reject(err);
      });
    });
  }

  /**
   * 重新提交版本信息
   */
  reSubmitPackage = (para) => {
    const url = 'version-info/update';
    this.addProLoading = true;
    return new Promise((resolve, reject) => {
      http.post(url, para).then(res => {
        runInAction(() => {
          console.log('reSubmitPackage res.data=', res.data);
          message.success(`重新提交成功`, 2);
          this.addProLoading = false;
          this.submitPackageVisible = false;
          this.getProBranch(this.curProductInfo.productId);
          this.getVersionInBranch();
        });
        resolve(res);

      }, err => {
        console.log('reSubmitPackage err=', err);
        message.error(`重新提交失败`, 2);
        runInAction(() => {
          this.addProLoading = false;
        });
        reject(err);
      });
    });
  }

  /**
   * 版本提交历史信息分页
   */
  versionHistory = () => {
    this.tableLoading = true;
    console.log('versionHistory', toJS(this.curVersion));
    let paras = {
      versionId: this.curVersion.versionId,
      orderColumn: this.verOrderColumn,
      orderDir: this.verOrderDir,
      page: this.verPage,
      pageSize: this.verPageSize,
      key: this.verKey,
    }
    const url = 'version-history/page';
    return new Promise((resolve, reject) => {
      http.post(url, paras).then(res => {
        runInAction(() => {
          this.tableLoading = false;
          this.verHistoryData = res.data.items;
          this.verTotal = res.data.total;
        });
        resolve(res);
      }, err => {
        runInAction(() => {
          this.tableLoading = false;
        });
        reject(err);
      });
    });
  }

  /**
   * OTA升级记录
   */
  otaHistory = () => {
    this.otaTableLoading = true;

    let paras = {
      versionId: this.curVersion.versionId,
      orderColumn: this.otaOrderColumn,
      orderDir: this.otaOrderDir,
      page: this.otaPage,
      pageSize: this.otaPageSize,
      updateOk: Number(this.updateOk),
      key: this.otaRecordKey,

    }
    const url = 'ota-upgrade-log/page';
    return new Promise((resolve, reject) => {
      http.post(url, paras).then(res => {
        runInAction(() => {
          this.otaTableLoading = false;
          this.otaTableData = res.data.items;
          this.otaTotal = res.data.total;
        });
        resolve(res);

      }, err => {
        runInAction(() => {
          this.otaTableLoading = false;
        });
        reject(err);
      });
    });
  }

  /**
   * 获取版本历史table
   */
  queryVersionHistoryTable = (params) => {
    const url = 'device/versionInfo';
    return new Promise((resolve, reject) => {
      http.post(url, params).then(res => {
        runInAction(() => {
          console.log('queryVersionHistoryTable res.data=', res.data);

        });
        resolve(res);

      }, err => {
        console.log('queryVersionHistoryTable err=', err);
        runInAction(() => {
        });
        reject(err);
      });
    });
  }

  /**
   * 获取版本历史table
   */
  queryTestHistoryTable = (params) => {
    const url = 'device/check';
    return http.post(url, params);
  }

  /**
   * 获取版本信息
   */
  versionBaseInfo = (versionId) => {
    const url = 'version-info' + '?versionId=' + versionId;
    return new Promise((resolve, reject) => {
      http.get(url).then(res => {
        runInAction(() => {
          this.versionInfo = res.data;
        });
        resolve(res);

      }, err => {
        reject(err);
      });
    });
  }

  /**
   * 获取选中设备列表
   */
  @action
  getDevTableData = (para) => {
    const url = 'device/page';
    para.page = this.devPage;
    para.pageSize = this.devPageSize;
    para.orderColumn = this.devOrderColumn;
    para.orderDir = this.devOrderDir;
    para.deviceType = this.deviceType ? Number(this.deviceType) : undefined;
    return new Promise((resolve, reject) => {
      http.post(url, para).then(res => {
        runInAction(() => {
          this.devTableData = res.data.items;
          this.devTotal = res.data.total;
          console.log('getDevTableData getTable res.data=', res.data);
        });
        resolve(res);

      }, err => {
        runInAction(() => {
        });
        reject(err);
      });
    });
  }

  /**
   * ota配置升级接口
   */
  otaUpdate = (para) => {
    let verId = {
      versionId: this.curVersion.versionId,
    }
    const url = 'ota-upgrade-config/save';
    return new Promise((resolve, reject) => {
      http.post(url, para).then(res => {
        runInAction(() => {
          message.success('OTA升级成功', 2);
          this.otaConfigInfo(verId);
          console.log('otaUpdateres.data=', res.data);
        });
        resolve(res);

      }, err => {
        runInAction(() => {
          message.error('OTA升级失败', 2);

        });
        reject(err);
      });
    });
  }

  /**
   * 获取ota配置信息
   */
  otaConfigInfo = (para) => {
    const url = 'ota-upgrade-config/data';
    this.otaConfigLoading = true;
    return new Promise((resolve, reject) => {
      http.post(url, para).then(res => {
        runInAction(() => {
          this.otaConfigLoading = false;
          this.otaConfigList = res.data;
        });
        resolve(res);

      }, err => {
        runInAction(() => {
          this.otaConfigLoading = false;
        });
        reject(err);
      });
    });
  }

  /**
   * 删除OTA配置
   */
  deleteOtaConfig = (para) => {
    const url = 'ota-upgrade-config/delete';
    let verId = {
      versionId: this.curVersion.versionId,
    }
    return new Promise((resolve, reject) => {
      http.post(url, para).then(res => {
        runInAction(() => {
          message.success('OTA删除成功', 2);
          this.otaConfigInfo(verId);
          console.log('deleteOtaConfig res.data=', res.data);
        });
        resolve(res);

      }, err => {
        runInAction(() => {
          message.error('OTA删除失败', 2);
        });
        reject(err);
      });
    });
  }

  /**
   * 编辑OTA配置
   */
  editOtaConfig = (para) => {
    const url = 'ota-upgrade-config/update';
    let verId = {
      versionId: this.curVersion.versionId,
    }
    return new Promise((resolve, reject) => {
      http.post(url, para).then(res => {
        runInAction(() => {
          message.success('编辑OTA配置成功', 2);
          this.otaConfigInfo(verId);
          console.log('editOtaConfig res.data=', res.data);
        });
        resolve(res);

      }, err => {
        runInAction(() => {
          message.error('编辑OTA配置失败', 2);
        });
        reject(err);
      });
    });
  }

  /**
   * 获取单个配置信息
   */
  getSingleOtaConfig = (otaId) => {
    this.editOtaConfigInfo = {};
    const url = 'ota-upgrade-config' + '?otaId=' + otaId;
    return http.get(url).then(res => {
      this.editOtaConfigInfo = res.data;
      console.log('getSingleOtaConfig=', res.data);
    }, err => {

    });
  }

  getCurBranchType = () => {
    const curBranchInfo = this.proBranchArr.find(x => {
      return this.curBranch == x.branchName;
    });
    return _.get(curBranchInfo, 'branchType', 1);
  }

  getCode = () => {
    const url = 'version-info/captcha'
    return http.get(url);
  }

}

