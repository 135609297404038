import React, { Component, useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { captureVideoFrame2, requestFullScreen, exitFullScreen, emptyFunc, eq, intlGet, replaceStr, Log, } from 'src/utils/mix';
import playerBg from 'src/assets/images/playerBg.png';
import flvjs from 'flv.js';
import { CommonStore, UiStore, AuthStore } from 'src/stores';
import './index.scss';
import MyIcon from '../myIcon';
import { observable, action, runInAction } from 'mobx';
import { Icon, message, Spin, Slider, Dropdown, Menu, Divider } from 'antd';
import ElliComponent from '../ellipsis';
 

// flvjs.LoggingControl.enableAll = false;

const log = new Log('player.tsx');

const domainReg = /^.+\/\/[^\/]+/;

export function getVideoSrc(devId: string, channelNo: number | string, devType: number | string, plateNo: string,
  time = 0,
) {
  return `${devId}_${channelNo}_${devType}_${plateNo}_${time}`
}

export const decodeVideoSrc = (src: string) => {
  const [deviceId, channelNo, devType, plateNo, date] = src.split("_");
  return {
    deviceId, channelNo, devType, plateNo, date
  }
}

interface PlayerComProps {
  commonStore?: CommonStore,
  uiStore?: UiStore,
  authStore?: AuthStore,
  /**
   * 如果是历史视频直接使用，
   * 如果是直播视频，请求视频流  deviceId_channelNo_devType_plateNo_time,
   *                 不请求      deviceId_channelNo_devType_plateNo_time===src(真实)
   */
  src?: string,
  replayUrl?: string, // 视频回放url, 用于视频src清空后，再次回放
  askForStream?: boolean,  // 真是的src, 不用在做转化
  index?: number,
  className?: string,
  isLive?: boolean,
  poster?: string,
  onClick?: (e) => void,
  onDestory?: (i: number, src: string, stop?: boolean) => void,
  autoplay?: boolean,
  hasAudio?: boolean,
  onReplay?: (i: number) => void,
  onEnded?: (i: number) => void,
  loop?: boolean;
  stopTime?: number,   // 停止视频 通过比较两次时间戳是否相等
  clickRightMenu?: (action: 'string', data: any) => void;
}

type VideoStatus = 'none' | 'loading' | 'play' | 'stop'; // 未初始化 拉流 播放 停止

@inject('commonStore', 'uiStore', 'authStore')
@observer
export default class FlvPlayer extends Component<PlayerComProps> {
  static defaultProps: PlayerComProps = {
    src: '',
    // src: mp4Url,
    className: '',
    isLive: false,
    askForStream: true,
    onClick: emptyFunc,
    index: 0,
    autoplay: true,
    poster: playerBg,
    hasAudio: false,
  }
  rootDiv: React.RefObject<HTMLDivElement>;

  player: flvjs.Player | undefined;
  videoElement: HTMLVideoElement | undefined;

  deviceId: string = '';  // 直播时用
  channel: number = 1; // 直播时用
  devType: number = 1; // 直播时用
  msgId: string = '';  // 直播时用 ws mgsId
  unsubscribleWS: Function | undefined;
  @observable videoStatus: VideoStatus = 'none'; // 只有在直播的时候才有用   

  @observable showLoadingText = false;  // 显示正在加载文本，当订阅视频成功后，视频play之前

  constructor(props) {
    super(props);
    // console.log('FlvPlayer constructor src', props.src);
    this.rootDiv = React.createRef();
  }

  componentDidMount() {
    const { src = '', isLive, askForStream, autoplay } = this.props;
    if(autoplay){
      if (isLive && src) {
        this.changeVideoStatus('loading');
      }
      if (isLive && askForStream) {
        this.askForLiveStream();
      } else {
        this.loadSrc(src);
      }
    }
    this.addEvents();
  }


  componentDidUpdate(prevProps: PlayerComProps) {
    const { isLive, src = '', askForStream, stopTime } = this.props;
    log.flow('componentDidUpdate');
    const prevSrc = prevProps.src;
    const isSrcChanged = !eq(src, prevSrc);   // src 完全相同
    // 外层控制停止
    const isStop = stopTime !== prevProps.stopTime;
    if (isSrcChanged) {
      prevSrc && this.destroyVideo(false, prevSrc);
      if (askForStream && src) {
        this.changeVideoStatus('loading');
      }
      if (isLive && askForStream) {
        this.askForLiveStream();
      } else {
        this.loadSrc(src);
        this.changeShowLoadingText(true);
      }
    } else if (isStop) {
      prevSrc && this.destroyVideo(false, prevSrc);
    }

  }

  componentWillUnmount() {
    log.flow('componentWillUnmount');
    const { commonStore, isLive, askForStream } = this.props;
    const videoElement: HTMLVideoElement = this.getVideoElement();
    videoElement.removeEventListener('play', this.onPlay);
    this.destroyVideo();

    if (isLive && askForStream) {
      this.unsubscribleWS && this.unsubscribleWS();
    }
  }

  @action
  changeShowLoadingText = (show: boolean) => {
    this.showLoadingText = show;
  }

  @action
  changeVideoStatus = (status: VideoStatus) => {
    this.videoStatus = status;
  }

  onPlay = () => {
    this.changeVideoStatus('play');
    this.changeShowLoadingText(false);
  }

  onEnded = () => {
    log.flow('onEnded');
    const { onEnded, index = 0 } = this.props;
    onEnded && onEnded(index);
  }

  getVideoElement = () => {
    if (this.videoElement) {
      return this.videoElement;
    } else {
      const videoElement: HTMLVideoElement = this.rootDiv.current!.querySelector('video')!;
      this.videoElement = videoElement;
      return videoElement;
    }
  }

  addEvents = () => {
    const videoElement: HTMLVideoElement = this.getVideoElement();
    videoElement.addEventListener('play', this.onPlay);
    videoElement.addEventListener('ended', this.onEnded)
  }

  getPushStreamUrl = (deviceId: string, channel: number, hasDomain: boolean) => {
    const { authStore } = this.props;
    // rtmp://jmcl.jmadas.com:1935/live/livestream/${deviceId}/${channel}
    const temp = authStore!.dict!.platform_config['live_push_url'];
    const formatStr = replaceStr(temp, {
      deviceId,
      channel
    });
    return hasDomain
      ? formatStr
      : formatStr.replace(domainReg, '');
  }

  getPullStreamUrl = (deviceId: string, channel: number, hasDomain: boolean) => {
    const { authStore } = this.props;
    // rtmp://jmcl.jmadas.com:1935/live/livestream/${deviceId}/${channel}
    const temp = authStore!.dict!.platform_config['live_play_url'];
    const formatStr = replaceStr(temp, {
      deviceId,
      channel
    }); 
    return hasDomain
      ? formatStr
      : formatStr.replace(domainReg, '');
  }

  subscribeWSEvent = () => {
    const { commonStore } = this.props;
    // this.changeIsLoading(true);

    // 如果是直播流, 需要监听ws, 返回成功就可以直播
    this.unsubscribleWS = commonStore!.subscribeWSEvent(3001, (type, wsData) => {
      const { ts, msgId, data, code } = wsData;
      if (eq(this.msgId, msgId)) {
        if (eq(code, 0)) {
          log.flow(3001);

          this.unsubscribleWS!();
          // let src = `/live/livestream/${this.deviceId}/${this.channel}.flv`;
          let src = this.getPullStreamUrl(this.deviceId, this.channel, true)

          this.changeVideoStatus('play');
          this.changeShowLoadingText(true);

          this.loadSrc(src);
        } else if (eq(code, -1)) {
          log.flow(3001, 'error');
          this.unsubscribleWS!();

          message.error(intlGet('load_video_fail'));
          this.destroyVideo();
        }
      }
    });
  }

  closeLiveStream = () => {
    const { commonStore, askForStream } = this.props;
    if (!askForStream) {
      return;
    }
    commonStore!.closeVideoStream(this.deviceId, this.channel, this.devType);

    // const isLoading = eq(this.videoStatus, 'loading');
    // const isPlaying = eq(this.videoStatus, 'play');
    // if (isLoading || isPlaying) {
    // }
  }

  @action
  askForLiveStream = () => {
    const { askForStream } = this.props;
    if (!askForStream) {
      return;
    }
    // this.closeLiveStream();
    const { commonStore, src: channelStr } = this.props;
    if (!channelStr) { return }

    const [deviceId, channel, devType, plateNo, date] = channelStr.split('_');

    this.deviceId = deviceId;
    this.channel = Number(channel);
    this.devType = Number(devType);

    this.subscribeWSEvent();  // 1111232
    // const rtmpUrl = `rtmp://jmcl.jmadas.com:1935/live/livestream/${deviceId}/${channel}`
    const rtmpUrl = this.getPushStreamUrl(deviceId, this.channel, true);
    this.msgId = commonStore!.subsribeVideo(deviceId, this.channel, this.devType, rtmpUrl);
  }

  loadSrc = (src: string) => {
    const { isLive, index, autoplay, askForStream, hasAudio, loop = false } = this.props;
    const videoElement: HTMLVideoElement = this.getVideoElement();
    const preUrl = askForStream
      ? 'http://jmcl.jmadas.com:82'
      : 'http://47.111.16.22:8082';
    if (src) {
      // console.log(`hasAudio ${hasAudio}`)
      log.flow('loadSrc');
      log.data('loadSrc src', `askForStream:${askForStream}, src:${src}`)
      let player;
      if (isLive) {
        if (!askForStream) {
          src = src.split('===')[1];
        }
        player = this.player = flvjs.createPlayer({
          type: 'flv',
          isLive: true,
          hasAudio: hasAudio,
          url: src,
          cors: true,
        }, {
            enableStashBuffer: false,
          });
      } else {
        player = this.player = flvjs.createPlayer({
          type: 'mp4',
          url: src,
        });
      }
      player.attachMediaElement(videoElement);
      player.load();
    }
    // 原生video控制
    videoElement.autoplay = autoplay!;
    videoElement.loop = loop;
    if (!isLive) {
      videoElement.controls = true;
    }
    if(!autoplay){
      videoElement.play();
    }
  }

  /**
   * @desc 直播模式下销毁视频 并设置视频状态
   * @parms stop 是否点击的关闭按钮 如果只是更换了url 则传入false
   */
  destroyVideo = (stop = false, oldSrc = '') => {
    log.flow('destroyVideo');

    const { onDestory, index = 0, isLive, src = '', askForStream } = this.props;
    oldSrc = oldSrc || src;
    if (isLive) {

      const isLoading = eq(this.videoStatus, 'loading');
      const isPlaying = eq(this.videoStatus, 'play');
      if (isLoading || isPlaying) {
        this.closeLiveStream();
        this.changeVideoStatus('stop');
        this.player && this.player.destroy();
        this.player = undefined;
        onDestory && onDestory(index, oldSrc, stop);
      }

    } else {
      if (this.player) {
        this.player.destroy();
        this.player = undefined;
        onDestory && onDestory(index, oldSrc);
      }
    }
    this.changeShowLoadingText(false);
  }

  toFullScreen = () => {
    const { uiStore } = this.props;
    const isFullScreen = uiStore!.isFull;
    const rootDiv = this.rootDiv.current!;
    if (isFullScreen) {
      exitFullScreen();
    } else {
      requestFullScreen(rootDiv);
    }
  }

  captureVideoFrame = () => {
    if (!this.player) { return }
    const videoElement: HTMLVideoElement = this.rootDiv.current!.querySelector('video')!;
    var image = captureVideoFrame2(videoElement);
    var link = document.createElement('a')!;
    link.download = "screenshot.png";
    link.href = image;
    link.click();
  }

  replayVideo = () => {
    const { isLive, src, onReplay, index = 0 } = this.props;
    if (isLive && src) {
      this.changeVideoStatus('loading');
    }
    // this.getVideoElement().autoplay = true;
    this.askForLiveStream();
    onReplay && onReplay(index);
  }

  // isLive=true askFor=false
  getStandarSrc = () => {
    const { isLive, askForStream, src } = this.props;
    if (src) {
      if (isLive) {
        if (askForStream) {
          return src;
        } else {
          return src.split('===')[0];
        }
      }
    }
    return '';
  }

  clickRightMenu = ({ item, key }) => {
    const { index } = this.props;
    log.data('clickRightMenu key', key)
    const { clickRightMenu } = this.props;
    if (key === '4') {
      this.destroyVideo(true, '')
    } else {
      // 其余的功能需要传到组件外
      clickRightMenu!(key, index);
    }
  }

  render() {
    const { className, src = '', isLive, uiStore, index, onClick, poster,
      clickRightMenu, autoplay
    } = this.props;

    // console.log(`src ${src}, index ${index}`)
    log.flow('player render', `src ${src}, index ${index}`);
    const isFull = uiStore!.isFull;
    const showPlay =(!autoplay && eq(this.videoStatus, 'none')) || eq(this.videoStatus, 'stop');
    const showLoading = isLive && eq(this.videoStatus, 'loading');
    const standardSrc = this.getStandarSrc();
    const rightMenuJSX = !!clickRightMenu && isLive
      ? <Menu
        onClick={this.clickRightMenu}
        className="playerRightMenu"
      >
        <Menu.Item key="1">
          <MyIcon icon="iconcuowuguanbiquxiao-yuankuang p-color" />
          清除所有视频
        </Menu.Item>
        <Menu.Item key="2">
          <MyIcon icon="iconcuowuguanbiquxiao-yuankuang p-color" />
          清除当前视频
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item key="3">
          <MyIcon icon="iconquanbuzanting p-color" />
          停止所有播放
        </Menu.Item>
        <Menu.Item key="4">
          <MyIcon icon="iconquanbuzanting p-color" />
          停止当前播放
        </Menu.Item>

        <Menu.Divider />

        <Menu.Item key="5">
          <MyIcon icon="iconquanping-mianxing p-color" />
          全屏
          </Menu.Item>
      </Menu> : <span></span>;

    return (
      <div className={`flvPlayerComponent ${className} ${isLive ? 'isLive' : ''} ${this.videoStatus}`}
        ref={this.rootDiv}
        onClick={onClick!.bind(this, index)}
      // onDoubleClick={this.toFullScreen}
      >
        {clickRightMenu ?
          <Dropdown overlay={rightMenuJSX} trigger={['contextMenu']}>
            <video className="flvPlayerVideo" poster={poster}></video>
          </Dropdown> :
          <video className="flvPlayerVideo" poster={poster}></video>
        }


        {src && isLive ?
          <div className="controlBar">
            <MyIcon icon="iconyiping" className="stop" title={intlGet('stop')} onClick={this.destroyVideo.bind(this, true, '')} />
            <div className="voice">
              <MyIcon icon="iconyinliang3" className="voice" title={intlGet('volume')} />
              <div className="voiceBar">
                <Slider vertical defaultValue={30} />
              </div>
            </div>
            <div className="plateNo">
              <PlateNoAndSpeed player={this.player} src={standardSrc} />
            </div>
            <MyIcon icon="iconpaizhao" className="capture" title={intlGet('screenshot')} onClick={this.captureVideoFrame} />
            <MyIcon icon={isFull ? "iconsuoxiao2" : "iconfangda"} className="fullScreen" title={isFull ? intlGet('exit_fullscreen') : intlGet('fullscreen')} onClick={this.toFullScreen} />
          </div>
          : null
        }
        {showPlay ?
          <MyIcon icon="iconshipin" className="playIcon" onClick={this.replayVideo} /> : null
        }
        {showLoading ?
          <Spin size="large" /> : null
        }
        {isLive && this.showLoadingText && (this.videoStatus != 'stop')
          ? <span className="loadingText">等待设备响应...</span>
          : null
        }
      </div>
    )
  }
}

interface PlateNoAndSpeedProps {
  player?: flvjs.Player,
  src: string,
}
const PlateNoAndSpeed = (props: PlateNoAndSpeedProps) => {
  const [speed, setSpeed] = useState('0.0');
  const { player, src: replayUrl } = props;

  useEffect(() => {
    log.flow('PlateNoAndSpeed useEffect');
    log.data('player', player);
    // player 销毁了，但是这个组件的props 里面还存在player
    if (player && player['_emitter']) {
      player.on(flvjs.Events.STATISTICS_INFO, (e) => {
        // console.log('STATISTICS_INFO', e);
        const { speed } = e;
        if (speed != undefined) {
          setSpeed(speed.toFixed(1))
        }
      })
    } else {
      setSpeed('0.0');
    }
  }, [player])

  const [r_deviceId, r_channel, r_devType, r_plateNo, r_date] = replayUrl.split('_');
  const plateNoAndSpeed = `${r_plateNo}-CH${r_channel} ${speed}kb/s`;

  return <ElliComponent text={`${plateNoAndSpeed}`} />
}



