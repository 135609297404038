import React from 'react';
import './index.scss';

interface IndexProps {
  text: React.ReactNode,
  title?: string,
  onClick?: (e) => void,
  className?: string,
}

const ElliComponent = (props: IndexProps) => {
  let { text, title, onClick = () => { }, className = '' } = props;
  const titleEl = title || text;
  return <div className={`ellipsisComponent ${className}`}
    title={(titleEl as string)}
    onClick={onClick}
  >
    <div className="text">{text}</div>
  </div>
}

export default ElliComponent;

