import React from 'react';
import { inject, observer } from "mobx-react";
import { Empty, message, Modal, Input } from 'antd';
import VideoList from './videoList/videoList';
import ParamButton from './paramButton/index';
import 'xterm/css/xterm.css'
import { getSearchParams, eq } from '../../../src/utils/mix';
import { DeviceStore, CommonStore, DeviceTreeStore } from '../../../src/stores'
import { getVideoSrc } from '../../../src/components/flvPlayer';
import ReactTer from 'terminal-in-react';

import './index.scss';
import DevConfigForm from '../../../src/components/devConfigForm/devConfigForm';
import { action, toJS, observable, when } from "mobx";
import { setTimeout } from "timers";
import H5Info from './h5Info';
import H5 from './h5/index';

interface DevConcoleProps {
  deviceStore?: DeviceStore;
  commonStore?: CommonStore;
  deviceTreeStore?: DeviceTreeStore;
}

@inject('deviceStore', 'commonStore', 'deviceTreeStore')
@observer
export default class DevConsole extends React.Component<DevConcoleProps> {

  @observable devType = 3; // default
  // @observable devConfigPara: any = {}; // default
  @observable intervalIds: any = undefined;

  constructor(props: DevConcoleProps) {
    super(props);
    const { deviceStore, deviceTreeStore, commonStore } = this.props;
    // when(
    //   () => !!Object.keys(deviceStore!.devConfigPara).length,
    //   () => this.getConfigData()
    // )
    const { arr } = getSearchParams();
    commonStore!.openWebSocket();
    document.title = decodeURI('设备控制台');
    if (arr) {
      let ar = arr.split('_');
      const devType = Number(ar[2]);
      this.changeDevType(devType);

      deviceStore!.getDevConsole(ar[0], ar[1]).then(res => {

        let d = toJS(deviceStore!.devConsoleDetailInfo);
        let channels: any[] = [];
        let newChannel: any = (d.deviceChannels || []).sort((a, b) => a.channelPos - b.channelPos);

        newChannel.map((x, index) => {
          channels.push(getVideoSrc(d.deviceId, x.channelNo, d.devType, d.devName, Date.now()));
          console.log('clickChannel', x.channelNo);
        });

        //实时视频
        setTimeout(function () {
          deviceTreeStore!.addVideoUrls(channels);
          commonStore!.subscribeSimpleDevice([{ deviceId: d.deviceId, devType: d.devType }]);
        }, 1300); //default 1000 

      }, error => {

      });
      this.intervalIds = setInterval(() => {
        deviceStore!.getDevConsole(ar[0], ar[1]);
      }, 1000 * 6);

      deviceStore!.getParamConfig(ar[0], ar[1], ar[2], ar[4]);
    }
  }

  componentDidMount() {

  }

  componentWillMount() {

  }

  componentWillUnmount() {
    console.log('devConsole page componentWillUnmount');
    const { commonStore } = this.props;
    commonStore!.closeWebSocket();
    this.intervalIds && clearInterval(this.intervalIds);
  }

  changeCmdValue = (e) => {
    console.log(e.target.value);
  }

  @action
  changeDevType = (type: number) => {
    this.devType = type;
  }

  @action
  showMsg = () => {
    return 'hello,world';
  }

  //null string will not come in
  @action
  creactPrint = (texts, print) => {
    let str = texts.join(' ');
    //document.getElementsByClassName('iQSzyY')[0].setAttribute('id','hello')
    document.getElementsByClassName('iQSzyY')[0].setAttribute('class', 'sc-htoDjs iQSzyY hides')
    print(`connect...`);
    this.shellZhiling(str, print);
  }

  //shell zhiling
  @action
  shellZhiling = (cmd: string, print) => {
    const { commonStore, deviceStore } = this.props;
    let d = toJS(deviceStore!.devConsoleDetailInfo);
    commonStore!.dispatchShell(d.deviceId, cmd, d.devType).then(res => {
      let curdata: any = res;
      const reg = /^\{.*\}$/;
      const isJSON = reg.test(curdata.content);
      let responseStr = '';
      if (isJSON) {
        let result = JSON.parse(curdata.content).ret; //true or false
        responseStr = result.replace('success:', '');
      } else {
        responseStr = curdata.content
      }

      print(<p style={{ color: '#21A3F1', maxWidth: '600px', wordWrap: 'break-word' }}>{responseStr}</p>);

      document.getElementsByClassName('iQSzyY')[0].setAttribute('class', 'sc-htoDjs iQSzyY')
      document.getElementsByClassName('fDBBfG')[0].setAttribute('id', 'iiput')
      // @ts-ignore
      document.getElementById('iiput').focus()

    }, err => {
      let errdata: any = err;
      console.log('shell error data=', errdata);
      print(<div style={{ color: 'red' }}>{`fail time out`}</div>);
      document.getElementsByClassName('iQSzyY')[0].setAttribute('class', 'sc-htoDjs iQSzyY')
      document.getElementsByClassName('fDBBfG')[0].setAttribute('id', 'iiput')
      // @ts-ignore
      document.getElementById('iiput').focus()
    });
  }

  @action
  judgeColor = () => {
    let len = document.getElementsByClassName('ihWlDo').length;
    for (let i = 0; i < len; i++) {
      let str = document.getElementsByClassName('ihWlDo')[i].innerHTML
      if (str.indexOf('fail time out') > -1) {
        document.getElementsByClassName('ihWlDo')[i].setAttribute('class', 'sc-gzVnrw ihWlDo fail');
      } else if (str.indexOf('bin etc test.log') > -1) {
        document.getElementsByClassName('ihWlDo')[i].setAttribute('class', 'sc-gzVnrw ihWlDo success');
      }
    }
    document.getElementsByClassName('iQSzyY')[0].setAttribute('class', 'sc-htoDjs iQSzyY')
  }

  // @action
  // getConfigData = () => {
  //   const { deviceStore } = this.props
  //   let curdata = toJS(deviceStore!.devConfigPara);
  //   let devType = toJS(this.devType);
  //   let arr: any = [];
  //   for (let va in curdata) {
  //     let item: any = curdata[va];
  //     const {value, ov} = item;
  //     let obj = {
  //       deviceType: devType,
  //       key: va,
  //       value: value,
  //       ov
  //     }
  //     arr.push(obj);
  //   };
  //   this.devConfigPara = arr;
  //   console.log('convert config arr=', toJS(this.devConfigPara));
  // }

  render() {
    const { deviceStore } = this.props;
    // console.log('config parxxa=', toJS(deviceStore!.devConfigPara));
    if (this.devType === 8) {
      return <H5 />
    }

    return (
      <div className={'devcConcolePage'}>
        <div className={'top'}>
          <div className={'left'}>
            {this.devType === 8
              ? <H5Info />
              : <VideoList />
            }

          </div>
          <div className={'right'}>
            <ParamButton />
          </div>
        </div>
        <div className={'bottom'}>
          <div className={'left'}>
            <div className={'configTitle'}>
              参数配置
            </div>
            <div className={'configForm'}>
              {[2, 3, 5, 6, 8].includes(this.devType) ?
                // {(eq(toJS(this.devType), 2) || eq(toJS(this.devType), 3) || eq(toJS(this.devType), 5)) ?
                <DevConfigForm
                  initValues={deviceStore!.devConfigPara}
                  readonly={false}
                  devType={this.devType} />
                : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ width: '100%' }} />
              }
            </div>
          </div>
          <div className={'right'}>
            <div className={'title'}>
              cmd
            </div>
            <div className={'console'} id={'devdos'}>
              <ReactTer
                color='white'
                prompt='white'
                promptSymbol={'>$ '}
                outputColor={'white'}
                backgroundColor='black'
                barColor='black'
                hideTopBar={true}
                allowTabs={false}
                style={{ fontWeight: "bold", fontSize: "1.4em" }}
                commandPassThrough={(cmd, print) => {
                  this.creactPrint(cmd, print);
                }}
                commands={{
                  'open-google': () => window.open('https://www.google.com/', '_blank'),
                  showmsg: this.showMsg,
                  popup: () => alert('Terminal in React'),
                }}
                description={{
                  'open-google': 'opens google.com',
                  showmsg: 'shows a message',
                  alert: 'alert', popup: 'alert'
                }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
