import React from 'react';

import { Modal, Select, Table, Pagination, Tag, Empty, message } from 'antd';
import { action, observable, toJS, runInAction, computed } from 'mobx';
import { DeviceStore, VersionStore, CommonStore } from '../../../../../src/stores';
import { inject, observer } from 'mobx-react';

interface RollbackProps {
  deviceStore?: DeviceStore,
  commonStore?: CommonStore,
  deviceId: string,
  devType: number,
}

@inject('deviceStore', 'versionStore', 'commonStore')
@observer
export default class Rollback extends React.Component<RollbackProps>{

  @observable fileNodeMap = observable.map<string, FileNode>();
  @observable selectedFileName = '';

  @computed
  get apkFileArr() {
    return Array.from(this.fileNodeMap.values());
  }

  componentDidMount() {
    this.getFiles();
  }

  @action
  getFiles = () => {
    const { commonStore, deviceId, devType } = this.props;
    const path = '/storage/emulated/0/'

    const cmdStr = `ls ${path}`;

    return commonStore!.dispatchShell(deviceId, cmdStr, devType).then(res => {
      let curdata: any = res;
      let contentStr = JSON.parse(curdata.content).ret; //true or false 
      // console.log('shell log=', contentStr);
      if (contentStr.indexOf('success:') > -1) {
        const successStr = contentStr.replace('success:', '');
        const fileArr = parseFileStr(successStr, path).filter(x => {
          return x.name.endsWith('.apk') && x.type === 'file';
          return x.type === 'file';
        });
        runInAction(() => {
          fileArr.forEach(x => {
            this.fileNodeMap.set(x.id, x);
          })
        });

      } else if (contentStr.indexOf('error:') > -1) {

      }

    }, err => {

    });
  }

  @action
  selectFile = (file: FileNode) => {
    this.selectedFileName = file.name;
  }

  rollBackApk = () => {
    const { commonStore, deviceId, devType , deviceStore} = this.props;
    const cmdStr = `pm install -r -d /storage/emulated/0/${this.selectedFileName}`;
    commonStore!.dispatchShell(deviceId, cmdStr, devType)
    message.success('下发完成!');
    deviceStore!.changeShowRollback(false)
  }

  render() {
    const {deviceStore} = this.props;

    return <Modal
      visible={true}
      title="版本回滚"
      okText="回滚"
      className="rollbackModal"
      onCancel={deviceStore!.changeShowRollback.bind(this, false)}
      onOk={this.rollBackApk}
      
    >
      {this.apkFileArr.length ?
        <ul className="apkFile">
          {this.apkFileArr.map(x => {
            const { name, } = x;
            return <li key={name}
              className={`${this.selectedFileName === name ? 'active': ''}`}
              onClick={this.selectFile.bind(this, x)}
            >{name}</li>
          })}
        </ul>
        : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      }
    </Modal>
  }
}


const parseFileStr = (str: string, pathStr: string) => {
  const fileArr: FileNode[] = [];
  str.split('\r\n').forEach(x => {
    const fileInfo = x.split(/\s+/);
    console.log('fileInfo fileInfo', x, fileInfo);
    const fileName = fileInfo[fileInfo.length - 1];
    let isDirectory = false;
    //　是文件夹的判断条件　文件信息有６段
    if (fileInfo.length === 6) {
      isDirectory = true;
    }

    const fileNode: FileNode = {
      id: `${pathStr}/${fileName}`,
      name: fileName,
      type: isDirectory ? 'directory' : 'file',
      size: isDirectory ? 0 : Number(fileInfo[3] != undefined ? fileInfo[3].replace(',', '') : 0),
      pId: pathStr,
      children: [],
    }
    const isLink = fileInfo[fileInfo.length - 2] === '->';
    if (x.length && !isLink) {
      fileArr.push(fileNode);
    }
  })

  console.log('fileArr', fileArr);
  return fileArr;
}