import http from 'axios';
import consts from '../../src/consts';
import { eq } from './mix';
import { apiPrefix } from '../../src/config';

const instance = http.create();

instance.interceptors.request.use(
  config => {
    // console.log('config', config);
    // config.headers.token = '815ad1f2c4eb41eba474638f51041680';
    if(config.url && config.url.indexOf('jm-txt/')>-1){

    }else{
      config.url = apiPrefix + config.url;
    }
    // console.log('config', config);
    // 对原生进度事件的处理
    // config.onDownloadProgress = function (progressEvent) {
    //   console.log('progressEvent', progressEvent);
    // }
    return config;
  },
  err => {

  }
);

instance.interceptors.response.use(
  (res) => {
    const { data } = res;
    const { code } = data;
    if (eq(code, consts.http_code.success) && eq(res.status, 200)) {
      return data;
    } else {
      !eq(res.status, 200) && console.error('request fail', res);
      if (eq(code, consts.http_code.unanthent)) {
        const url = window.location.href;
        if (!url.includes('/login')) {
          window.location.href = '/login';
        }
      }
      return Promise.reject(res);
    }
  },
  (err) => {
    return Promise.reject(err);
  }
);

const upload = http.create({});

upload.interceptors.request.use(
  config => {
    config.url = apiPrefix + config.url;
    config.headers = {
      'Content-Type': 'multipart/form-data;charset=UTF-8'
    }
    return config;
  },
  err => {

  }
);

upload.interceptors.response.use(
  (res) => {
    const { data } = res;
    const { code } = data;
    if (eq(code, consts.http_code.success) && eq(res.status, 200)) {
      return data;
    } else {
      console.error('request fail', res);
      if (eq(code, consts.http_code.unanthent)) {
        const url = window.location.href;
        if (!url.includes('/login')) {
          window.location.href = '/login';
        }
      }
      return Promise.reject(res);
    }
  },
  (err) => {
    return Promise.reject(err);
  }
);

const formatDataHeader = {
  'Content-Type': 'multipart/form-data;charset=UTF-8'
};

/**
 * @desc 采用formData形式进行表单提交
 * @param url 
 * @param data 数据json格式
 * @param fileListField // 文件列表的字段
 */
const formHttp = (url: string, data: Object = {}, fileListField = '') => {
  const formData = new FormData();
  Object.keys(data).map(x => {
    if (data.hasOwnProperty(x)) {
      const value = data[x];
      if (x === fileListField) {
        const fileList: File[] = Array.isArray(value) ? value : [value];
        fileList.map(file => {
          formData.append(fileListField, file);
        });
      } else {
        formData.append(x, value);
      }
    }
  })
  return instance.post(url, formData, { headers: formatDataHeader });
}

export { instance as http, upload, formHttp, formatDataHeader };


