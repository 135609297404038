import { observable, action, computed, runInAction, toJS } from "mobx";
import { http, eq, timeFormatDefaultStr, intlGet } from "src/utils";
import { PaginationData, FetchStatus } from "src/types";
import moment from "moment";
import { message } from "antd";

interface SearchParams extends PageParams {
  startTime: string;
  endTime: string;
  alarmTypes: number[];
  deviceType: number;
  mediaType: number;
  alarmLevel: number;
  orgIds: string[];
  vehicleIds: string[];
  driverIds: number[];
  minSpeed?: number;
  brake?: number;
}

interface SearchMediaParams extends PageParams {
  date: string;
  alarmType: number;
}

export class AlarmStore {
  alarmSearchParams: SearchParams = {
    startTime: "",
    endTime: "",
    alarmTypes: [],
    deviceType: -1,
    mediaType: -1,
    alarmLevel: -1,
    orgIds: [],
    vehicleIds: [],
    driverIds: [],
    page: 0,
    pageSize: 20,
    order: "",
    sort: "",
    total: 0,
  };

  // 统计过滤条件
  statType: SearchMediaParams | undefined = undefined;

  @observable alarmCount = observable.map<string, AlarmQueryRes>(); // key vehicleId;
  @observable alarmStatArr: AlarmStatInter[] = [];
  @observable.shallow oneVehAlarmMap = observable.map<string, PaginationData>(); // key vehicleId;
  alarmTypes: number[] = [];
  @observable selectedVehicleId = "";
  @observable selectedAlarmIndex: number = -1;
  @observable alarmDialogNum = "multiple"; // 告警弹框数量  单个 one 多个 multiple

  @observable fetchCountStatus: FetchStatus = "none";
  @observable fetchPageStatus: FetchStatus = "none";

  @observable selectExport = false; // 当点击选择导出时为true
  @observable exportAlarmIds = observable.map<string, any>(); // 导出时使用
  @observable checkMode = 1; // 查看模式  1 全部 2 附件;

  @action
  setCheckMode = (checkMode: number) => {
    this.checkMode = checkMode;
  };

  @action
  setAlarmDialogNum = (num: string) => {
    this.alarmDialogNum = num;
  };

  changeAlarmSearchParams = (params: Partial<SearchParams>) => {
    console.log("changeAlarmSearchParams", params);
    this.alarmSearchParams = Object.assign({}, this.alarmSearchParams, params);
  };

  changeSearchMediaParams = (
    params: Partial<SearchMediaParams> | undefined
  ) => {
    if (params) {
      this.statType = Object.assign({}, this.statType, params);
    } else {
      this.statType = params;
    }
  };

  @computed
  get alarmArr() {
    return Array.from(this.alarmCount.values());
  }

  @computed
  get totalAlarm() {
    let result = 0;
    this.alarmArr.forEach((x) => {
      const { alarmTotal = 0 } = x;
      result = result + alarmTotal;
    });
    return result;
  }

  @computed
  get exportSelectedAlarmId() {
    const ids = Array.from(this.exportAlarmIds.values());
    return ids.filter((x) => !!x);
  }

  @computed
  get vehicleAlarmData() {
    return this.oneVehAlarmMap.get(this.selectedVehicleId);
  }

  @computed
  get vehicleAlarmDataLen() {
    const vehicleData = this.vehicleAlarmData;
    return (vehicleData && vehicleData.data && vehicleData.data.length) || 0;
  }

  @action
  changeSelectExport = (select: boolean) => {
    this.selectExport = select;
    // 如果取消选择，则清空原来已选择的告警
    if (!select) {
      this.exportAlarmIds.clear();
    }
  };

  @action
  toggleSelectedAlarmId = (alarmId: string, obj) => {
    const isSelected = this.exportAlarmIds.has(alarmId);
    if (!isSelected) {
      //未勾选
      let urls: any[] = [];
      if (obj.picResource && obj.picResource.length > 0) {
        obj.picResource.map((x) => {
          urls.push(x.url);
        });
      }
      if (obj.vidResource && obj.vidResource.length > 0) {
        obj.vidResource.map((y) => {
          urls.push(y.url);
        });
      }
      let curObj = {
        plateNo: obj.plateNo,
        urls: urls,
      };
      this.exportAlarmIds.set(alarmId, curObj);
    } else {
      this.exportAlarmIds.delete(alarmId);
    }
  };

  @action
  changeSelectedAlarmIndex = (alarmIndex: number) => {
    this.selectedAlarmIndex = alarmIndex;
  };

  // @action
  // changeAlarmTypeFilter = (filter: AlarmTypeFilter | undefined) => {
  //   this.alarmTypeFilter = filter;
  // }

  @action
  changeSelectedVehicleId = (key: string) => {
    this.selectedVehicleId = key;
    this.changeSearchMediaParams(undefined);
    this.getAlarmStat();
    this.getAlarmQueryPageFromServer(key);
  };

  @action
  getAlarmQueryPageFromServer = (vehicleId: string) => {
    console.log("getAlarmQueryPageFromServer");
    const url = "alarm/page";
    const { startTime, endTime, alarmTypes, mediaType, minSpeed, brake } =
      this.alarmSearchParams;

    const { page = 0, pageSize = 20 } = this.statType || {};

    let newAlarmTypes: number[] = [];
    let newStartTime = "";
    let newEndTime = "";
    // 通过页面中间栏过滤条件（时间，类型）过滤
    if (this.statType && this.statType.date) {
      const { alarmType, date } = this.statType;
      // 没有选择类型，用表单里面的类型
      if (eq(alarmType, -1)) {
        newAlarmTypes = alarmTypes;
      } else {
        newAlarmTypes = [alarmType];
      }
      const momentDate = moment(date);
      newStartTime =
        momentDate.startOf("day").valueOf() - moment(startTime).valueOf() < 0
          ? startTime
          : momentDate.startOf("day").format(timeFormatDefaultStr);
      newEndTime =
        momentDate.endOf("day").valueOf() - moment(endTime).valueOf() > 0
          ? endTime
          : momentDate.endOf("day").format(timeFormatDefaultStr);
    } else {
      newAlarmTypes = alarmTypes;
      newStartTime = startTime;
      newEndTime = endTime;
    }
    console.log("getAlarmQueryPageFromServer alarmType2", newAlarmTypes);

    let params = {
      vehicleId,
      startTime: newStartTime,
      endTime: newEndTime,
      alarmTypes: newAlarmTypes,
      page,
      pageSize,
      orderColumn: "",
      orderDir: null,
      onlyFile: mediaType === 2 ? true : false,
      minSpeed,
      brake,
    };

    this.fetchPageStatus = "pending";
    return http.post(url, params).then(
      (res) => {
        runInAction(() => {
          let data: PaginationData = res.data;

          if (data.items) {
            data = { ...data, data: data.items };
            delete data.items;
          }

          this.oneVehAlarmMap.set(vehicleId, data);

          this.fetchPageStatus = "success";
        });
      },
      (err) => {
        runInAction(() => {
          this.fetchPageStatus = "fail";
        });
      }
    );
  };

  @action
  getAlarmStat = () => {
    const url = "alarm/dateCount";

    const { startTime, endTime, minSpeed, brake, alarmTypes } =
      this.alarmSearchParams;
    const params = {
      alarmTypes,
      startTime,
      endTime,
      vehicleId: this.selectedVehicleId,
      minSpeed,
      brake,
    };
    return http.post(url, params).then(
      (res) => {
        runInAction(() => {
          this.alarmStatArr = res.data || [];
        });
      },
      (err) => {
        console.error(`err ${url}`, err);
      }
    );
  };

  @action
  getAlarmQueryCountFromServer = () => {
    const url = "alarm/count";
    const {
      startTime,
      endTime,
      alarmTypes,
      orgIds,
      vehicleIds,
      page,
      pageSize,
      order,
      sort,
      minSpeed,
      brake,
    } = this.alarmSearchParams;
    const params = {
      startTime,
      endTime,
      alarmTypes,
      orgIds,
      vehicleIds,
      page,
      pageSize,
      orderColumn: sort,
      orderDir: order || null,
      minSpeed,
      brake,
    };

    console.log("count res.data=", params);
    this.selectedVehicleId = "";
    this.fetchCountStatus = "pending";
    return http.post(url, params).then(
      (res) => {
        runInAction(() => {
          this.alarmCount.clear();
          const { items = [], total, hasNext, hasPrevious } = res.data;
          if (!items.length) {
            message.warn(intlGet("No data was queried"));
          }
          items.forEach((x: AlarmQueryRes) => {
            const { vehicleId } = x;
            this.alarmCount.set(vehicleId, x);
          });
          // this.alarmSearchParams.total = total;
          Object.assign(this.alarmSearchParams, {
            total,
            hasNext,
            hasPrevious,
          });
          this.oneVehAlarmMap.clear();
          this.fetchCountStatus = "success";
        });
        if (this.alarmArr.length) {
          const { vehicleId } = this.alarmArr[0];
          this.changeSelectedVehicleId(vehicleId);
          // this.getAlarmStat();
        }
      },
      (err) => {
        runInAction(() => {
          this.fetchCountStatus = "fail";
        });
      }
    );
  };
}
