import {observable, action, runInAction, computed} from 'mobx';
import {copy} from 'src/utils/mix';
import { http } from 'src/utils/http';
import { FetchStatus } from 'src/types';

type ParseFunc = (d: any) => any[]
export interface ParseObj2{
  parseFunc: ParseFunc,
  key: string,
}

export class ReqData<Search, Data>{

  url: string;
  defaultSearch: Search;
  search: Search;
  parseObj: ParseObj2

  @observable dataMap = observable.map<string | number, Data>(); 
  @observable loading: FetchStatus = 'none';

  constructor(url: string, defaultSearch: Search, parseObj: ParseObj2,){
    this.url = url;
    this.defaultSearch = copy(defaultSearch);
    this.search = copy(defaultSearch);
    this.parseObj = parseObj;
  }

  @computed
  get dataArr(){
    return Array.from(this.dataMap.values());
  }

  @action
  getData = (method: string, reqParams: any) => {
    this.loading = 'pending';
    const {key, parseFunc} = this.parseObj;
    return http[method](this.url, reqParams).then(res => {

      runInAction(() => {
        const data = parseFunc(res);
        this.dataMap.clear();
        data.forEach(x => {
          this.dataMap.set(x[key], x);
        });
        this.loading = 'success'
      })

    }, err => {
      console.error('err', err);
      runInAction(() => {
        this.loading = 'fail'
      })
    })
  }
}