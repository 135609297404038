import { observable, action, computed, runInAction, toJS } from 'mobx';
import { http, eq, intlGet, isUndefinded, Log } from 'src/utils';
import { Dict, AlarmType, AlarmTree, LabelAndValue } from 'src/types';

const log = new Log('dict.store.ts');

const dictPrefix: { [key in keyof Dict]?: string } = {
  dev_exc_cause: 'fault_cause_',
  net_signal: 'net_signal_',
  gps_dir: 'gps_dir_',
  time_unit: 'time_unit_',
  acc_type: 'acc_type_',
  protocol_type:'protocol_type_',
  command_act: 'command_act_',
}

export class DictStore {
  @observable.ref dict: Dict | undefined;
  @observable.shallow alarmMap = observable.map<string, AlarmType>(); // key id
  @observable.shallow alarmMapByAlarm = observable.map<number, AlarmType>(); // key alarm

  // constructor(){
  //   this.getDictFromServer()
  // }

  getNameCodeByAlarm = (alarm: number, alias: boolean = false) => {
    const alarmItem = this.alarmMapByAlarm.get(alarm);
    if (alarmItem) {
      return alias && alarmItem.alias || alarmItem.nameCode;
    } else {
      return alarm == undefined
        ? ''
        : alarm.toString(); 
    }
  }

  getDictDataKVArr = (key: keyof Dict) => {
    const arr: LabelAndValue[] = [];
    if (this.dict) {
      const dictData = this.dict[key];
      Object.keys(dictData).map(x => {
        const item = dictData[x];
        arr.push({ value: x, label: item })
      });  
      return arr;
    } else {
      console.log('字典不存在')
    }
    return arr;
  }
 
  @computed
  get alarmRoots() {
    const alarmArr = Array.from(this.alarmMap.values());
    return alarmArr.filter(x => eq(x.alarm, 0));
  }

  @computed
  get alarmIds() {
    return Array.from(this.alarmMap.keys());
  }

  // getPlatFormConfig = (filed: string) => {
  //   return this.dict!.platform_config[filed];
  // }

  /**
   * @desc 获取对应告警类型Id
   * @param source 0 设备 1 平台
   */
  getResponseTypeAlarmIds = (source: number) => {
    let keyArr: any[] = [];
    Array.from(this.alarmMap.keys()).map(x => {
      //父节点不能要 type: "catagory",只要 ‘alarm’ && eq(this.alarmMap.get(x)!.type,'alarm')
      if (eq(source, Number(this.alarmMap.get(x)!.source))) {
        keyArr.push(x);
      }
    });
    return keyArr;
  }

  //设备0，平台1
  getAlarmBySource = (source: number) => {
    let obj = {};
    console.log('this.alarmMap.values()=', toJS(Array.from(this.alarmMap.values())))
    Array.from(this.alarmMap.keys()).map(x => {
      if (eq(source, Number(this.alarmMap.get(x)!.source))) {
        obj[x] = false;
      }
    });
    return obj;
  }

  getAlarmListBySourceAndBase = (source: number, base?: 0 | 1) => {
    return Array.from(this.alarmMap.values()).
      filter(x => {
        return eq(Number(x.source), source) && !eq(x.pid, '0') &&
          (isUndefinded(base) ? true : eq(x.base, base))
      });
  }

  isActiveSafety = (alarm: number) => {
    const alarmData = this.alarmMapByAlarm.get(alarm);
    let res: boolean;
    if (alarmData) {
      res = !!alarmData.active;
    } else {
      log.data(`alarm: ${alarm} 不在告警字典中！`);
      res = false;
    }
    return res;
  }


  @computed
  get alarmTree() {
    const alarmObj: { [id: string]: AlarmTree } = {};
    const alarmArr = toJS(Array.from(this.alarmMap.values()));
    alarmArr.forEach(x => {
      const { nameCode, id } = x;
      alarmObj[x.id] = { ...x, ...{ value: id, key: id, title: intlGet(nameCode) || nameCode } }
    });

    const tree: (AlarmTree)[] = [];
    const mountAlarm = (m: AlarmType) => {
      const { pid, id, nameCode } = m;
      const alarm = alarmObj[id];
      const pAlarm = alarmObj[pid];

      // 一级菜单
      if (!pAlarm) {
        tree.push(alarm);
      } else {
        pAlarm.children = pAlarm.children || [];
        pAlarm.children.push(alarm);
      }
    }
    alarmArr.forEach(m => mountAlarm(m));
    console.log('mountAlarm', tree);
    return tree;
  }

  getAlarmByIds = (ids: string[]) => {
    return ids.map(x => this.alarmMap.get(x)!)
  }

  /**
   * @desc 字典翻译
   * @param type 字典中的类型
   * @param key  需要翻译的键值
   * @param i18n 是否用国际化翻译
   * @param defultKeyValue 默认的键值对 如{-1: ''} 表示如果value为-1则翻译为‘’；
   */
  transfrom = (type: keyof Dict, key: string | number, i18n = true, defultKeyValue = {}) => {
    let defualtValue = defultKeyValue[key];
    if (!isUndefinded(defualtValue)) { return defualtValue };

    const dictStr = this.dict![type][key];
    const isDictStrValid = !isUndefinded(dictStr);
    defualtValue = !isUndefinded(defultKeyValue[dictStr]) ? defultKeyValue[dictStr] : '';
    if (i18n) {
      let prefix = dictPrefix[type] || '';
      return isDictStrValid ? intlGet(prefix + dictStr) : defualtValue;
    } else {
      return isDictStrValid ? dictStr : defualtValue;
    }

  }

  /**
   * @desc 从后台获取字典
   */
  @action
  getDictFromServer = () => {
    // const url = 'platform/comm/dict';
    const url="dictvalue/detail";
    return http.get(url).then(
      res => {
        console.log('dict res', res);
        runInAction(() => {
          this.dict = res.data;
        })
      },
      err => {
        console.log('err', err);
      }
    )
  }

  @action
  getAlarmTreeFromServer = () => {
    const url = 'comm/tree/alarm';
    return http.get(url).then(res => {
      runInAction(() => {
        res.data.map(x => {
          const { id, alarm } = x;
          this.alarmMap.set(id, x);
          if (!eq(id, '0')) {
            this.alarmMapByAlarm.set(alarm, x);
          }
        });
      })
    })
  }

}
