const configType = {
  3: {
    1: "基础配置",
    2: "设备接入配置",
    3: "部标配置",
    4: "报警上传配置",
    5: "报警开关配置",
    6: "报警速度阈值设置",
    7: "报警灵敏度设置",
    8: "超速报警设置",
    9: "超时报警设置",
    10: "辅助驾驶报警时间间隔",
    11: "疲劳驾驶报警时间间隔",
    12: "定制功能参数",
  },
  6: {
    1: "基础配置",
    2: "设备接入配置",
    3: "部标配置",
    4: "报警上传配置",
    5: "报警开关配置",
    6: "报警速度阈值设置",
    7: "报警灵敏度设置",
    8: "超速报警设置",
    9: "超时报警设置",
    10: "辅助驾驶报警时间间隔",
    11: "疲劳驾驶报警时间间隔",
    12: "定制功能参数",

  },
  2: {
    1: "基础配置",
    2: "设备接入配置",
    3: "部标配置",
    4: "报警上传配置",
    5: "报警开关配置",
    6: "报警速度阈值设置",
    8: "超速报警设置",
    9: "超时报警设置",
    12: "疲劳驾驶报警灵敏度设置",
    13: "疲劳驾驶报警时间间隔",
  },
  5: {
    1: "基础配置",
    2: "设备接入配置",
    3: "部标配置",
    4: "报警上传配置",
    5: "报警开关配置",
    6: "报警速度阈值设置",
    7: "报警灵敏度设置",
    8: "超速报警设置",
    9: "超时报警设置",
    10: "辅助驾驶报警时间间隔",
    11: "疲劳驾驶报警时间间隔",
  },
  8: {
    1: "基础配置",
  },
  9: {
    1: "基础配置",
  },
  10: {
    1: "基础配置",
  },
}

Object.keys(configType).forEach(devType => {
  if(devType !== '8'){
    const parents = configType[devType];
    parents[999] = '新增参数';
  }
})

export default configType;

export const alarmType_dms_h2 = {
  "Closed_Eye_DS": 1,
  "Yawning_DS": 2,
  // "Sleep_Open_Eye_DS": 3,
  "Down_Head_DS": 4,
  "LOOK_AROUND_DS": 5,
  "NO_FACE_DS": 6,
  "PHONING_DS": 7,
  "SMOKING_DS": 8,
  "OCCLUSION_DS": 9,
  "Up_Head_DS": 10,
  "Unfasten_Seatbelt_DS": 11,
  "HAND_OFF_DS": 12,
  "HAND_SINGLE_OFF_DS": 13,
  "INFRARED_BLOCKING_DS": 14
}
export const alarmType_adas_h2 = {
  "UpLdw": 1,
  "UpHmw": 2,
  "UpTTC": 3,
  "UpPDW": 4,

  "UpAebPedL1": 5,
  "UpAebPedL2": 6,
  "UpAebPedL3": 7,

  "UpAebVehL1": 8,
  "UpAebVehL2": 9,
  "UpAebVehL3": 10,

}

export const cvbsCh_h3 = {
  "adas": 1,
  "dms": 2,
  "ipc1": 3,
  "ipc2": 4,
  "ipc3": 5,
}

export const getNum_h3 = (alarms) => {
  var type = 0;
  for (var i = 0; i < alarms.length; i++) {
    var a = cvbsCh_h3[alarms[i]];
    type += 1 << a;
  }
  return type;
}

export const getcvbsCh_h3 = (result) => {
  var alarm: string[] = [];
  if (((result >> 1) & 0x1) == 1) {
    alarm.push("adas");
  }
  if (((result >> 2) & 0x1) == 1) {
    alarm.push("dms");
  }

  if (((result >> 3) & 0x1) == 1) {
    alarm.push("ipc1");
  }
  if (((result >> 4) & 0x1) == 1) {
    alarm.push("ipc2");
  }

  if (((result >> 5) & 0x1) == 1) {
    alarm.push("ipc3");
  }

  return alarm;
}

export const alarmType_all_h2 = {
  "Ldw": 1,
  "Hmw": 2,
  "TTC": 3,
  "PDW": 4,
  "slide": 5,
  "follow": 6,

  "Closed_Eye_DS": 7,
  "Yawning_DS": 8,
  "Down_Head_DS": 9,
  "LOOK_AROUND_DS": 10,
  "NO_FACE_DS": 11,
  "PHONING_DS": 12,
  "SMOKING_DS": 13,
  "OCCLUSION_DS": 14,
  "Up_Head_DS": 15,
  "Unfasten_Seatbelt_DS": 16,
  "HAND_OFF_DS": 17,
  // "HAND_SINGLE_OFF_DS": 13,
  "INFRARED_BLOCKING_DS": 18
}

export const getNum_all = (alarms) => {
  var type = 0;
  for (var i = 0; i < alarms.length; i++) {
    var a = alarmType_all_h2[alarms[i]];
    type += 1 << a;
  }
  return type;
}

export const getNum_dms = (alarms) => {
  var type = 8;
  for (var i = 0; i < alarms.length; i++) {
    var a = alarmType_dms_h2[alarms[i]];
    type += 1 << a;
  }
  return type;
}

export const getNum_adas = (alarms) => {
  var type = 0;
  for (var i = 0; i < alarms.length; i++) {
    var a = alarmType_adas_h2[alarms[i]];
    type += 1 << a;
  }
  return type;
}

export const getAlarm_dms = (result) => {
  var alarm: string[] = [];
  if (((result >> 1) & 0x1) == 1) {
    alarm.push("Closed_Eye_DS");
  }
  if (((result >> 2) & 0x1) == 1) {
    alarm.push("Yawning_DS");
  }
  // if (((result>>3) & 0x1) == 1) {
  //     alarm.push("Sleep_Open_Eye_DS");
  // }
  if (((result >> 4) & 0x1) == 1) {
    alarm.push("Down_Head_DS");
  }
  if (((result >> 5) & 0x1) == 1) {
    alarm.push("LOOK_AROUND_DS");
  }
  if (((result >> 6) & 0x1) == 1) {
    alarm.push("NO_FACE_DS");
  }
  if (((result >> 7) & 0x1) == 1) {
    alarm.push("PHONING_DS");
  }
  if (((result >> 8) & 0x1) == 1) {
    alarm.push("SMOKING_DS");
  }
  if (((result >> 9) & 0x1) == 1) {
    alarm.push("OCCLUSION_DS");
  }
  if (((result >> 10) & 0x1) == 1) {
    alarm.push("Up_Head_DS");
  }
  if (((result >> 11) & 0x1) == 1) {
    alarm.push("Unfasten_Seatbelt_DS");
  }
  if (((result >> 12) & 0x1) == 1) {
    alarm.push("HAND_OFF_DS");
  }
  if (((result >> 13) & 0x1) == 1) {
    alarm.push("HAND_SINGLE_OFF_DS");
  }
  if (((result >> 14) & 0x1) == 1) {
    alarm.push("INFRARED_BLOCKING_DS");
  }
  return alarm;
}

export const getAlarm_adas = (result) => {
  var alarm: string[] = [];
  if (((result >> 1) & 0x1) == 1) {
    alarm.push("UpLdw");
  }
  if (((result >> 2) & 0x1) == 1) {
    alarm.push("UpHmw");
  }

  if (((result >> 3) & 0x1) == 1) {
    alarm.push("UpTTC");
  }
  if (((result >> 4) & 0x1) == 1) {
    alarm.push("UpPDW");
  }

  if (((result >> 5) & 0x1) == 1) {
    alarm.push("UpAebPedL1");
  }
  if (((result >> 6) & 0x1) == 1) {
    alarm.push("UpAebPedL2");
  }
  if (((result >> 7) & 0x1) == 1) {
    alarm.push("UpAebPedL3");
  }
  if (((result >> 8) & 0x1) == 1) {
    alarm.push("UpAebVehL1");
  }
  if (((result >> 9) & 0x1) == 1) {
    alarm.push("UpAebVehL2");
  }
  if (((result >> 10) & 0x1) == 1) {
    alarm.push("UpAebVehL3");
  }

  return alarm;
}

// const alarmType_all_h2_obj = {}
// for (let key in alarmType_all_h2) {
//   let value = alarmType_all_h2[key];
//   alarmType_all_h2_obj[value] = key;
// }
export const getAlarm_all = (result) => {
  var alarm: string[] = [];
  for (let key in alarmType_all_h2) {
    let value = alarmType_all_h2[key];
    if (((result >> value) & 0x1) == 1) {
      alarm.push(key);
    }
  }

  return alarm;
}