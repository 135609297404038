import React from 'react';
import { inject, observer } from "mobx-react";
import 'xterm/css/xterm.css'
import { getSearchParams, eq } from '../../../src/utils/mix';
import { DeviceStore, CommonStore, DeviceTreeStore } from '../../../src/stores'

import './index.scss';
import { action, toJS, observable, when } from "mobx";
import H5 from './h5/index';
import DevConsole from './dev';
import { h5DevTypes } from 'src/consts';

interface DevConcoleProps {
  deviceStore?: DeviceStore;
  commonStore?: CommonStore;
  deviceTreeStore?: DeviceTreeStore;
}

@inject('deviceStore', 'commonStore', 'deviceTreeStore')
@observer
export default class DevConsolePage extends React.Component<DevConcoleProps> {

  @observable devType = 3; // default

  constructor(props: DevConcoleProps) {
    super(props);

    const { arr } = getSearchParams();
    if (arr) {
      let ar = arr.split('_');
      const devType = Number(ar[2]);
      this.devType = devType;
    }
  }

  render() {
    if (h5DevTypes.includes(this.devType)) {
      return <H5 />
    }else{
      return <DevConsole />
    }
  }
}
