import React, { Component } from 'react';
import { Form, Select, Button, Input, DatePicker } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { action, observable } from 'mobx';
import moment from 'moment';
import 'moment/locale/zh-cn'
moment.locale('zh-cn')

const Option = Select.Option;
const { RangePicker } = DatePicker;
interface FilterProps extends FormComponentProps {
  loading: boolean;
  func:()=>void;
}

class Filter extends React.Component<FilterProps>{

  constructor(props: FilterProps) {
    super(props);
  }

  @observable mark:boolean = false;

  @action
  requestQuery = () => {
    const {func} = this.props;
    func();
  }

  @action
  handleSearchSubmit = (e) => {
    const { form, loading } = this.props;
    // e.preventDefault();
    return new Promise((resolve, reject) => {
      form.validateFields((err, values) => {
        if (err) {
          reject(err);
          console.log('form validateFields err', err);
        } else {
          const timeFormatDefaultStr = 'YYYY-MM-DD HH:mm:ss';
          if (values.startEndTime) {
            const [startTime, endTime]: [moment.Moment, moment.Moment] = values.startEndTime
            values.startTime = startTime.format(timeFormatDefaultStr);
            values.endTime = endTime.format(timeFormatDefaultStr);
          }
          console.log('form validateFields values', values);
          resolve(values);
        }
      })
    })
  }

  @action
  resetForm = (e) => {
    const { form } = this.props;
    form.resetFields();

  }

  render() {
    const { form, loading } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <Form >
        <Form.Item >
          {getFieldDecorator('startEndTime', {
            rules: [
              { required: false, },
            ],
          })(
            <RangePicker
              // disabledDate={false}
              // disabledTime={false}
              showTime={{
                hideDisabledOptions: true,
                defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')],
              }}
              format="YYYY-MM-DD HH:mm:ss"
            />
          )}
        </Form.Item>

        <Form.Item >
          {getFieldDecorator('result', {
            rules: [
              { required: false, },
            ],
          })(
            <Select
              // defaultValue={devManageStore!.deviceType}
              style={{ width: 130 }}
              placeholder={'产测结果'}
              // onChange={this.handleDeviceTypeSelect}
              allowClear={true}
            >
              {[{
                number: 'PASS',
                suggest: '成功',
              },{
                number: 'FAIL',
                suggest: '失败',
              }].map(x => {
                return <Option key={x.number} value={x.number}>{x.suggest}</Option>
              })
              }
            </Select>
          )}
        </Form.Item>

        <Button
          className='search'
          icon="search"
          type='primary'
          onClick={this.requestQuery.bind(this)}
          loading={loading}>{'查询'}</Button>

        <Button
          className='search'
          icon="redo"
          type='primary'
          style={{ marginLeft: '10px' }}
          onClick={this.resetForm}
        >{'重置'}</Button>

      </Form>
    )
  }
}

export default Form.create<FilterProps>()(Filter);