import React, { Component } from 'react';
import './index.scss';
import { Modal, Select, Table, Pagination, Tag } from 'antd';
import { action, observable, toJS } from 'mobx';
import { DeviceStore, VersionStore } from '../../../../../src/stores';
import { inject, observer } from 'mobx-react';
import Filter from './filter';
import { mountainToLine,  getSearchParams, formatTime } from '../../../../utils/mix';

const Option = Select.Option;
interface devMonitorProps {
  changeDevMonitor: () => void;
  deviceStore?: DeviceStore;
  versionStore?: VersionStore;
}

@inject('deviceStore', 'versionStore')
@observer
export default class TestHistory extends React.Component<devMonitorProps>{

  imei = '';

  constructor(props) {
    super(props);

    const {imei} = getSearchParams();
    this.imei = imei
    this.firstQuery();
  };

  componentDidMount() {

  }

  @observable tableLoading: boolean = false;
  @observable total: number = 0;
  @observable tableData: any[] = [];
  @observable filterPara: any = {};
  @observable page: number = 0;
  @observable pageSize: number = 20;
  @observable orderItem: string = '';
  @observable orderDirection: string = '';

  @observable formRef: any = undefined;

  colums: any[] = [
    {
      align: 'center',
      title: 'id',
      width: 100,
      dataIndex: "id",
    },
    {
      align: 'center',
      title: '产测结果',
      width: 100,
      dataIndex: "totalResult"
    },
    {
      align: 'center',
      title: '产测时间',
      width: 200,
      dataIndex: "checkTime",
      render: (text, record) => {
        return formatTime(text, 1)
      }
    },
    {
      align: 'center',
      title: 'imei号',
      width: 150,
      dataIndex: "imei"
    },
    {
      align: 'center',
      title: 'gps模组状态',
      width: 150,
      dataIndex: "gps"
    },
    {
      align: 'center',
      title: '4G模组状态',
      width: 150,
      dataIndex: "lteStatus"
    },
    {
      align: 'center',
      title: 'WiFi模组状态',
      width: 150,
      dataIndex: "wifi"
    },
    {
      align: 'center',
      title: '右转状态',
      width: 100,
      dataIndex: "turnRight"
    },
    {
      align: 'center',
      title: '左转状态',
      width: 100,
      dataIndex: "turnLeft"
    },
    {
      align: 'center',
      title: '速度',
      width: 100,
      dataIndex: "speed"
    },
    {
      align: 'center',
      title: '陀螺仪',
      width: 250,
      dataIndex: "gyroscope"
    },
  ];


  //改变过滤条件
  @action
  changeFilterPara = (para) => {
    this.filterPara = para;
  }

  @action
  firstQuery = () => {
    let para: any = {
      imei: this.imei,
      page: this.page,
      pageSize: this.pageSize,
      orderItem: this.orderItem,
      orderDirection: this.orderDirection,
    };
    this.getTable(para);
  }

  @action
  queryTable = () => {
    const { versionStore } = this.props;
    let temp = toJS(this.filterPara);
    this.formRef && this.formRef.handleSearchSubmit().then(res => {
      temp = res;
      console.log('temp=', temp);

      const result = temp.result || '';
      const startTime = temp.startTime ? temp.startTime : undefined;
      const endTime = temp.endTime ? temp.endTime : undefined;
      const key = temp.fuzzy ? temp.fuzzy.trim() : undefined;
      let para: any = {
        startTime: startTime,
        endTime: endTime,
        page: this.page,
        pageSize: this.pageSize,
        imei: this.imei,
        result,
        orderColumn: this.orderItem,
        orderDir: this.orderDirection,
      };
      this.getTable(para);
    }, err => {

    });
  }

  @action
  getTable = (para) => {
    const { versionStore } = this.props
    this.tableLoading = true;
    versionStore!.queryTestHistoryTable(para).then(rs => {
      const res: any = rs;
      this.tableData = res.data.items;
      this.total = res.data.total;
      this.tableLoading = false;
    }, err => {
      this.tableLoading = false;
    });
  }

  @action
  closeModal = () => {
    const { changeDevMonitor } = this.props;
    changeDevMonitor();
  }

  @action
  changePageNum = (num: number) => {
    this!.page = num - 1;
    this.queryTable();
    console.log('changeNum', num);
  }

  @action
  changPageSize = (pageSize: number) => {

    this!.pageSize = pageSize;
    this!.page = 0;
    this.queryTable();
  }

  @action
  tableChange = (pagination, filters, sorter) => {
    const { deviceStore } = this.props;
    console.log('tableChange', pagination, filters, sorter);
    if (sorter && sorter.order) {
      this.orderItem = mountainToLine(sorter.field);
      if (sorter.order == 'descend') {
        this.orderDirection = 'desc';
      } else {
        this.orderDirection = 'asc';
      };
      this.page = 0;

      this.queryTable();
    }
  }

  getScrollHeight = () => {
    const { } = this.props;
    if (window.innerWidth < 1630) {
      return 'calc(100vh - 340px)';
    } else {
      return 'calc(100vh - 340px)';
    }
  }

  render() {

    return (
      <Modal
        width={1200}
        visible={true}
        title={'产测历史'}
        mask={false}
        maskClosable={false}
        onCancel={this.closeModal.bind(this)}
        footer={null}
        className='devMonitor'>
        <div className='filter'>
          <Filter
            wrappedComponentRef={(form) => {
              this.formRef = form;
            }}
            loading={this.tableLoading}
            func={this.queryTable}
          />
        </div>
        <div className='tableDown'>
          <Table
            className="antdTableAndPagination"
            scroll={{ x: 1530, y: 400 }}
            rowKey="id"
            pagination={false}
            onChange={this.tableChange}
            loading={{
              tip: '加载中' + '...',
              spinning: this!.tableLoading
            }}
            bordered={true}
            size="small"
            dataSource={toJS(this!.tableData)}
            columns={this.colums} />

          {(toJS(this!.tableData).length > 0) &&
            <Paginations
              changPageSize={this.changPageSize}
              pageNum={this!.page + 1}
              pageSize={this!.pageSize}
              total={this!.total}
              changePageNum={this.changePageNum} />
          }
        </div>

      </Modal>
    )
  }
}

interface PageProps {
  pageNum: number;
  pageSize: number;
  total: number;
  changePageNum: (num: number) => void;
  changPageSize: (pageSize: number) => void;
}

@observer
class Paginations extends React.Component<PageProps> {

  constructor(props) {
    super(props);
  };

  @action
  onChange = (page) => {
    const { changePageNum, } = this.props;
    // console.log(page);
    changePageNum(page);
  }

  showTotal = (total) => {
    return '总共' + total + '条';
  }


  @action
  onShowSizeChange = (current, pageSizes) => {
    const { changPageSize } = this.props;
    console.log('onShowSizeChange', current, pageSizes);
    changPageSize(pageSizes);
  }

  render() {
    return <Pagination
      pageSizeOptions={['20', '50', '100']}
      showSizeChanger
      showQuickJumper={false}
      onShowSizeChange={this.onShowSizeChange}
      pageSize={this.props.pageSize}
      current={this.props.pageNum}
      onChange={this.onChange}
      total={this.props.total}
      showTotal={this.showTotal}
    />;
  }
}
