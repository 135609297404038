import { AuthStore } from "./auth.store";
import { LoginStore } from "./login.store";
import { CommonStore } from "./common.store";
import { UiStore } from "./ui.store";
import { DeviceTreeStore } from "./deviceTree.store";
import { DeviceStore } from "./device.store";
import { VersionStore } from "./version.store";
import { HomeStore } from "./home.store";
import { ProductTestStore } from "./productTest.store";
import { VersionHisStore } from "./versionHis.store";
import { DictStore } from "./dict.store";
import { TabStore } from "./tab.store";
import { AlarmStore } from "./alarmQuery.store";
import { CheckCarStore } from "./checkCar.store";
import { ComDeviceStore } from "./comDevice.store";

export * from "./ui.store";
export * from "./deviceTree.store";
export * from "./auth.store";
export * from "./login.store";
export * from "./common.store";
export * from "./version.store";
export * from "./project.store";
export * from "./home.store";
export * from "./productTest.store";
export * from "./versionHis.store";
export * from "./device.store";
export * from "./auth.store";
export * from "./dict.store";
export * from "./tab.store";
export * from "./alarmQuery.store";
export * from './checkCar.store';
export * from './comDevice.store';
//singleton
export class SingletonStore {
  authStore = new AuthStore();
  loginStore = new LoginStore();
  commonStore = new CommonStore();
  uiStore = new UiStore();
  dictStore = new DictStore();
  tabStore = new TabStore();
}

//multiple
export class MultipleStore {
  deviceTreeStore = new DeviceTreeStore();
  deviceStore = new DeviceStore();
  versionStore = new VersionStore();
  homeStore = new HomeStore();
  productTestStore = new ProductTestStore();
  versionHisStore = new VersionHisStore();
  alarmStore = new AlarmStore();
  checkCarStore = new CheckCarStore();
  comDeviceStore = new ComDeviceStore();
}
