import React from 'react';
import './index.scss';
import { inject, observer, Provider } from "mobx-react";
import { Button, message, Modal, Input, Select, Icon, TreeSelect, Empty, Spin, Tree } from "antd";
import { intlGet, eq, copy,  } from "../../../src/utils/mix";
import { CommonStore, DeviceStore, UiStore, AuthStore } from '../../../src/stores';
import { action, observable, runInAction, toJS, computed, when, } from "mobx";
import { MessageType, WSResponse, } from '../../../src/models';
import LabelAndValue from 'src/components/labelAndValue';
import { h5DevTypes } from 'src/consts';

const { TreeNode } = TreeSelect;
const { TreeNode: TreeNod } = Tree;


interface FileManagerProps {
  deviceStore?: DeviceStore,
  commonStore?: CommonStore,
}

const mountTree = (arrData: FileNode[]) => {
  console.log('mountTree', arrData);
  // 一份新的键值数据
  const dataObj: { [layout: string]: FileNode } = {};
  arrData.forEach(x => {
    dataObj[x.id] = copy(x);
  })

  let treeData: FileNode[] = [];
  const mountFunc = (d: FileNode) => {
    const { id, pId } = d;
    const nodeData = dataObj[id];
    const pNodeData = dataObj[pId];

    // 一级菜单
    if (!pNodeData) {
      treeData.push(nodeData)
    } else {
      pNodeData.children = pNodeData.children || [];
      pNodeData.children.push(nodeData)
    }

  }
  arrData.forEach(x => mountFunc(x));
  return treeData;
}

/**
 * 
 * @param str 
 * @param pathStr 
 * @param devType 8 h5
 * @returns 
 */
 const parseFileStr = (str: string, pathStr: string, devType: number) => {
  const fileArr: FileNode[] = [];
  const splitStr = str.includes('\r\n') ? '\r\n' : '\n'
  str.split(splitStr).forEach(x => {
    const fileInfo = x.split(/\s+/);
    console.log('fileInfo fileInfo', x, fileInfo);
    let fileName = '';
    let isLink = fileInfo[0].startsWith('l');
    if(isLink){
      const arrowIndex = fileInfo.indexOf('->');
      fileName = fileInfo[arrowIndex - 1]
    }else{
      fileName = fileInfo[fileInfo.length - 1];
    }

    let isDirectory = isLink || fileInfo[0].startsWith('d');
    const sizeIndex = (h5DevTypes.includes(devType))? 4: 3;
    const fileNode: FileNode = {
      id: `${pathStr}/${fileName}`,
      name: fileName,
      type: isDirectory ? 'directory' : 'file',
      size: isDirectory ? 0 : Number(fileInfo[sizeIndex] != undefined ? fileInfo[sizeIndex].replace(',', '') : 0),
      pId: pathStr,
      children: [],
    }
    // const isLink = fileInfo[fileInfo.length - 2] === '->';
    if (x.length && !['.', '..'].includes(fileName)) {
      fileArr.push(fileNode);
    }
  })

  console.log('fileArr', fileArr);
  return fileArr;
}

const formatFileSize = (size: number) => {
  return (size === 0
    ? 0
    : (size >= 1024
      ? (size / 1024).toFixed(0)
      : (size / 1024).toFixed(2)
    )
  ) + 'kb';
}


@inject('deviceStore', 'commonStore')
@observer
class FileManager extends React.Component<FileManagerProps>{
  
  unSubscribeWS: Function[] = [];
  @observable fileNodeMap = observable.map<string, FileNode>();
  @observable selectedFileId = '';
  @observable fetchedIds: string[] = [];
  @observable remain = 0;

  timer: NodeJS.Timeout | undefined;


  @computed
  get fileNodeArr() {
    return Array.from(this.fileNodeMap.values());
  }

  @computed
  get fileNodeTree() {
    return mountTree(this.fileNodeArr);
  }

  @computed
  get selectedFile() {
    return this.fileNodeMap.get(this.selectedFileId);
  }

  componentDidMount() {
    const { commonStore, deviceStore } = this.props;

    const unSubscribeFun = commonStore!.subscribeWSEvent(2005, this.receiveWS2005);
    this.unSubscribeWS.push(unSubscribeFun);
    when(() => {
      console.log('FileManager when devConsoleDetailInfo', toJS(deviceStore!.devConsoleDetailInfo))
      return toJS(deviceStore!.devConsoleDetailInfo);
    }, () => {
      setTimeout(() => {
        this.getFiles('');
      }, 500);
    })
  }

  @action
  componentWillUnmount() {
    const { deviceStore } = this.props;
    this.unSubscribeWS.forEach(x => x());
    deviceStore!.grabbingFile = 0;
  }

  @action
  stopGrab = () => {
    this.remain = 0;
    this.timer && clearInterval(this.timer);
  }

  @action
  timing = () => {
    this.remain = 60;
    this.timer = setInterval(() => {
      runInAction(() => {
        this.remain = this.remain - 1;
        if (this.remain <= 0) {
          this.stopGrab();
        }
      })
    }, 1000)
  }

  @action
  receiveWS2005 = (type: MessageType, data: WSResponse) => {
    const { deviceStore } = this.props;
    if (eq(type, 2005)) {
      const result = data.data as any;
      // console.log('receive receiveLogRealInfo =', data);
      if (eq(data.code, 0) && deviceStore!.grabbingFile == 1) {
        deviceStore!.grabbingFile = 0;
        this.remain = 0;
        if (result.url) {
          if (this.selectedFile) {
            this.selectedFile.downLoadUrl = result.url;
            this.fetchedIds.push(this.selectedFileId);
          }

        }
      }
    }
  }

  setFiles = (fileStr: string, path: string, devType: number) => {
    const fileArr = parseFileStr(fileStr, path, devType);
    runInAction(() => {
      fileArr.forEach(x => {
        this.fileNodeMap.set(x.id, x);
      })
    });
  }

  @action
  getFiles = (path: string) => {
    console.log('getFiles');
    const { commonStore, deviceStore } = this.props;
    let d = toJS(deviceStore!.devConsoleDetailInfo);
    const cmdStr = `ls -la ${path}/`;

    const devType = d.devType;
    return commonStore!.dispatchShell(d.deviceId, cmdStr, devType).then(res => {
      let curdata: any = res;
      if (curdata.content) {
        const reg = /^\{.*\}$/;
        const isJSON = reg.test(curdata.content);
        if (isJSON) {
          let contentStr = JSON.parse(curdata.content).ret;
          // console.log('shell log=', contentStr);
          if (contentStr.indexOf('success:') > -1) {
            const successStr = contentStr.replace('success:', '');
            // const fileArr = parseFileStr(successStr, path);
            // runInAction(() => {
            //   fileArr.forEach(x => {
            //     this.fileNodeMap.set(x.id, x);
            //   })
            // });
            this.setFiles(successStr, path, devType);
          } else if (contentStr.indexOf('error:') > -1) {

          }
        } else {
          const successStr = curdata.content;
          this.setFiles(successStr, path, devType);
        }
      }
      document.getElementsByClassName('iQSzyY')[0].setAttribute('class', 'sc-htoDjs iQSzyY')
      document.getElementsByClassName('fDBBfG')[0].setAttribute('id', 'iiput')
      // @ts-ignore
      document.getElementById('iiput').focus()

    }, err => {
      let errdata: any = err;
      // console.log('shell error data=', errdata);
      document.getElementsByClassName('iQSzyY')[0].setAttribute('class', 'sc-htoDjs iQSzyY')
      document.getElementsByClassName('fDBBfG')[0].setAttribute('id', 'iiput')
      // @ts-ignore
      document.getElementById('iiput').focus()
    });
  }

  onLoadData = treeNode => {
    console.log('onLoadData', treeNode);
    const { nodeId } = treeNode.props;
    return this.getFiles(nodeId);
  }

  renderTreeNodes = (data: FileNode[]) =>
    data.map(item => {
      const { id, name, type, size, children } = item;
      const formatSize = formatFileSize(size);

      if (type === 'directory') {
        return (
          <TreeNod title={name} key={id} nodeId={id} >
            {this.renderTreeNodes(children!)}
          </TreeNod>
        );
      }
      return <TreeNode key={id} title={`${name} ${formatSize}`} nodeId={id} isLeaf={true} />;
    });

  @action
  clickTreeNode = (selectedKeys: string[]) => {
    const { deviceStore } = this.props;
    console.log('clickTreeNode', selectedKeys);
    const fileId = selectedKeys[0];
    const fileNode = this.fileNodeMap.get(fileId);
    if (fileNode) {
      const { type, } = fileNode;
      this.selectedFileId = fileId;
      // if (type === 'file') {
      // }
    }
  }

  @action
  grabFile = (file: FileNode) => {
    if (file.downLoadUrl) {
      message.warn('文件已经抓取过!');
      return;
    }
    const { commonStore, deviceStore } = this.props;
    let d = toJS(deviceStore!.devConsoleDetailInfo);
    deviceStore!.grabbingFile = 1;
    commonStore!.logScrapFile(d.deviceId, file.id, d.devType)
    this.timing();
  }

  downloadFile = (url: string) => {
    window.open(url, '_blank');
  }

  render() {
    const { commonStore, deviceStore } = this.props;
    const selectedFile = toJS(this.selectedFile);
    const canFetch = selectedFile && (selectedFile.type === 'file');
    const remainStr = this.remain === 0
      ? ''
      : `(${this.remain})`
    const loading = deviceStore!.grabbingFile == 1 && this.remain !== 0;

    return <div className="fileManagerCom"
    >
      <div className="leftTree">
        <Tree loadData={this.onLoadData}
          onSelect={this.clickTreeNode}
        >
          {this.renderTreeNodes(this.fileNodeTree)}
        </Tree>
      </div>
      <div className="fileInfo">
        <div className="title ">
          文件列表
        </div>
        <div className="fileList">
          {this.fetchedIds.map(x => {
            const file = this.fileNodeMap.get(x)!;
            return <div className="fileItem" key={x}>
              <div className="top">
                <LabelAndValue label="文件名" value={file.name} />
                <Icon type="download"
                  onClick={this.downloadFile.bind(this, file.downLoadUrl!)}
                ></Icon>
              </div>
              <LabelAndValue label="文件路径" value={file.id} />
              <LabelAndValue label="文件大小" value={formatFileSize(Number(file.size))} />
            </div>
          })
          }
        </div>
        <div className="bottom">
          <Button
            type="primary"
            className="fetchBtn"
            disabled={!canFetch}
            onClick={this.grabFile.bind(this, selectedFile!)}
            loading={loading}
          >抓取文件{remainStr}</Button>
          <Button icon="poweroff" type="danger" onClick={this.stopGrab}
            className="stop"
            disabled={!loading}
          >

          </Button>
        </div>
      </div>

    </div>
  }
}

export default FileManager;