import React from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { ProductTestStore, } from 'src/stores';
import './index.scss';

import AfterCom from './after';
import BeforeCom from './before';
import { getSearchParams } from 'src/utils/mix';

interface ProductTestDetailPageProps {
  productTestStore?: ProductTestStore,
}

@inject('productTestStore')
@(withRouter as any)
@observer
export default class ProductTestDetailPage extends React.Component<ProductTestDetailPageProps>{


  componentDidMount(){
    const {productTestStore} = this.props;
    const {viewId, category} = getSearchParams();
    productTestStore!.getDetail(viewId, category)
    
  }
  
  render() {
    const { category} = getSearchParams();
    const isAfter = category === '1' || category === '5';

    return <div className="productTestDetailPage">
      {isAfter &&<AfterCom  />}
      {!isAfter &&<BeforeCom  />}
    </div>
  }
}