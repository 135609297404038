import React, { Component } from 'react';
import { inject, observer, Provider } from 'mobx-react';
import { configure, observable, runInAction, action } from 'mobx';
import { withRouter, RouteComponentProps, Route, Switch, Redirect } from 'react-router-dom';
import { AuthStore, DictStore } from '../src/stores';
import { eq } from '../src/utils/mix';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import moment from 'moment';
import { loadWidthStore } from './router';
import http from 'axios';
import intl from "react-intl-universal";

import './App.scss';
// import '../src/assets/fixAntdStyle.scss'
import LoginPage from '../src/pages/login';
import Layout from '../src/pages/layout';

import DevConsoleCom from 'src/pages/devConsole';
import ProTestDetailCom from 'src/pages/productTest/detail';
const DevConsole = loadWidthStore(DevConsoleCom);
const ProTestDetail = loadWidthStore(ProTestDetailCom);

interface Props {
  authStore?: AuthStore;
  dictStore?: DictStore;
}

moment.locale('zh-cn');


@inject('authStore','dictStore')
@(withRouter as any)
@observer
class App extends Component<Props & Partial<RouteComponentProps>>{

  unregisterCallback;
  @observable haslocale = false;
  clearHeart;


  constructor(props) {
    super(props);
    this.loadLocales();
  }

  componentDidMount() {
    // 监听路由，并做权限控制
    console.log('APP componentDidMount 2');
    const { history, authStore,dictStore } = this.props;
    this.unregisterCallback = history!.listen((location, action) => {
      console.log('location, action', location, action);
      // const { pathname } = location;
      // this.changeRoute(pathname, action);
    });

    const getDict = authStore!.getDict;
    const getDict2 = dictStore!.getDictFromServer;
    const getAlarmTree = dictStore!.getAlarmTreeFromServer;
    const isLoginPage = this.isLoginPage();

    let requests: any = [];

    if (!isLoginPage) {
      requests = [getDict(),getDict2()];
      Promise.all(requests);
    }

  }
  componentWillUnmount() {
    this.unregisterCallback();
    this.clearHeart && clearInterval(this.clearHeart);
  }

  loadLocales() {

    let currentLocale = 'zh-CN'

    const loadJSONLocale = http
      .get(`/locales/${currentLocale}.json`)
      .then(res => {
        console.log("App locale data", res.data);
        return intl.init({
          currentLocale,
          locales: {
            [currentLocale]: res.data
          }
        });
      })

    // const lang = currentLocale.replace('-', '_');
    // const loadPluginLocale = import(
    //   /* webpackInclude: /en_US|zh_CN/ */
    //   `antd/lib/locale-provider/${lang}.js`
    // );
    const promiseArr = [loadJSONLocale];

    Promise.all(promiseArr).then(res => {
      runInAction(() => {
        this.haslocale = true;
      });
    });
  }


  isPublicPage = () => {
    const { location } = this.props;
    const { pathname } = location!;

    const isPbulic = !pathname.startsWith('/private');
    return isPbulic;
  }


  isLoginPage = () => {
    const { location } = this.props;
    const { pathname } = location!;
    return eq(pathname, '/login') || eq(pathname, '/');
  }

  render() {

    const { authStore ,dictStore } = this.props;

    // 防止没有国际化
    if(!this.haslocale){
      return null;
    }

    // 防止没有权限的页面没有字典值
    if (!this.isLoginPage() && (!authStore!.dict || !dictStore!.dict)) {
      return null
    }

    return (
      <ConfigProvider locale={zhCN}>
        <div className="App" >
          <Switch>
            <Route path="/login" component={LoginPage} />
            <Route path="/devConsole" component={DevConsole} />
            <Route path="/productTestDetail" component={ProTestDetail} />
            {/* <Route path="/404" exact component={NotFound} /> */}
            <Redirect from="/" exact to="/login" />{/*注意redirect转向的地址要先定义好路由*/}
            {/* <Redirect from="/private" exact to="/login" /> */}

            <Route path="/private" component={Layout} />
            <Redirect to="/login" />
          </Switch>
        </div>
      </ConfigProvider>

    )
  }
}

export default App;
