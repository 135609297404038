import React from 'react';
import {observable, action, toJS} from 'mobx';
import { inject, observer } from 'mobx-react';
import { DeviceTreeStore } from '../../../../src/stores';
import './index.scss';

import {  MapAndVideoMode } from '../../../../src/types';
import {  requestFullScreen, intlGet } from '../../../../src/utils/mix';

import MyIcon from '../../../../src/components/myIcon';
import { PlayerList } from '../../../../src/components/flvPlayer';

interface VideoListProps {
  deviceTreeStore?: DeviceTreeStore,
}

@inject('deviceTreeStore')
@observer
export default class VideoListWrapper extends React.Component<VideoListProps>{

  @observable mode: MapAndVideoMode = 'video';
  playerList!: { wrappedInstance: PlayerList };

  @action
  clickControlBar = (type: Control) => {
    console.log('clickcontrolBar', type);
    const { deviceTreeStore } = this.props;
    const {changeVideoCount,
      changeFullScreenIndex,
    } = deviceTreeStore!;

    switch (type) {
      case '4':
      case '9':
        // 清空选择的通道，防止再次增加新的通道
        // deviceTreeStore!.changeSelectedChannel([]);
        const newVideoCount = Number(type);
        changeVideoCount(newVideoCount);
        changeFullScreenIndex(-1);   // 点击分屏按钮取消全屏状态
        break;

      case 'fullscreen':
        const playerList = document.querySelector('.devcConcolePage .videoListComponent')!;
        requestFullScreen(playerList);
        break;
    }
  }

  setPlayerList = (playerList) => {
    this.playerList = playerList;
  }

  render() {
    return (
      <div className="mapAndVideo">
        <div className="filter">
            <VideoControl onClick={this.clickControlBar} />
        </div>
        <div className="content">
          <MapAndVideo
            mode={this.mode}
            setPlayerList={this.setPlayerList}
          />
        </div>
      </div>
    )
  }
}

type Control = '4' | '9' | 'play' | 'stop' | 'fullscreen';
interface VideoControlProps {
  onClick: (type: Control) => void,
}
interface IconTitle {
  icon: string,
  title: string,
}
const screen_i18n = intlGet('screen');
@observer
export class VideoControl extends React.Component<VideoControlProps> {
  controls: Control[] = ['4', '9',  'fullscreen']; //'play', 'stop',
  iconMap:any = {
    4: { icon: 'iconsiping', title: '4 ' + '屏' },
    9: { icon: 'iconjiuping', title: '9 ' + '屏' },
    //play: { icon: 'iconquanbukaishi', title: intlGet('play_all') },
    //stop: { icon: 'iconquanbuzanting', title: intlGet('stop_all') },
    fullscreen: { icon: 'iconquanping-mianxing', title: intlGet('full_sreen_all') },
  }
  render() {
    const { onClick } = this.props;
    return <div className="videoControl">
      {
        this.controls.map(x => {
          const iconTitle = this.iconMap[x];
          const { icon, title } = iconTitle;

          return <MyIcon key={x} icon={icon} title={title} onClick={onClick.bind(null, x)} />
        })
      }
    </div>
  }
}

interface MapAndVideoProps {
  deviceTreeStore?: DeviceTreeStore,
  mode: MapAndVideoMode,
  setPlayerList: (e) => void,
}

@inject('deviceTreeStore')
@observer
export class MapAndVideo extends React.Component<MapAndVideoProps>{

  constructor(props) {
    super(props);
  }

  render() {
    const { mode, deviceTreeStore, setPlayerList } = this.props;
    const srcArr = deviceTreeStore!.videoUrls;
    console.log('live player list=',toJS(deviceTreeStore!.videoUrls));
    const videoListCom = <PlayerList 
    autoplay={false}
    srcMap={srcArr} 
    count={deviceTreeStore!.videoCount} 
    ref={setPlayerList} />
    switch (mode) {
      case 'video':
        return videoListCom;
    }
  }
}
