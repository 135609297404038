/**
 * @desc 图片下载
 * @param imgsrc 
 * @param name 
 */
export const downloadIamge = (imgsrc, name?: string | number) => {//下载图片地址和图片名
  let image = new Image();
  // 解决跨域 Canvas 污染问题
  image.setAttribute("crossOrigin", "anonymous");
  image.onload = function () {
    let canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;
    let context = canvas.getContext("2d");
    context!.drawImage(image, 0, 0, image.width, image.height);
    let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
    let a = document.createElement("a"); // 生成一个a元素
    let event = new MouseEvent("click"); // 创建一个单击事件
    a.download = name && name.toString() || `photo_${Date.now()}`; // 设置图片名称
    a.href = url; // 将生成的URL设置为a.href属性
    a.dispatchEvent(event); // 触发a的单击事件
  };
  image.src = imgsrc;
}

export const downLoadFileByUrl = (url, filename = "temp") => {
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  console.log(`downLoadFileByUrl url ${url}`);
}

/**
    fileUrl: "http://jm-video-input.oss-cn-hangzhou.aliyuncs.com/vod/device/354311090651103/event/adas_20210108065916.264"
    replace /jm-video-input/vod/device/354311090651103/event/adas_20210108065916.264
    proxy /jm
    nginx  location ~ /jm-video-input {
            rewrite    /jm-[^\/]+\/(.+)$ /$1 break;
            proxy_pass http://jm-video-input.oss-cn-hangzhou.aliyuncs.com;
        }

    部标 http://47.111.140.110/jm/808_STORAGE/DOWNLOAD_FILE/3110906314020000000000/2021-01-08/8ab94960517e11eb90185573a2a7bd22/03_1610081002.264
 */

export const replaceMediaUrl = (url: string) => {
  const reg1 = /^http\:\/\/([^.]+).+\.com/;
  const reg2 = /^(http|https)\:\/\/[^/]+\/([^/]+)\//;
  if(reg1.test(url)){
    return url.replace(reg1, '/$1');
  }
  return url.replace(reg2, '/$2/');
}


