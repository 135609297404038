import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable, action, toJS, computed } from 'mobx';
import FlvPlayer, { getVideoSrc } from './player';
import { eq, Log } from 'src/utils/mix';
import { CommonStore } from 'src/stores';
import uuv1 from 'uuid/v1';

const log = new Log('flvPlayer playerList.tsx');

interface PlayerListProps {
  commonStore?: CommonStore,
  count: number,
  srcMap: { [index: number]: string },
  noLive?: boolean, //非实时视频
  videoActiveIndex?: number,
  fullScreenIndex?: number,
  changeVideoActiveIndex?: (index: undefined | number) => void,
  changeFullScreenIndex?: (index: number) => void,
  clickRightMemu?: (action: string, data: any) => void,
  autoplay?: boolean,
}

@inject('commonStore')
@observer
export class PlayerList extends React.Component<PlayerListProps>{

  rootClass = 'playList_' + uuv1();
  @observable stopTime = 0;

  componentDidMount() {

  }

  componentWillUnmount() {

  }

  @action
  stopAll = () => {
    this.stopTime = Date.now();
  }

  @action
  selectVideo = (i: number) => {
    const { changeVideoActiveIndex } = this.props;
    if (changeVideoActiveIndex) {
      changeVideoActiveIndex(i);
    }
  }

  @action
  onDoubleClick = (i: number) => {
    const { changeFullScreenIndex, fullScreenIndex = -1 } = this.props;
    if (changeFullScreenIndex) {
      let newIndex: number;
      if (fullScreenIndex === -1) {
        newIndex = i;
      } else {
        newIndex = -1;
      }
      log.data(`onDoubleClick i: ${i}, newIndex: ${newIndex}`);
      changeFullScreenIndex(newIndex);
    }
  }

  @action
  destoryVideo = (i: number, src: string) => {
    console.log('playerlist destory video');
  }

  onReplay = (i: number) => {

  }

  clickRightMenu = (action: string, data) => {
    const { clickRightMemu } = this.props;
    log.data('clickRightMenu key', action)
    // <Menu.Item key="1">清除所有视频</Menu.Item>
    //     <Menu.Item key="2">清除当前视频</Menu.Item>
    //     <Menu.Item key="3">停止所有播放</Menu.Item>
    //     <Menu.Item key="4">停止当前播放</Menu.Item>
    //     <Menu.Item key="5">全屏</Menu.Item>
    clickRightMemu && clickRightMemu(action, data);
  }

  render() {
    const { count, srcMap, videoActiveIndex, fullScreenIndex, autoplay,
      noLive = false, clickRightMemu } = this.props;
    const countArr = new Array(count).fill(1);
    console.log('playerLis fullScreenIndex', fullScreenIndex);

    return (

      <div className={`videoListComponent layout-${count} ${this.rootClass}`} >
        {countArr.map((x, i) => {
          const src = srcMap[i];
          const key = src + '_' + i;
          const active = eq(videoActiveIndex, i);
          const isFullScreen = eq(fullScreenIndex, i);

          return <PlayerWrapper
            noLive={noLive}
            key={key}
            // key={src}
            src={src}
            index={i}
            isActive={active}
            isFullScreen={isFullScreen}
            onClick={this.selectVideo}
            onDoubleClick={this.onDoubleClick}
            onDestory={this.destoryVideo}
            onReplay={this.onReplay}
            stopTime={this.stopTime}
            clickRightMenu={clickRightMemu}
            autoplay={autoplay}
            
          />
        })}
      </div>
    )
  }
}

interface PlayerWrapperProps {
  noLive?: boolean,
  index: number,
  isActive: boolean,
  isFullScreen: boolean,
  src: string,
  onClick: (e) => void,
  onDoubleClick: (i: number) => void,
  // onContextMenu: (i: number, e) => void,
  onDestory: (i: number, src: string) => void,
  onReplay: (i: number) => void;
  stopTime: number,
  clickRightMenu ?: (action: 'string', data: any) => void;
  autoplay?: boolean,
}

@observer
class PlayerWrapper extends Component<PlayerWrapperProps> {

  render() {
    const { index, isActive, src, onDestory, onClick, onReplay, stopTime,autoplay,
      isFullScreen, onDoubleClick, clickRightMenu, noLive = false,
    } = this.props;
    const className = 'num num-' + (index + 1);
    const activeClass = isActive ? 'active' : '';
    const isFullScreenClass = isFullScreen ? 'fullScreen' : '';

    return <div className={`playerWrapper ${className} ${activeClass} ${isFullScreenClass}`}
      onClick={onClick.bind(this, index)}
      onDoubleClick={onDoubleClick.bind(this, index)}
    // onContextMenu={onContextMenu.bind(this, index)}
    >
      <FlvPlayer
        isLive={noLive ? false : true}
        loop={true}
        src={src}
        index={index}
        onDestory={onDestory}
        onReplay={onReplay}
        stopTime={stopTime}
        clickRightMenu={clickRightMenu}
        autoplay={autoplay}
      />
    </div>
  }
}