import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider, } from 'mobx-react';
import { BrowserRouter as Router } from "react-router-dom";
import ErrorBoundary from '../src/components/errorBoundary';
import { SingletonStore } from './stores';
import zhCN from 'antd/es/locale/zh_CN';
import { ConfigProvider } from 'antd';

ReactDOM.render(
  <ConfigProvider locale={zhCN}>
    <Provider {...new SingletonStore()}>
      <Router>
        {/* <ErrorBoundary > */}
          <App />
        {/* </ErrorBoundary> */}
      </Router>
    </Provider>
  </ConfigProvider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
