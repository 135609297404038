import { message } from 'antd';
import React from 'react';
import MyIcon from 'src/components/myIcon';
import { intlGet, eq, getBdmStr, getLoadStr, copy, isInteger } from '.';

export const transDecimalToBinaryNumArr = (binary: number = 0, start = 0, end?: number) => {
  const num2 = binary.toString(2);
  let num2Rev = num2.split('').reverse().slice(start, end);
  const res: number[] = [];
  for (let i = 0, len = num2Rev.length; i < len; i++) {
    const item = num2Rev[i];
    if (item === '1') {
      res.push(i)
    }
  }
  console.log("transDecimalToBinaryNumArr", JSON.stringify({ binary, start, end, res }));
  return res;
}

export const getChannelStr = (chLos: number[], channelNos: number[], resType: string | number[] = 'jsx', showchannelNos = true) => {
  console.log('getChannelStr', 'chLos=', chLos, 'channelNos=', channelNos);
  chLos = chLos || [];
  channelNos = channelNos.sort((a, b) => a - b);

  const abnStr = intlGet('No data');
  const norStr = intlGet('normal');
  if (resType === 'str') {
    const channelArr = channelNos.map(x => {
      const isAbnor = chLos.includes(x);
      return `${intlGet('channel')}${x}[${isAbnor ? abnStr : norStr}]`
    });

    return channelArr.join(',')
  } else if (resType === 'detail') {
    const channelArr = channelNos.map((x, i) => {
      const isAbnor = chLos.includes(x);
      return <span key={x}>
        {intlGet('channel')}{x}
        <span className={isAbnor ? 'abnor' : 'normal'} >
          [{isAbnor ? abnStr : norStr}]
        </span>
        {i !== (channelNos.length - 1) ? ', ' : null}
      </span>
    });
    return <div className='videoChannel'>
      {channelArr}
    </div>;
  } else {
    const cNos = showchannelNos ? resType : channelNos
    return <div className='videoChannelCom'>
      {(cNos as number[]).map(x => {
        const isDisabled = chLos.includes(x);
        const isDisabledClass = isDisabled ? 'disabled' : '';
        const icon = isDisabled
          ? <MyIcon icon="iconshipin-4" />
          : <MyIcon icon="icon16shipin-2" />
        return <div className={`ch ${isDisabledClass}`} key={x} >
          {icon}
          <span className='no'>{x}</span>
        </div>
      })}
    </div>
  }
}

/**
 * @desc 获取存储信息  固定4个存储器
 * @param storageExc 
 * @param storageBkExc 
 * @param resType  jsx str detail
 * @returns 
 */
 export const getStoreStr = (storageExc: number[], resType = 'jsx') => {

  storageExc = storageExc || [];
  const disabled1 = storageExc.includes(0);
  const disabled1Class = disabled1 ? 'disabled' : '';
  const disabled2 = storageExc.includes(2)
  const disabled2class = disabled2 ? 'disabled' : '';
  const disabled3 = storageExc.includes(1)
  const disabled3class = disabled3 ? 'disabled' : '';
  const disabled4 = storageExc.includes(12)
  const disabled4class = disabled4 ? 'disabled' : '';
  const abnStr = intlGet('No data');
  const norStr = intlGet('normal');
  if (resType === 'str') {
    const store1 = `硬盘[${disabled1 ? abnStr : norStr}]`
    const store2 = `防护存储器[${disabled2 ? abnStr : norStr}]`
    const store3 = `SD卡1[${disabled3 ? abnStr : norStr}]`
    const store4 = `SD卡2[${disabled4 ? abnStr : norStr}]`
    const stores = [store1, store2, store3, store4];
    return stores.join(',')
  } else if (resType === 'detail') {
    return <div className='mapStorage'>
      <span>硬盘<span className={disabled1 ? 'abnor' : 'normal'}>
        [{disabled1 ? abnStr : norStr}]
      </span>
      </span>,
      <span>防护存储器<span className={disabled2 ? 'abnor' : 'normal'}>
        [{disabled2 ? abnStr : norStr}]
      </span>
      </span>,
      <span>SD卡1<span className={disabled3 ? 'abnor' : 'normal'}>
        [{disabled3 ? abnStr : norStr}]
      </span>
      </span>,
      <span>SD卡2<span className={disabled4 ? 'abnor' : 'normal'}>
        [{disabled4 ? abnStr : norStr}]
      </span>
      </span>
    </div>
  } else {
    return <div className='mapStorageCom'>
      <MyIcon icon="iconyunyingpanico" className={disabled1Class} title={'硬盘'} />
      <MyIcon icon="icongutaiyingpan" className={disabled2class} title={'防护存储器'} />
      <MyIcon icon="iconSSDqia" className={disabled3class} title={'SD卡1'} />
      <MyIcon icon="iconSSDqia" className={disabled4class} title={'SD卡2'} />
    </div>
  }
}

const gpsBitMap = {
  18: 'GPS卫星',
  19: '北斗卫星',
  20: 'GLONASS卫星',
  21: 'Galileo卫星',
}

export const getGps = (gpsBit: number) => {
  const gpsStrArr: string[] = [];
  Object.keys(gpsBitMap).sort().forEach(x => {
    if((gpsBit >> Number(x)) & 1){
      const str = gpsBitMap[x];
      gpsStrArr.push(str);
    }
  })
  return gpsStrArr.join('/');
}

export const getVehDialogState = (vehInfo: GpsDialogInter) => {
  const { acc, networkIntensity: dbm, gnssNum, online = 1,
    stateBit,
  } = vehInfo;
  let accStr = online === 1
    ? (eq(acc, 1) ? intlGet('acc_type_open') : intlGet('acc_type_close'))
    : intlGet('acc_type_close');
  let dbmStr = getBdmStr(dbm);
  let gpsStr = getGps(stateBit);

  const stateArr = [
    [intlGet('acc_status'), accStr],
    [intlGet('network'), `${dbmStr}(${dbm}dbm)`],
    [intlGet('satellite_num'), gnssNum],
    [intlGet('location'), gpsStr],
  ]
  const statuStr = stateArr.map(x => `${x[0]}: ${x[1]}`).join(', ');
  return statuStr;
}

export const converHMS2 = (sec: number) => {
  const second = sec % 60;
  const hour = Math.floor(sec / 60 / 60);
  const minute = Math.floor((sec - second - hour * 60 * 60) / 60);

  // console.log('converHMS', sec, `${hour}:${minute}:${second}`)
  if (hour) {
    // return { h: hour, m: minute, s: second }
    return <React.Fragment>
      <span className="number">{hour}</span>
      <span className="unit">{intlGet('hour')}</span>
      <span className="number">{minute}</span>
      <span className="unit">{intlGet('minute2')}</span>
      <span className="number">{second}</span>
      <span className="unit">{intlGet('second')}</span>
    </React.Fragment>
  } else if (minute) {
    return <React.Fragment>
      <span className="number">{minute}</span>
      <span className="unit">{intlGet('minute2')}</span>
      <span className="number">{second}</span>
      <span className="unit">{intlGet('second')}</span>
    </React.Fragment>
  } else {
    return <React.Fragment>
      <span className="number">{second}</span>
      <span className="unit">{intlGet('second')}</span>
    </React.Fragment>
  }
}

interface WarnData {
  label: string,
  norMsg: string,
  abnorMsg: string,
  state: number,  // -1 初始化 0 异常 1 正常
}

const warnMap3: { [key: string]: WarnData } = {
  // 4: {
  //   label: 'GNSS模块',
  //   norMsg: '',
  //   abnorMsg: '故障',
  //   state: -1,
  // },
  5: {
    label: 'GNSS天线',
    norMsg: '正常',
    abnorMsg: '未接或被剪',
    state: -1,
  },
  6: {
    label: 'GNSS模块',
    norMsg: '',
    abnorMsg: '短路',
    state: -1,
  },
  7: {
    label: '设备主电源',
    norMsg: '正常',
    abnorMsg: '欠压',
    state: -1,
  },
  8: {
    label: '设备主电源',
    norMsg: '',
    abnorMsg: '掉电',
    state: -1,
  },
  // 9: {
  //   label: '设备LCD或显示器',
  //   norMsg: '',
  //   abnorMsg: '故障',
  //   state: -1,
  // },
  // 10: {
  //   label: 'TTS模块',
  //   norMsg: '',
  //   abnorMsg: '故障',
  //   state: -1,
  // },
  // 12: {
  //   label: '道路运输证IC卡模块',
  //   norMsg: '',
  //   abnorMsg: '故障',
  //   state: -1,
  // },
  // 24: {
  //   label: '车辆VSS',
  //   norMsg: '',
  //   abnorMsg: '故障',
  //   state: -1,
  // },
  // 25: {
  //   label: '车辆油量',
  //   norMsg: '',
  //   abnorMsg: '异常报警',
  //   state: -1,
  // },
}

const getGNSS = (warnBit: number,) => {
  const num2 = warnBit.toString(2);
  const num2Rev = num2.split('').reverse().join('');

  const newWarnMap = copy(warnMap3);
  const warnKeys = [5, 6];
  let hasWarn = false;
  for (let i = 0; i < warnKeys.length; i++) {
    const key = warnKeys[i]
    if (num2Rev[key] === '1') {
      const warnData = newWarnMap[key];
      const { abnorMsg } = warnData;
      hasWarn = true;
      return <span className={'abnor yellow'} >
        {abnorMsg}
      </span>
    }
  }
  if (!hasWarn) {
    const key = warnKeys[0]
    const warnData = newWarnMap[key];
    const { norMsg } = warnData;
    return <span className={'normal'} >
      {norMsg}
    </span>
  }
}

const getMainPower = (warnBit: number) => {
  const num2 = warnBit.toString(2);
  const num2Rev = num2.split('').reverse().join('');

  const newWarnMap = copy(warnMap3);
  const warnKeys = [7, 8];
  let hasWarn = false;
  for (let i = 0; i < warnKeys.length; i++) {
    const key = warnKeys[i]
    if (num2Rev[key] === '1') {
      const warnData = newWarnMap[key];
      const { abnorMsg } = warnData;
      hasWarn = true;
      return <span className={'abnor yellow'} >
        {abnorMsg}
      </span>
    }
  }
  if (!hasWarn) {
    const key = warnKeys[0]
    const warnData = newWarnMap[key];
    const { norMsg } = warnData;
    return <span className={'normal'} >
      {norMsg}
    </span>
  }
}

export const getVehState3 = (
  gpsData: GpsDialogInter,
  channelNos: number[] = [],
  resType: 'str' | 'detail' = 'str',
) => {
  const warnBit = gpsData.warnBit || 0;

  const strArr: any[] = [];
  const gnssJsx = getGNSS(warnBit);
  const mainPowerJsx = getMainPower(warnBit);
  const jsxArr: any[] = [
    ['天线', gnssJsx],                  // 
    ['电源', mainPowerJsx],            // 
  ];

  if (gpsData.storageWarnBit !== -1) {
    const storageExc = transDecimalToBinaryNumArr(gpsData.storageWarnBit, 0, 13);
    const storeStr = getStoreStr(storageExc, 'str');
    const storeJsx = getStoreStr(storageExc, 'detail');

    strArr.unshift([intlGet('storage'), storeStr]);
    jsxArr.unshift([intlGet('storage'), storeJsx]);
  }

  if (gpsData.videoLostWarnBit !== -1) {
    const chLos = transDecimalToBinaryNumArr(gpsData.videoLostWarnBit).map(x => x + 1);
    const channelStr = getChannelStr(chLos, channelNos, 'str');
    const channelJsx = getChannelStr(chLos, channelNos, 'detail');

    strArr.unshift([intlGet('video_channel'), channelStr]);
    jsxArr.unshift([intlGet('video_channel'), channelJsx]);
  }


  const statuStr = strArr.map(x => `${x[0]}: ${x[1]}`).join(', ');
  const statuJsx = jsxArr.map(x => {
    const [label, content] = x;
    return <div className="item" key={label}>
      <div className="label">{label}: </div>
      <div>{content}</div>
    </div>
  });
  const resMap = {
    str: statuStr,
    detail: statuJsx,
  }
  const res = resMap[resType];
  // console.log('getVehState3', JSON.stringify({gpsData, channelNos}));
  console.log('getVehState3', gpsData.gpsTime, res);
  return res;
}

const earlyWarningMap = {
  0: "紧急报警",
  1: "超速报警",
  2: "疲劳驾驶报警",
  3: "危险驾驶行为报警",
  4: "GNSS 模块发生故障报警",
  5: "GNSS 天线未接或被剪断报警",
  7: "设备主电源欠压报警",
  8: "设备主电源掉电报警",
  11: "摄像头故障报警",
  13: "超速预警",
  14: "疲劳驾驶预警",
  29: "碰撞预警",
}

export const getEarlyWarning = (warnNum: number) => {
  const warnArr = transDecimalToBinaryNumArr(warnNum);
  const warnStrArr: string[] = [];
  warnArr.forEach(x => {
    const str = earlyWarningMap[x];
    if (str) {
      warnStrArr.push(str)
    }
  })
  return warnStrArr;
}



export const validatorNumber = (canBeEmpy?: boolean) => {
  return (rule, value: string, callback) => {
    console.log('validatorNumber', value);

    if((canBeEmpy && (value === undefined || value === '')) || isInteger(value)){
      callback()
    }else{
      callback('err');
      message.warn(intlGet('请输入数字!'))
    }
  }
} 