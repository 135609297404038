import {observable, action, runInAction, computed} from 'mobx';
import {copy, mountainToLine} from 'src/utils/mix';
import { http } from 'src/utils/http';

export type ParseFunc = (d: any) => {
  page: number,
  pageSize: number,
  total: number,
  data: any;
}
export interface ParseObj{
  parseFunc: ParseFunc,
  key: string,
}

export class ReqTableData<Search, Data>{

  url: string;
  defaultSearch: Search;
  search: Search;
  parseObj: ParseObj

  @observable dataMap = observable.map<string | number, Data>(); 
  @observable loading: boolean = false;

  @observable page = 0;
  @observable pageSize = 0;
  @observable orderColumn = '';
  @observable orderDir = '';
  @observable total = 0;

  constructor(url: string, defaultSearch: Search, parseObj: ParseObj, pageSize = 20){
    this.url = url;
    this.defaultSearch = copy(defaultSearch);
    this.search = copy(defaultSearch);
    this.parseObj = parseObj;
    this.pageSize = pageSize;
  }

  @computed
  get dataArr(){
    return Array.from(this.dataMap.values());
  }

  changeSearch = (search: Search) => {
    this.search = search;
  }

  @action
  chagnePage = (page: number) => {
    this.page = page;
  }

  @action
  changePageSize = (pageSize: number) => {
    this.pageSize = pageSize;
    this.page = 0;
  }

  @action
  changeOrderDir = (dir: string) => {
    this.orderDir = dir;
  }

  @action
  changeOrderColumn = (orderColumn: string) => {
    this.orderColumn = orderColumn;
  }

  @action
  tableChange = (pagination, filters, sorter, extra) => {
    
    let dir = '';

    if (sorter && sorter.order) {
      if (sorter.order == 'descend') {
        dir = 'desc';
      } else {
        dir = 'asc';
      };
      this.orderColumn = mountainToLine(sorter.field);
      this.orderDir = dir;
    }
    this.page = pagination.current - 1;
    if (pagination && pagination.pageSize && pagination.pageSize !== this.pageSize) {
      this.page = 0;
    }
    this.pageSize = pagination.pageSize;
  }

  getSearParmas = () => {
    return {
      ...this.search,
      page: this.page,
      pageSize: this.pageSize,
      orderColumn: this.orderColumn,
      orderDir: this.orderDir,
    }
  }

  @action
  getData = (method: string, reqParams: any) => {
    this.loading = true;
    const {key, parseFunc} = this.parseObj;
    return http[method](this.url, reqParams).then(res => {

      runInAction(() => {
        const {page, pageSize, total, data} = parseFunc(res);
        this.dataMap.clear();
        data.forEach(x => {
          this.dataMap.set(x[key], x);
        });
        this.page = page;
        this.total = total;

        this.loading = false;
      })

    }, err => {
      console.error('err', err);
      runInAction(() => {
        this.loading = false
      })
    })
  }
}