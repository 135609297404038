export * from './mix';
export * from './http';
export * from './download';
export * from './log';
export * from './common';

/*************************************  ts 高级类型用法  ****************************************************/
/*
interface Person{
  name: string;
  age: number;
}

let person: Person = {
  name: 'Jarid',
  age: 35
}

// keyof 索引类型查询操作符
// 对于任何类型 T， keyof T的结果为 T上已知的公共属性名的联合

let personProps: keyof Person;  // 'name | 'age' 索引类型查询

// T[K] 索引访问操作符
function getProperty<T, K extends keyof T>(o: T, name: K): T[K]{  
  return o[name];
}

let name = getProperty(person, 'name');
let age = getProperty(person, 'age');
// let unknown = getProperty(person, 'unknown'); /o  

interface Map<T>{
  [key: string]: T;
}
let keys: keyof Map<number>; //string
let value: Map<number>['foo']; // number

// 映射类型
type Keys = 'option1' | 'option2'; 
type Flags  = {[k in Keys]: boolean};
type Partial<T> = {[P in keyof T]?: T[P]}
type Readonly<T> = {
  readonly [P in keyof T]: T[P]
}
type Pick<T, K extends keyof T> = {
  [p in K]: T[p] 
}
type Record<K extends string, T> = {
  [P in K]: T;
}

declare const FormLayouts: ["horizontal", "inline", "vertical"];
export declare type FormLayout = (typeof FormLayouts)[number];
*/
